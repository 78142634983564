/* eslint-disable no-underscore-dangle */
import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, styled, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';

import { getPieces } from 'src/services/pieces';
import { getAllUnderAssemblies } from 'src/services/underAssembly';
import { Assemblies, CataloguePieceName } from '../../../../components/3d/Assemblies';
import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import { ButtonRow, LowerCaseButton, RoundedButton } from '../../../../components/common';
import { CustomDataGrid } from '../../../../components/DataGrid';
import DeleteWarningModal from '../../../../components/DeleteWarningModal';
import useLoadDataList from '../../../../hook/useLoadDataList';
import useToggle from '../../../../hook/useToggle';
import { getAllAssemblies } from '../../../../services/assemblies';
import {
  Catalog,
  CatalogAssembly,
  createCatalog,
  deleteCatalog,
  findAllCatalogs,
  PieceCatalog,
  UnderAssemblyCatalog,
  updateCatalog,
} from '../../../../services/catalog';
import { handleMutation } from '../../../../utils/api.utils';

const Line = styled(Box)({
  padding: '16px',
  display: 'flex',
  widows: '100%',
  justifyContent: 'center',
  border: '1px solid #F2F4F7',
});

type DataRow = {
  id: string;
  name: string;
  countCatalogAssembly: number;
  countUser: number;
  catalogAssembly: CatalogAssembly[];
  pieceCatalog: PieceCatalog[];
  underAssemblyCatalog: UnderAssemblyCatalog[];
};

const formatRow = (row: Catalog): DataRow => ({
  id: row.id,
  name: row.name,
  countCatalogAssembly: row.catalogAssembly.length,
  countUser: row._count.userCatalog,
  catalogAssembly: row.catalogAssembly,
  pieceCatalog: row.pieceCatalog,
  underAssemblyCatalog: row.underAssemblyCatalog,
});

const Catalogs = () => {
  const { data: catalogs, onRefresh } = useLoadDataList(findAllCatalogs);
  const { data: assemblies } = useLoadDataList(getAllAssemblies);
  const { data: pieces } = useLoadDataList(() => getPieces(undefined));
  const { data: underAssemblies } = useLoadDataList(getAllUnderAssemblies);
  const [rows, setRows] = useState<DataRow[]>([]);
  const [catalogToDelete, setCatalogToDelete] = useState<string>();
  const [selectedAssemblies, setSelectedAssemblies] = useState<number[]>([]);
  const [selectedPieces, setSelectedPieces] = useState<number[]>([]);
  const [selectedUnderAssemblies, setSelectedUnderAssemblies] = useState<number[]>([]);
  const [assemblyNames, setAssemblyNames] = useState<CataloguePieceName>({});
  const [pieceNames, setPieceNames] = useState<CataloguePieceName>({});
  const [underAssemblyNames, setUnderAssemblyNames] = useState<CataloguePieceName>({});
  const [selectedCatalog, setSelectedCatalog] = useState<string>();
  const [selectCatalogName, setSelectCatalogName] = useState('');
  const [edit, toggleEdit] = useToggle();

  const reset = () => {
    toggleEdit();
    setSelectCatalogName('');
    setSelectedCatalog(undefined);
    setSelectedAssemblies([]);
    setSelectedPieces([]);
    setSelectedUnderAssemblies([]);
  };

  const columns: GridColDef<DataRow>[] = [
    {
      field: 'name',
      headerName: 'nom',
      flex: 1,
    },
    {
      field: 'countCatalogAssembly',
      headerName: 'Monuments dans le catalogue',
      flex: 1,
    },
    {
      field: 'countUser',
      headerName: 'Utilisateurs associés au catalogue',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              toggleEdit();
              setSelectedAssemblies(
                params.row.catalogAssembly
                  .filter((catalog) => catalog.isDisplayed)
                  .map((catalog) => catalog.assemblyId),
              );
              setSelectedPieces(
                params.row.pieceCatalog
                  .filter((catalog) => catalog.isDisplayed)
                  .map((catalog) => catalog.pieceId),
              );
              setSelectedUnderAssemblies(
                params.row.underAssemblyCatalog
                  .filter((catalog) => catalog.isDisplayed)
                  .map((catalog) => catalog.underAssemblyId),
              );
              setAssemblyNames(
                params.row.catalogAssembly.reduce(
                  (acc, catalogAssembly) => ({
                    ...acc,
                    [catalogAssembly.assemblyId]: catalogAssembly.name,
                  }),
                  {} as CataloguePieceName,
                ),
              );
              setPieceNames(
                params.row.pieceCatalog.reduce(
                  (acc, pieceCatalog) => ({
                    ...acc,
                    [pieceCatalog.pieceId]: pieceCatalog.name,
                  }),
                  {} as CataloguePieceName,
                ),
              );
              setUnderAssemblyNames(
                params.row.underAssemblyCatalog.reduce(
                  (acc, underAssemblyCatalog) => ({
                    ...acc,
                    [underAssemblyCatalog.underAssemblyId]: underAssemblyCatalog.name,
                  }),
                  {} as CataloguePieceName,
                ),
              );
              setSelectCatalogName(params.row.name);
              setSelectedCatalog(params.row.id);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setCatalogToDelete(params.row.id);
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => setRows(catalogs.map((row) => formatRow(row))), [catalogs]);

  const handleDelete = () => {
    if (catalogToDelete) handleMutation(deleteCatalog, catalogToDelete, onRefresh);
  };

  const handleSubmit = useCallback(() => {
    if (selectedCatalog)
      handleMutation(
        (datas) => updateCatalog(datas, selectedCatalog),
        {
          catalogAssemblies: assemblies
            .filter((assembly) => {
              if (assemblyNames[assembly.id] || selectedAssemblies.includes(assembly.id))
                return true;
              return false;
            })
            .map((assembly) => {
              const isDisplayed = selectedAssemblies.includes(assembly.id);
              return { assemblyId: assembly.id, name: assemblyNames[assembly.id], isDisplayed };
            }),
          pieceCatalog: pieces
            .filter((piece) => {
              if (pieceNames[piece.id] || selectedPieces.includes(piece.id)) return true;
              return false;
            })
            .map((piece) => {
              const isDisplayed = selectedPieces.includes(piece.id);
              return { pieceId: piece.id, name: pieceNames[piece.id], isDisplayed };
            }),
          underAssemblyCatalog: underAssemblies
            .filter((underAssembly) => {
              if (
                underAssemblyNames[underAssembly.id] ||
                selectedUnderAssemblies.includes(underAssembly.id)
              )
                return true;
              return false;
            })
            .map((underAssembly) => {
              const isDisplayed = selectedUnderAssemblies.includes(underAssembly.id);
              return {
                underAssemblyId: underAssembly.id,
                name: underAssemblyNames[underAssembly.id],
                isDisplayed,
              };
            }),
          name: selectCatalogName,
        },
        () => {
          onRefresh();
          toggleEdit();
        },
      );
    else
      handleMutation(
        createCatalog,
        {
          catalogAssemblies: assemblies.map((assembly) => {
            const isDisplayed = selectedAssemblies.includes(assembly.id);
            return { assemblyId: assembly.id, name: assemblyNames[assembly.id], isDisplayed };
          }),
          pieceCatalog: pieces.map((piece) => {
            const isDisplayed = selectedAssemblies.includes(piece.id);
            return { pieceId: piece.id, name: pieceNames[piece.id], isDisplayed };
          }),
          underAssemblyCatalog: underAssemblies.map((underAssembly) => {
            const isDisplayed = selectedUnderAssemblies.includes(underAssembly.id);
            return {
              underAssemblyId: underAssembly.id,
              name: underAssemblyNames[underAssembly.id],
              isDisplayed,
            };
          }),
          name: selectCatalogName,
        },
        () => {
          onRefresh();
          toggleEdit();
        },
      );
  }, [
    selectedCatalog,
    assemblies,
    pieces,
    underAssemblies,
    selectCatalogName,
    assemblyNames,
    selectedAssemblies,
    pieceNames,
    selectedPieces,
    underAssemblyNames,
    selectedUnderAssemblies,
    onRefresh,
    toggleEdit,
  ]);

  return (
    <>
      <ButtonRow padding="16px">
        <RoundedButton
          startIcon={<Add color="primary" />}
          color="primary"
          variant="outlined"
          onClick={reset}
        >
          Créer un catalogue
        </RoundedButton>
      </ButtonRow>
      <CustomDataGrid rows={rows} columns={columns} />
      <DeleteWarningModal
        header="SUPPRIMER LE CATALOGUE ?"
        onClose={() => {
          setCatalogToDelete(undefined);
        }}
        open={!!catalogToDelete}
        handleDelete={handleDelete}
      />
      <CenteredModal open={edit} handleClose={reset} sx={{ width: '90%' }}>
        <ModalTitle>
          <Typography variant="h5">CATALOGUE</Typography>
        </ModalTitle>
        <Line>
          <TextField
            label="Nom du catalogue"
            value={selectCatalogName}
            onChange={(event) => setSelectCatalogName(event.target.value)}
          />
        </Line>
        <Assemblies
          assemblies={assemblies}
          onClick={(id: number) => {
            if (selectedAssemblies.includes(id))
              setSelectedAssemblies((ids) => ids.filter((it) => it !== id));
            else setSelectedAssemblies((ids) => [...ids, id]);
          }}
          selected={selectedAssemblies}
          assemblyNames={assemblyNames}
          setAssemblyNames={setAssemblyNames}
          pieceNames={pieceNames}
          setPieceNames={setPieceNames}
          underAssemblyNames={underAssemblyNames}
          setUnderAssemblyNames={setUnderAssemblyNames}
          pieces={pieces}
          selectedPieces={selectedPieces}
          setSelectedPieces={setSelectedPieces}
          underAssemblies={underAssemblies}
          selectedUnderAssemblies={selectedUnderAssemblies}
          setSelectedUnderAssemblies={setSelectedUnderAssemblies}
        />
        <Line>
          <LowerCaseButton variant="contained" onClick={handleSubmit}>
            Sauvegarder
          </LowerCaseButton>
        </Line>
      </CenteredModal>
    </>
  );
};

export default Catalogs;
