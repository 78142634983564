import { z } from 'zod';

export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === 'too_small') {
    if (issue.type === 'string') {
      if (issue.minimum === 1) {
        return { message: 'Champ obligatoire' };
      }
      return { message: `Le champ doit contenir au moins ${issue.minimum} caractères` };
    }

    if (issue.type === 'array' && issue.minimum === 1) {
      return { message: 'Champ obligatoire' };
    }

    if (issue.type === 'number') {
      return { message: `Le nombre doit être supérieur ou égal à ${issue.minimum}` };
    }
  }

  if (issue.code === 'invalid_type') {
    if (issue.received === 'null') {
      return { message: 'Champ obligatoire' };
    }

    return { message: 'Champ non valide' };
  }

  if (issue.code === 'invalid_string') {
    return { message: 'Champ non valide' };
  }

  if (issue.code === 'invalid_date') {
    return { message: 'Date non valide' };
  }

  if (issue.code === 'custom') {
    if (issue.path[0] === 'phone') return { message: 'Numéro de téléphone incorrect' };
    if (issue.path[0] === 'email') return { message: 'Email incorrect' };
    // if (issue.path[0] === 'password')
    //   return {
    //     message: '',
    //   };

    return { message: 'Champ non valide' };
  }

  return { message: ctx.defaultError };
};
