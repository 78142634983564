/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Button, ButtonGroup, styled, Typography } from '@mui/material';
import { Edges, OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { Fragment, SetStateAction, useState } from 'react';
import { fabricationFiltersAdmin } from 'src/components/3d/Assemblies';
import { ButtonRow } from 'src/components/common';
import { categoriesLabels, CategoryType } from 'src/constants/category';
import { useEnv } from 'src/context/EnvProvider';
import useFilters from 'src/hook/useFilter';
import { Buckets } from 'src/services/file';
import { createPiece } from 'src/services/pieces';
import { isAccesory } from 'src/utils/configurator.utils';
import { upload3dPiece, uploadImage } from 'src/utils/file.utils';

type PieceImageProps = {
  pieceUrl: string;
  name: string | undefined;
  fileName: string | undefined;
  reset: () => void;
  isTimeToSave: boolean;
  setIsTimeToSave: (isTimeToSave: boolean) => void;
  category: CategoryType;
  fabricationFilterState: string[];
  blockedAxis: string[];
  polishing: { x: string; y: string; z: string };
  sawing: { x: string; y: string; z: string };
  extra: {
    french: string;
    import: string;
  };
  details: {
    french: { linear: PieceDetails; m2: PieceDetails };
    import: { linear: PieceDetails; m2: PieceDetails };
  };
};

const castPieceDetails = (pieceDetails: PieceDetails) => {
  return {
    faceX: Number.parseFloat(pieceDetails.faceX),
    faceX2: Number.parseFloat(pieceDetails.faceX2),
    faceY: Number.parseFloat(pieceDetails.faceY),
    faceY2: Number.parseFloat(pieceDetails.faceY2),
    faceZ: Number.parseFloat(pieceDetails.faceZ),
    faceZ2: Number.parseFloat(pieceDetails.faceZ2),
  };
};
const PieceImage = ({
  pieceUrl,
  name,
  fileName,
  reset,
  isTimeToSave,
  setIsTimeToSave,
  category,
  fabricationFilterState,
  blockedAxis,
  polishing,
  sawing,
  details,
  extra,
}: PieceImageProps) => {
  const gltf = useGLTF(pieceUrl);

  const pieces =
    gltf.scene.children[0].children.length > 0
      ? gltf.scene.children[0].children
      : gltf.scene.children;

  const gl = useThree((state) => state.gl);

  const savePiece = () => {
    gl.domElement.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], 'image.png');

        const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
        if (name && fileName && pictureName) {
          await createPiece(
            name,
            fileName,
            pictureName,
            category,
            fabricationFilterState,
            blockedAxis,
            {
              x: Number.parseFloat(polishing.x),
              y: Number.parseFloat(polishing.y),
              z: Number.parseFloat(polishing.z),
            },
            {
              x: Number.parseFloat(sawing.x),
              y: Number.parseFloat(sawing.y),
              z: Number.parseFloat(sawing.z),
            },
            {
              french: {
                extra: Number.parseFloat(extra.french),
                linear: castPieceDetails(details.french.linear),
                m2: castPieceDetails(details.french.m2),
              },
              import: {
                extra: Number.parseFloat(extra.import),
                linear: castPieceDetails(details.import.linear),
                m2: castPieceDetails(details.import.m2),
              },
            },
          );
          reset();
        }
      }
    });
  };

  if (isTimeToSave) {
    savePiece();
    setIsTimeToSave(false);
  }

  return (
    <>
      <OrbitControls />

      <ambientLight />
      <pointLight position={[5, 2, 5]} intensity={0.5} />
      <pointLight position={[-5, 2, 5]} intensity={0.5} />
      <pointLight position={[0, 2, 5]} intensity={0.5} />
      <pointLight position={[0, 2, -5]} intensity={0.5} />

      {pieces.map((piece: any, index: number) => {
        return (
          <Fragment key={index}>
            <mesh key={index} geometry={piece.geometry} position={piece.position}>
              <>
                <Edges />
                <meshStandardMaterial />
              </>
            </mesh>
          </Fragment>
        );
      })}
    </>
  );
};

export type PieceDetails = {
  faceX: string;
  faceX2: string;
  faceY: string;
  faceY2: string;
  faceZ: string;
  faceZ2: string;
};

type AxisInput = {
  x: string;
  y: string;
  z: string;
};

const detailsInformations = [
  {
    key: 'french',
    label: 'Pour les granits français: ',
  },
  {
    key: 'import',
    label: 'Pour les granits import: ',
  },
] as const;

const detailsInputs = {
  line1: [
    {
      key: 'faceX',
      label: 'X',
    },
    {
      key: 'faceY',
      label: 'Y',
    },
    {
      key: 'faceZ',
      label: 'Z',
    },
  ],
  line2: [
    {
      key: 'faceX2',
      label: 'X',
    },
    {
      key: 'faceY2',
      label: 'Y',
    },
    {
      key: 'faceZ2',
      label: 'Z',
    },
  ],
} as const;

const modes = [
  {
    key: 'linear',
    label: 'Mètre linéaire: ',
  },
  {
    key: 'm2',
    label: 'Mètre carré: ',
  },
] as const;

export const ImportComponent = () => {
  const { buckets } = useEnv();

  const [category, setCategory] = useState<CategoryType>('ANY');
  const [fileName, setFileName] = useState<string>();
  const [blockedAxis, setBlockedAxis] = useState<string[]>([]);
  const [name, setName] = useState<string>();
  const [isTimeToSave, setIsTimeToSave] = useState<boolean>(false);
  const [fabricationFiltersComponent, fabricationFilterState] = useFilters(
    fabricationFiltersAdmin,
    [],
  );
  const [details, setDetails] = useState<{
    french: { linear: PieceDetails; m2: PieceDetails };
    import: { linear: PieceDetails; m2: PieceDetails };
  }>({
    french: {
      linear: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
      m2: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
    },
    import: {
      linear: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
      m2: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
    },
  });
  const [polishing, setPolishing] = useState<AxisInput>({ x: '1', y: '1', z: '1' });
  const [sawing, setSawing] = useState<AxisInput>({ x: '2', y: '2', z: '2' });
  const [extra, setExtra] = useState<{ french: string; import: string }>({
    french: '0',
    import: '0',
  });

  const reset = () => {
    setFileName(undefined);
    setName(undefined);
  };

  const changeDetails = (
    country: 'french' | 'import',
    mode: 'linear' | 'm2',
    key: keyof PieceDetails,
    value: string,
  ) => {
    setDetails({
      ...details,
      [country]: {
        ...details[country],
        [mode]: { ...details[country][mode], [key]: value },
      },
    });
  };

  return (
    <>
      <Input
        value={name}
        type="text"
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Select
        name="sens"
        id="sens"
        onChange={(e) => {
          setCategory(e.target.value as SetStateAction<CategoryType>);
        }}
      >
        {categoriesLabels.map((el) => {
          return (
            <option selected={category === el.key} value={el.key}>
              {el.key === 'ANY' ? 'Autre pièces' : null}
              {el.key === 'ANY_ACCESSORY' ? 'Autre accesoires' : null}
              {el.key !== 'ANY_ACCESSORY' && el.key !== 'ANY' ? el.label : null}
            </option>
          );
        })}
      </Select>
      <>
        <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
          Modifiable sur:
        </Typography>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{ width: '100%' }}
        >
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => {
              if (blockedAxis.includes('x')) {
                setBlockedAxis(blockedAxis.filter((el) => el !== 'x'));
              } else {
                setBlockedAxis([...blockedAxis, 'x']);
              }
            }}
            variant={blockedAxis.includes('x') ? 'outlined' : 'contained'}
          >
            X
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => {
              if (blockedAxis.includes('y')) {
                setBlockedAxis(blockedAxis.filter((el) => el !== 'y'));
              } else {
                setBlockedAxis([...blockedAxis, 'y']);
              }
            }}
            variant={blockedAxis.includes('y') ? 'outlined' : 'contained'}
          >
            Y
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => {
              if (blockedAxis.includes('z')) {
                setBlockedAxis(blockedAxis.filter((el) => el !== 'z'));
              } else {
                setBlockedAxis([...blockedAxis, 'z']);
              }
            }}
            variant={blockedAxis.includes('z') ? 'outlined' : 'contained'}
          >
            z
          </Button>
        </ButtonGroup>

        <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
          Polissage:
        </Typography>
        <Row
          style={{
            justifyContent: 'space-between',
            gap: '12px',
            marginBottom: '12px',
          }}
        >
          <Row style={{ gap: 8 }}>
            <Label>X</Label>
            <Input
              value={polishing.x}
              onChange={(e) => setPolishing({ ...polishing, x: e.target.value })}
            />
          </Row>
          <Row style={{ gap: 8 }}>
            <Label>Z</Label>
            <Row>
              <Input
                value={polishing.z}
                onChange={(e) => setPolishing({ ...polishing, z: e.target.value })}
              />
            </Row>
          </Row>
          <Row style={{ gap: 8 }}>
            <Label>Y</Label>
            <Row>
              <Input
                value={polishing.y}
                onChange={(e) => setPolishing({ ...polishing, y: e.target.value })}
              />
            </Row>
          </Row>
        </Row>
        <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
          Sciage:
        </Typography>
        <Row
          style={{
            justifyContent: 'space-between',
            gap: '12px',
            marginBottom: '12px',
          }}
        >
          <Row style={{ gap: 8 }}>
            <Label>X</Label>
            <Input value={sawing.x} onChange={(e) => setSawing({ ...sawing, x: e.target.value })} />
          </Row>
          <Row style={{ gap: 8 }}>
            <Label>Z</Label>
            <Row>
              <Input
                value={sawing.z}
                onChange={(e) => setSawing({ ...sawing, z: e.target.value })}
              />
            </Row>
          </Row>
          <Row style={{ gap: 8 }}>
            <Label>Y</Label>
            <Row>
              <Input
                value={sawing.y}
                onChange={(e) => setSawing({ ...sawing, y: e.target.value })}
              />
            </Row>
          </Row>
        </Row>

        {detailsInformations.map((detailsInformation) => {
          return (
            <Container>
              <Typography variant="h1" style={{ fontSize: '18px' }}>
                {detailsInformation.label}
              </Typography>
              <Row style={{ gap: 8, width: '100%' }}>
                <Label sx={{ width: 'fit-content' }}>Plus value</Label>
                <Input
                  sx={{ flex: 1 }}
                  value={extra[detailsInformation.key]}
                  onChange={(e) => setExtra({ ...extra, [detailsInformation.key]: e.target.value })}
                />
              </Row>

              {modes.map((mode) => {
                return (
                  <>
                    <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
                      {mode.label}
                    </Typography>

                    <Row>
                      {detailsInputs.line1.map((input) => {
                        return (
                          <Row style={{ gap: 8 }}>
                            <Label>{input.label}</Label>
                            <Input
                              sx={{ flex: 1 }}
                              value={details[detailsInformation.key][mode.key][input.key]}
                              onChange={(e) =>
                                changeDetails(
                                  detailsInformation.key,
                                  mode.key,
                                  input.key,
                                  e.target.value,
                                )
                              }
                            />
                          </Row>
                        );
                      })}
                    </Row>
                    <Row>
                      {detailsInputs.line2.map((input) => {
                        return (
                          <Row style={{ gap: 8 }}>
                            <Label>{input.label}</Label>
                            <Input
                              sx={{ flex: 1 }}
                              value={details[detailsInformation.key][mode.key][input.key]}
                              onChange={(e) =>
                                changeDetails(
                                  detailsInformation.key,
                                  mode.key,
                                  input.key,
                                  e.target.value,
                                )
                              }
                            />
                          </Row>
                        );
                      })}
                    </Row>
                  </>
                );
              })}
            </Container>
          );
        })}
      </>

      <ButtonRow>{fabricationFiltersComponent}</ButtonRow>

      <Input
        type="file"
        onChange={async (e: any) => {
          if (e.target.files && e.target.files[0]) {
            const pieceFileName = await upload3dPiece(e.target.files[0]);
            setFileName(pieceFileName ?? undefined);
          }
        }}
      />

      {fileName ? (
        <>
          <Canvas
            style={{
              width: '300px',
              height: '300px',
              border: '1px solid black',
            }}
            camera={{
              position: [1.5, 1, 4],
              fov: 40,
            }}
            dpr={[1, 2]}
            gl={{ preserveDrawingBuffer: true }}
            raycaster={{
              params: {
                Points: { threshold: 0.01 },
                Line: { threshold: 0.01 },
              },
            }}
          >
            <PieceImage
              pieceUrl={`${buckets?.BUCKET_3D_PIECES ?? ''}/${fileName}`}
              name={name}
              fileName={fileName}
              reset={() => reset()}
              setIsTimeToSave={setIsTimeToSave}
              isTimeToSave={isTimeToSave}
              category={category}
              fabricationFilterState={fabricationFilterState}
              blockedAxis={blockedAxis}
              polishing={polishing}
              sawing={sawing}
              details={details}
              extra={extra}
            />
          </Canvas>

          <ButtonContainer>
            <StyledButton variant="contained" onClick={() => setIsTimeToSave(true)}>
              <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'capitalize' }}>
                Save piece
              </Typography>
            </StyledButton>
          </ButtonContainer>
        </>
      ) : null}
    </>
  );
};

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '12px',

  width: '190px',
  height: '40px',
});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginBottom: '12px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '12px',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
  marginBottom: '10px',
});
