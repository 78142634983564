import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailIcon from '@mui/icons-material/Mail';
import { Box, IconButton, Link, styled, Typography } from '@mui/material';
import { useRef } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEnv } from '../context/EnvProvider';
import { useUser } from '../context/UserProvider';
import useHandleClickOutside from '../hook/useHandleClickOutside';
import useToggle from '../hook/useToggle';
import paths from '../navigation/paths';
import { formatAddress } from '../utils/address.utils';
import HelpIcon from './icons/HelpIcon';
import HomeIcon from './icons/HomeIcon';
import LocationIcon from './icons/LocationIcon';
import LogoutIcon from './icons/LogoutIcon';
import PhoneIcon from './icons/PhoneIcon';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 100px',
  height: '100px',
  gap: '20px',
  backgroundColor: 'white',
  borderBottom: '1px solid #F2F4F7',
  width: '100%',
});

const Logo = styled('img')({
  height: '60%',
  objectFit: 'contain',
});

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  color: theme.palette.primary.main,
  height: '100%',
}));

const Title = styled(Box)({
  display: 'flex',
});

const Profile = styled('img')({
  height: '60%',
  objectFit: 'cover',
});

const Help = styled(Box)(({ theme: muiTheme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: muiTheme.palette.primary.dark,
  height: '100%',
  gap: '10px',
  padding: '15px',
  width: '220px',
  cursor: 'pointer',
  color: 'white',
}));

const DropDown = styled(Box)(({ theme: muiTheme }) => ({
  position: 'absolute',
  top: '99px',
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: muiTheme.palette.primary.dark,
  gap: '10px',
  padding: '15px',
  width: '220px',
  borderRadius: '0px 0px 10px 10px',
  zIndex: 100,
  color: 'white',
}));

const NavigateHome = styled(Link)(({ theme: muiTheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: muiTheme.palette.primary.main,
  height: '100%',
  gap: '10px',
  padding: '0 30px',
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'none',
}));
const HeaderNav = () => {
  const [showDropDown, toggleDropDown] = useToggle();
  const navigate = useNavigate();
  const { buckets } = useEnv();
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useHandleClickOutside(ref, toggleDropDown, buttonRef);

  const { user } = useUser();

  const location = useLocation();

  const isHome = location.pathname.split('/').reverse()[0] === 'home';

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/${paths.connexion.root}`);
  };

  return (
    <Container>
      <Wrapper>
        {!isHome && (
          <NavigateHome href={`/${paths.home.root}`}>
            <HomeIcon color="primary" />
            Accueil
          </NavigateHome>
        )}
        <Logo src="/logo_paysage.svg" sx={{ margin: isHome ? '0 40px' : 0 }} />
      </Wrapper>
      <Wrapper>
        <Box component="div">
          <Title>
            <LocationIcon />
            <Typography variant="h3">ADRESSE DE LIVRAISON</Typography>
          </Title>
          <Typography variant="body2" marginLeft="20px">
            {user && formatAddress(user.company.deliveryAddress)}
          </Typography>
        </Box>
        <Box component="div">
          <Title>
            <LocationIcon />
            <Typography variant="h3">ADRESSE DE FACTURATION</Typography>
          </Title>
          <Typography variant="body2" marginLeft="20px">
            {user && formatAddress(user.company.billingAddress)}
          </Typography>
        </Box>
        <Box component="div">
          <Title>
            <PhoneIcon />
            <Typography variant="h3">TÉLÉPHONE</Typography>
          </Title>
          <Typography variant="body2" marginLeft="20px">
            {formatPhoneNumber(user?.company?.phone ?? '')}
          </Typography>
        </Box>
      </Wrapper>
      <Wrapper>
        <Profile
          src={`${buckets?.BUCKET_PROFILE_PICTURES || ''}/${user?.profilePicture ?? 'default'}`}
        />
        <IconButton onClick={handleLogout} sx={{ color: 'white' }}>
          <LogoutIcon fontSize="large" />
        </IconButton>
        <Help onClick={toggleDropDown} ref={buttonRef}>
          <HelpIcon color="inherit" fontSize="large" />
          <Typography marginLeft="10px" variant="h4">
            Besoin d&apos;aide ?
          </Typography>
          <ArrowForwardIosIcon color="inherit" fontSize="large" />
        </Help>
        {showDropDown && (
          <DropDown ref={ref}>
            <Typography variant="body2">
              Assistance VisioGranits par mail ou par téléphone.
            </Typography>
            <Wrapper sx={{ color: 'white' }}>
              <PhoneIcon color="inherit" />
              <Typography variant="body2">
                {formatPhoneNumber(user?.leaderGranitCompany?.assistancePhoneNumber ?? '')}
              </Typography>
            </Wrapper>
            <Wrapper sx={{ color: 'white' }}>
              <MailIcon color="inherit" />
              <Typography variant="body2">visiogranits@gmail.com</Typography>
            </Wrapper>
          </DropDown>
        )}
      </Wrapper>
    </Container>
  );
};

export default HeaderNav;
