import { zodResolver } from '@hookform/resolvers/zod';
import { countryToAlpha2 } from 'country-to-iso';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import { useUser } from '../../../../context/UserProvider';
import useToggle from '../../../../hook/useToggle';
import { updateUser } from '../../../../services/user';
import { COUNTRIES_LABEL, emptyAddress, schemaAddress } from '../../../../utils/address.utils';
import { handleMutation } from '../../../../utils/api.utils';
import { areSameObject } from '../../../../utils/object.utils';

const schemaUserForm = z.object({
  reason: z.string().trim().min(1),
  name: z.string(),
  email: z.string().refine((val) => isEmail(val)),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  phone: z.string().refine((val) => isValidPhoneNumber(val)),
  deliveryAddress: schemaAddress,
  billingAddress: schemaAddress,
});

type SchemaUserForm = z.infer<typeof schemaUserForm>;

const emptyUser: SchemaUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  reason: '',
  name: '',
  phone: '',
  deliveryAddress: emptyAddress,
  billingAddress: emptyAddress,
};

const useGeneral = () => {
  const [edit, toggleEdit, setEdit] = useToggle();
  const [billingAddress, setBillingAddress] = useState(false);
  const [editPassword, toggleEditPassword] = useToggle();

  const { user, onRefresh } = useUser();

  const defaultValues = useMemo(
    () =>
      user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            reason: user.company.reason,
            name: user.company.name,
            phone: user.company.phone,
            deliveryAddress: {
              ...user.company.deliveryAddress,
              country: COUNTRIES_LABEL[user.company.deliveryAddress.country] ?? '',
            },
            billingAddress: {
              ...user.company.billingAddress,
              country: COUNTRIES_LABEL[user.company.billingAddress.country] ?? '',
            },
            profilePicture: user.profilePicture,
          }
        : emptyUser,
    [user],
  );

  const methods = useForm<SchemaUserForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaUserForm),
  });

  const submit: SubmitHandler<SchemaUserForm> = (data) => {
    handleMutation(
      (datas) => updateUser(datas, user?.id ?? ''),
      {
        ...data,
        deliveryAddress: {
          ...data.deliveryAddress,
          country: countryToAlpha2(data.deliveryAddress.country) || '',
        },
        billingAddress: billingAddress
          ? { ...data.billingAddress, country: countryToAlpha2(data.billingAddress.country) || '' }
          : {
              ...data.deliveryAddress,
              country: countryToAlpha2(data.deliveryAddress.country) || '',
            },
      },
      () => {
        onRefresh();
        toggleEdit();
        toast.success('Informations mises à jour avec succès');
      },
    );
  };

  useEffect(() => {
    methods.reset(defaultValues);
    setBillingAddress(
      !user || !areSameObject(user.company.billingAddress, user.company.deliveryAddress),
    );
  }, [defaultValues, methods, user]);

  return {
    methods,
    submit,
    edit,
    toggleEdit,
    billingAddress,
    setBillingAddress,
    editPassword,
    toggleEditPassword,
    user,
    onRefresh,
    setEdit,
  };
};

export default useGeneral;
