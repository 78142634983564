/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
import { Button, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useLoadData from 'src/hook/useLoadData';
import {
  getAdditionalsPriceOnCustomStele,
  updateAdditionalsPriceOnCustomStele,
} from 'src/services/customStele';

export type PieceDetails = {
  faceX: string;
  faceX2: string;
  faceY: string;
  faceY2: string;
  faceZ: string;
  faceZ2: string;
};

type AxisInput = {
  x: string;
  y: string;
  z: string;
};

const detailsInformations = [
  {
    key: 'french',
    label: 'Pour les granits français: ',
  },
] as const;

const detailsInputs = {
  line1: [
    {
      key: 'faceX',
      label: 'X',
    },
    {
      key: 'faceY',
      label: 'Y',
    },
    {
      key: 'faceZ',
      label: 'Z',
    },
  ],
  line2: [
    {
      key: 'faceX2',
      label: 'X',
    },
    {
      key: 'faceY2',
      label: 'Y',
    },
    {
      key: 'faceZ2',
      label: 'Z',
    },
  ],
} as const;

const modes = [
  {
    key: 'linear',
    label: 'Mètre linéaire: ',
  },
  {
    key: 'm2',
    label: 'Mètre carré: ',
  },
] as const;

export const Steles = () => {
  const nbrBase = 3;
  const nbrHead = 10;

  const [headNumber, setHeadNumber] = useState<number>(0);
  const [bodyNumber, setBodyNumber] = useState<number>(0);

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const { data, refreshing } = useLoadData(
    () => getAdditionalsPriceOnCustomStele(headNumber, bodyNumber),
    [headNumber, bodyNumber],
  );

  const [polishing, setPolishing] = useState<AxisInput>({ x: '1', y: '1', z: '1' });
  const [sawing, setSawing] = useState<AxisInput>({ x: '2', y: '2', z: '2' });
  const [extra, setExtra] = useState<{ french: string }>({
    french: '0',
  });
  const [details, setDetails] = useState<{
    french: { linear: PieceDetails; m2: PieceDetails };
  }>({
    french: {
      linear: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
      m2: {
        faceX: '0',
        faceX2: '0',
        faceY: '0',
        faceY2: '0',
        faceZ: '0',
        faceZ2: '0',
      },
    },
  });

  useEffect(() => {
    setPolishing({
      x: data?.polishingX.toString() || '1',
      y: data?.polishingY.toString() || '1',
      z: data?.polishingZ.toString() || '1',
    });
    setSawing({
      x: data?.sawingX.toString() || '2',
      y: data?.sawingY.toString() || '2',
      z: data?.sawingZ.toString() || '2',
    });
    setExtra({
      french: data?.frenchExtra.toString() || '0',
    });
    setDetails({
      french: {
        linear: {
          faceX: data?.frenchLinearDetails?.faceX.toString() || '0',
          faceX2: data?.frenchLinearDetails?.faceX2.toString() || '0',
          faceY: data?.frenchLinearDetails?.faceY.toString() || '0',
          faceY2: data?.frenchLinearDetails?.faceY2.toString() || '0',
          faceZ: data?.frenchLinearDetails?.faceZ.toString() || '0',
          faceZ2: data?.frenchLinearDetails?.faceZ2.toString() || '0',
        },
        m2: {
          faceX: data?.frenchM2Details?.faceX.toString() || '0',
          faceX2: data?.frenchM2Details?.faceX2.toString() || '0',
          faceY: data?.frenchM2Details?.faceY.toString() || '0',
          faceY2: data?.frenchM2Details?.faceY2.toString() || '0',
          faceZ: data?.frenchM2Details?.faceZ.toString() || '0',
          faceZ2: data?.frenchM2Details?.faceZ2.toString() || '0',
        },
      },
    });
  }, [data]);

  const changeDetails = (
    country: 'french',
    mode: 'linear' | 'm2',
    key: keyof PieceDetails,
    value: string,
  ) => {
    setDetails({
      ...details,
      [country]: {
        ...details[country],
        [mode]: { ...details[country][mode], [key]: value },
      },
    });
  };

  const handleUpdateAdditionalsPrice = async () => {
    if (data) {
      setIsSaveLoading(true);
      await updateAdditionalsPriceOnCustomStele({
        id: data.id,
        headNumber,
        bodyNumber,
        polishingX: Number.parseFloat(polishing.x),
        polishingY: Number.parseFloat(polishing.y),
        polishingZ: Number.parseFloat(polishing.z),
        sawingX: Number.parseFloat(sawing.x),
        sawingY: Number.parseFloat(sawing.y),
        sawingZ: Number.parseFloat(sawing.z),
        frenchExtra: Number.parseFloat(extra.french),
        frenchLinearDetails: {
          faceX: Number.parseFloat(details.french.linear.faceX),
          faceX2: Number.parseFloat(details.french.linear.faceX2),
          faceY: Number.parseFloat(details.french.linear.faceY),
          faceY2: Number.parseFloat(details.french.linear.faceY2),
          faceZ: Number.parseFloat(details.french.linear.faceZ),
          faceZ2: Number.parseFloat(details.french.linear.faceZ2),
        },
        frenchM2Details: {
          faceX: Number.parseFloat(details.french.m2.faceX),
          faceX2: Number.parseFloat(details.french.m2.faceX2),
          faceY: Number.parseFloat(details.french.m2.faceY),
          faceY2: Number.parseFloat(details.french.m2.faceY2),
          faceZ: Number.parseFloat(details.french.m2.faceZ),
          faceZ2: Number.parseFloat(details.french.m2.faceZ2),
        },
      });
      setIsSaveLoading(false);
    }
  };

  return (
    <Container>
      <Row
        style={{
          justifyContent: 'space-between',
          gap: '24px',
          width: '100%',
        }}
      >
        <Column style={{ width: '100%' }}>
          <div>
            <Label>Haut de la stèle</Label>
            <Select
              name="head"
              id="heads"
              value={headNumber}
              onChange={(e) => {
                setHeadNumber(Number.parseInt(e.target.value, 10));
              }}
            >
              {Array.from({ length: nbrHead })
                .map((_, i) => i + 1)
                .map((head, index) => {
                  return (
                    <option key={index} selected={index === headNumber} value={index}>
                      Haut {index + 1}
                    </option>
                  );
                })}
            </Select>
          </div>
        </Column>
        <Column style={{ width: '100%' }}>
          <div>
            <Label>Bas de la stèle</Label>
            <Select
              name="base"
              id="bases"
              onChange={(e) => {
                setBodyNumber(Number.parseInt(e.target.value, 10));
              }}
            >
              {Array.from({ length: nbrBase })
                .map((_, i) => i + 1)
                .map((base, index) => {
                  return (
                    <option key={index} selected={index === bodyNumber} value={index}>
                      Bas {index + 1}
                    </option>
                  );
                })}
            </Select>
          </div>
        </Column>
      </Row>
      {refreshing || !data ? null : (
        <>
          <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
            Polissage:
          </Typography>
          <Row
            style={{
              justifyContent: 'space-between',
              gap: '12px',
              marginBottom: '12px',
            }}
          >
            <Row style={{ gap: 8 }}>
              <Label>X</Label>
              <Input
                value={polishing.x}
                onChange={(e) => setPolishing({ ...polishing, x: e.target.value })}
              />
            </Row>
            <Row style={{ gap: 8 }}>
              <Label>Z</Label>
              <Row>
                <Input
                  value={polishing.z}
                  onChange={(e) => setPolishing({ ...polishing, z: e.target.value })}
                />
              </Row>
            </Row>
            <Row style={{ gap: 8 }}>
              <Label>Y</Label>
              <Row>
                <Input
                  value={polishing.y}
                  onChange={(e) => setPolishing({ ...polishing, y: e.target.value })}
                />
              </Row>
            </Row>
          </Row>
          <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
            Sciage:
          </Typography>
          <Row
            style={{
              justifyContent: 'space-between',
              gap: '12px',
              marginBottom: '12px',
            }}
          >
            <Row style={{ gap: 8 }}>
              <Label>X</Label>
              <Input
                value={sawing.x}
                onChange={(e) => setSawing({ ...sawing, x: e.target.value })}
              />
            </Row>
            <Row style={{ gap: 8 }}>
              <Label>Z</Label>
              <Row>
                <Input
                  value={sawing.z}
                  onChange={(e) => setSawing({ ...sawing, z: e.target.value })}
                />
              </Row>
            </Row>
            <Row style={{ gap: 8 }}>
              <Label>Y</Label>
              <Row>
                <Input
                  value={sawing.y}
                  onChange={(e) => setSawing({ ...sawing, y: e.target.value })}
                />
              </Row>
            </Row>
          </Row>

          {detailsInformations.map((detailsInformation) => {
            return (
              <Container>
                <Typography variant="h1" style={{ fontSize: '18px' }}>
                  {detailsInformation.label}
                </Typography>
                <Row style={{ gap: 8, width: '100%' }}>
                  <Label sx={{ width: 'fit-content' }}>Plus value</Label>
                  <Input
                    sx={{ flex: 1 }}
                    value={extra[detailsInformation.key]}
                    onChange={(e) =>
                      setExtra({ ...extra, [detailsInformation.key]: e.target.value })
                    }
                  />
                </Row>

                {modes.map((mode) => {
                  return (
                    <>
                      <Typography variant="body1" style={{ fontSize: '12px', width: '100%' }}>
                        {mode.label}
                      </Typography>

                      <Row>
                        {detailsInputs.line1.map((input) => {
                          return (
                            <Row style={{ gap: 8 }}>
                              <Label>{input.label}</Label>
                              <Input
                                sx={{ flex: 1 }}
                                value={details[detailsInformation.key][mode.key][input.key]}
                                onChange={(e) =>
                                  changeDetails(
                                    detailsInformation.key,
                                    mode.key,
                                    input.key,
                                    e.target.value,
                                  )
                                }
                              />
                            </Row>
                          );
                        })}
                      </Row>
                      <Row>
                        {detailsInputs.line2.map((input) => {
                          return (
                            <Row style={{ gap: 8 }}>
                              <Label>{input.label}</Label>
                              <Input
                                sx={{ flex: 1 }}
                                value={details[detailsInformation.key][mode.key][input.key]}
                                onChange={(e) =>
                                  changeDetails(
                                    detailsInformation.key,
                                    mode.key,
                                    input.key,
                                    e.target.value,
                                  )
                                }
                              />
                            </Row>
                          );
                        })}
                      </Row>
                    </>
                  );
                })}
              </Container>
            );
          })}
        </>
      )}
      <ButtonContainer>
        <StyledButton variant="contained" onClick={handleUpdateAdditionalsPrice}>
          <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'capitalize' }}>
            {isSaveLoading ? 'Chargement...' : 'Sauvegarder les modifications'}
          </Typography>
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  width: '100%',
  gap: '12px',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  justifyContent: 'space-between',
});

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '30px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '12px',

  width: '190px',
  height: '40px',
});
