import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues } from 'react-hook-form';

import { IAutocompleteRHF } from './FormComponents.type';

export const AutocompleteRHF = <
  T extends FieldValues,
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  control,
  AutocompleteProps,
  TextFieldProps,
  renderInput,
  options,
}: IAutocompleteRHF<T, Option, Multiple, DisableClearable, FreeSolo>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, ...field } }) => (
      <Autocomplete
        {...field}
        {...AutocompleteProps}
        disabled={TextFieldProps?.disabled}
        options={options}
        onChange={(e, data) => onChange(data)}
        renderInput={renderInput || ((params) => <TextField {...params} {...TextFieldProps} />)}
      />
    )}
  />
);
