/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { ButtonRow, FormLine, LowerCaseButton, StyledForm } from 'src/components/common';
import useFilters from 'src/hook/useFilter';
import { createAssembly } from 'src/services/assemblies';
import { Buckets } from 'src/services/file';
import { getAssemblyObject } from 'src/utils/configurator.utils';
import { uploadImage } from 'src/utils/file.utils';
import styled from 'styled-components';
import { WebGLRenderer } from 'three';

import { Upload } from '@mui/icons-material';
import { useEnv } from 'src/context/EnvProvider';
import { StateNames, States } from '../../../../Screens/Configurator';
import { fabricationFiltersAdmin, styleFilters, typeFilters } from '../../Assemblies';
import { CustomSteleType } from '../../Stele';

type AssemblyProps = {
  pieces: any[];
  customSteles: CustomSteleType[];
  activePieces: any[];
  customPlatings: any[];
  patterns: {
    [key: string]: {
      [key: string]: any;
    }[];
  };
  gl: WebGLRenderer | undefined;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  centerCamera: () => void;
};

export const Assembly = ({
  pieces,
  customSteles,
  activePieces,
  customPlatings,
  patterns,
  gl,
  setValue,
  setPieceIndex,
  centerCamera,
}: AssemblyProps) => {
  const { buckets } = useEnv();

  const [name, setName] = useState<string>('');
  const [customImage, setCustomImage] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // @ts-ignore
    if (fileInputRef.current) fileInputRef.current?.click();
  };

  const [typeFiltersComponent, typeFilterState] = useFilters(
    typeFilters.filter((el) => el.key !== 'ALL'),
    [],
    true,
  );
  const [fabricationFiltersComponent, fabricationFilterState] = useFilters(
    fabricationFiltersAdmin,
    [],
  );
  const [styleFiltersComponent, styleFilterState] = useFilters(styleFilters, []);

  const createAssemblySubmit = () => {
    setIsLoading(true);

    const assemblyObject = getAssemblyObject(
      pieces,
      customSteles,
      customPlatings,
      activePieces,
      patterns,
    );

    gl?.domElement.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], 'image.png');

        const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
        if (name && pictureName) {
          await createAssembly({
            ...assemblyObject,
            name,
            pictureUrl: pictureName,
            customImage,
            type: typeFilterState[0],
            fabrication: fabricationFilterState,
            style: styleFilterState,
            description,
          });
        }
      }
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    setValue('texture', true);
    setPieceIndex(-1);
    centerCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadProfilePicture = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
    return pictureName ?? '';
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files) {
      const fileKey = await uploadProfilePicture(e.target.files[0]);
      setCustomImage(fileKey);
    }
  };

  const handleRemoveImage = () => {
    setCustomImage('');
    // @ts-ignore
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  return (
    <Container>
      <label>
        Nom:
        <Input
          style={{ width: '100%' }}
          type="text"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name ?? ''}
        />
      </label>
      <label>
        Description:
        <TexteArea
          style={{ width: '100%' }}
          name="description"
          onChange={(e) => setDescription(e.target.value)}
          value={description ?? ''}
        />
      </label>

      <FilterSectionTitle>TYPE</FilterSectionTitle>
      <ButtonRow>{typeFiltersComponent}</ButtonRow>
      <FilterSectionTitle>FABRICATION</FilterSectionTitle>
      <ButtonRow>{fabricationFiltersComponent}</ButtonRow>
      <FilterSectionTitle>STYLE</FilterSectionTitle>
      <ButtonRow>{styleFiltersComponent}</ButtonRow>

      <UploadContainer onClick={handleUploadClick} textColor="black">
        {customImage ? (
          <Image src={`${buckets?.BUCKET_3D_PIECES || ''}/${customImage}`} alt="logo" />
        ) : (
          <>
            <Upload />
            <Typography variant="body2">Cliquer pour ajouter une image</Typography>
            <Typography variant="body2">PNG, JPG, PDF...</Typography>
          </>
        )}
        <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={onChange} />
      </UploadContainer>
      {customImage ? (
        <FormLine>
          <LowerCaseButton color="primary" onClick={handleRemoveImage}>
            Supprimer la photo
          </LowerCaseButton>
        </FormLine>
      ) : null}

      <Button textColor="white" onClick={() => createAssemblySubmit()}>
        {isLoading ? (
          <BeatLoader
            color="#fff"
            loading={isLoading}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          'Créer un assemblage'
        )}
      </Button>
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
});

type ButtonType = {
  textColor: string;
};

const Button = styled.button<ButtonType>`
  background-color: #4caf50;
  border: none;
  color: ${(props) => props.textColor};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
`;

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const TexteArea = styled('textarea')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const FilterSectionTitle = styled(Typography)({
  color: '#475467',
  fontWeight: 500,
});

const Image = styled('img')({
  width: '200px',
  height: '200px',
  borderRadius: 5,
  cursor: 'pointer',
  objectFit: 'cover',
});

const UploadContainer = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  height: 200,
  backgroundColor: '#F9FAFC',
});
