import { api } from './api';

export type UserLoginData = {
  email: string;
  password: string;
};

export type LoginData = {
  token: string;
  admin: boolean;
};

export const login = async (datas: UserLoginData) => {
  const { data } = await api.post<LoginData>(`/auth/login`, datas);
  return data;
};

export const verify = async () => {
  const { data } = await api.get<{ status: string }>('auth/verify');
  return data;
};

export const verifyAdmin = async () => {
  const { data } = await api.get<{ status: string }>('auth/admin/verify');
  return data;
};
