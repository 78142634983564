import { Box, FormControlLabel, styled, Typography } from '@mui/material';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormReset,
} from 'react-hook-form';
import { NumberFieldRHF } from 'src/components/forms/NumberInput';
import { ClientInformationsFormSchema } from 'src/hook/useConfiguratorNavigation';

import { ModalTitle } from '../../../components/CenteredModal';
import { FormLine, LowerCaseButton } from '../../../components/common';
import CheckboxRHF from '../../../components/forms/CheckboxRhf';
import { TextFieldRHF } from '../../../components/forms/TextFieldRhf';
import FileSettings from '../../../components/icons/FileSettingsIcon';
import { Quote } from '../../../services/quotes';
import muiTheme from '../../../themes/muiTheme';

interface IClientInformationForm {
  quote?: Quote;
  closeForm: () => void;
  control: Control<ClientInformationsFormSchema>;
  handleSubmit: UseFormHandleSubmit<ClientInformationsFormSchema>;
  errors: FieldErrors<ClientInformationsFormSchema>;
  reset: UseFormReset<ClientInformationsFormSchema>;
}

const StyledForm = styled('form')(({ theme }) => ({
  background: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '10px',
}));

const Body = styled(Box)({
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const ClientInformationsForm = ({
  quote,
  closeForm,
  control,
  handleSubmit,
  errors,
  reset,
}: IClientInformationForm) => {
  const onSubmitForm: SubmitHandler<ClientInformationsFormSchema> = () => {
    closeForm();
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
      <ModalTitle sx={quote ? { background: '#F7F0E7' } : {}}>
        <Typography
          sx={{
            color: quote ? muiTheme.palette.secondary.main : muiTheme.palette.primary.main,
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
          variant="h2"
          textAlign="left"
        >
          <FileSettings fontSize="large" />
          Paramètres du devis
        </Typography>
      </ModalTitle>
      <Body>
        <Typography>
          Les paramètres du devis sont modifiables à tout moment de la création du projet.
        </Typography>
        <FormLine>
          <TextFieldRHF
            control={control}
            name="clientFirstName"
            TextFieldProps={{
              label: 'Prénom',
              error: Boolean(errors.clientFirstName),
              helperText: errors.clientFirstName?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
          <TextFieldRHF
            control={control}
            name="clientLastName"
            TextFieldProps={{
              label: 'Nom',
              error: Boolean(errors.clientLastName),
              helperText: errors.clientLastName?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
        </FormLine>
        <FormLine>
          <TextFieldRHF
            control={control}
            name="clientAddress.address"
            TextFieldProps={{
              label: 'Adresse',
              error: Boolean(errors.clientAddress?.address),
              helperText: errors.clientAddress?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
          <TextFieldRHF
            control={control}
            name="clientAddress.addressDetails"
            TextFieldProps={{
              label: "Complément d'adresse",
              error: Boolean(errors.clientAddress?.addressDetails),
              helperText: errors.clientAddress?.addressDetails?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
        </FormLine>
        <FormLine>
          <TextFieldRHF
            control={control}
            name="clientAddress.city"
            TextFieldProps={{
              label: 'Ville',
              error: Boolean(errors.clientAddress?.city),
              helperText: errors.clientAddress?.city?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
          <TextFieldRHF
            control={control}
            name="clientAddress.zipCode"
            TextFieldProps={{
              label: 'Code postal',
              error: Boolean(errors.clientAddress?.zipCode),
              helperText: errors.clientAddress?.zipCode?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
        </FormLine>
        <div />
        <FormLine>
          <TextFieldRHF
            control={control}
            name="cemetary"
            TextFieldProps={{
              label: 'Nom du cimetière',
              error: Boolean(errors.cemetary),
              helperText: errors.cemetary?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
          <TextFieldRHF
            control={control}
            name="concessionName"
            TextFieldProps={{
              label: 'Numéro de concession',
              error: Boolean(errors.concessionName),
              helperText: errors.concessionName?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
        </FormLine>
        <FormLine>
          <Box component="div">
            <Box
              component="div"
              sx={{ display: 'flex', alignItems: 'center', color: '#D0D5DD', gap: '16px' }}
            >
              <NumberFieldRHF
                control={control}
                name="concessionHeight"
                TextFieldProps={{
                  label: 'Longueur de la concession',
                  error: Boolean(errors.concessionHeight),
                  helperText: errors.concessionHeight?.message,
                  color: quote ? 'secondary' : 'primary',
                }}
              />
              X
              <NumberFieldRHF
                control={control}
                name="concessionWidth"
                TextFieldProps={{
                  label: 'Largeur de la concession',
                  error: Boolean(errors.concessionWidth),
                  helperText: errors.concessionWidth?.message,
                  color: quote ? 'secondary' : 'primary',
                }}
              />
            </Box>
            {/* <FormControlLabel
              control={
                <CheckboxRHF
                  control={control}
                  name="useDimensions"
                  checkboxProps={{
                    color: quote ? 'secondary' : 'primary',
                  }}
                />
              }
              label="Utiliser pour pré-paramétrer la dimension hors-tout"
            /> */}
          </Box>
          <TextFieldRHF
            control={control}
            name="familyRef"
            TextFieldProps={{
              label: 'Référence famille',
              error: Boolean(errors.familyRef),
              helperText: errors.familyRef?.message,
              color: quote ? 'secondary' : 'primary',
            }}
          />
        </FormLine>
        <FormLine>
          <div />
          <LowerCaseButton
            color={quote ? 'secondary' : 'primary'}
            onClick={() => {
              closeForm();
              reset();
            }}
          >
            {quote ? 'Annuler' : 'Passer cette étape'}
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            type="submit"
            color={quote ? 'secondary' : 'primary'}
          >
            {quote ? 'Valider' : 'Commencer'}
          </LowerCaseButton>
        </FormLine>
      </Body>
    </StyledForm>
  );
};

export default ClientInformationsForm;
