/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from '@mui/icons-material/Check';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { MenuItem, Select as MuiSelect, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

type EngravingItemProps = {
  el: any;
  selectedPattern: number | undefined;
  elIndex: number;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  changeText: (
    pattern: {
      uuid: string;
      pieceUUID: string;
      orientation: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse';
    },
    key: 'text' | 'fontSize' | 'letterSpacing' | 'fontFamily' | 'align' | 'color',
    value: string,
  ) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setOrientation: (orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean }) => void;
  shouldUpdateTexture: React.MutableRefObject<{
    pieceUUID: string;
    orientation: string;
  } | null>;
};

export const EngravingItem = ({
  el,
  selectedPattern,
  elIndex,
  setSelectedPattern,
  changeText,
  patterns,
  setPatterns,
  orientation,
  setOrientation,
  shouldUpdateTexture,
}: EngravingItemProps) => {
  const [text, setText] = useState(el.text);
  const fonts = ['arial', 'times', 'england'];
  const aligns = ['left', 'center', 'right'];
  const colors = ['#ECB86B', '#FFFFFF', '#101828'];

  const handleChangeText = useCallback(
    (
      pattern: {
        uuid: string;
        pieceUUID: string;
        orientation: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse';
      },
      key: 'text' | 'fontSize' | 'letterSpacing' | 'fontFamily' | 'align' | 'color',
      value: string,
    ) => {
      shouldUpdateTexture.current = {
        pieceUUID: el.pieceUUID,
        orientation: `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
      };
      changeText(pattern, key, value);
    },
    [shouldUpdateTexture, changeText, orientation.key, el.pieceUUID],
  );

  useEffect(() => {
    if (text !== el.text && orientation.key !== 'free') {
      handleChangeText(
        {
          uuid: el.uuid,
          pieceUUID: el.pieceUUID,
          orientation: `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
        },
        'text',
        text,
      );
    }
  }, [text, el]);

  return (
    <PieceContainer
      isSelected={selectedPattern === elIndex}
      onClick={() => {
        if (orientation.key === 'free') {
          if (selectedPattern !== elIndex) {
            setOrientation({
              key: el.orientation,
              inverse: el.orientation.includes('inverse'),
            });
          }
          setSelectedPattern(selectedPattern === elIndex ? -1 : elIndex);
        } else {
          setSelectedPattern(elIndex);
        }
      }}
    >
      <Column
        style={{
          alignItems: 'flex-start',
          width: '45%',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography
            variant="body2"
            color="#344054"
            style={{ marginBottom: '4px', fontWeight: 600 }}
          >
            Texte {elIndex}
          </Typography>
          <TextArea
            disabled={orientation.key === 'free'}
            name="texte"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </div>

        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.5);"
          style={{ marginTop: '8px', marginBottom: '4px', fontWeight: 600, fontSize: 12 }}
        >
          Style
        </Typography>
        <MuiSelect
          style={{
            width: '100%',
            height: '45px',
            borderRadius: '8px',
            color: '#273135',
          }}
          disabled={orientation.key === 'free'}
          name="align"
          id="align"
          defaultValue={el.fontFamily}
          onClick={(e) => {
            if (selectedPattern === elIndex) e.stopPropagation();
          }}
          onChange={(e) => {
            handleChangeText(
              {
                uuid: el.uuid,
                pieceUUID: el.pieceUUID,
                orientation: el.orientation,
              },
              'fontFamily',
              e.target.value as string,
            );
          }}
        >
          {fonts.map((element) => {
            return (
              <MenuItem
                key={element}
                value={element}
                style={{ margin: 0, fontFamily: element }}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                {element === 'arial' ? 'Baton' : null}
                {element === 'times' ? 'Romaine' : null}
                {element === 'england' ? 'Anglaise' : null}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </Column>
      <Column
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '50%',
        }}
      >
        <div>
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.5);"
            style={{ marginBottom: '4px', fontWeight: 600, fontSize: 12 }}
          >
            Taille & approche ( cm )
          </Typography>
          <Row style={{ gap: 11 }}>
            <Row style={{ alignItems: 'center' }}>
              <FormatSizeIcon style={{ margin: 0, marginRight: 4, color: '#475467' }} />
              <Input
                disabled={orientation.key === 'free'}
                type="text"
                name="fontSize"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) =>
                  handleChangeText(
                    {
                      uuid: el.uuid,
                      pieceUUID: el.pieceUUID,
                      orientation: el.orientation,
                    },
                    'fontSize',
                    e.target.value,
                  )
                }
                value={el.fontSize}
              />
            </Row>
            <Row style={{ alignItems: 'center' }}>
              <DensityLargeIcon
                style={{ margin: 0, marginRight: 4, color: '#475467', transform: 'rotate(90deg)' }}
              />
              <Input
                disabled={orientation.key === 'free'}
                type="text"
                name="letterSpacing"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) =>
                  handleChangeText(
                    {
                      uuid: el.uuid,
                      pieceUUID: el.pieceUUID,
                      orientation: el.orientation,
                    },
                    'letterSpacing',
                    e.target.value,
                  )
                }
                value={el.letterSpacing}
              />
            </Row>
          </Row>
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.5);"
            style={{ marginTop: '10px', marginBottom: '4px', fontWeight: 600, fontSize: 12 }}
          >
            Alignement
          </Typography>
          <MuiSelect
            style={{
              width: '100%',
              height: '45px',
              borderRadius: '8px',
              color: '#273135',
            }}
            disabled={orientation.key === 'free'}
            name="align"
            id="align"
            defaultValue={el.align}
            onChange={(e) => {
              handleChangeText(
                {
                  uuid: el.uuid,
                  pieceUUID: el.pieceUUID,
                  orientation: el.orientation,
                },
                'align',
                e.target.value as string,
              );
            }}
          >
            {aligns.map((element) => {
              return (
                <MenuItem
                  key={element}
                  value={element}
                  style={{ margin: 0 }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {element === 'left' ? (
                    <FormatAlignLeftIcon style={{ margin: 0, marginRight: 4, color: '#475467' }} />
                  ) : null}
                  {element === 'center' ? (
                    <FormatAlignCenterIcon
                      style={{ margin: 0, marginRight: 4, color: '#475467' }}
                    />
                  ) : null}
                  {element === 'right' ? (
                    <FormatAlignRightIcon style={{ margin: 0, marginRight: 4, color: '#475467' }} />
                  ) : null}
                </MenuItem>
              );
            })}
          </MuiSelect>
        </div>
        <Row style={{ justifyContent: 'space-between', width: '100%' }}>
          {colors.map((element, elementIndex) => {
            return (
              <Square
                // eslint-disable-next-line react/no-array-index-key
                key={elementIndex}
                color={element}
                onClick={(e) => {
                  if (orientation.key !== 'free')
                    handleChangeText(
                      {
                        uuid: el.uuid,
                        pieceUUID: el.pieceUUID,
                        orientation: el.orientation,
                      },
                      'color',
                      element,
                    );
                  if (selectedPattern === elIndex) e.stopPropagation();
                }}
              >
                <CheckIcon
                  style={{
                    color: element === '#FFFFFF' ? 'black' : 'white',
                    opacity: el.color === element ? 1 : 0,
                    width: 13,
                  }}
                />
              </Square>
            );
          })}
          <BorderImage
            onClick={(e) => {
              const newPattensTmp = { ...patterns };

              newPattensTmp[el.pieceUUID][el.orientation].elements = patterns[el.pieceUUID][
                el.orientation
              ].elements.filter((element: any) => element.uuid !== el.uuid);
              setPatterns(newPattensTmp);
              e.stopPropagation();
            }}
          >
            <Image
              src="/icons/bin.svg"
              alt="bin"
              style={{
                height: 18,
                width: 20,
              }}
            />
          </BorderImage>
        </Row>
      </Column>
    </PieceContainer>
  );
};

const PieceContainer = styled('div')(({ isSelected }: { isSelected: boolean }) => ({
  position: 'relative',
  width: '100%',
  height: '250px',

  borderRadius: '10px',
  border: `1px solid ${isSelected ? '#BC9A67 ' : 'white'}`,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  backgroundSize: '140px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',

  cursor: 'pointer',
  padding: '12px',
}));

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Input = styled('input')({
  padding: '12px 12px',
  margin: '8px 0px',
  boxSizing: 'border-box',

  border: '1px solid #C2C9D1',
  width: '100%',
  maxWidth: '100px',

  height: '32px',
  borderRadius: '8px',

  '&:focus': {
    outline: 'none !important',
    border: '2px solid #548797',
  },
});

const TextArea = styled('textarea')({
  padding: '12px 12px',
  margin: '8px 0px',
  boxSizing: 'border-box',

  border: '1px solid #C2C9D1',
  width: '100%',
  height: '112px',

  borderRadius: '8px',
  resize: 'none',
  '&:focus': {
    outline: 'none !important',
    border: '2px solid #548797',
  },
});

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const Square = styled('div')(({ color }: { color: string }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  backgroundColor: color,
  borderRadius: 8,
  border: `1px solid ${color === '#FFFFFF' ? '#98A2B3' : color}`,
}));

const Image = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: 5,
  cursor: 'pointer',
});

const BorderImage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: 50,
  cursor: 'pointer',
  border: '1px solid #C46D5E',
});
