import { api } from './api';
import { Fabrication } from './assemblies';

export type Granit = {
  id: string;
  name: string;
  fabrication: Fabrication;
  image: string;
  volumePrice: number;
  sawingPrice: number;
  polishingPrice: number;
  purchaseVolumePrice: number;
  purchaseSawingPrice: number;
  purchasePolishingPrice: number;
  weight: number;
  maxWorkableLength: number;
  minWorkableThickness: number;
  minLength: number;
  maxLength: number;
  largeSize: number;
  largeExtra: number;
  eco: boolean;
  societies: {
    society: {
      name: string;
      id: string;
    };
  }[];
};

type CreateOrUpdateGranit = Omit<Granit, 'societies'> & { societiesId: string[] };

export const getGranits = async () => {
  const { data } = await api.get<
    (Granit & {
      reducedImage: string;
    })[]
  >(`granit`);

  return data;
};

export const createGranit = async (datas: Omit<CreateOrUpdateGranit, 'id'>) => {
  return api.post<void>(`/granit`, datas);
};

export const updateGranit = async (datas: CreateOrUpdateGranit) => {
  return api.patch<void>('/granit', datas);
};

export const deleteGranits = async (datas: string[]) => {
  return api.delete<void>('/granit', { params: { ids: datas } });
};
