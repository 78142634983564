import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoutIcon from 'src/components/icons/LogoutIcon';
import useLoadData from 'src/hook/useLoadData';
import paths from 'src/navigation/paths';
import { verify } from 'src/services/auth';

export const Maintenance = () => {
  const { data, refreshing } = useLoadData(verify);

  const adminAuth = false;
  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');
  const admin = localStorage.getItem('admin') === 'true';

  const { accessToken, expiry } = JSON.parse(auth ?? '{}') as {
    accessToken: string;
    expiry: number;
  };

  const [token, setToken] = useState<string | null>(accessToken);

  useEffect(() => {
    if (!adminAuth && admin) navigate(`/${paths.admin.root}`, { replace: true });
    if (adminAuth && !admin) navigate(`/${paths.home.root}`, { replace: true });

    if ((!data && !refreshing) || data?.status === 'INVALID') {
      localStorage.clear();
      setToken(null);
      navigate(`/${paths.connexion.root}`, { replace: true });
    }
  }, [refreshing, data, admin, navigate, adminAuth]);

  if (!token || expiry < Date.now()) {
    localStorage.clear();
    return <Navigate to={`/${paths.connexion.root}`} replace />;
  }

  if (data?.status === 'VALID') {
    return <Navigate to={`/${paths.home.root}`} />;
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/${paths.connexion.root}`);
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100vh',
        width: '100vw',
      }}
    >
      <h2>Visiogranits est actuellement en maintenance</h2>
      <IconButton onClick={handleLogout} sx={{ color: 'white' }}>
        <LogoutIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};
