import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUser } from '../../context/UserProvider';
import paths from '../../navigation/paths';

const RequireActivatedAccount = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.status === 'PENDING' && user.role !== 'ADMIN') navigate(paths.home.root);
  }, [navigate, user]);

  return <Outlet />;
};

export default RequireActivatedAccount;
