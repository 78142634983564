import { DependencyList, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../context/UserProvider';
import { handleApiError } from '../utils/api.utils';

function useLoadDataList<T>(
  loadFunction: () => Promise<T[]>,
  hookDependencies?: DependencyList,
  condition = true,
) {
  const [data, setData] = useState<T[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();
  const { homePath } = useUser();

  const loadData = async () => {
    try {
      if (condition) {
        const res = await loadFunction();
        setData(res);
        setRefreshing(false);
      } else {
        setData([]);
        setRefreshing(false);
      }
    } catch (error) {
      setData([]);
      handleApiError(error, () => navigate(homePath));
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  return { refreshing, onRefresh, data, loadData };
}

export default useLoadDataList;
