import { SvgIcon, SvgIconProps } from '@mui/material';

const HeadStoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 0.333344H7.33333C9.54 0.333344 10.6667 2.12668 10.6667 4.33334V11.44C9.25333 10.7533 8.04667 10.3333 6 10.3333C3.95333 10.3333 2.74667 10.7533 1.33333 11.44V4.33334C1.33333 2.12668 2.46 0.333344 4.66667 0.333344ZM0 13.6667V13.2067C1.77333 12.08 6.82 9.56001 12 13.1667V13.6667H0ZM8.12952 3.66668L8.66667 4.20382L6.53714 6.33334L8.66667 8.46287L8.12952 9.00001L6 6.87049L3.87048 9.00001L3.33333 8.46287L5.46286 6.33334L3.33333 4.20382L3.87048 3.66668L6 5.7962L8.12952 3.66668Z"
      />
    </SvgIcon>
  );
};

export default HeadStoneIcon;
