/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, ButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useEnv } from 'src/context/EnvProvider';
import useLoadDataList from 'src/hook/useLoadDataList';
import { Assembly } from 'src/services/assemblies';
import {
  createLinkedPieces,
  deleteLinkedPiecesOnAssembly,
  deleteLinkedPiecesOnUnderAssembly,
  getLinkedPiecesOnAssembly,
  getLinkedPiecesOnUnderAssembly,
} from 'src/services/configurator';
import styled from 'styled-components';

type LinkedPiecesProps = {
  assembly: Assembly | null;
  pieceIndex: number;
  activePieces: any[];
};
export const LinkedPieces = ({ assembly, pieceIndex, activePieces }: LinkedPiecesProps) => {
  const { buckets } = useEnv();

  const underAssemblyId = activePieces[pieceIndex]?.underAssembly
    ? activePieces[pieceIndex]?.underAssembly.id
    : -1;

  const { data: linkedPiecesOnAssembly, onRefresh: refreshLinkedPiecesOnAssembly } =
    useLoadDataList(() => getLinkedPiecesOnAssembly(assembly?.id ?? -1), [assembly?.id]);

  const { data: linkedPiecesOnUnderAssembly, onRefresh: refreshLinkedPiecesOnUnderAssembly } =
    useLoadDataList(() => getLinkedPiecesOnUnderAssembly(underAssemblyId), [underAssemblyId]);

  const [selectedPieces, setSelectedPieces] = useState<any[]>([]);
  const [axis, setAxis] = useState<string[] | null>(null);
  const [mode, setMode] = useState<'assembly' | 'underAssembly'>('assembly');

  const handleSetAxis = (ax: string) => {
    const index = axis ? axis.indexOf(ax) : -1;
    if (axis && index > -1) {
      setAxis(axis?.filter((a) => a !== ax));
    } else if (axis && index === -1) {
      setAxis([...axis, ax]);
    } else {
      setAxis([ax]);
    }
  };

  useEffect(() => {
    if (mode === 'assembly') {
      refreshLinkedPiecesOnAssembly();
    } else {
      refreshLinkedPiecesOnUnderAssembly();
    }
  }, [mode, refreshLinkedPiecesOnAssembly, refreshLinkedPiecesOnUnderAssembly]);

  return (
    <>
      <Column style={{ alignItems: 'center', marginBottom: '4px' }}>
        <p>Index {pieceIndex}</p>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button
            disabled={assembly === null}
            style={{ fontSize: '10px' }}
            onClick={() => setMode('assembly')}
            variant={mode === 'assembly' ? 'contained' : 'outlined'}
          >
            Assemblage
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => setMode('underAssembly')}
            variant={mode === 'underAssembly' ? 'contained' : 'outlined'}
          >
            Sous Assemblage
          </Button>
        </ButtonGroup>
      </Column>

      <Column style={{ alignItems: 'center', gap: '16px', padding: '16px' }}>
        <Row>
          {selectedPieces.map((el) => {
            return (
              <ImageContainer
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                imageUrl={`${buckets?.BUCKET_3D_PIECES || ''}/${el.pictureUrl}`}
                isSelected={el.pieceIndex === pieceIndex}
                onClick={() => {
                  setSelectedPieces(
                    selectedPieces.filter((element) => element.pieceIndex !== el.pieceIndex),
                  );
                }}
              />
            );
          })}
        </Row>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button
            style={{ fontSize: '10px' }}
            disabled={pieceIndex >= activePieces.length}
            onClick={() => {
              if (
                !selectedPieces.some((el) => el.pieceIndex === pieceIndex) &&
                pieceIndex < activePieces.length
              ) {
                setSelectedPieces([...selectedPieces, { ...activePieces[pieceIndex], pieceIndex }]);
              }
            }}
          >
            Ajouter
          </Button>

          <Button
            style={{ fontSize: '10px' }}
            onClick={() => handleSetAxis('x')}
            variant={axis && axis?.indexOf('x') !== -1 ? 'contained' : 'outlined'}
          >
            X
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => handleSetAxis('y')}
            variant={axis && axis?.indexOf('y') !== -1 ? 'contained' : 'outlined'}
          >
            Y
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={() => handleSetAxis('z')}
            variant={axis && axis?.indexOf('z') !== -1 ? 'contained' : 'outlined'}
          >
            Z
          </Button>
          <Button
            style={{ fontSize: '10px' }}
            onClick={async () => {
              if (
                !selectedPieces ||
                selectedPieces.length === 0 ||
                !axis ||
                (mode === 'assembly' && assembly === null)
              )
                return;

              const piecesIndexNumber = selectedPieces.map((el) => el.pieceIndex);

              if (mode === 'underAssembly') {
                const piece = activePieces[piecesIndexNumber[0]];

                if (piece && piece.underAssembly) {
                  await createLinkedPieces(
                    piecesIndexNumber,
                    piece.underAssembly.id,
                    axis,
                    'underAssembly',
                  );
                }
              } else if (assembly) {
                await createLinkedPieces(piecesIndexNumber, assembly.id, axis, 'assembly');
              }
              setSelectedPieces([]);
              setAxis(null);
              if (mode === 'assembly') refreshLinkedPiecesOnAssembly();
              else refreshLinkedPiecesOnUnderAssembly();
            }}
          >
            Lier les pièces
          </Button>
        </ButtonGroup>
      </Column>
      {mode === 'assembly'
        ? linkedPiecesOnAssembly?.map((el) => {
            return (
              <Line key={el.id}>
                <p>
                  piece {el.piecesIndex.join(', ')} sur {el.axis.join(', ')}
                </p>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await deleteLinkedPiecesOnAssembly(el.id);
                    refreshLinkedPiecesOnAssembly();
                  }}
                >
                  x
                </div>
              </Line>
            );
          })
        : linkedPiecesOnUnderAssembly?.map((el) => {
            return (
              <Line key={el.id}>
                <p>
                  piece {el.piecesIndex.join(', ')} sur {el.axis.join(', ')}
                </p>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await deleteLinkedPiecesOnUnderAssembly(el.id);
                    refreshLinkedPiecesOnUnderAssembly();
                  }}
                >
                  x
                </div>
              </Line>
            );
          })}
    </>
  );
};

const Input = styled('input')({
  width: '100%',
  padding: '12px 20px',
  margin: '8px 0',
  boxSizing: 'border-box',
});

const Line = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '8px',
});

const ImageContainer = styled('div')(
  ({ imageUrl, isSelected }: { imageUrl: string; isSelected?: boolean }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '100px',
    height: '100px',
    borderRadius: 10,
    cursor: 'pointer',
    border: `1px solid ${isSelected ? '#BC9A67' : 'black'}`,
    backgroundImage: `url("${imageUrl}")`,
    backgroundSize: '100px',
  }),
);
