import { SvgIcon, SvgIconProps } from '@mui/material';

const ReligiousIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 15">
      <path d="M12.7576 12.4848H1.24246C1.00136 12.4848 0.770128 12.5806 0.599641 12.7511C0.429153 12.9216 0.333374 13.1528 0.333374 13.3939V14.6061C0.333374 14.6864 0.3653 14.7635 0.42213 14.8203C0.478959 14.8772 0.556036 14.9091 0.636404 14.9091H13.3637C13.444 14.9091 13.5211 14.8772 13.578 14.8203C13.6348 14.7635 13.6667 14.6864 13.6667 14.6061V13.3939C13.6667 13.1528 13.5709 12.9216 13.4004 12.7511C13.23 12.5806 12.9987 12.4848 12.7576 12.4848Z" />
      <path d="M3.39156 11.1461C3.37032 11.1922 3.36103 11.243 3.36454 11.2937C3.36805 11.3443 3.38425 11.3933 3.41165 11.4361C3.43905 11.4789 3.47677 11.5141 3.52134 11.5385C3.56591 11.5629 3.6159 11.5757 3.66671 11.5757H10.3334C10.3842 11.5757 10.4342 11.5629 10.4787 11.5385C10.5233 11.5141 10.561 11.4789 10.5884 11.4361C10.6158 11.3933 10.632 11.3443 10.6355 11.2937C10.6391 11.243 10.6298 11.1922 10.6085 11.1461L9.50004 8.74605C9.45197 8.60218 9.36072 8.47663 9.23871 8.3865C9.1167 8.29638 8.96987 8.24606 8.81822 8.24241H7.90913V4.60605C7.90913 4.52568 7.94106 4.44861 7.99789 4.39178C8.05472 4.33495 8.13179 4.30302 8.21216 4.30302H9.42428C9.50465 4.30302 9.58173 4.27109 9.63856 4.21426C9.69539 4.15744 9.72731 4.08036 9.72731 3.99999V2.78787C9.72731 2.7075 9.69539 2.63042 9.63856 2.57359C9.58173 2.51676 9.50465 2.48484 9.42428 2.48484H8.21216C8.13179 2.48484 8.05472 2.45291 7.99789 2.39608C7.94106 2.33925 7.90913 2.26218 7.90913 2.18181V0.969687C7.90913 0.889318 7.87721 0.812241 7.82038 0.755412C7.76355 0.698583 7.68647 0.666656 7.6061 0.666656H6.39398C6.31361 0.666656 6.23653 0.698583 6.17971 0.755412C6.12288 0.812241 6.09095 0.889318 6.09095 0.969687V2.18181C6.09095 2.26218 6.05902 2.33925 6.00219 2.39608C5.94537 2.45291 5.86829 2.48484 5.78792 2.48484H4.5758C4.49543 2.48484 4.41835 2.51676 4.36152 2.57359C4.30469 2.63042 4.27277 2.7075 4.27277 2.78787V3.99999C4.27277 4.08036 4.30469 4.15744 4.36152 4.21426C4.41835 4.27109 4.49543 4.30302 4.5758 4.30302H5.78792C5.86829 4.30302 5.94537 4.33495 6.00219 4.39178C6.05902 4.44861 6.09095 4.52568 6.09095 4.60605V8.24241H5.18186C5.03422 8.24609 4.89107 8.29389 4.77085 8.37965C4.65062 8.46542 4.55884 8.58522 4.50731 8.72363L3.39156 11.1461Z" />
    </SvgIcon>
  );
};

export default ReligiousIcon;
