import { ArrowForwardIos, Check } from '@mui/icons-material';
import { Box, Link, styled, Typography } from '@mui/material';

import HomeIcon from '../../../components/icons/HomeIcon';
import { useEnv } from '../../../context/EnvProvider';
import { useUser } from '../../../context/UserProvider';
import paths from '../../../navigation/paths';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 100px',
  gap: '20px',
  backgroundColor: 'white',
  borderBottom: '1px solid #EAECF0',
  width: '100%',
  height: '100px',
  boxShadow: '0px 2px 6px rgba(140, 140, 140, 0.1)',
  zIndex: 2,
});

const Logo = styled('img')({
  height: '60%',
});

const Profile = styled('img')({
  height: '60%',
  objectFit: 'contain',
  borderRadius: '24px',
  marginRight: '10px',
});

const NavigateHome = styled(Link)(({ theme: muiTheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: muiTheme.palette.primary.main,
  height: '100%',
  gap: '10px',
  padding: '0 30px',
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'none',
}));

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '25px',
  maxWidth: '70%',
  overflowX: 'auto',
  height: '100%',
});

const RoundIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '100%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  flex: '0 0 40px',
  fontWeight: '700',
}));

const DisabledIcon = styled(RoundIcon)({
  backgroundColor: 'white',
  color: 'rgba(152, 162, 179, 1)',
  border: '1px solid #EAECF0;',
});

const PrevSteps = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontWeight: '600',
  cursor: 'pointer',
});

const NextSteps = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  textDecoration: 'none',
  color: 'rgba(152, 162, 179, 1)',
  fontWeight: '600',
});

const steps = {
  CATALOG: 'Catalogue',
  CUSTOMIZATION: 'Personnalisation du monument',
  ACCESSORIES: 'Accessoires',
  PATTERNS: 'Motifs & Gravures',
  QUOTE: 'Devis',
} as const;

export type QuoteStep = keyof typeof steps;

const stepsEntries = Object.entries(steps);
type StepKeys = 'CATALOG' | 'CUSTOMIZATION' | 'PATTERNS' | 'ACCESSORIES' | 'QUOTE';

const NavBar = ({
  currStep,
  setStep,
}: {
  currStep: QuoteStep;
  setStep?: (step: StepKeys) => void;
}) => {
  const { buckets } = useEnv();

  const { user } = useUser();

  const currStepIndex = stepsEntries.map(([key]) => key).indexOf(currStep || '');

  return (
    <Container>
      <Box
        component="div"
        sx={{ height: '100%', display: 'flex', alignItems: 'center', gap: '25px' }}
      >
        <NavigateHome href={`/${paths.home.root}`}>
          <HomeIcon color="primary" />
          Accueil
        </NavigateHome>
        <Logo src="/logo_paysage.svg" />
      </Box>
      <Wrapper>
        {stepsEntries.map(([key, value], index) => {
          return (
            <Box
              component="div"
              sx={{ display: 'flex', alignItems: 'center', gap: '16px', fontWeight: 700 }}
              key={key}
            >
              {currStepIndex >= index ? (
                <PrevSteps
                  onClick={() => {
                    if (setStep && key !== 'CATALOG') setStep(key as StepKeys);
                  }}
                >
                  <RoundIcon>{currStepIndex === index ? index + 1 : <Check />}</RoundIcon>
                  <Typography variant="inherit">{value}</Typography>
                </PrevSteps>
              ) : (
                <NextSteps>
                  <DisabledIcon>{index + 1}</DisabledIcon>
                  <Typography variant="inherit">{value}</Typography>
                </NextSteps>
              )}

              {index + 1 !== Object.keys(steps).length && (
                <ArrowForwardIos sx={{ color: 'rgba(208, 213, 221, 1)' }} />
              )}
            </Box>
          );
        })}
      </Wrapper>
      <Profile
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        src={`${buckets?.BUCKET_PROFILE_PICTURES || ''}/${user?.profilePicture ?? 'default'}`}
      />
    </Container>
  );
};

export default NavBar;
