import { zodResolver } from '@hookform/resolvers/zod';
import { countryToAlpha2 } from 'country-to-iso';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import { signup } from '../../../services/user';
import { emptyAddress, schemaAddress } from '../../../utils/address.utils';
import { handleMutation } from '../../../utils/api.utils';
import { isPassword } from '../../../utils/user.utils';

const schemaRegisterForm = z
  .object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    email: z.string().refine((val) => isEmail(val)),
    password: z
      .string()
      .trim()
      .min(1)
      .refine((val) => isPassword(val)),
    confirmPassword: z.string().trim().min(1),
    name: z.string(),
    phone: z.string().refine((val) => isValidPhoneNumber(val)),
    address: schemaAddress,
    reason: z.string().trim().min(1),
    function: z.string(),
    siret: z.string().trim().min(1),
    headOffice: z.boolean(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Ces mots de passe ne correspondent pas. Veuillez réessayer.',
        path: ['confirmPassword'],
      });
    }
  });

type SchemaRegisterForm = z.infer<typeof schemaRegisterForm>;

const useRegister = () => {
  const navigate = useNavigate();

  const methods = useForm<SchemaRegisterForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      address: emptyAddress,
      name: '',
      reason: '',
      function: '',
      siret: '',
      headOffice: false,
    },
    mode: 'onSubmit',
    resolver: zodResolver(schemaRegisterForm),
  });

  const submit: SubmitHandler<SchemaRegisterForm> = (data) => {
    const {
      confirmPassword,
      address: { country, ...address },
      ...rest
    } = data;

    handleMutation(
      signup,
      { ...rest, address: { ...address, country: countryToAlpha2(country) || '' } },
      () => navigate(-1),
    );
  };

  return { methods, submit };
};

export default useRegister;
