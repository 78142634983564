/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { styled, TextField } from '@mui/material';
import { useState } from 'react';
import { PatternType } from 'src/constants/pattern';
import { useEnv } from 'src/context/EnvProvider';
import useLoadDataList from 'src/hook/useLoadDataList';
import { getPatterns, Pattern } from 'src/services/pattern';
import { User } from 'src/services/user';
import { getPiece } from 'src/utils/configurator.utils';
import { Euler, Vector2, Vector3 } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

type Catalogprops = {
  pieces: any[];
  pieceIndex: number;
  setPatterns: (patterns: any) => void;
  patterns: any;
  setSelectedImportPage: (selectedImportPage: PatternType | undefined) => void;
  setSelectedPiece: (selectedPiece: PatternType) => void;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  selectedPage: string;
  user: User;
  selectedImportPage: PatternType;
  orbitRef: any;
  customSteles: any[];
  customPlatings: any[];
};

export const Catalog = ({
  pieces,
  pieceIndex,
  setPatterns,
  patterns,
  setSelectedPiece,
  setSelectedImportPage,
  orientation,
  selectedPage,
  user,
  selectedImportPage,
  orbitRef,
  customSteles,
  customPlatings,
}: Catalogprops) => {
  const { data } = useLoadDataList(() => getPatterns(user?.id));

  const [filter, setFilter] = useState<string>('');

  const filteredDatas = data.filter((el) => {
    if (el.type && !el.type.includes(selectedImportPage as string) && selectedImportPage !== 'ALL')
      return false;

    if (selectedImportPage === 'ALL' && el.type && el.type.includes('TMP')) return false;

    if (filter) {
      if (
        el.name.toLowerCase().replace(' ', '').includes(filter.toLowerCase().replace(' ', '')) ||
        el.description
          .toLowerCase()
          .replace(' ', '')
          .includes(filter.toLowerCase().replace(' ', ''))
      )
        return true;
    } else if (el?.user?.role === 'ADMIN' || el?.userId === user.id) return true;
    return false;
  });

  const { buckets } = useEnv();

  const getRotation = () => {
    if (orientation.key === 'y') {
      return new Euler(0, orientation.inverse ? Math.PI : 0, 0);
    }
    return new Euler(0, 0, 0);
  };

  const getOrientationName = () => {
    return `${orientation.key}${orientation.inverse ? '_inverse' : ''}`;
  };

  const addPattern = (el: Pattern, ratio: number) => {
    const patternsTmp = { ...patterns };

    const piece = getPiece(pieces, customSteles, customPlatings, pieceIndex);
    if (!piece?.uuid) return;

    if (!patternsTmp[piece?.uuid]) {
      patternsTmp[piece.uuid] = [];
    }

    if (!patternsTmp[piece.uuid][getOrientationName()]) {
      patternsTmp[piece.uuid][getOrientationName()] = {
        id: getOrientationName(),
        texture: null,
        elements: [],
        camera: orbitRef.current.object.clone(),
      };
    }

    const getNumberParseFloat = (value: any) => {
      return Number.parseFloat(value as string);
    };

    patternsTmp[piece.uuid][getOrientationName()].elements?.push({
      uuid: generateUUID(),
      patternId: el.id,
      imageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.image}`,
      reducedImageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.reducedImage}`,
      pieceUUID: piece.uuid,
      orientation: getOrientationName(),
      position: new Vector3(0, 0, 0),
      rotation: getRotation(),
      scale: new Vector2(
        getNumberParseFloat(Math.round(el.height * ratio)),
        getNumberParseFloat(el.height),
      ),
      inverse_horizontal: false,
      inverse_vertical: false,
      type: 'image',
      name: el.name,
      default: new Vector2(
        getNumberParseFloat(Math.round(el.height * ratio)),
        getNumberParseFloat(el.height),
      ),
      price: el.price,
      leaderGranitEngraving: el.leaderGranitEngraving,
      ratio,
      user: el.user,
    });

    setPatterns(patternsTmp);
  };

  return (
    <Container>
      <TextField
        style={{ width: '100%' }}
        autoFocus
        variant="standard"
        label="Rechercher un mot clé..."
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
      <PiecesContainer>
        {filteredDatas?.map((el: Pattern, index: number) => {
          return (
            <Col
              onClick={() => {
                const patternImage = new Image();
                patternImage.addEventListener('load', (event) => {
                  setSelectedImportPage(undefined);
                  const target = event.target as HTMLImageElement;
                  const ratio = (target.width ?? 1) / (target.height ?? 1);
                  addPattern(el, ratio);
                });
                patternImage.src = `${buckets?.BUCKET_3D_PIECES || ''}/${el.image}`;
              }}
            >
              <PieceContainer
                key={index}
                backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/${el.reducedImage}`}
              />
              <PieceContainerLabel>{el.name}</PieceContainerLabel>
            </Col>
          );
        })}
      </PiecesContainer>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  zIndex: 2,
  padding: '22px',
  overflowY: 'auto',
  maxHeight: window.innerHeight - 334,
});

const PiecesContainer = styled('div')({
  marginTop: '22px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
});

const PieceContainer = styled('div')(
  ({
    backgroundImage,
    backgroundColor,
  }: {
    backgroundImage: string;
    backgroundColor?: string;
  }) => ({
    width: '140px',
    height: '120px',

    backgroundColor: `${backgroundColor || '#3d3d3d'}`,
    borderRadius: '10px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    backgroundImage: `url("${backgroundImage}");`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
);

const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
});

const PieceContainerLabel = styled('div')(({ backgroundColor }: { backgroundColor?: string }) => ({
  backgroundColor: `${backgroundColor || '#3d3d3d'}`,
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '140px',
  height: '20px',
  color: 'white',

  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
}));
