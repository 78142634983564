import { createContext, useContext, useMemo } from 'react';

import useLoadData from '../hook/useLoadData';
import paths from '../navigation/paths';
import { getUserDetails, User } from '../services/user';
import { isAdmin } from '../utils/user.utils';

type ContextProps = {
  user: User | null;
  onRefresh: () => Promise<void>;
  homePath: string;
};

interface IUserProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<ContextProps>({
  user: null,
  onRefresh: async () => {},
  homePath: `/${paths.home.root}`,
});

export const UserProvider = ({ children }: IUserProviderProps) => {
  const { data: user, onRefresh } = useLoadData(getUserDetails);

  const userProviderValue = useMemo(
    () => ({
      user,
      onRefresh,
      homePath: `/${isAdmin(user) ? `${paths.admin.root}/` : ''}${paths.home.root}`,
    }),
    [onRefresh, user],
  );

  return <UserContext.Provider value={userProviderValue}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
