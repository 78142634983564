import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues } from 'react-hook-form';

import { ISelectRHF } from './FormComponents.type';

export const SelectRHF = <T extends FieldValues>({
  name,
  control,
  TextFieldProps,
  options,
}: ISelectRHF<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <TextField select {...TextFieldProps} inputRef={ref} {...field}>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )}
  />
);
