import { useState } from 'react';
import { Constant, getConstants } from 'src/services/configurator';

import useLoadDataList from './useLoadDataList';

export const useCreateMacro = (pieceId: number) => {
  const [selectedModifications, setSelectedModifications] =
    useState<{ modificationId: number; ratio: string; isFixed: boolean }[]>();

  const [name, setName] = useState<string>('');
  const [selectedConstants, setSelectedConstants] = useState<Constant[]>();

  const isConstantSelected = (constant: Constant) => {
    if (selectedConstants?.find((element) => element.id === constant.id)) return true;
    return false;
  };

  const { data: constants } = useLoadDataList(() => getConstants(pieceId));

  return {
    selectedModifications,
    setSelectedModifications,
    name,
    setName,
    selectedConstants,
    setSelectedConstants,
    isConstantSelected,
    constants,
  };
};
