import { MutableRefObject, useEffect } from 'react';

const useHandleClickOutside = (
  ref: MutableRefObject<Node | null>,
  handler: () => void,
  buttonRef?: MutableRefObject<Node | null>,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !ref?.current ||
        ref.current.contains(event.target as Node) ||
        (buttonRef?.current && buttonRef?.current.contains(event.target as Node))
      ) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, buttonRef]);
};

export default useHandleClickOutside;
