const paths = {
  connexion: {
    root: 'login',
    register: 'register',
    forgotPassword: 'forgot-password',
  },
  maintenance: {
    root: 'maintenance',
  },
  home: {
    root: 'home',
    project: {
      root: 'project',
      init: 'init',
      catalog: { root: 'catalog', assemblyId: ':assemblyId' },
      configurator: {
        root: 'configurator',
        assembly: ':assemblyId',
      },
    },
    mySpace: 'myspace',
    'quote&orders': 'quotes&orders',
    agencies: 'agencies',
  },
  admin: {
    root: 'admin',
    home: {
      root: 'home',
      settings: 'settings',
      users: {
        root: 'users',
        user: ':userId',
      },
      'quote&orders': 'quotes&orders',
      catalogManagement: 'catalogManagement',
    },
  },
};

export default paths;
