/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';
import { getMacros } from 'src/services/configurator';
import { getMovingAxisOf2Point } from 'src/utils/configurator.utils';
import { getSize, getSizeOfModification } from 'src/utils/macro.utils';
import { getNewGeometryWithSimpleMode } from 'src/utils/modification.utils';

import useLoadDataList from './useLoadDataList';

export const useMacros = (
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  },
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void,
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void,
  pieceId: number | null,
  pieceIndex: number,
  activePieces: any[],
  pieces: any[],
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void,
) => {
  const [option, setOption] = useState<'macro' | 'linked' | 'create' | 'link'>('macro');

  const [size, setSize] = useState<string[]>();

  const { data: macros, onRefresh: refreshMacros } = useLoadDataList(
    () => getMacros(pieceId),
    [pieceId],
  );

  useEffect(() => {
    if (macros) {
      const macrosSize: any[] = [];

      for (const el of macros) {
        macrosSize.push(`${getSize(macros, history, el.id)}`);
      }
      setSize(macrosSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [macros]);

  const checkMin = (value: string) => {
    if (Number.parseFloat(value) < Number.parseFloat(activePieces[pieceIndex].granit?.minLength))
      return activePieces[pieceIndex].granit?.minLength as string;
    return value;
  };

  const applyMacro = (macroIndex: number) => {
    const macro = macros?.find((el) => el.id === macros[macroIndex].id);
    if (macro && size) {
      const objs: any = [];
      let macroSize = Number.parseFloat(size[macroIndex]);
      let hasOnValueFixed = false;
      for (const element of macro.constants) {
        macroSize -= element.constant.distance;
      }
      for (const m of macro.modifications) {
        if (m.isFixed) {
          hasOnValueFixed = true;
          macroSize -= Number.parseFloat(
            getSizeOfModification(history[pieceIndex], m.modification),
          );
        }
      }
      for (const [index, modification] of macro.modifications.entries()) {
        if (modification.modification.simpleModeAxis) {
          const copyGeometry = getNewGeometryWithSimpleMode(
            history,
            pieceIndex,
            {
              points: modification.modification.points,
              simpleModeAxis: modification.modification.simpleModeAxis,
            },
            {
              size: checkMin((macroSize * Number.parseFloat(modification.ratio)).toString()),
            },
            pieces,
          );

          changeGeometry(pieceIndex, copyGeometry);
          checkCollision(pieceIndex, modification.modification.simpleModeAxis);
        } else {
          const axis = getMovingAxisOf2Point(
            modification.modification.points[0],
            modification.modification.points[1],
          );
          if (axis) {
            if (hasOnValueFixed) {
              if (!modification.isFixed) {
                let firstPoint: any = null;
                let secondPoint: any = null;
                if (
                  modification.modification.points[1][axis] -
                    modification.modification.points[0][axis] <
                  0
                ) {
                  firstPoint = modification.modification.points[1];
                  secondPoint = modification.modification.points[0];
                } else {
                  firstPoint = modification.modification.points[0];
                  secondPoint = modification.modification.points[1];
                }
                const obj = {
                  firstPoint,
                  secondPoint,
                  axis: [axis],
                  delta: (macroSize * Number.parseFloat(modification.ratio)) / 100,
                };
                objs.push(obj);
              }
            } else {
              let firstPoint: any = null;
              let secondPoint: any = null;

              firstPoint = modification.modification.points[0];
              secondPoint = modification.modification.points[1];

              const obj = {
                firstPoint,
                secondPoint,
                axis: [axis],
                delta: (macroSize * Number.parseFloat(modification.ratio)) / 100,
              };
              objs.push(obj);
            }
          }
        }
      }

      changePoint(objs);
    }
  };

  return {
    size,
    setSize,
    macros,
    option,
    setOption,
    applyMacro,
    refreshMacros,
  };
};
