import { Paper, Popover, styled, Typography } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  frFR,
  gridPageCountSelector,
  gridPageSelector,
  GridValidRowModel,
  GridValueFormatterParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { MouseEvent, useState } from 'react';

import { CustomPagination } from './CustomPagination';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  border: `0.5px solid #EAECF0`,
  borderRadius: '8px',

  '& .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },

  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    border: `0.5px solid #D0D5DD`,
    background: '#F9FAFB',
  },

  '.MuiDataGrid-cell': {
    border: `0.5px solid #D0D5DD`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: "'Open sans', 'sans-serif'",
    minHeight: '80px',
    maxHeight: '80px',
    display: 'flex',
    justifyContent: 'center',
  },

  '& .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.MuiDataGrid-cell .MuiIconButton-sizeSmall': {
    border: '1px solid #BC9A67',
    color: '#BC9A67',
  },
})) as typeof DataGrid;

const GridPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <CustomPagination page={page} pageCount={pageCount} handleChangePage={apiRef.current.setPage} />
  );
};

function isOverflown(element: Element): boolean {
  return (
    element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth
  );
}

export const CustomDataGrid = <T extends GridValidRowModel>(dataGridProps: DataGridProps<T>) => {
  const { components, rows, columns, ...rest } = dataGridProps;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [overValue, setOverValue] = useState('');
  const [showPopper, setShowPopper] = useState(false);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    const isCurrentlyOverflown = isOverflown(event.currentTarget.children[0]);

    const field = event.currentTarget.dataset.field ?? '';
    const id = event.currentTarget.parentElement?.dataset.id ?? '';
    const row = rows.find((r) => r.id === id);
    setShowPopper(isCurrentlyOverflown);
    setOverValue((row?.[field] as string | undefined) ?? '');
    setAnchorEl(event.currentTarget);
  };

  const formattedColumns = columns.map((column) => ({
    ...column,
    valueFormatter: (params: GridValueFormatterParams) => String(params.value || '-'),
  }));

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl && showPopper);

  return (
    <>
      <StyledDataGrid
        rows={rows}
        columns={formattedColumns}
        disableSelectionOnClick
        disableColumnMenu
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: GridPagination,
          ...components,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          },
        }}
        autoPageSize
        {...rest}
      />
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper>
          <Typography sx={{ p: 1 }}>{overValue}</Typography>
        </Paper>
      </Popover>
    </>
  );
};
