import { Add, Delete, Edit } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Chip, TextField, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import { CenteredModal, ModalTitle } from '../../../components/CenteredModal';
import { LowerCaseButton, RoundedButton } from '../../../components/common';
import { CustomDataGrid } from '../../../components/DataGrid';
import DeleteWarningModal from '../../../components/DeleteWarningModal';
import SearchComponent from '../../../components/Search';
import { useUser } from '../../../context/UserProvider';
import useLoadDataList from '../../../hook/useLoadDataList';
import useToggle from '../../../hook/useToggle';
import {
  Agency,
  getCompanyAgencies,
  getNotLinkedAgencies,
  linkAgencies,
  ReducedCompany,
  unlinkAgencies,
  updateControlMode,
} from '../../../services/user';
import { formatAddress } from '../../../utils/address.utils';
import { handleMutation } from '../../../utils/api.utils';
import { isAdmin } from '../../../utils/user.utils';
import { EditAgencies } from './EditAgencies';

type DataRow = {
  id: string;
  name: string;
  deliveryAddress: string;
  billingAddress: string;
  phone: string;
  control: boolean;
  userId: string;
};

const formatDataRow = (row: Agency): DataRow => ({
  id: row.company.id,
  userId: row.company.user.id,
  name: row.company.name,
  phone: row.company.phone,
  deliveryAddress: formatAddress(row.company.deliveryAddress),
  billingAddress: formatAddress(row.company.deliveryAddress),
  control: row.control,
});

const AgenciesManagement = ({ userId }: { userId: string }) => {
  const [search, setSearch] = useState('');
  const [addAgency, toggleAddAgency] = useToggle();
  const [deleteAgency, toggleDeleteAgency] = useToggle();
  const { user } = useUser();

  const [selectedAgencies, setSelectedAgencies] = useState<ReducedCompany[]>([]);
  const [checkedAgencies, setCheckedAgencies] = useState<GridSelectionModel>([]);
  const [openedAgency, setOpenedAgency] = useState<Agency>();

  const { data, onRefresh } = useLoadDataList(() => getCompanyAgencies(userId));
  const { data: agencies, onRefresh: refreshAgencies } = useLoadDataList(getNotLinkedAgencies);
  const [rows, setRows] = useState<DataRow[]>([]);

  const handleUpdateControlMode = (checked: boolean, agencyId: string) =>
    handleMutation((value) => updateControlMode(agencyId, value), checked, onRefresh);

  const columns: GridColDef<DataRow>[] = [
    {
      field: 'name',
      headerName: 'Agence',
      flex: 1,
    },
    {
      field: 'deliveryAddress',
      headerName: 'Adresse de livraison',
      flex: 1,
    },
    {
      field: 'billingAddress',
      headerName: 'Adresse de facturation',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string>) => formatPhoneNumber(params.value ?? ''),
    },
    {
      field: 'control',
      headerName: 'Mode contrôle',
      flex: 1,
      renderCell: (params: GridRenderCellParams<boolean, DataRow>) => (
        <Checkbox
          checked={params.value}
          onChange={(_event, checked) => handleUpdateControlMode(checked, params.row.userId)}
        />
      ),
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      renderCell: (params: GridRenderCellParams<boolean, DataRow>) => (
        <GridActionsCellItem
          icon={<Edit color="secondary" />}
          label="Modifier"
          onClick={() => {
            const agency = data.find((row) => row.company.id === params.row.id);
            setOpenedAgency(agency);
          }}
        />
      ),
    },
  ];

  useEffect(() => setRows(data.map((row) => formatDataRow(row))), [data]);

  const submitAgencies = () => {
    handleMutation(
      (datas) => linkAgencies(datas, userId),
      {
        agencyIds: selectedAgencies.map((agency) => agency.id),
      },
      () => {
        toggleAddAgency();
        setSelectedAgencies([]);
        onRefresh();
        refreshAgencies();
      },
    );
  };

  const handleDelete = () => {
    handleMutation(
      unlinkAgencies,
      {
        agencyIds: checkedAgencies as string[],
      },
      () => {
        setCheckedAgencies([]);
        onRefresh();
        refreshAgencies();
      },
    );
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          padding: '16px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <EditAgencies
          openedAgency={openedAgency}
          open={!!openedAgency}
          handleClose={() => setOpenedAgency(undefined)}
          handleEditAgency={(newOpenedAgency: Agency) => {
            onRefresh();
            if (openedAgency) {
              setOpenedAgency({ ...openedAgency, ...newOpenedAgency });
            }
          }}
        />
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: '16px',
            maxWidth: '80%',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {isAdmin(user) && (
            <>
              <RoundedButton variant="outlined" onClick={toggleAddAgency}>
                <Add color="primary" />
                Ajouter une agence
              </RoundedButton>

              <RoundedButton
                variant="outlined"
                color="error"
                disabled={checkedAgencies.length === 0}
                onClick={toggleDeleteAgency}
              >
                <Delete color={checkedAgencies.length === 0 ? 'inherit' : 'error'} />
                Supprimer
              </RoundedButton>
            </>
          )}
        </Box>
        <SearchComponent search={search} setSearch={setSearch} />
      </Box>
      <CustomDataGrid
        columns={columns}
        rows={rows}
        rowHeight={80}
        checkboxSelection={isAdmin(user)}
        sx={{ flex: '1' }}
        onSelectionModelChange={(newSelectionModel) => {
          setCheckedAgencies(newSelectionModel);
        }}
        selectionModel={checkedAgencies}
      />
      <CenteredModal open={addAgency} handleClose={toggleAddAgency}>
        <ModalTitle>
          <Typography variant="h5">AJOUTER DES AGENCES</Typography>
        </ModalTitle>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '50px 10%',
            gap: '30px',
          }}
        >
          <Autocomplete
            multiple
            id="tags-filled"
            options={agencies}
            getOptionLabel={(option: ReducedCompany) => `${option.reason}-${option.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Pas d'agences trouvées"
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => (
                <Chip
                  variant="outlined"
                  label={`${option.reason}-${option.name}`}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Agences à ajouter" />}
            value={selectedAgencies}
            onChange={(_event, value) => setSelectedAgencies(value)}
          />
          <LowerCaseButton variant="contained" onClick={submitAgencies}>
            Enregistrer
          </LowerCaseButton>
          <LowerCaseButton
            variant="outlined"
            onClick={() => {
              toggleAddAgency();
              setSelectedAgencies([]);
            }}
          >
            Annuler
          </LowerCaseButton>
        </Box>
      </CenteredModal>
      <DeleteWarningModal
        open={deleteAgency}
        onClose={toggleDeleteAgency}
        header="Désassocier ces Agences ?"
        handleDelete={handleDelete}
      />
    </>
  );
};

export default AgenciesManagement;
