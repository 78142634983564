/* eslint-disable react/jsx-no-bind */
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useResizeDetector } from 'react-resize-detector';

interface MyDocumentProps {
  url: string;
  width?: number;
}

export const MyDocument: React.FC<MyDocumentProps> = ({ url, width }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from({ length: numPages || 0 }).map((el, index) => (
        <Box key={`page_${index + 1}`} component="div" sx={{ border: '1px solid' }}>
          <Page pageNumber={index + 1} width={width} />
        </Box>
      ))}
    </Document>
  );
};

interface PdfProps {
  url: string;
}

export const Pdf: React.FC<PdfProps> = ({ url }) => {
  const { width, ref } = useResizeDetector();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '16px',
      }}
      ref={ref}
    >
      <MyDocument url={url} width={width} />
    </Box>
  );
};
