import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { ToastContainer } from 'react-toastify';

import { NavigationRoutes } from './navigation/navigation';
import muiTheme from './themes/muiTheme';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

const App = () => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavigationRoutes />
    </ThemeProvider>
  );
};

export default App;
