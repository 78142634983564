import {
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { StateNames, States } from 'src/Screens/Configurator';
import { Assembly } from 'src/services/assemblies';
import { Modification } from 'src/services/configurator';

import { Macros } from '../Utils/Macros';
import { Modifications } from '../Utils/Modifications';
import { Transform } from '../Utils/Transform';

type TombaleProps = {
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  geometry: number[];
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void;
  pieces: any[];
  pieceIndex: number;
  assembly: Assembly | null;
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void;
  modifications: Modification[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  refreshModifications: () => void;
  activePieces: any[];
  reloadPiece: boolean;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
};

export const Tombale = ({
  changeGeometry,
  geometry,
  checkCollision,
  pieces,
  pieceIndex,
  assembly,
  selectedPoints,
  setSelectedPoints,
  changePoint,
  modifications,
  history,
  refreshModifications,
  activePieces,
  reloadPiece,
  setValue,
  getValue,
}: TombaleProps) => {
  const [hasChanfrains, setHasChanfrains] = useState<boolean>(false);
  const [hasQueue, setHasQueue] = useState<boolean>(true);
  const [hasEcoincons, setHasEcoincons] = useState<boolean>(true);
  const [hasEdges, setHasEdges] = useState<boolean[]>([false, false, false, false]);
  const [edgeSize, setEgdeSize] = useState<string>('5');

  const switchButtons = [
    {
      key: 'chanfreins',
      label: 'Chanfreins',
      set: setHasChanfrains,
      var: hasChanfrains,
    },
    {
      key: 'queue',
      label: 'Queue de billard',
      set: setHasQueue,
      var: hasQueue,
    },
    {
      key: 'ecoicons',
      label: 'Ecoiçons',
      set: setHasEcoincons,
      var: hasEcoincons,
    },
  ];

  const checkButtons = {
    label: 'Arête',
    keys: ['avant', 'gauche', 'droite', 'arrière'],
  };

  return (
    <Container>
      {getValue('hasAdvancedMode') ? (
        <div
          style={{
            backgroundColor: '#D32F2F70',
            padding: 12,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 12,
            marginBottom: 12,
          }}
        >
          <p style={{ fontSize: 12 }}>Attention, le mode avancé est activé.</p>
          <p style={{ fontSize: 12 }}>
            Les assemblages de pièces ne sont plus pris en compte. Vous modifierez et déplacerez
            chaque pièce indépendamment des autres.
          </p>
        </div>
      ) : null}

      <Row style={{ justifyContent: 'space-between' }}>
        <Row>
          <Typography variant="body2" color="#344054">
            Dimension
          </Typography>
          <Typography
            variant="body2"
            color="#344054"
            style={{ marginLeft: '4px', fontWeight: 700 }}
          >
            Tombale
          </Typography>
        </Row>

        <Row style={{ alignItems: 'center', gap: '6px', marginLeft: '0px' }}>
          <Typography variant="body2" color="#344054" style={{ fontWeight: 400, fontSize: '12px' }}>
            Mode avancé
          </Typography>
          <IOSSwitch
            checked={getValue('hasAdvancedMode')}
            onChange={() => setValue('hasAdvancedMode', !getValue('hasAdvancedMode'))}
          />
        </Row>
      </Row>

      <Transform
        changeGeometry={changeGeometry}
        checkCollision={checkCollision}
        pieces={pieces}
        pieceIndex={pieceIndex}
        assembly={assembly}
        activePieces={activePieces}
        advancedMode={getValue('hasAdvancedMode')}
        reloadPiece={reloadPiece}
        history={history}
        getValue={getValue}
      />

      {modifications && modifications.length > 0 ? <Divider variant="middle" /> : null}

      <Modifications
        changeGeometry={changeGeometry}
        checkCollision={checkCollision}
        selectedPoints={selectedPoints}
        setSelectedPoints={setSelectedPoints}
        changePoint={changePoint}
        modifications={modifications}
        history={history}
        refreshModifications={refreshModifications}
        pieces={pieces}
        pieceIndex={pieceIndex}
        activePieces={activePieces}
      />

      <Macros
        changePoint={changePoint}
        changeGeometry={changeGeometry}
        modifications={modifications}
        history={history}
        pieces={pieces}
        pieceIndex={pieceIndex}
        activePieces={activePieces}
        checkCollision={checkCollision}
      />

      {/* {switchButtons.map((element) => {
        return (
          <>
            <Row
              key="tombale"
              style={{ alignItems: 'center', gap: '6px', margin: '10px', marginLeft: '0px' }}
            >
              <Typography
                variant="body2"
                color="#344054"
                style={{ fontWeight: 400, fontSize: '12px' }}
              >
                {element.label}
              </Typography>
              <IOSSwitch checked={element.var} onChange={() => element.set(!element.var)} />
            </Row>

            {element.key === 'queue' ? (
              <>
                <Row
                  style={{
                    justifyContent: 'space-between',
                    marginTop: '12px',
                    marginBottom: '12px',
                    marginLeft: '-12px',
                  }}
                >
                  {checkButtons.keys.map((key, index) => {
                    return (
                      <Row style={{ alignItems: 'center' }}>
                        <Checkbox
                          checked={hasEdges[index]}
                          onChange={() =>
                            setHasEdges(
                              hasEdges.map((el, i) => {
                                if (i === index) return !el;
                                return el;
                              }),
                            )
                          }
                          sx={{
                            color: '#D0D5DD',
                            '&.Mui-checked': {
                              color: '#BC9A67',
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          color="#667085"
                          style={{ fontWeight: 400, fontSize: '12px' }}
                        >
                          {checkButtons.label} {key}
                        </Typography>
                      </Row>
                    );
                  })}
                </Row>
                <Column style={{ width: '33%' }}>
                  <Label>Largeur</Label>
                  <Input
                    type="text"
                    name="topWidth"
                    onChange={(e) => {
                      setEgdeSize(e.target.value);
                    }}
                    value={edgeSize}
                  />
                </Column>
              </>
            ) : null}
          </>
        );
      })} */}
    </Container>
  );
};

const Container = styled('div')({
  height: '100%',
  width: '100%',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#BC9A67' : '#BC9A67',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#BC9A67',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '0px',
  color: 'rgba(0, 0, 0, 0.5)',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
