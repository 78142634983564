/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import useLoadDataList from 'src/hook/useLoadDataList';
import { createConstant, deleteConstant, getConstants } from 'src/services/configurator';
import { Piece } from 'src/services/pieces';
import styled from 'styled-components';

type ConstantsProps = {
  pieces: Piece[];
  pieceIndex: number;
};

export const Constants = ({ pieces, pieceIndex }: ConstantsProps) => {
  const [option, setOption] = useState<'constants' | 'create'>('constants');
  const [name, setName] = useState<string | null>(null);
  const [distance, setDistance] = useState<string | null>(null);

  const { data: constants, onRefresh: refreshConstants } = useLoadDataList(() =>
    getConstants(pieces[pieceIndex].id),
  );

  return (
    <Container>
      <Menu>
        <p onClick={() => setOption('constants')}>Constantes</p>
        <p onClick={() => setOption('create')}>Création</p>
      </Menu>
      {option === 'create' ? (
        <>
          <label>
            Nom:
            <Input
              style={{ width: '100%' }}
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name ?? ''}
            />
          </label>
          <label>
            Distance:
            <Input
              style={{ width: '100%' }}
              type="text"
              name="name"
              onChange={(e) => setDistance(e.target.value)}
              value={distance ?? ''}
            />
          </label>

          <Button
            isActive={name !== ''}
            textColor="white"
            onClick={async () => {
              if (name && distance)
                await createConstant(
                  name,
                  Number.parseFloat(distance),
                  pieces[pieceIndex].id,
                  pieceIndex,
                );
              setDistance(null);
              setName(null);
            }}
          >
            Créer une constante
          </Button>
        </>
      ) : null}
      {option === 'constants' ? (
        <>
          {constants?.map((constant) => {
            return (
              <NameLine>
                <p>{constant.name}</p>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await deleteConstant(constant.id);
                    refreshConstants();
                  }}
                >
                  x
                </div>
              </NameLine>
            );
          })}
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const Input = styled.input`
  padding: 12px 20px;
  width: 40%;
  margin: 8px 0;
  box-sizing: border-box;
`;

type ButtonType = {
  isActive: boolean;
  textColor: string;
};

const Button = styled.button<ButtonType>`
  background-color: ${(props) => (props.isActive ? '#4caf50' : '#a1a1a1')};
  border: none;
  color: ${(props) => props.textColor};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
`;

const NameLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
