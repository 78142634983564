/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { zodResolver } from '@hookform/resolvers/zod';
import { InputAdornment, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import { LowerCaseButton, StyledForm } from '../../../../components/common';
import { NumberFieldRHF } from '../../../../components/forms/NumberInput';
import useLoadDataList from '../../../../hook/useLoadDataList';
import { fabrications } from '../../../../services/assemblies';
import { getDeliveryTimes, updateDeliveryTimes } from '../../../../services/deliveryTimes';
import { handleMutation } from '../../../../utils/api.utils';
import { FabricationLabels } from './Granits';

interface IDeliveryTimesFormProps {
  open: boolean;
  toggleOpen: () => void;
}

const schemaDeliveryTimeForm = z.record(z.enum(fabrications), z.number());

type SchemaDeliveryTimeForm = z.infer<typeof schemaDeliveryTimeForm>;

const defaultTimes = fabrications.reduce(
  (acc, fabrication) => ({
    [fabrication]: 0,
    ...acc,
  }),
  {} as SchemaDeliveryTimeForm,
);

const DeliveryTimesForm = ({ open, toggleOpen }: IDeliveryTimesFormProps) => {
  const { data: deliveryTimes } = useLoadDataList(getDeliveryTimes);

  const defaultValues = useMemo(
    () =>
      deliveryTimes.reduce(
        (acc, { deliveryTime, fabrication }) => ({
          ...acc,
          [fabrication]: deliveryTime,
        }),
        defaultTimes,
      ),
    [deliveryTimes],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<SchemaDeliveryTimeForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaDeliveryTimeForm),
  });

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  const submit: SubmitHandler<SchemaDeliveryTimeForm> = (data) => {
    handleMutation(
      updateDeliveryTimes,
      fabrications.map((fabrication) => ({ fabrication, deliveryTime: data[fabrication] ?? 0 })),
      () => {
        toast.success('Délais de livraison mis à jour');
        toggleOpen();
      },
    );
  };

  return (
    <CenteredModal open={open} handleClose={toggleOpen}>
      <ModalTitle>
        <Typography variant="h5">DÉLAIS DE LIVRAISON</Typography>
      </ModalTitle>
      <StyledForm onSubmit={handleSubmit(submit)}>
        {fabrications.map((fabrication) => (
          <NumberFieldRHF
            control={control}
            name={fabrication}
            key={fabrication}
            TextFieldProps={{
              label: FabricationLabels[fabrication],
              error: Boolean(errors[fabrication]),
              helperText: errors[fabrication]?.message,
              InputProps: {
                endAdornment: <InputAdornment position="end">Semaines</InputAdornment>,
              },
            }}
          />
        ))}
        <LowerCaseButton variant="contained" type="submit">
          Enregistrer
        </LowerCaseButton>
        <LowerCaseButton
          variant="outlined"
          onClick={() => {
            reset(defaultValues);
            toggleOpen();
          }}
        >
          Annuler
        </LowerCaseButton>
      </StyledForm>
    </CenteredModal>
  );
};

export default DeliveryTimesForm;
