import { styled, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Assembly as AssemblyType } from 'src/services/assemblies';
import { Constraint } from 'src/services/configurator';
import { WebGLRenderer } from 'three';

import { Steles } from './Admin/Steles';
import { StateNames, States } from '../../../Screens/Configurator';
import { Assembly } from './Admin/Assembly';
import { Constants } from './Admin/Constants';
import { Constraints } from './Admin/Constraints';
import { LinkedPieces } from './Admin/LinkedPieces';
import { Pieces } from './Admin/Pieces';
import { UnderAssembly } from './Admin/UnderAssembly';
import { DefaultPattern } from './Admin/DefaultPattern';

type Key =
  | 'constraints'
  | 'constants'
  | 'settings'
  | 'assemblies'
  | 'linkedPieces'
  | 'pieces'
  | 'underAssemblies'
  | 'steles'
  | 'defaultPattern';

type AdminProps = {
  pieces: any[];
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  constraints: Constraint[] | undefined;
  refreshConstraints: () => void;
  pieceIndex: number;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  assembly: AssemblyType | null;
  customPlatings: any[];
  customSteles: any[];
  activePieces: any[];
  patterns: {
    [key: string]: {
      [key: string]: any;
    }[];
  };
  gl: WebGLRenderer | undefined;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  centerCamera: () => void;
};

export const Admin = ({
  pieces,
  selectedPoints,
  setSelectedPoints,
  constraints,
  refreshConstraints,
  pieceIndex,
  history,
  assembly,
  customSteles,
  customPlatings,
  activePieces,
  patterns,
  gl,
  setValue,
  setPieceIndex,
  centerCamera,
}: AdminProps) => {
  const [option, setOption] = useState<number>(0);

  const titles: { label: string; key: Key }[] = [
    {
      label: 'Contraintes',
      key: 'constraints',
    },

    {
      label: 'Constantes',
      key: 'constants',
    },

    {
      label: 'Assemblage',
      key: 'assemblies',
    },
    {
      label: 'Linked Pieces',
      key: 'linkedPieces',
    },
    {
      label: 'Pieces',
      key: 'pieces',
    },
    {
      label: 'Stèles',
      key: 'steles',
    },
    {
      label: 'Sous assemblage',
      key: 'underAssemblies',
    },
    {
      label: 'Motif par défaut',
      key: 'defaultPattern',
    },
  ];

  return (
    <Container>
      <Tabs
        value={option}
        scrollButtons
        variant="scrollable"
        onChange={(_event: React.SyntheticEvent, newValue: number) => {
          setOption(newValue);
        }}
      >
        {titles.map((el) => {
          return <Tab label={el.label} />;
        })}
      </Tabs>
      {pieces && pieces.length > 0 ? (
        <>
          {titles[option].key === 'constraints' ? (
            <Constraints
              selectedPoints={selectedPoints}
              setSelectedPoints={setSelectedPoints}
              constraints={constraints}
              history={history}
              refreshConstraints={refreshConstraints}
              pieces={pieces}
              pieceIndex={pieceIndex}
            />
          ) : null}
          {titles[option].key === 'constants' ? (
            <Constants pieces={pieces} pieceIndex={pieceIndex} />
          ) : null}
          {titles[option].key === 'linkedPieces' ? (
            <LinkedPieces assembly={assembly} pieceIndex={pieceIndex} activePieces={activePieces} />
          ) : null}
          {titles[option].key === 'assemblies' ? (
            <Assembly
              pieces={pieces}
              customSteles={customSteles}
              activePieces={activePieces}
              patterns={patterns}
              gl={gl}
              setValue={setValue}
              setPieceIndex={setPieceIndex}
              centerCamera={centerCamera}
              customPlatings={customPlatings}
            />
          ) : null}
        </>
      ) : null}
      {titles[option].key === 'underAssemblies' ? (
        <UnderAssembly
          pieces={pieces}
          activePieces={activePieces}
          setValue={setValue}
          setPieceIndex={setPieceIndex}
          centerCamera={centerCamera}
          gl={gl}
        />
      ) : null}
      {titles[option].key === 'pieces' ? <Pieces /> : null}
      {titles[option].key === 'steles' ? <Steles /> : null}
      {titles[option].key === 'defaultPattern' ? (
        <DefaultPattern pieces={pieces} patterns={patterns} />
      ) : null}
    </Container>
  );
};

const Container = styled('div')({
  maxHeight: window.innerHeight - 334,
  overflowY: 'auto',

  padding: '14px',
});
