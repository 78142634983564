import { api } from './api';

export type CatalogAssembly = {
  assemblyId: number;
  name?: string;
  isDisplayed: boolean;
};

export type PieceCatalog = {
  pieceId: number;
  name?: string;
  isDisplayed: boolean;
};

export type UnderAssemblyCatalog = {
  underAssemblyId: number;
  name?: string;
  isDisplayed: boolean;
};

export type Catalog = {
  id: string;
  name: string;
  _count: { userCatalog: number };
  catalogAssembly: CatalogAssembly[];
  pieceCatalog: PieceCatalog[];
  underAssemblyCatalog: UnderAssemblyCatalog[];
};

export type CatalogData = {
  name: string;
  catalogAssemblies: CatalogAssembly[];
  pieceCatalog: PieceCatalog[];
  underAssemblyCatalog: UnderAssemblyCatalog[];
};

export const findAllCatalogs = async () => {
  const { data } = await api.get<Catalog[]>('/catalog');

  return data;
};

export const createCatalog = async (datas: CatalogData) => {
  return api.post('/catalog', datas);
};

export const updateCatalog = async (datas: CatalogData, catalogId: string) => {
  return api.patch(`/catalog/${catalogId}`, datas);
};

export const deleteCatalog = async (catalogId: string) => {
  return api.delete(`/catalog/${catalogId}`);
};
