import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import { useUser } from '../context/UserProvider';
import { updateUser } from '../services/user';
import { handleMutation } from '../utils/api.utils';
import useToggle from './useToggle';

const schemaUserForm = z.object({
  email: z.string().refine((val) => isEmail(val)),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
});

type SchemaUserForm = z.infer<typeof schemaUserForm>;

const emptyUser = {
  firstName: '',
  lastName: '',
  email: '',
};

const useAdminInformations = () => {
  const [edit, toggleEdit] = useToggle();
  const [editPassword, toggleEditPassword] = useToggle();

  const { user } = useUser();

  const defaultValues = useMemo(
    () =>
      user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          }
        : emptyUser,
    [user],
  );

  const methods = useForm<SchemaUserForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaUserForm),
  });

  const submit: SubmitHandler<SchemaUserForm> = (data) => {
    handleMutation(
      (datas) => updateUser(datas, user?.id ?? ''),
      {
        ...data,
      },
      () => {
        toggleEdit();
        toast.success('Informations mises à jour avec succès');
      },
    );
  };

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods, user]);

  return { edit, toggleEdit, methods, submit, editPassword, toggleEditPassword, user };
};

export default useAdminInformations;
