export const searchBarMatch = (search: string, elements: (string | undefined | null)[]) => {
  const regexArr = search
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .split(/\s+/g)
    .map((pattern) => new RegExp(pattern, 'i'));

  return elements.some((element) => {
    if (!element) return false;
    const normalizedString = element.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
    return regexArr.every((regex) => regex.test(normalizedString));
  });
};
