import { api } from './api';

export type QuoteSettings = {
  activeDeposit: boolean;
  deposit: number;
  euroDeposit: boolean;
  activeSoleInstallation: boolean;
  soleInstallation: number;
  addInstallationToSolePrice: boolean;
  activeMonumentInstallation: boolean;
  monumentInstallation: number;
  activeCineraryInstallation: boolean;
  cineraryInstallation: number;
  activeSpecialInstallation: boolean;
  specialInstallation: number;
  addInstallationToMonumentPrice: boolean;
  activeCustomDelivery: boolean;
  customDelivery: number;
  postageDue: boolean;
  simplifiedQuote: boolean;
  footerText: string;
  displayDelivery: boolean;
};

export type UpdateQuoteSettings = {
  activeDeposit: boolean;
  deposit: string;
  euroDeposit: boolean;
  activeSoleInstallation: boolean;
  soleInstallation: string;
  addInstallationToSolePrice: boolean;
  activeMonumentInstallation: boolean;
  monumentInstallation: string;
  activeCineraryInstallation: boolean;
  cineraryInstallation: string;
  activeSpecialInstallation: boolean;
  specialInstallation: string;
  addInstallationToMonumentPrice: boolean;
  activeCustomDelivery: boolean;
  customDelivery: string;
  postageDue: boolean;
  simplifiedQuote: boolean;
  footerText: string;
  displayDelivery: boolean;
};

export const findQuoteSettings = async (userId: string) => {
  const { data } = await api.get<QuoteSettings>(`/quote-settings/${userId}`);

  return data;
};

export const updateQuoteSettings = async (datas: UpdateQuoteSettings, userId: string) => {
  const formatedDatas = {
    activeDeposit: datas.activeDeposit,
    deposit: Number.parseFloat(datas.deposit),
    euroDeposit: datas.euroDeposit,
    activeSoleInstallation: datas.activeSoleInstallation,
    soleInstallation: Number.parseFloat(datas.soleInstallation),
    addInstallationToSolePrice: datas.addInstallationToSolePrice,
    activeMonumentInstallation: datas.activeMonumentInstallation,
    monumentInstallation: Number.parseFloat(datas.monumentInstallation),
    activeCineraryInstallation: datas.activeCineraryInstallation,
    cineraryInstallation: Number.parseFloat(datas.cineraryInstallation),
    activeSpecialInstallation: datas.activeSpecialInstallation,
    specialInstallation: Number.parseFloat(datas.specialInstallation),
    addInstallationToMonumentPrice: datas.addInstallationToMonumentPrice,
    activeCustomDelivery: datas.activeCustomDelivery,
    customDelivery: Number.parseFloat(datas.customDelivery),
    postageDue: datas.postageDue,
    displayDelivery: datas.displayDelivery,
    simplifiedQuote: datas.simplifiedQuote,
    footerText: Number.parseFloat(datas.footerText),
  };
  return api.post(`/quote-settings/${userId}`, formatedDatas);
};
