/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Modification } from 'src/services/configurator';

import { getPieceSize } from './configurator.utils';
import { getModificationData, getNewGeometryWithSimpleMode } from './modification.utils';

export const applyModification = (
  modificationToApply: Modification,
  delta: number,
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void,
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  },
  pieces: any[],
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void,
  axe: 'x' | 'y' | 'z',
  modificationPieceIndex: number,
) => {
  if (modificationToApply) {
    const data = getModificationData(modificationToApply);

    const pieceSize = getPieceSize(
      axe,
      pieces[modificationPieceIndex].geometry.attributes.position.array as Float32Array,
    );

    const dataSize = {
      size: (Number.parseFloat(pieceSize) + delta).toFixed(2),
      axis: data.simpleModeAxis,
    };

    if (modificationPieceIndex !== -1) {
      const copyGeometry = getNewGeometryWithSimpleMode(
        history,
        modificationPieceIndex,
        data,
        dataSize,
        pieces,
      );

      changeGeometry(modificationPieceIndex, copyGeometry);
      checkCollision(modificationPieceIndex, axe);
    }
  }
};
