import { Delete, Edit, West } from '@mui/icons-material';
import { Box, IconButton, Input, styled, Typography } from '@mui/material';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import { toast } from 'react-toastify';

import { CenteredModal } from '../../../../components/CenteredModal';
import { FlexBox, FormLine, LowerCaseButton, RoundedButton } from '../../../../components/common';
import { CustomPhoneInput } from '../../../../components/forms/PhoneInputRhf';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../context/EnvProvider';
import { Buckets } from '../../../../services/file';
import { updateProfilePicture } from '../../../../services/user';
import { uploadImage } from '../../../../utils/file.utils';
import { isElevatedRole, isHeadOffice } from '../../../../utils/user.utils';
import EditPasswordForm from '../../components/EditPassword';
import useGeneral from './useGeneral';

export const ProfilePictureContainer = styled('div')({
  width: '12%',
  position: 'relative',
  float: 'left',
  borderRadius: '100%',
  border: '1px solid #548797',
  margin: '30px 4% 30px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&::before': {
    content: '""',
    paddingTop: '100%',
    display: 'block',
  },
});

export const Profile = styled('img')({
  objectFit: 'contain',
  background: 'none',
  height: '100%',
  width: '100%',
});

export const StyledEditIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '100%',
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  position: 'absolute',
  bottom: 0,
  right: 0,
  boxSizing: 'border-box',
  backgroundColor: 'white',
  padding: '8px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#F5F5F5',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
  },
}));

export const Wrapper = styled(Box)({
  width: '83%',
  display: 'flex',
  gap: '10px',
  padding: '30px 0',
  boxSizing: 'border-box',
  flexWrap: 'wrap',
});

export const CornerBox = styled(Box)({
  position: 'absolute',
  top: '0',
  right: '0',
  margin: '15px 40px',
});

export const ImageContainer = styled(Box)({
  position: 'absolute',
  height: '100%',
  maxWidth: '100%',
  borderRadius: '100%',
  overflow: 'hidden',
});

export const uploadProfilePicture = async (
  file: File,
  userId: string | null | undefined,
  onRefresh: () => void,
) => {
  const pictureName = await uploadImage(file, Buckets.BUCKET_PROFILE_PICTURES);
  if (pictureName && userId) {
    await updateProfilePicture(pictureName, userId);
    toast.success('Photo de profil mise à jour');
    onRefresh();
  }
};

const General = () => {
  const {
    edit,
    toggleEdit,
    methods,
    submit,
    billingAddress,
    setBillingAddress,
    editPassword,
    toggleEditPassword,
    user,
    onRefresh,
    setEdit,
  } = useGeneral();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;

  const { buckets } = useEnv();

  return (
    <>
      <form onSubmit={handleSubmit(submit)} style={{ padding: '30px' }}>
        <CornerBox>
          {edit && (
            <LowerCaseButton variant="contained" type="submit">
              Enregistrer les modifications
            </LowerCaseButton>
          )}
          {!edit && (
            <LowerCaseButton variant="contained" onClick={toggleEdit}>
              Modifier les Informations
            </LowerCaseButton>
          )}
        </CornerBox>

        <Typography variant="h5">INFORMATIONS GÉNÉRALES</Typography>
        <ProfilePictureContainer>
          <ImageContainer>
            <Profile
              src={`${buckets?.BUCKET_PROFILE_PICTURES ?? ''}/${user?.profilePicture ?? 'default'}`}
              alt="pp"
            />
          </ImageContainer>
          <StyledEditIcon component="label">
            <Input
              type="file"
              sx={{ display: 'none' }}
              inputProps={{
                multiple: false,
                accept: 'image',
              }}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  await uploadProfilePicture(e.target.files[0], user?.id, onRefresh);
                }
              }}
            />
            <Edit />
          </StyledEditIcon>
        </ProfilePictureContainer>
        <Wrapper>
          <FormLine>
            <TextFieldRHF
              name="reason"
              control={control}
              TextFieldProps={{
                label: 'Raison sociale',
                error: Boolean(errors.reason),
                helperText: errors.reason?.message,
                disabled: !edit,
              }}
            />
            {!isElevatedRole() && (
              <TextFieldRHF
                name="name"
                control={control}
                TextFieldProps={{
                  label: "Nom de l'agence",
                  error: Boolean(errors.name),
                  helperText: errors.name?.message,
                  disabled: !edit,
                }}
              />
            )}
            <PhoneInput
              name="phone"
              control={control}
              inputComponent={CustomPhoneInput}
              label="Téléphone"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              disabled={!edit}
              defaultCountry="FR"
            />
            {isHeadOffice(user) && <div />}
            <div />
          </FormLine>
          <Typography variant="h6">Adresse de Livraison</Typography>
          <FormLine>
            <TextFieldRHF
              name="deliveryAddress.address"
              control={control}
              TextFieldProps={{
                label: 'Adresse',
                error: Boolean(errors.deliveryAddress?.address),
                helperText: errors.deliveryAddress?.address?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.addressDetails"
              control={control}
              TextFieldProps={{
                label: "Complément d'adresse",
                error: Boolean(errors.deliveryAddress?.addressDetails),
                helperText: errors.deliveryAddress?.addressDetails?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.zipCode"
              control={control}
              TextFieldProps={{
                label: 'Code Postal',
                error: Boolean(errors.deliveryAddress?.zipCode),
                helperText: errors.deliveryAddress?.zipCode?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.city"
              control={control}
              TextFieldProps={{
                label: 'Ville',
                error: Boolean(errors.deliveryAddress?.city),
                helperText: errors.deliveryAddress?.city?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.country"
              control={control}
              TextFieldProps={{
                label: 'Pays',
                error: Boolean(errors.deliveryAddress?.country),
                helperText: errors.deliveryAddress?.country?.message,
                disabled: !edit,
              }}
            />
          </FormLine>
          {billingAddress ? (
            <>
              <Typography variant="h6">Adresse de Facturation</Typography>
              <FormLine>
                {edit && (
                  <IconButton
                    sx={{ position: 'absolute', left: '-45px', top: '7px' }}
                    onClick={() => setBillingAddress(false)}
                  >
                    <Delete color="error" />
                  </IconButton>
                )}

                <TextFieldRHF
                  name="billingAddress.address"
                  control={control}
                  TextFieldProps={{
                    label: 'Adresse',
                    error: Boolean(errors.billingAddress?.address),
                    helperText: errors.billingAddress?.address?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.addressDetails"
                  control={control}
                  TextFieldProps={{
                    label: "Complément d'adresse",
                    error: Boolean(errors.billingAddress?.addressDetails),
                    helperText: errors.billingAddress?.addressDetails?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.zipCode"
                  control={control}
                  TextFieldProps={{
                    label: 'Code Postal',
                    error: Boolean(errors.billingAddress?.zipCode),
                    helperText: errors.billingAddress?.zipCode?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.city"
                  control={control}
                  TextFieldProps={{
                    label: 'Ville',
                    error: Boolean(errors.billingAddress?.city),
                    helperText: errors.billingAddress?.city?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.country"
                  control={control}
                  TextFieldProps={{
                    label: 'Pays',
                    error: Boolean(errors.billingAddress?.country),
                    helperText: errors.billingAddress?.country?.message,
                    disabled: !edit,
                  }}
                />
              </FormLine>
            </>
          ) : (
            <FlexBox sx={{ flexDirection: 'column', gap: '5px' }}>
              <RoundedButton
                variant="outlined"
                onClick={() => {
                  setBillingAddress(true);
                  setEdit(true);
                }}
              >
                <West color="primary" />
                Ajouter une adresse de facturation
              </RoundedButton>
              <Typography textAlign="right" variant="caption">
                Si différente de l’adresse de livraison
              </Typography>
            </FlexBox>
          )}
        </Wrapper>
        <Typography variant="h5">INFORMATIONS DE CONNEXION</Typography>
        <Wrapper>
          <FormLine>
            <TextFieldRHF
              name="lastName"
              control={control}
              TextFieldProps={{
                label: 'Nom',
                error: Boolean(errors.lastName),
                helperText: errors.lastName?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="firstName"
              control={control}
              TextFieldProps={{
                label: 'Prénom',
                error: Boolean(errors.firstName),
                helperText: errors.firstName?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="email"
              control={control}
              TextFieldProps={{
                label: 'Email',
                error: Boolean(errors.email),
                helperText: errors.email?.message,
                disabled: !edit,
              }}
            />
            <LowerCaseButton variant="contained" onClick={toggleEditPassword}>
              Modifier le mot de passe
            </LowerCaseButton>
          </FormLine>
        </Wrapper>
      </form>
      <CenteredModal open={editPassword} handleClose={toggleEditPassword} padding>
        <EditPasswordForm handleClose={toggleEditPassword} />
      </CenteredModal>
    </>
  );
};

export default General;
