import { zodResolver } from '@hookform/resolvers/zod';
import { AlertColor } from '@mui/material';
import axios from 'axios';
import { addDays, addHours } from 'date-fns';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import paths from '../../navigation/paths';
import { login } from '../../services/auth';

const schemaLoginForm = z.object({
  email: z.string().refine((val) => isEmail(val)),
  password: z.string().trim().min(1),
});

type SchemaLoginForm = z.infer<typeof schemaLoginForm>;

const useLogin = () => {
  const [hasLoginAlert, setHasLoginAlert] = useState<AlertColor>();
  const [alertMessage, SetAlertMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaLoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(schemaLoginForm),
  });

  const onSubmit: SubmitHandler<SchemaLoginForm> = async (data) => {
    try {
      const { token, admin } = await login(data);
      localStorage.setItem(
        'auth',
        JSON.stringify({
          accessToken: token,
          expiry: rememberMe
            ? addDays(new Date(), 100).getTime()
            : addHours(new Date(), 100).getTime(),
        }),
      );
      localStorage.setItem('admin', String(admin));
      if (admin) navigate(`/${paths.admin.root}`);
      navigate(`/${paths.home.root}`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const disabled = error.response?.data?.message === 'DISABLED';
        SetAlertMessage(
          disabled
            ? "Votre compte a été refusé, si vous pensez que c'est une erreur veuillez contacter VisioGranits"
            : 'Votre compte est en attente de validation, pour accéder au configurateur, veuillez patienter ou contacter VisioGranits',
        );
        setHasLoginAlert(disabled ? 'error' : 'warning');
      } else {
        SetAlertMessage('Mot de passe ou adresse mail incorrect');
        setHasLoginAlert('error');
      }
    }
  };

  return {
    control,
    handleSubmit,
    errors,
    hasLoginAlert,
    rememberMe,
    setRememberMe,
    onSubmit,
    alertMessage,
  };
};

export default useLogin;
