import { Box, Typography } from '@mui/material';

import { CenteredModal } from '../../../../components/CenteredModal';
import { FormLine, LowerCaseButton } from '../../../../components/common';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import useAdminInformations from '../../../../hook/useAdminInformations';
import EditPasswordForm from '../../../mySpace/components/EditPassword';

const AdminInformations = () => {
  const { edit, toggleEdit, methods, submit, editPassword, toggleEditPassword } =
    useAdminInformations();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;

  return (
    <>
      <Box
        component="div"
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <form
          onSubmit={handleSubmit(submit)}
          style={{
            width: '50%',
            display: 'flex',
            gap: '30px',
            flexDirection: 'column',
            padding: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">INFORMATIONS DE CONNEXION</Typography>
          <TextFieldRHF
            name="lastName"
            control={control}
            TextFieldProps={{
              label: 'Nom',
              error: Boolean(errors.lastName),
              helperText: errors.lastName?.message,
              disabled: !edit,
            }}
          />
          <TextFieldRHF
            name="firstName"
            control={control}
            TextFieldProps={{
              label: 'Prénom',
              error: Boolean(errors.firstName),
              helperText: errors.firstName?.message,
              disabled: !edit,
            }}
          />
          <TextFieldRHF
            name="email"
            control={control}
            TextFieldProps={{
              label: 'Email',
              error: Boolean(errors.email),
              helperText: errors.email?.message,
              disabled: !edit,
            }}
          />
          <FormLine>
            {edit && (
              <LowerCaseButton variant="contained" type="submit">
                Enregistrer les modifications
              </LowerCaseButton>
            )}
            {!edit && (
              <LowerCaseButton variant="contained" onClick={toggleEdit}>
                Modifier les Informations
              </LowerCaseButton>
            )}
          </FormLine>
          <FormLine>
            <LowerCaseButton variant="contained" onClick={toggleEditPassword}>
              Modifier le mot de passe
            </LowerCaseButton>
          </FormLine>
        </form>
      </Box>
      <CenteredModal open={editPassword} handleClose={toggleEditPassword} padding>
        <EditPasswordForm handleClose={toggleEditPassword} />
      </CenteredModal>
    </>
  );
};

export default AdminInformations;
