import { api } from './api';

export type GlobalCoefficients = {
  frenchEco: number;
  frenchNormal: number;
  importEco: number;
  importNormal: number;
  patterns: number;
  accessories: number;
  delivery: number;
  coeffImport: number;
  coeffFr: number;
  coeffEcoImport: number;
  coeffEcoFr: number;
  engraving: Engravings;
};

export type Engravings = {
  gold: {
    coeff1: number;
    coeff2: number;
    coeff3: number;
    coeff4: number;
    coeff5: number;
    coeff6: number;
    coeff7: number;
  };
  white: {
    coeff1: number;
    coeff2: number;
    coeff3: number;
    coeff4: number;
    coeff5: number;
    coeff6: number;
    coeff7: number;
  };
};

export type Coefficients = {
  isDetailed: boolean;
  globalCoefs: GlobalCoefficients;
  detailedCoefs: { [K in 'MONUMENT' | 'CINERARY' | 'COLUMBARIUM']: { [key: string]: number } };
};

export const getUserCoefficients = async (userId: string) => {
  const { data } = await api.get<Coefficients>(`/coefficients/${userId}`);

  return data;
};

export const updateGlobalCoefficients = async (userId: string, coefficients: Coefficients) => {
  return api.put(`/coefficients/${userId}`, coefficients);
};

export const getAdminEngravingsPrice = async () => {
  const { data } = await api.get<Engravings>(`/coefficients/admin/engravings`);

  return data;
};

export const updateAdminEngravingsCoefficients = async (engravings: Engravings) => {
  return api.put(`/coefficients/admin/engravings`, engravings);
};
