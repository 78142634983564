type GenericObject = Record<string, unknown>;

export const areSameObject = <T extends GenericObject>(objectA: T, objectB: T): boolean => {
  const keys = Object.keys(objectA);

  return keys.every((key) => {
    if (typeof objectA[key] === 'object') {
      return areSameObject(objectA[key] as GenericObject, objectB[key] as GenericObject);
    }
    return objectA[key] === objectB[key];
  });
};
