/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CategoryType } from 'src/constants/category';

import { api } from './api';
import { Fabrication } from './assemblies';

export type Piece = {
  id: number;
  name: string;
  pieceUrl: string;
  pictureUrl: string;
  type: CategoryType;
  texture: any;
  pieceFabrication: { fabrication: Fabrication }[];
  underAssembly?: any;
  blockedAxis: string[];
  uuid?: any;
  frenchLinearDetails: any;
  frenchM2Details: any;
  importLinearDetails: any;
  importM2Details: any;
  polishingX: number;
  polishingY: number;
  polishingZ: number;
  sawingX: number;
  sawingY: number;
  sawingZ: number;
  frenchExtra: number;
  importExtra: number;
  isBlocked: boolean;
};

const pieceTypes = [
  'STELE',
  'TOMB',
  'FOUNDATION',
  'PLATING',
  'BOX',
  'SIDEWALKS',
  'SOLE',
  'ANY',
  'VASE',
  'FLOWER',
  'URNS',
  'ANY_ACCESSORY',
];

export type PieceType = (typeof pieceTypes)[number];

export const getPieces = async (type: CategoryType | undefined) => {
  const { data } = await api.get<Piece[]>(type ? `/pieces/${type}` : '/pieces');
  return data;
};

export const getPiece = async (id: string) => {
  const { data } = await api.get<Piece>(`/pieces/${id}`);
  return data;
};

export const deletePiece = async (id: string) => {
  await api.delete(`/pieces/${id}`);
};

export const updatePiece = async (
  id: number,
  name: string,
  category: CategoryType,
  fabricationFilterState: string[],
  blockedAxis: string[],
  polishing: { x: number; y: number; z: number },
  sawing: { x: number; y: number; z: number },
  details: {
    french: { extra: number; linear: any; m2: any };
    import: { extra: number; linear: any; m2: any };
  },
) => {
  await api.patch(`/pieces/${id}`, {
    name,
    category,
    fabricationFilterState,
    blockedAxis,
    polishing,
    sawing,
    details,
  });
};

export const createPiece = async (
  name: string,
  pieceUrl: string,
  pictureUrl: string,
  category: CategoryType,
  fabricationFilterState: string[],
  blockedAxis: string[],
  polishing: { x: number; y: number; z: number },
  sawing: { x: number; y: number; z: number },
  details: {
    french: { extra: number; linear: any; m2: any };
    import: { extra: number; linear: any; m2: any };
  },
) => {
  const { data } = await api.post(`/pieces`, {
    name,
    pieceUrl,
    pictureUrl,
    category,
    fabricationFilterState,
    blockedAxis,
    polishing,
    sawing,
    details,
  });

  return data;
};

export const setDefaultPattern = async (payload: any) => {
  const { data } = await api.post(`/pieces/defaultPattern`, {
    patterns: payload,
  });

  return data;
};
