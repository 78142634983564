import { api } from './api';

export enum Buckets {
  'BUCKET_3D_PIECES' = 'BUCKET_3D_PIECES',
  'BUCKET_PROFILE_PICTURES' = 'BUCKET_PROFILE_PICTURES',
}

export type BucketsUrls = { [bucket in Buckets]: string };

export type SignedUploadUrl = {
  url: string;
  key: string;
};

export const getUploadUrl = async (bucketName: string) => {
  const { data } = await api.get<SignedUploadUrl>(`/file/url/${bucketName}`);

  return data;
};

export const getBuckets = async () => {
  const { data } = await api.get<BucketsUrls>('/file/buckets');

  return data;
};

export const getFile = async (key: string) => {
  const { data } = await api.get<string>(`/file/${key}`);
  return data;
};
