import { api } from './api';

export const createConstraint = (
  axis: string[],
  points: Float32Array[],
  pieceId: number,
  index: number,
) => {
  return api.post(`/constraints`, { axis, points, pieceId, index });
};

export type Constraint = {
  id: number;
  axis: string[];
  points: { x: number; z: number; y: number }[];
  pieceIndex: number;
};

export const getConstraints = async (pieceId: number) => {
  const { data } = await api.get<Constraint[]>(`/constraints/${pieceId}`);
  return data;
};

export const getConstraintsOfPieces = async (piecesId: number[]) => {
  if (!piecesId || piecesId.length === 0) return [];
  const { data } = await api.get<{ id: number; constraints: Constraint[] }[]>(
    `/constraints/pieces/${piecesId.join(',')}`,
  );
  return data;
};

export const deleteConstraint = async (id: number) => {
  await api.delete(`/constraints/${id}`);
};

export const createModification = (
  name: string,
  points: Float32Array[],
  distance: number,
  pieceId: number,
  index: number,
  simpleModeAxis?: 'x' | 'y' | 'z',
) => {
  return api.post(`/modifications`, {
    name,
    points,
    distance,
    pieceId,
    index,
    simpleModeAxis,
  });
};

export type Modification = {
  id: number;
  points: { x: number; z: number; y: number; id: number; index?: number }[];
  distance: number;
  name: string;
  pieceIndex: number;
  pieceId: number;
  simpleModeAxis?: 'x' | 'y' | 'z';
};

export const getModifications = async (pieceId: number) => {
  const { data } = await api.get<Modification[]>(`/modifications/${pieceId}`);
  return data;
};

export const getModificationsOfPieces = async (piecesId: number[]) => {
  const { data } = await api.get<{ id: number; modifications: Modification[] }[]>(
    `/modifications/pieces/${piecesId.join(',')}`,
  );
  return data;
};

export const deleteModification = async (id: number) => {
  await api.delete(`/modifications/${id}`);
};

export const createMacro = (
  name: string,
  macros: { modificationId: number; ratio: string; isFixed: boolean }[],
  constants: Constant[] | undefined,
  pieceId: number,
  index: number,
) => {
  return api.post(`/macros`, { name, macros, constants, pieceId, index });
};

export type Macro = {
  id: number;
  modifications: {
    ratio: string;
    modification: Modification;
    isFixed: boolean;
  }[];
  constants: { constant: Constant }[];
  name: string;
  pieceIndex: number;
};

export const getMacros = async (pieceId: number | null) => {
  const { data } = await api.get<Macro[]>(`/macros/${pieceId || ''}`);
  return data;
};

export const deleteMacro = async (id: number) => {
  await api.delete(`/macros/${id}`);
};

export const createConstant = (name: string, distance: number, pieceId: number, index: number) => {
  return api.post(`/constants`, { name, distance, pieceId, index });
};

export type Constant = {
  id: number;
  distance: number;
  name: string;
  pieceIndex: number;
};

export const getConstants = async (pieceId: number) => {
  const { data } = await api.get<Constant[]>(`/constants/${pieceId}`);
  return data;
};

export const deleteConstant = async (id: number) => {
  await api.delete(`/constants/${id}`);
};

export const createLinkedMacro = (macros: Macro[]) => {
  return api.post(`/macros/link`, { macros });
};

export type LinkedMacro = {
  firstMacro: Macro;
  macroToTrigger: Macro;
};

export const getLinkedMacro = async (pieceId: number) => {
  const { data } = await api.get<LinkedMacro[]>(`/macros/link/${pieceId}`);
  return data;
};

export type LinkedPieces = {
  id: number;
  piecesIndex: number[];
  axis: string[];
};

export const getLinkedPiecesOnAssembly = async (assemblyId: number) => {
  const { data } = await api.get<LinkedPieces[]>(`/linked-pieces/assembly/${assemblyId}`);
  return data;
};

export const getLinkedPiecesOnUnderAssembly = async (underAssemblyId: number) => {
  const { data } = await api.get<LinkedPieces[]>(`/linked-pieces/underAssembly/${underAssemblyId}`);
  return data;
};

export const createLinkedPieces = (
  piecesIndex: number[],
  assemblyId: number,
  axis: string[],
  mode: string,
) => {
  return api.post(`/linked-pieces`, { piecesIndex, assemblyId, axis, mode });
};

export const deleteLinkedPiecesOnAssembly = async (id: number) => {
  await api.delete(`/linked-pieces/assembly/${id}`);
};

export const deleteLinkedPiecesOnUnderAssembly = async (id: number) => {
  await api.delete(`/linked-pieces/underAssembly/${id}`);
};
