import { styled, Switch } from '@mui/material';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 60,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    height: '24px',
    // width: '60px',
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    margin: 1,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
  },
}));

export default StyledSwitch;
