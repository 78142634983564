/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useUser } from 'src/context/UserProvider';
import { useMacros } from 'src/hook/useMacros';
import { deleteMacro, Modification } from 'src/services/configurator';
import styled from 'styled-components';

import { isAdmin } from '../../../../../utils/user.utils';
import { CreateMacro } from '../../Admin/CreateMacro';

type MacrosProps = {
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void;
  modifications: Modification[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  pieces: any[];
  pieceIndex: number;
  activePieces: any[];
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void;
};

export const Macros = ({
  history,
  modifications,
  changePoint,
  pieces,
  pieceIndex,
  activePieces,
  changeGeometry,
  checkCollision,
}: MacrosProps) => {
  const { user } = useUser();
  const { size, setSize, macros, option, setOption, applyMacro, refreshMacros } = useMacros(
    history,
    changePoint,
    changeGeometry,
    pieces[pieceIndex] ? pieces[pieceIndex].id : -1,
    pieceIndex,
    activePieces,
    pieces,
    checkCollision,
  );

  return (
    <Container>
      {isAdmin(user) ? (
        <Menu>
          <p onClick={() => setOption('macro')}>Macros</p>|
          <p onClick={() => setOption('create')}>Création</p>
        </Menu>
      ) : null}
      {option === 'create' ? (
        <CreateMacro
          modifications={modifications}
          pieceId={pieces[pieceIndex].id}
          pieceIndex={pieceIndex}
          refreshMacros={refreshMacros}
        />
      ) : null}
      {option === 'macro'
        ? macros?.map((macro, index) => {
            // if (macro.pieceIndex !== pieceIndex) return <></>;
            return (
              <Column key={index}>
                <Label>{macro.name}</Label>
                <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Input
                    value={size && size[index] ? size[index] : ''}
                    onChange={(e) => {
                      setSize(
                        size?.map((el, i) => {
                          if (i === index) return e.target.value;
                          return el;
                        }),
                      );
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        applyMacro(index);
                      }
                    }}
                    onBlur={() => {
                      applyMacro(index);
                    }}
                  />
                  {isAdmin(user) ? (
                    <div
                      style={{ cursor: 'pointer', marginLeft: '12px' }}
                      onClick={async () => {
                        await deleteMacro(macro.id);
                        refreshMacros();
                      }}
                    >
                      x
                    </div>
                  ) : null}
                </Row>
              </Column>
            );
          })
        : null}
    </Container>
  );
};

const Container = styled('div')({});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  cursor: 'pointer',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '14px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
