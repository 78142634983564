import { CustomSteleType } from 'src/components/3d/Stele';
import { CategoryType } from 'src/constants/category';
import { Vector3 } from 'three';

import { api } from './api';
import { Granit } from './granits';
import { Piece } from './pieces';
import { Quote } from './quotes';
import { AdditionalsPriceType } from './customStele';

export type Position = {
  x: number;
  y: number;
  z: number;
};

export const fabrications = ['FRENCH', 'CHINA', 'INDIA', 'OTHER', 'IMPORT'] as const;
export type Fabrication = (typeof fabrications)[number];

export type Assembly = {
  id: number;
  name: string;
  type: 'MONUMENT' | 'COLUMBARIUM' | 'CINERARY';
  fabrication: Fabrication[];
  style: string[];
  pictureUrl: string;
  customImage: string;
  width: number;
  depth: number;
  modelId: number;
  description: string;
  pieces: {
    isBlocked: boolean;
    piece: Piece;
    position: Position;
    newGeometry: number[];
    granit: Granit;
    patterns: {
      assemblyId: number;
      id: number;
      orientation: string;
      pattern?: {
        id: number;
        image: string;
        name: string;
        description: string;
        reducedImage: string;
        height: number;
        ratio: number;
        price: number;
        leaderGranitEngraving: boolean;
        user: { role: string };
      };
      pieceId: number;
      patternId?: number;
      positionX: number;
      positionY: number;
      scaleX: number;
      scaleY: number;
      image: string;
      camera: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      letterSpacing: number;
      text: string;
      align: string;
      type: string;
      imageRatio: number;
    }[];
  }[];

  underAssemblyOnAssembly: {
    assemblyId: number;
    underAssembly: {
      id: number;
      name: string;
      pieceOnUnderAssembly: {
        id: number;
        piece: Piece;
        position: Position;
      }[];
      modificationOnUnderAssembly: {
        modification: any;
        type: string;
      }[];
      UnderAssemblyFabrication: {
        fabrication: string;
      }[];
    };
    underAssemblyOnAssemblyDetailsPiece: {
      pieceOnUnderAssemblyId: number;
      position: Position;
      newGeometry: number[];
      granit: Granit;
      isBlocked: boolean;
      patterns: {
        assemblyId: number;
        id: number;
        orientation: string;
        pattern?: {
          id: number;
          image: string;
          name: string;
          description: string;
          reducedImage: string;
          height: number;
          ratio: number;
          price: number;
          leaderGranitEngraving: boolean;
          user: { role: string };
        };
        pieceId: number;
        patternId?: number;
        positionX: number;
        positionY: number;
        scaleX: number;
        scaleY: number;
        image: string;
        camera: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        letterSpacing: number;
        text: string;
        align: string;
        type: string;
        imageRatio: number;
      }[];
    }[];
  }[];
  steles: {
    id: string;
    boxLeftHeight: number;
    boxRightHeight: number;
    boxHeight: number;
    boxUpWidth: number;
    boxDownWidth: number;
    headIndex: number;
    baseIndex: number;
    depth: number;
    shape: any;
    extrudeSettings: any;
    position: Vector3;
    stele?: any;
    granit?: Granit;
    texture?: THREE.Texture | null;
    isBlocked?: boolean;
    additionalPriceForCustomStele: AdditionalsPriceType;
    patterns: {
      assemblyId: number;
      id: number;
      orientation: string;
      pattern?: {
        id: number;
        image: string;
        name: string;
        description: string;
        reducedImage: string;
        height: number;
        ratio: number;
        price: number;
        user: { role: string };
      };
      pieceId: number;
      patternId?: number;
      positionX: number;
      positionY: number;
      scaleX: number;
      scaleY: number;
      image: string;
      camera: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      letterSpacing: number;
      text: string;
      align: string;
      type: string;
      imageRatio: number;
    }[];
  }[];
  platings: {
    position: Vector3;
    plating?: {
      id: string;
      back: number;
      front: number;
      depth: number;
      length: number;
      monumentBack: number;
      monumentFront: number;
      monumentLength: number;
      monumentWidth: number;
      isActive: boolean;
      key: string;
      isRotated: boolean;
    };
    granit?: Granit;
    texture?: THREE.Texture | null;
    isBlocked?: boolean;
  }[];
  quote?: Quote;
};

export const getAllAssemblies = async () => {
  const { data } = await api.get<Assembly[]>(`/assemblies/all`);
  return data;
};

export const getUserAssemblies = async (userId: string | undefined) => {
  if (!userId) return [];
  const { data } = await api.get<Assembly[]>(`/assemblies/user/${userId}`);
  return data;
};

export const getAssembly = async (id: string | undefined) => {
  if (!id) return null;

  const { data } = await api.get<Assembly>(`/assemblies/${id}`);
  return data;
};

export type CreateAssembly = {
  name: string;
  pictureUrl: string;
  customImage: string;
  pieces: {
    id: number;
    position: Position;
    newGeometry: number[];
  }[];
  steles: CustomSteleType[];
  underAssemblies: {
    id: number;
    pieces: {
      id: number;
      position: Position;
      positionId: number;
      newGeometry: number[];
    }[];
  }[];
  fabrication: string[];
  style: string[];
  type: string;
  description: string;
};

export const createAssembly = async (data: CreateAssembly) => {
  return api.post<void>(`/assemblies`, data);
};

export type UpdateAssembly = {
  width: number;
  depth: number;
  pieces: {
    id: number;
    position: Position;
    newGeometry: number[];
  }[];
  steles: CustomSteleType[];
  underAssemblies: {
    id: number;
    pieces: {
      id: number;
      position: Position;
      positionId: number;
      newGeometry: number[];
    }[];
  }[];
};

export const updateAssembly = async (data: UpdateAssembly, id: string) => {
  return api.patch<void>(`/assemblies/${id}`, data);
};

export const deleteAssembly = async (id: number) => {
  await api.delete(`/assemblies/${id}`);
};

export type CreateUnderAssembly = {
  name: string;
  pictureUrl: string;
  pieces: {
    id: number;
    position: Position;
  }[];
  modificationsIds: (number | undefined)[];
  fabricationFilterState: string[];
};

export const createUnderAssembly = async (data: CreateUnderAssembly) => {
  return api.post(`/under-assemblies`, data);
};

export type UnderAssembly = {
  id: number;
  name: string;
  pictureUrl: string;
  type: string;
  pieceOnUnderAssembly: {
    pieceId: number;
    piece: Piece;
    position: Position;
    positionId: number;
  }[];
};

export const getUnderAssemblies = async (type: CategoryType | undefined) => {
  const { data } = await api.get<UnderAssembly[]>(
    type ? `/under-assemblies/${type}` : '/under-assemblies',
  );
  return data;
};

export const updatePictureOfAssembly = async (id: number, pictureUrl: string) => {
  await api.patch(`/assemblies/picture/${id}`, {
    pictureUrl,
  });
};
