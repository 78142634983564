import { SvgIcon, SvgIconProps } from '@mui/material';

const FrenchFlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 12">
      <path d="M5.5 0.5H10.5V11.5H5.5V0.5Z" fill="white" />
      <path
        d="M2.5 0.5C0.84325 0.5 0 1.73125 0 3.25V8.75C0 10.2688 0.84325 11.5 2.5 11.5H5.5V0.5H2.5Z"
        fill="#0A2463"
      />
      <path
        d="M13 0.5H10.5V11.5H13.5C15.1568 11.5 16 10.2688 16 8.75V3.25C16 1.731 15.9895 0.5 13 0.5Z"
        fill="#C46D5E"
      />
    </SvgIcon>
  );
};

export default FrenchFlagIcon;
