/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import {
  float32ToPoint,
  getModificationWithHistory,
  getPointHistoryName,
  isAccesory,
  updateBoundingBox,
} from 'src/utils/configurator.utils';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import * as THREE from 'three';
import { Vector3, Box3 } from 'three';
import { movePieceOnTop } from 'src/utils/piece';
import { useEnv } from '../context/EnvProvider';

export const useImportPiece = (
  activePieces: any[],
  setActivePieces: (activePieces: any[]) => void,
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  },
  setHistory: (history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  }) => void,
) => {
  const loader = new GLTFLoader();

  const [pieces, setPieces] = useState<any[]>([]);
  const [loadedPieces, setLoadedPieces] = useState<{ id: number; piece: GLTF }[] | undefined>();
  const { buckets } = useEnv();

  const saveHistoryWithUUID = (uuid: string, array: Float32Array, newArray?: Float32Array) => {
    const newHistory: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    } = history;
    const newIndexHistory: { [key: string]: Float32Array } = history[uuid] ?? {};
    const geometry: Float32Array = array;

    for (let i = 0; i < geometry.length; i += 3) {
      const point = new Float32Array([geometry[i + 0], geometry[i + 1], geometry[i + 2]]);

      if (newArray) {
        const newPoint = new Float32Array([newArray[i + 0], newArray[i + 1], newArray[i + 2]]);
        newIndexHistory[getPointHistoryName(point)] = newPoint;
      } else {
        newIndexHistory[getPointHistoryName(point)] = point;
      }
    }

    newHistory[uuid] = newIndexHistory;
    setHistory(newHistory);
  };

  const removeImportedPiece = (pieceIndexToRemove: number | number[]) => {
    setPieces(
      pieces.filter((el, index) => {
        if (typeof pieceIndexToRemove === 'number') {
          return index !== pieceIndexToRemove;
        }
        return !pieceIndexToRemove.includes(index);
      }),
    );
  };

  useEffect(() => {
    const pieceToLoad: any[] = [...activePieces];
    const newPieces: any[] = [];

    // if (activePieces.length > pieces.length) {
    //   for (const [index, el] of activePieces.entries()) {
    //     if (!pieces[index]) {
    //       newPieces.push(el);
    //     }
    //   }
    // }

    activePieces.forEach((el, index) => {
      if (!pieces.some((piece) => piece.uuid === el.uuid)) {
        newPieces.push(el);
      }
    });

    const loadData = async () => {
      const mapedPieceToLoad = loadedPieces
        ? pieceToLoad.filter((el) => !loadedPieces?.find((element) => element.id === el.id))
        : pieceToLoad;

      const awaitedloadedPieces = await Promise.all(
        mapedPieceToLoad.map((piece) => {
          const url = `${buckets?.BUCKET_3D_PIECES ?? ''}/${piece.pieceUrl as string}`;
          return loader.loadAsync(url);
        }),
      );

      const awaitedloadedPiecesMaped = awaitedloadedPieces.map((el, index) => {
        return {
          id: mapedPieceToLoad[index].id,
          piece: el,
        };
      });

      if (loadedPieces) {
        setLoadedPieces([...loadedPieces, ...awaitedloadedPiecesMaped]);
      } else {
        setLoadedPieces([...awaitedloadedPiecesMaped]);
      }

      const pieceToFormate = activePieces.map((el) => {
        const refArray = loadedPieces
          ? [...loadedPieces, ...awaitedloadedPiecesMaped]
          : [...awaitedloadedPiecesMaped];

        const originalPiece = refArray.find((element) => element.id === el.id);
        return originalPiece;
      });

      const formatedPiece: any[] = pieceToFormate.map((el) => {
        if (el!.piece.scene.children[0].children.length > 0) {
          const firstChild = el!.piece.scene.children[0].children[0];
          return {
            ...el!.piece.scene.children[0].children[0].clone(),
            otherChildren: el!.piece.scene.children[0].children.filter(
              (element) => element.uuid !== firstChild.uuid,
            ),
          };
        }
        return el!.piece.scene.children[0].clone();
      });

      const mapedPiece = formatedPiece.map((el, index) => {
        const deltaPosition = new Vector3(0, 0, 0);
        if (pieces[index] && pieceToLoad[index].uuid !== pieces[index].uuid) {
          const oldBox = new Box3();
          const newBox = new Box3();

          const oldBoxGeometry = [...pieces[index].geometry.attributes.position.array];
          const newBoxGeometry = [...el.geometry.attributes.position.array];

          oldBox.setFromArray(oldBoxGeometry);
          newBox.setFromArray(newBoxGeometry);

          const oldBaseCenter = new Vector3(
            (oldBox.min.x + oldBox.max.x) / 2,
            oldBox.min.y,
            (oldBox.min.z + oldBox.max.z) / 2,
          );

          const newBaseCenter = new Vector3(
            (newBox.min.x + newBox.max.x) / 2,
            newBox.min.y,
            (newBox.min.z + newBox.max.z) / 2,
          );

          deltaPosition.set(
            oldBaseCenter.x - newBaseCenter.x,
            oldBaseCenter.y - newBaseCenter.y,
            oldBaseCenter.z - newBaseCenter.z,
          );
        }
        if (pieceToLoad[index].position) {
          el?.position.set(
            +pieceToLoad[index].position.x,
            +pieceToLoad[index].position.y,
            +pieceToLoad[index].position.z,
          );
        }

        if (!pieces || (index > pieces.length && pieceToLoad[index].position)) {
          el?.position.set(
            +pieceToLoad[index].position.x,
            +pieceToLoad[index].position.y,
            +pieceToLoad[index].position.z,
          );
        } else if (pieces[index]) {
          el?.position.set(
            +pieces[index].position.x + deltaPosition.x,
            +pieces[index].position.y + deltaPosition.y,
            +pieces[index].position.z + deltaPosition.z,
          );
        }

        if (history[pieceToLoad[index].uuid] === undefined) {
          if (pieceToLoad[index].newGeometry) {
            saveHistoryWithUUID(
              pieceToLoad[index].uuid,
              el.geometry.attributes.position.array,
              new Float32Array(pieceToLoad[index].newGeometry),
            );

            el.geometry = el.geometry.clone();
            el.geometry.attributes.position.array = new Float32Array(
              pieceToLoad[index].newGeometry,
            );
            updateBoundingBox(el);
          } else {
            // setup history
            saveHistoryWithUUID(pieceToLoad[index].uuid, el.geometry.attributes.position.array);
          }
        } else {
          el.geometry = el.geometry.clone();
          const geometry = el.geometry.attributes.position.array;
          for (let i = 0; i < geometry.length; i += 3) {
            const point = new Float32Array([geometry[i + 0], geometry[i + 1], geometry[i + 2]]);
            const historyPoint = getModificationWithHistory(
              history[pieceToLoad[index].uuid],
              float32ToPoint(point),
            );
            geometry[i] = historyPoint.x;
            geometry[i + 1] = historyPoint.y;
            geometry[i + 2] = historyPoint.z;
          }
          updateBoundingBox(el);
        }

        return {
          ...el,
          geometry: el.geometry.clone(),
          id: pieceToLoad[index].id,
          uuid: pieceToLoad[index].uuid,
        };
      });

      centerNewPieces(mapedPiece, newPieces);

      for (const [elIndex, el] of mapedPiece.entries()) {
        if (isAccesory(activePieces[elIndex].type))
          movePieceOnTop(mapedPiece, elIndex, el.position as Vector3);
      }

      // attention avec LGC03, le point d'origine n'est pas bon

      setPieces([...mapedPiece]);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePieces]);

  const getBoxWithPieceAndPosition = (pieceToTake: any, position: Vector3) => {
    return new THREE.Box3().setFromArray(
      // eslint-disable-next-line array-callback-return
      pieceToTake.geometry.attributes.position.array.map((el: any, index: number) => {
        if (index % 3 === 0) return el + position.x;
        if (index % 3 === 1) return el + position.y;
        if (index % 3 === 2) return el + position.z;
      }),
    );
  };

  const centerNewPieces = (allPieces: any[], newActivePieces: any[]) => {
    if (newActivePieces.length === 0 || allPieces.length === 0) return;

    const newPieces = allPieces.slice(-newActivePieces.length);
    const actualPieces = allPieces.slice(0, allPieces.length - newActivePieces.length);

    if (actualPieces.length === 0) return;

    if (activePieces[allPieces.length - 1] && activePieces[allPieces.length - 1].underAssembly) {
      const mergedBoxOfNewPieces = new THREE.Box3().copy(
        getBoxWithPieceAndPosition(newPieces[0], newPieces[0].position),
      );
      const mergedBoxOfActualPieces = new THREE.Box3().copy(
        getBoxWithPieceAndPosition(actualPieces[0], actualPieces[0].position),
      );

      for (const [index, el] of newPieces.entries()) {
        const pieceBox = getBoxWithPieceAndPosition(el, el.position);
        mergedBoxOfNewPieces.union(pieceBox);
      }
      for (const [index, el] of actualPieces.entries()) {
        const pieceBox = getBoxWithPieceAndPosition(el, el.position);
        mergedBoxOfActualPieces.union(pieceBox);
      }

      const centerOfNewPieces = new THREE.Vector3();
      const centerOfActualPieces = new THREE.Vector3();

      mergedBoxOfNewPieces.getCenter(centerOfNewPieces);
      mergedBoxOfActualPieces.getCenter(centerOfActualPieces);

      const deltaX = centerOfActualPieces.x - centerOfNewPieces.x;
      const deltaZ = centerOfActualPieces.z - centerOfNewPieces.z;

      let deltaY = 0;

      if (activePieces[allPieces.length - 1].type === 'SOLE') {
        deltaY = mergedBoxOfActualPieces.min.y - mergedBoxOfNewPieces.max.y;
      }
      for (const [index, el] of newPieces.entries()) {
        el.position.set(el.position.x + deltaX, el.position.y + deltaY, el.position.z + deltaZ);
      }
    }
  };

  return { pieces, removeImportedPiece };
};
