/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-destructuring */
import { Button, Checkbox, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useUser } from 'src/context/UserProvider';
import { useModifications } from 'src/hook/useModifications';
import { createModification, deleteModification, Modification } from 'src/services/configurator';
import { getModificationWithHistory, pointToFloat32 } from 'src/utils/configurator.utils';
import styled from 'styled-components';
import { Vector3 } from 'three';

import { isAdmin } from '../../../../../utils/user.utils';

type ModificationsProps = {
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void;
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  modifications: Modification[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  refreshModifications: () => void;
  pieces: any[];
  activePieces: any[];
  pieceIndex: number;
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void;
};

export const Modifications = ({
  selectedPoints,
  setSelectedPoints,
  changePoint,
  modifications,
  history,
  refreshModifications,
  pieces,
  pieceIndex,
  activePieces,
  changeGeometry,
  checkCollision,
}: ModificationsProps) => {
  const { user } = useUser();
  const [name, setName] = useState<string>();

  const {
    modifyPoints,
    sizes,
    setSizes,
    datas,
    setDatas,
    simpleModeAxis,
    setSimpleModeAxis,
    error,
  } = useModifications(
    history,
    changePoint,
    setSelectedPoints,
    pieceIndex,
    modifications,
    changeGeometry,
    pieces,
    activePieces,
    checkCollision,
  );

  const createModifications = async () => {
    try {
      if (
        selectedPoints &&
        (selectedPoints.length === 2 || (simpleModeAxis && selectedPoints.length === 4)) &&
        name
      ) {
        const v1 = new Vector3(
          selectedPoints[0].point[0],
          selectedPoints[0].point[1],
          selectedPoints[0].point[2],
        );
        const v2 = new Vector3(
          selectedPoints[1].point[0],
          selectedPoints[1].point[1],
          selectedPoints[1].point[2],
        );

        let distance = 0;

        if (simpleModeAxis) {
          distance =
            v2[simpleModeAxis] > v1[simpleModeAxis]
              ? v2[simpleModeAxis] - v1[simpleModeAxis]
              : v1[simpleModeAxis] - v2[simpleModeAxis];
        } else {
          distance = v1.distanceTo(v2);
        }

        const sortedPoint = selectedPoints;

        if (distance < 0) {
          const selectedPointCopy = sortedPoint[0];
          sortedPoint[0] = sortedPoint[1];
          sortedPoint[1] = selectedPointCopy;
        }

        await createModification(
          name,
          sortedPoint.map((el) => el.point),
          distance,
          pieces[pieceIndex].id,
          pieceIndex,
          simpleModeAxis,
        );
        refreshModifications();
        setSelectedPoints(null);
        setName(undefined);
      }
    } catch (error_) {
      console.log(error_);
    }
  };

  const selectModification = (modification: Modification) => {
    setSelectedPoints(
      modification.points.map((p) => {
        const point = getModificationWithHistory(history[pieces[pieceIndex].uuid], p);

        return {
          point: new Float32Array([point.x, point.y, point.z]),
          pieceIndex,
        };
      }),
    );
  };

  return (
    <Container>
      {isAdmin(user) ? (
        <>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            style={{ width: '100%' }}
          />
          <StyledButton
            disabled={
              !(
                selectedPoints &&
                ((simpleModeAxis && (selectedPoints.length === 4 || selectedPoints.length === 2)) ||
                  (!simpleModeAxis && selectedPoints.length === 2)) &&
                name
              )
            }
            variant="contained"
            onClick={async () => createModifications()}
          >
            Create modification {selectedPoints ? selectedPoints.length : 0} points
          </StyledButton>
          <Row style={{ alignItems: 'center' }}>
            <Checkbox
              checked={simpleModeAxis !== undefined}
              onChange={() =>
                simpleModeAxis === undefined ? setSimpleModeAxis('x') : setSimpleModeAxis(undefined)
              }
              sx={{
                color: '#D0D5DD',
                '&.Mui-checked': {
                  color: '#BC9A67',
                },
              }}
            />
            {simpleModeAxis === undefined ? (
              <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'capitalize' }}>
                Mode étirement d&apos;une zone spécifique
              </Typography>
            ) : null}

            {simpleModeAxis === undefined ? null : (
              <>
                <StyledButton
                  style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                  variant={simpleModeAxis === 'x' ? 'contained' : 'outlined'}
                  onClick={() => setSimpleModeAxis('x')}
                >
                  X
                </StyledButton>
                <StyledButton
                  style={{ borderRadius: 0 }}
                  variant={simpleModeAxis === 'y' ? 'contained' : 'outlined'}
                  onClick={() => setSimpleModeAxis('y')}
                >
                  Y
                </StyledButton>
                <StyledButton
                  style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                  variant={simpleModeAxis === 'z' ? 'contained' : 'outlined'}
                  onClick={() => setSimpleModeAxis('z')}
                >
                  Z
                </StyledButton>
              </>
            )}
          </Row>
        </>
      ) : null}

      {modifications?.map((modification, index) => {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          activePieces[pieceIndex]?.blockedAxis?.includes(
            (modification.simpleModeAxis ?? '') as string,
          )
        )
          return null;
        return (
          <Column key={index}>
            <Label onClick={() => selectModification(modification)}>{modification.name}</Label>
            {isAdmin(user) ? (
              <Row>
                <Column>
                  <p style={{ fontSize: 10, margin: 0 }}>(0/1 Valeur affiché)</p>
                  <p style={{ fontSize: 10, margin: 0 }}>(2/3 Zone d&apos;étirement)</p>
                </Column>
                {modification.points
                  .sort((a, b) => {
                    if (a.index !== undefined && b.index !== undefined) {
                      return a.index < b.index ? -1 : 1;
                    }
                    return 0;
                  })
                  .map((el, i) => {
                    return (
                      <div
                        style={{ margin: 10, cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedPoints([
                            {
                              pieceIndex: 0,
                              point: pointToFloat32(
                                getModificationWithHistory(history[pieces[pieceIndex].uuid], el),
                              ),
                            },
                          ]);
                        }}
                      >
                        {i}
                      </div>
                    );
                  })}
              </Row>
            ) : null}
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Input
                disabled={activePieces[pieceIndex]?.isBlocked ?? false}
                value={sizes && sizes[index] ? sizes[index].size : ''}
                onChange={(e) => {
                  if (sizes) {
                    setSizes(
                      sizes.map((element, i) => {
                        if (i === index) {
                          return { ...sizes[i], size: e.target.value };
                        }
                        return element;
                      }),
                    );
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && datas) {
                    modifyPoints(datas[index], index);
                  }
                }}
                onBlur={() => {
                  if (datas) {
                    modifyPoints(datas[index], index);
                  }
                }}
              />
              {isAdmin(user) ? (
                <div
                  style={{ cursor: 'pointer', marginLeft: '12px' }}
                  onClick={async () => {
                    await deleteModification(modification.id);
                    refreshModifications();
                  }}
                >
                  x
                </div>
              ) : null}
            </Row>
            {error && error.inputIndex === index ? (
              <Typography variant="subtitle2" sx={{ marginBottom: '12px' }} color="error">
                {error.message}
              </Typography>
            ) : null}
          </Column>
        );
      })}
    </Container>
  );
};

const Container = styled('div')({});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  width: '100%',
  height: '40px',
}));

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '14px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
