/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';
import { Modification } from 'src/services/configurator';
import { getModificationWithHistory } from 'src/utils/configurator.utils';
import { getModificationData, getNewGeometryWithSimpleMode } from 'src/utils/modification.utils';

export const useModifications = (
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  },
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void,
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void,
  pieceIndex: number,
  modifications: Modification[] | undefined,
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void,
  pieces: any[],
  activePieces: any[],
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void,
) => {
  const [sizes, setSizes] = useState<
    {
      size: string;
      axis: ('x' | 'z' | 'y')[];
    }[]
  >();

  const [datas, setDatas] = useState<any[]>();
  const [simpleModeAxis, setSimpleModeAxis] = useState<'x' | 'y' | 'z' | undefined>();
  const [error, setError] = useState<{ message: string; inputIndex: number }>();

  useEffect(() => {
    const datasTmp: any[] = [];

    if (modifications) {
      if (modifications)
        for (const el of modifications) {
          const data = getModificationData(el);
          datasTmp.push(data);
        }

      setDatas(datasTmp);

      if (datasTmp && history[pieces[pieceIndex].uuid]) {
        const sizesTmp: any[] = [];
        for (const el of datasTmp) {
          const firstPoint = getModificationWithHistory(
            history[pieces[pieceIndex].uuid],
            el.points[0],
          );
          const secondPoint = getModificationWithHistory(
            history[pieces[pieceIndex].uuid],
            el.points[1],
          );

          const axis: ('x' | 'z' | 'y')[] = [];

          if (firstPoint && secondPoint && el.simpleModeAxis === null) {
            if (firstPoint.x !== secondPoint.x) {
              axis.push('x');
            }
            if (firstPoint.y !== secondPoint.y) {
              axis.push('y');
            }
            if (firstPoint.z !== secondPoint.z) {
              axis.push('z');
            }
          } else {
            axis.push(el.simpleModeAxis);
          }

          sizesTmp.push({
            size: (el.distance * 100).toFixed(2),
            axis,
          });
        }

        setSizes(sizesTmp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieceIndex, modifications]);

  const modifyPoints = (data: any, dataIndex: number) => {
    const checkMin = (value: string) => {
      if (Number.parseFloat(value) < Number.parseFloat(activePieces[pieceIndex].granit?.minLength))
        return activePieces[pieceIndex].granit?.minLength;
      return value;
    };

    if (
      sizes &&
      sizes.length > 0 &&
      Number.parseFloat(sizes[dataIndex].size) >
        Number.parseFloat(activePieces[pieceIndex].granit?.maxLength)
    ) {
      setError({
        message: `Attention, votre pièce dépasse la taille maximale de ce granit (${Number.parseFloat(
          activePieces[pieceIndex].granit?.maxLength,
        )}). Elle sera coupée.`,
        inputIndex: dataIndex,
      });
    } else if (error) {
      setError(undefined);
    }

    if (sizes && sizes[dataIndex] && data) {
      if (data.simpleModeAxis) {
        const copyGeometry = getNewGeometryWithSimpleMode(
          history,
          pieceIndex,
          data,
          {
            size: checkMin(sizes[dataIndex].size),
          },
          pieces,
        );

        changeGeometry(pieceIndex, copyGeometry);
      } else {
        changePoint([
          {
            firstPoint: data.points[0],
            secondPoint: data.points[1],
            axis: sizes[dataIndex].axis,
            delta: Number.parseInt(sizes[dataIndex].size, 10) / 100,
          },
        ]);
        setSelectedPoints(null);
      }

      for (const el of sizes[dataIndex].axis) {
        checkCollision(pieceIndex, el);
      }
    }
  };
  return {
    modifyPoints,
    sizes,
    setSizes,
    datas,
    setDatas,
    simpleModeAxis,
    setSimpleModeAxis,
    error,
  };
};
