import { SvgIcon, SvgIconProps } from '@mui/material';

const PackageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M5.5 5.88666L0 3.57333V8.72666C0.00289871 8.94156 0.0672034 9.15115 0.185328 9.3307C0.303452 9.51025 0.470466 9.65227 0.666667 9.74L5.46 12H5.5V5.88666ZM6 4.99999L11.6733 2.61999C11.5779 2.51391 11.4623 2.42781 11.3333 2.36666L6.53333 0.119995C6.36661 0.0410838 6.18445 0.000152588 6 0.000152588C5.81555 0.000152588 5.63339 0.0410838 5.46667 0.119995L0.666667 2.36666C0.537708 2.42781 0.422148 2.51391 0.326667 2.61999L6 4.99999ZM6.5 5.88666V12H6.53333L11.3333 9.74C11.5285 9.65274 11.6949 9.51176 11.8129 9.3335C11.9309 9.15525 11.9958 8.94709 12 8.73333V3.57333L6.5 5.88666Z" />
    </SvgIcon>
  );
};

export default PackageIcon;
