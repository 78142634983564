import { Box, Button, styled } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
});

export const LowerCaseButton = styled(Button)({
  height: '56px',
  borderRadius: '8px',
  textTransform: 'unset',
  fontWeight: '500',
  padding: '6px 18px',
  boxSizing: 'border-box',
});

export const FormLine = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: 30,
  position: 'relative',

  '& > *': {
    flex: '1 1 0',
  },
});

export const FormCol = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 30,
  position: 'relative',

  '& > *': {
    flex: '1 1 0',
  },
});

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '30px',
});

export const RoundedButton = styled(Button)({
  padding: '8px 16px',
  gap: '4px',
  borderRadius: '20px',
  textTransform: 'capitalize',
  color: 'black',
});

export const ButtonRow = styled(Box)({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
});

export const FlexBox = styled(Box)({
  display: 'flex',
});
