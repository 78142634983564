import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues } from 'react-hook-form';

import { IFieldRHF } from './FormComponents.type';

export const formatNumber = (value: string | number) => {
  if (typeof value === 'string') {
    const number = value.replace(',', '.');
    return Number.isNaN(+number) || !number ? 0 : +number;
  }
  return value;
};

export const NumberFieldRHF = <T extends FieldValues>({
  name,
  control,
  TextFieldProps,
  caption,
}: IFieldRHF<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          width: '100%',
          ...TextFieldProps?.sx,
        }}
      >
        <TextField
          {...field}
          {...TextFieldProps}
          onBlur={(event) => field.onChange(formatNumber(event.target.value))}
          onChange={(event) => field.onChange(formatNumber(event.target.value))}
          inputRef={ref}
          sx={{ width: '100%', ...TextFieldProps?.sx }}
        />
        {caption && (
          <Typography variant="caption" align="right">
            {caption}
          </Typography>
        )}
      </Box>
    )}
  />
);
