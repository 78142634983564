/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Button, ButtonGroup, styled, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PatternsLabels } from 'src/constants/pattern';
import { useUser } from 'src/context/UserProvider';
import { usePattern } from 'src/hook/usePattern';
import { WebGLRenderer } from 'three';
import * as THREE from 'three';

import { StateNames, States } from '../../../../Screens/Configurator';
import { EngravingItem } from './Engravings/EngravingItem';
import { KanvasEditor } from './KanvasEditor';
import { Catalog } from './Patterns/Catalog';
import { PatternItem } from './Patterns/PatternItem';

type PatternsProps = {
  pieces: any[];
  pieceIndex: number;
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  selectedPattern: number | undefined;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  orbitRef: React.MutableRefObject<any>;
  centerCamera: () => void;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  gl: WebGLRenderer | undefined;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setOrientation: (orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean }) => void;
  selectedPage: string;
  customSteles: any[];
  customPlatings: any[];
};

export const PatternsEngraving = ({
  pieces,
  pieceIndex,
  setPatterns,
  patterns,
  setSelectedPattern,
  selectedPattern,
  orbitRef,
  centerCamera,
  setValue,
  gl,
  orientation,
  setOrientation,
  selectedPage,
  customPlatings,
  customSteles,
}: PatternsProps) => {
  const shouldUpdateTexture = useRef<{ pieceUUID: string; orientation: string } | null>(null);

  const {
    kanvasSize,
    selectedImportPage,
    setSelectedImportPage,
    setSelectedPiece,
    isButtonOpen,
    setIsButtonOpen,
    selectedPiece,
    updateKanvas,
    changePattern,
    applyPatternsTmp,
    getPatterns,
    addText,
    changeText,
  } = usePattern({
    pieces,
    customSteles,
    customPlatings,
    pieceIndex,
    setPatterns,
    patterns,
    orbitRef,
    centerCamera,
    setValue,
    gl,
    orientation,
    setOrientation,
    shouldUpdateTexture,
  });
  const { user } = useUser();

  const stageRef = useRef<any>();

  const [isPatternsLoaded, setIsPatternsLoaded] = useState<{ [key: string]: boolean }>({});

  const updateTexture = (pieceUUID: string, orientation: string) => {
    const patternsTmp = { ...patterns };

    if (stageRef.current && patternsTmp[pieceUUID] && patternsTmp[pieceUUID][orientation]) {
      const canvas = stageRef.current.toCanvas();
      const texture = new THREE.CanvasTexture(canvas);
      patternsTmp[pieceUUID][orientation].texture = texture;
    }
    setPatterns(patternsTmp);
  };

  const isTexturesLoading = () => {
    const patterns = getPatterns();
    for (let i = 0; i < patterns.length; i += 1) {
      if (isPatternsLoaded[i]) return true;
    }
    return false;
  };

  useEffect(() => {
    setIsButtonOpen(false);
    setOrientation({ key: 'free', inverse: false });
  }, [selectedPage, setIsButtonOpen, setOrientation]);

  useEffect(() => {
    if (getPatterns().length > 0 && selectedPattern === undefined) setSelectedPattern(0);
  }, [getPatterns, orientation, selectedPattern, setSelectedPattern]);

  useEffect(() => {
    if (
      shouldUpdateTexture &&
      shouldUpdateTexture.current !== null &&
      shouldUpdateTexture.current.pieceUUID &&
      shouldUpdateTexture.current.orientation
    ) {
      const { pieceUUID } = shouldUpdateTexture.current;
      const { orientation } = shouldUpdateTexture.current;
      updateTexture(pieceUUID, orientation);
      shouldUpdateTexture.current = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patterns]);

  useEffect(() => {
    setValue('texture', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuPaterns = useMemo(() => {
    const sortedLabels = PatternsLabels.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    const filteredLabels = sortedLabels.filter((el) => el.key !== 'TMP');
    return filteredLabels;
  }, []);

  return (
    <>
      {orientation.key === 'free' ? null : (
        <KanvasEditor
          orbitRef={orbitRef}
          kanvasSize={kanvasSize}
          orientation={orientation}
          setPatterns={setPatterns}
          patterns={patterns}
          pieces={pieces}
          pieceIndex={pieceIndex}
          selectedPattern={selectedPattern}
          setSelectedPattern={setSelectedPattern}
          stageRef={stageRef}
          customSteles={customSteles}
          customPlatings={customPlatings}
          shouldUpdateTexture={shouldUpdateTexture}
          isPatternsLoaded={isPatternsLoaded}
          setIsPatternsLoaded={setIsPatternsLoaded}
        />
      )}
      <Container>
        {selectedImportPage ? (
          <>
            <Row>
              <Typography
                variant="body2"
                color="#344054"
                style={{
                  marginLeft: '23px',
                  marginTop: '23px',
                  cursor: 'pointer',
                  zIndex: 2,
                }}
                onClick={() => setSelectedImportPage(undefined)}
              >
                {'Elements >'}
              </Typography>
              <Typography
                variant="body2"
                color="#344054"
                style={{ marginTop: '23px', marginLeft: '4px', fontWeight: 700 }}
              >
                {PatternsLabels.find((el) => el.key === selectedImportPage)?.label}
              </Typography>
            </Row>
            {user ? (
              <Catalog
                setPatterns={setPatterns}
                patterns={patterns}
                pieceIndex={pieceIndex}
                pieces={pieces}
                setSelectedImportPage={setSelectedImportPage}
                setSelectedPiece={setSelectedPiece}
                orientation={orientation}
                selectedPage={selectedPage}
                user={user}
                selectedImportPage={selectedImportPage}
                orbitRef={orbitRef}
                customSteles={customSteles}
                customPlatings={customPlatings}
              />
            ) : null}
          </>
        ) : (
          <>
            <ButtonGroup
              variant="text"
              aria-label="text button group"
              sx={{ padding: '22px', display: 'flex', justifyContent: 'center', paddingBottom: 0 }}
              disabled={isTexturesLoading()}
            >
              <Button
                sx={{ fontSize: 11, padding: '4px 8px' }}
                variant={orientation.key === 'free' ? 'contained' : 'text'}
                onClick={() => {
                  setSelectedPattern(undefined);
                  setOrientation({
                    key: 'free',
                    inverse: orientation.key === 'free' ? !orientation.inverse : false,
                  });
                }}
              >
                Vue globale
              </Button>
              {pieceIndex === -1 ? null : (
                <>
                  <Button
                    sx={{ fontSize: 11, padding: '4px 8px' }}
                    variant={orientation.key === 'z' ? 'contained' : 'text'}
                    onClick={() => {
                      setSelectedPattern(undefined);
                      setOrientation({
                        key: 'z',
                        inverse: orientation.key === 'z' ? !orientation.inverse : false,
                      });
                    }}
                  >
                    Vue de face
                  </Button>
                  <Button
                    sx={{ fontSize: 11, padding: '4px 8px' }}
                    variant={orientation.key === 'y' ? 'contained' : 'text'}
                    onClick={() => {
                      setSelectedPattern(undefined);
                      setOrientation({
                        key: 'y',
                        inverse: orientation.key === 'y' ? !orientation.inverse : false,
                      });
                    }}
                  >
                    Vue de haut
                  </Button>
                  <Button
                    sx={{ fontSize: 11, padding: '4px 8px' }}
                    variant={orientation.key === 'x' ? 'contained' : 'text'}
                    onClick={() => {
                      setSelectedPattern(undefined);
                      setOrientation({
                        key: 'x',
                        inverse: orientation.key === 'x' ? !orientation.inverse : false,
                      });
                    }}
                  >
                    Vue de coté
                  </Button>
                </>
              )}
            </ButtonGroup>
            {getPatterns()?.length > 0 ? (
              <MessageContainer
                style={{ height: 'fit-content', padding: '22px', paddingTop: 0, paddingBottom: 0 }}
              >
                <Typography
                  variant="body1"
                  color="#344054"
                  style={{
                    marginTop: '23px',
                    marginLeft: '4px',
                    fontWeight: 300,
                    fontSize: '14px',
                  }}
                >
                  {`Pour déplacer ${
                    selectedPage === 'patterns' ? ' un motif' : 'une gravure'
                  }, selectionnez une vue, cliquez sur le visuel à gauche ou sur le motif dans la
                  liste, maintenez le clic sur le visuel à gauche et bougez votre souris.`}
                </Typography>
              </MessageContainer>
            ) : null}
            <PiecesContainer>
              {getPatterns()?.length > 0 ? (
                getPatterns()?.map((el: any, elIndex: number) => {
                  if (
                    (selectedPage === 'patterns' && el.type !== 'image') ||
                    (selectedPage === 'engravings' && el.type !== 'text')
                  )
                    return <></>;
                  return selectedPage === 'patterns' ? (
                    <PatternItem
                      key={`pattern_${elIndex}`}
                      shouldUpdateTexture={shouldUpdateTexture}
                      selectedPattern={selectedPattern}
                      elIndex={elIndex}
                      updateKanvas={updateKanvas}
                      setSelectedPattern={setSelectedPattern}
                      el={el}
                      changePattern={changePattern}
                      applyPatternsTmp={applyPatternsTmp}
                      patterns={patterns}
                      setPatterns={setPatterns}
                      orientation={orientation}
                      setOrientation={setOrientation}
                    />
                  ) : (
                    <EngravingItem
                      key={`engraving${elIndex}`}
                      el={el}
                      selectedPattern={selectedPattern}
                      elIndex={elIndex}
                      setSelectedPattern={setSelectedPattern}
                      changeText={changeText}
                      patterns={patterns}
                      setPatterns={setPatterns}
                      orientation={orientation}
                      setOrientation={setOrientation}
                      shouldUpdateTexture={shouldUpdateTexture}
                    />
                  );
                })
              ) : (
                <MessageContainer>
                  <Typography
                    variant="body1"
                    color="#344054"
                    style={{
                      marginTop: '23px',
                      marginLeft: '4px',
                      fontWeight: 300,
                      fontSize: '14px',
                    }}
                  >
                    {pieceIndex === -1
                      ? 'Cliquez sur une pièce'
                      : `Ajoutez ${
                          selectedPage === 'patterns' ? ' un motif' : 'une gravure'
                        } en choisissant une vue`}
                  </Typography>
                </MessageContainer>
              )}
            </PiecesContainer>
          </>
        )}
      </Container>
      {orientation.key === 'free' ? null : (
        <PanelButtonContainer isOpen={isButtonOpen}>
          <PanelChoices
            isOpen={isButtonOpen}
            style={{
              flexDirection: PatternsLabels.length > 10 ? 'row' : 'column',
              flexWrap: 'wrap',
              width: PatternsLabels.length > 10 ? '100%' : '159px',
            }}
          >
            {selectedPage === 'patterns' ? (
              <>
                {menuPaterns.map((el) => {
                  return (
                    <PanelChoice
                      style={{ width: PatternsLabels.length > 10 ? '33%' : '99%' }}
                      onClick={() => {
                        setSelectedImportPage(el.key);
                        setIsButtonOpen(false);
                      }}
                    >
                      <Typography
                        key={el.key}
                        variant="body2"
                        color="#344054"
                        style={{
                          fontSize: '12px',
                          userSelect: 'none',
                          textAlign: 'center',
                        }}
                      >
                        {el.label}
                      </Typography>
                    </PanelChoice>
                  );
                })}
              </>
            ) : null}
          </PanelChoices>
          {isButtonOpen ? (
            <>
              <PanelButton
                isOpen
                onClick={() => {
                  setSelectedImportPage('ALL');
                  setIsButtonOpen(false);
                }}
                style={{ marginBottom: '12px' }}
              >
                <Typography
                  variant="body2"
                  color="black"
                  style={{
                    fontSize: '12px',
                  }}
                >
                  Tous les motifs
                </Typography>
              </PanelButton>
              {user?.role === 'ADMIN' ? (
                <PanelButton
                  isOpen
                  onClick={() => {
                    setSelectedImportPage('TMP');
                    setIsButtonOpen(false);
                  }}
                  style={{ marginBottom: '12px' }}
                >
                  <Typography
                    variant="body2"
                    color="black"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Motifs par défaut
                  </Typography>
                </PanelButton>
              ) : null}
            </>
          ) : null}

          {selectedPiece ? (
            <PanelButton isOpen={isButtonOpen} onClick={() => {}}>
              <Typography
                variant="body2"
                color={isButtonOpen ? 'black' : 'white'}
                style={{
                  fontSize: '12px',
                }}
              >
                Valider la sélection
              </Typography>
            </PanelButton>
          ) : (
            <PanelButton
              isOpen={isButtonOpen}
              onClick={() => {
                if (selectedPage === 'patterns') setIsButtonOpen(!isButtonOpen);
                if (selectedPage === 'engravings') addText();
              }}
            >
              <Typography
                variant="body2"
                color={isButtonOpen ? 'black' : 'white'}
                style={{
                  fontSize: '12px',
                }}
              >
                {isButtonOpen ? 'Annuler' : 'Ajouter un élément'}
              </Typography>
            </PanelButton>
          )}
        </PanelButtonContainer>
      )}
    </>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  top: 0,
});

const PanelButtonContainer = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',

  padding: '27px',

  backgroundColor: `rgb(0, 0, 0, ${isOpen ? 0.4 : 0})`,
  transition: 'background-color 0.5s ease',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  top: '0px',

  pointerEvents: 'none',
}));

const PanelButton = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '159px',
  height: '32px',

  backgroundColor: isOpen ? 'white' : '#BC9A67',
  transition: 'background-color 0.5s ease',

  borderRadius: '28px',
  padding: '8px 16px',

  cursor: 'pointer',
  zIndex: 2,
  pointerEvents: 'all',
}));

const PanelChoices = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  display: isOpen ? 'flex' : 'none',

  flexDirection: 'column',

  justifyContent: 'center',
  alignItems: 'flex-start',

  borderRadius: '10px',
  backgroundColor: 'white',

  width: '159px',

  marginBottom: '12px',

  '& > *:not(:last-child)': {
    borderBottom: '1px solid #98A2B3',
  },

  zIndex: 2,
  pointerEvents: 'all',
}));

const PanelChoice = styled('div')({
  padding: '10px 16px',
  width: '95%',

  cursor: 'pointer',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const PiecesContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
  padding: '22px',

  maxHeight: window.innerHeight - 528,
  overflowY: 'auto',
});

const MessageContainer = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});
