import { styled, Switch } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { ISwitchRHF } from './FormComponents.type';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 60,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    height: '24px',
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    margin: 1,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
  },
}));

const SwitchRHF = <T extends FieldValues>({ name, control, switchProps }: ISwitchRHF<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <StyledSwitch {...field} {...switchProps} inputRef={ref} checked={field.value} />
    )}
  />
);

export default SwitchRHF;
