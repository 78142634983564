/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import { Shape, Vector3 } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import * as THREE from 'three';
import { Granit } from 'src/services/granits';
import { BucketsUrls } from 'src/services/file';

export const isVectorNul = (vector: Vector3) => {
  if (vector.x === 0 && vector.y === 0 && vector.z === 0) return true;
  return false;
};

export const getWidthMonument = (pieces: any[]) => {
  let backLeft: Vector3 | undefined = undefined;
  let backRight: Vector3 | undefined = undefined;
  let frontLeft: Vector3 | undefined = undefined;
  let frontRight: Vector3 | undefined = undefined;

  let minY: number | undefined = undefined;

  const aroundNumber = (numberToRound: number) => {
    return parseFloat(numberToRound.toFixed(5));
  };

  pieces.forEach((element) => {
    const elementGeometry = element.geometry.attributes['position'].array;
    for (let i = 0; i < elementGeometry.length; i += 3) {
      if (minY === undefined || minY > aroundNumber(elementGeometry[i + 1] + element.position.y)) {
        minY = aroundNumber(elementGeometry[i + 1] + element.position.y);
      }
    }
  });

  pieces.forEach((element) => {
    const elementGeometry = element.geometry.attributes['position'].array;
    for (let i = 0; i < elementGeometry.length; i += 3) {
      const elementX = aroundNumber(elementGeometry[i + 0] + element.position.x);
      const elementY = aroundNumber(elementGeometry[i + 1] + element.position.y);
      const elementZ = aroundNumber(elementGeometry[i + 2] + element.position.z);

      if (elementY === minY) {
        if (backLeft === undefined) backLeft = new Vector3(elementX, elementY, elementZ);
        if (backRight === undefined) backRight = new Vector3(elementX, elementY, elementZ);
        if (frontLeft === undefined) frontLeft = new Vector3(elementX, elementY, elementZ);
        if (frontRight === undefined) frontRight = new Vector3(elementX, elementY, elementZ);

        if (elementX < backLeft.x) backLeft = new Vector3(elementX, minY, backLeft.z);
        if (elementX < frontLeft.x) frontLeft = new Vector3(elementX, minY, frontLeft.z);
        if (elementX > backRight.x) backRight = new Vector3(elementX, minY, backRight.z);
        if (elementX > frontRight.x) frontRight = new Vector3(elementX, minY, frontRight.z);

        if (elementZ < backLeft.z) backLeft = new Vector3(backLeft.x, minY, elementZ);
        if (elementZ > frontLeft.z) frontLeft = new Vector3(frontLeft.x, minY, elementZ);
        if (elementZ < backRight.z) backRight = new Vector3(backRight.x, minY, elementZ);
        if (elementZ > frontRight.z) frontRight = new Vector3(frontRight.x, minY, elementZ);
      }
    }
  });

  return {
    backLeft: backLeft ?? new Vector3(0, 0, 0),
    backRight: backRight ?? new Vector3(0, 0, 0),
    frontLeft: frontLeft ?? new Vector3(0, 0, 0),
    frontRight: frontRight ?? new Vector3(0, 0, 0),
  };
};

export const getLengthWithKey = (
  mode: 'closoir' | 'double closoir' | 'tete',
  key: 'front' | 'back' | 'left' | 'right',
  depth: { front: number; back: number; left: number; right: number },
  length: number,
) => {
  switch (mode) {
    case 'closoir': {
      if (key === 'front') return length;
      if (key === 'left' || key === 'right') return length - depth.front;
      if (key === 'back') return length - depth.right - depth.left;
      break;
    }
    case 'tete': {
      if (key === 'front' || key === 'back') return length;
      if (key === 'left' || key === 'right') return length - depth.front - depth.back;
      break;
    }
    case 'double closoir': {
      if (key === 'front' || key === 'back') return length - depth.right - depth.left;
      if (key === 'left' || key === 'right') return length;
      break;
    }
  }
  return 0;
};

export const getPositionWithIndex = (
  key: 'front' | 'back' | 'left' | 'right',
  depth: { front: number; back: number; left: number; right: number },
  pieces: any[],
  customPlatingsIsActive: { [key: string]: boolean },
  length: number,
  width: number,
  mode: 'closoir' | 'double closoir' | 'tete',
) => {
  const box = getWidthMonument(pieces);
  const deltaX = box.backRight.x - box.backLeft.x - width;
  const deltaZ = mode === 'closoir' ? depth.back / 2 : 0;

  if (box) {
    if (key === 'front') {
      let x = box.frontLeft.x + width / 2 + deltaX / 2;
      let y = box.frontLeft.y;
      let z = box.backLeft.z + length - depth.front;
      if (!customPlatingsIsActive['right'] && mode === 'double closoir') x = x + depth.left / 2;
      if (!customPlatingsIsActive['left'] && mode === 'double closoir') x = x - depth.right / 2;
      return new Vector3(x, y, z);
    }
    if (key === 'back') {
      let x = box.backLeft.x + width / 2 + deltaX / 2;
      let y = box.backLeft.y;
      let z = box.backLeft.z;

      if (!customPlatingsIsActive['right'] && mode !== 'tete') x = x + depth.left / 2;
      if (!customPlatingsIsActive['left'] && mode !== 'tete') x = x - depth.right / 2;
      return new Vector3(x, y, z);
    }
    if (key === 'left') {
      let x = box.backLeft.x + deltaX / 2;
      let y = box.frontLeft.y;
      let z = box.backLeft.z + length / 2 - deltaZ;
      if (!customPlatingsIsActive['front'] && mode !== 'double closoir') z = z + depth.back / 2;
      if (!customPlatingsIsActive['back'] && mode === 'tete') z = z - depth.front / 2;

      return new Vector3(x, y, z);
    }
    if (key === 'right') {
      let x = box.backLeft.x + width - depth.right + deltaX / 2;
      let y = box.frontRight.y;
      let z = box.backRight.z + length / 2 - deltaZ;

      if (!customPlatingsIsActive['front'] && mode !== 'double closoir') z = z + depth.back / 2;
      if (!customPlatingsIsActive['back'] && mode === 'tete') z = z - depth.front / 2;

      return new Vector3(x, y, z);
    }
  }
};

export const changePlatingShape = (shape: Shape, length: number, front: number, back?: number) => {
  shape.moveTo(-length / 2, 0);
  shape.lineTo(length / 2, 0);
  shape.lineTo(length / 2, -(back === undefined ? front : back));
  shape.lineTo(-length / 2, -front);
};

export const createPlatings = (
  texture: THREE.Texture | null,
  granit: Granit | null,
  pieces: any[],
  customPlatings: any[],
  setCustomPlatings: (customPlatings: any[]) => void,
  depth: number,
  mode: 'closoir' | 'double closoir' | 'tete' = 'closoir',
  customSize?: {
    length?: number;
    width?: number;
    heightFront?: number;
    heightBack?: number;
    orientation?: 'x' | 'z';
  },
) => {
  let platings: any[] = [];

  const platingsInformations = [
    {
      key: 'front',
      label: 'Devant',
      isRotated: false,
    },
    {
      key: 'back',
      label: 'Derière',
      isRotated: false,
    },
    {
      key: 'left',
      label: 'Gauche',
      isRotated: true,
    },
    {
      key: 'right',
      label: 'Droite',
      isRotated: true,
    },
  ];

  const monumentBox = getWidthMonument(pieces);
  let monumentWidth = monumentBox.frontRight.x - monumentBox.frontLeft.x;
  let monumentLength = monumentBox.frontRight.z - monumentBox.backRight.z;

  if (customSize) {
    if (customSize.length) monumentLength = customSize.length;
    if (customSize.width) monumentWidth = customSize.width;
  }

  if (monumentLength === 0) monumentLength = 2.4;
  if (monumentWidth === 0) monumentWidth = 1.4;

  platingsInformations.forEach((el, elIndex) => {
    let customPlatingsIsActive: { [key: string]: boolean } = {};

    if (customPlatings.length === 0) {
      customPlatingsIsActive['front'] = true;
      customPlatingsIsActive['back'] = true;
      customPlatingsIsActive['left'] = true;
      customPlatingsIsActive['right'] = true;
    } else {
      customPlatings.forEach((element) => {
        customPlatingsIsActive[element.key] = element.isActive;
      });
    }

    const shape = new Shape();
    const length = getLengthWithKey(
      mode,
      el.key as 'front' | 'back' | 'left' | 'right',
      {
        front: customPlatingsIsActive['front'] ? depth : 0,
        back: customPlatingsIsActive['back'] ? depth : 0,
        left: customPlatingsIsActive['left'] ? depth : 0,
        right: customPlatingsIsActive['right'] ? depth : 0,
      },
      el.key === 'front' || el.key === 'back' ? monumentWidth : monumentLength,
    );

    let shapeDetails = {
      display: true,
      hasSlope: false,
      isRotated: el.isRotated,
      depth: depth,
      length: length,
      front: customSize?.heightFront,
      back: customSize?.heightBack,
      monumentWidth,
      monumentLength,
      monumentFront: customSize?.heightFront ?? 0.2,
      monumentBack: customSize?.heightBack,
    };

    if (customSize) {
      if (customSize.orientation === 'x' && customSize.heightBack !== undefined) {
        if (el.key === 'left') {
          shapeDetails.back = customSize.heightFront ?? 0.2;
        }
        if (el.key === 'right') {
          shapeDetails.front = customSize.heightBack === undefined ? 0.2 : customSize.heightBack;
        }
      }
      if (customSize.orientation === 'z' && customSize.heightBack !== undefined) {
        if (el.key === 'front') {
          shapeDetails.back = customSize.heightFront ?? 0.2;
        }
        if (el.key === 'back') {
          shapeDetails.front = customSize.heightBack === undefined ? 0.2 : customSize.heightBack;
        }
      }
    }

    if (shapeDetails.front === undefined) shapeDetails.front = 0.2;

    changePlatingShape(shape, length, shapeDetails.front, shapeDetails.back);

    platings.push({
      texture: texture,
      granit: granit,
      id: generateUUID(),
      key: el.key,
      shape,
      shapeDetails,
      isActive: customPlatings[elIndex] ? customPlatings[elIndex].isActive : true,
      position: getPositionWithIndex(
        el.key as 'front' | 'back' | 'left' | 'right',
        {
          front: shapeDetails.depth,
          back: shapeDetails.depth,
          left: shapeDetails.depth,
          right: shapeDetails.depth,
        },
        pieces,
        customPlatingsIsActive,
        monumentLength,
        monumentWidth,
        mode,
      ),
    });
  });

  setCustomPlatings(platings);
};

export const isPlatingUse = (customPlatings: any[], key: 'front' | 'back' | 'left' | 'right') => {
  const plating = customPlatings.find((el) => el.key === key);

  if (!plating) return false;
  return plating.isActive;
};

export const changePlatingStatus = (
  key: 'front' | 'back' | 'left' | 'right',
  customPlatings: any[],
  setCustomPlatings: (customPlatings: any[]) => void,
) => {
  const mapedCustomPlatings = customPlatings.map((el) => {
    if (el.key === key) {
      return {
        ...el,
        isActive: !el.isActive,
      };
    }
    return el;
  });

  setCustomPlatings(mapedCustomPlatings);
};
