/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable unicorn/prefer-optional-catch-binding */
/* eslint-disable unicorn/no-negated-condition */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { checkResetCode, resetPassword, sendResetCode } from 'src/services/forgotPassword';
import { handleMutation } from 'src/utils/api.utils';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordForm {
  email: string;
  code?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;
}

const schema = z
  .object({
    email: z.string().email('Email invalide'),
    code: z.string().optional(),
    newPassword: z
      .string()
      .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
      .optional(),
    newPasswordConfirmation: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.newPassword && data.newPasswordConfirmation) {
        return data.newPassword === data.newPasswordConfirmation;
      }
      return true;
    },
    {
      message: 'Les mots de passe ne correspondent pas',
      path: ['newPasswordConfirmation'],
    },
  );

const useForgotPassword = () => {
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    resolver: zodResolver(schema),
    context: { _showCodeInput: showCodeInput },
  });

  const onSubmit = async (data: ForgotPasswordForm) => {
    try {
      if (!showCodeInput) {
        await handleMutation(sendResetCode, { email: data.email }, () => {
          setShowCodeInput(true);
          toast.success('Un code de réinitialisation a été envoyé à votre adresse email');
        });
      } else if (!showPasswordInputs) {
        await handleMutation(checkResetCode, { email: data.email, code: data.code }, () => {
          setShowPasswordInputs(true);
          toast.success('Code vérifié avec succès');
        });
      } else {
        // Appel API pour mettre à jour le mot de passe
        if (!data.newPassword) {
          throw new Error('Le mot de passe est requis');
        }
        await handleMutation(
          resetPassword,
          {
            email: data.email,
            code: data.code,
            password: data.newPassword,
          },
          () => {
            toast.success('Mot de passe mis à jour avec succès');
            // Rediriger vers la page de connexion
            navigate('/login');
          },
        );
      }
    } catch (error) {
      toast.error("Une erreur s'est produite");
    }
  };

  return {
    control,
    errors,
    handleSubmit,
    onSubmit,
    showCodeInput,
    showPasswordInputs,
  };
};

export default useForgotPassword;
