import PageWithTabs from '../../components/PageWithTabs';
import { useUser } from '../../context/UserProvider';
import AdditionalServices from './record/additionalServices/AdditionalServices';
import Coefficients from './record/coefficients/Coefficients';
import General from './record/general/General';
import QuoteSettings from './record/quoteSettings/QuoteSettings';

const tabs = ['general', 'quote', 'coefficients', 'additionalServices'] as const;

type Tabs = (typeof tabs)[number];

const MySpace = () => {
  const { user } = useUser();
  const tabsToComponent = (tab: Tabs) => {
    if (user)
      switch (tab) {
        case 'quote': {
          return <QuoteSettings userId={user.id} />;
        }
        case 'coefficients': {
          return <Coefficients userId={user.id} />;
        }
        case 'additionalServices': {
          return <AdditionalServices userId={user.id} />;
        }
        default: {
          return <General />;
        }
      }
    return <General />;
  };

  return <PageWithTabs pageName="Mon espace" tabs={tabs} tabToComponent={tabsToComponent} />;
};

export default MySpace;
