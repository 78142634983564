import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import paths from '../../navigation/paths';
import AdminPageWithNavBar from './components/AdminPageWithNavBar';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  gap: '20px',
  width: '100%',
  height: '100%',
});

const StyledButton = styled(Button)({
  display: 'flex',
  gridRow: 'auto / span 1',
  flexDirection: 'row',
  textTransform: 'unset',
  textAlign: 'left',
  position: 'relative',
  padding: '20px 20px',
});

const Illustrations = styled('img')({
  height: '90%',
  maxWidth: '40%',
});

const TextWrapper = styled(Box)({
  justifyContent: 'left',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '40px',
  padding: '0 30px',
  gap: '10px',
});

const FixedBox = styled(Box)({
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: '30px',
  height: '40px',
  width: '40px',
  borderRadius: '6px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const AdminHome = () => {
  const navigate = useNavigate();

  return (
    <AdminPageWithNavBar>
      <Wrapper>
        <StyledButton
          variant="contained"
          onClick={() => navigate(paths.admin.home.catalogManagement)}
        >
          <Illustrations src="/icons/newProject.svg" alt="newProject" />
          <TextWrapper>
            <Typography variant="h2">Catalogue</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        <StyledButton variant="contained" onClick={() => navigate(paths.admin.home.users.root)}>
          <Illustrations src="/icons/agence.svg" alt="agency" />
          <TextWrapper>
            <Typography variant="h2">Utilisateurs</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={() => navigate(paths.admin.home['quote&orders'])}
        >
          <Illustrations src="/icons/taxIllustration.svg" alt="taxIllustration" />
          <TextWrapper>
            <Typography variant="h2">Devis & commandes</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        <StyledButton variant="contained" onClick={() => navigate(paths.admin.home.settings)}>
          <Illustrations src="/icons/organizational.svg" alt="organizational" />
          <TextWrapper>
            <Typography variant="h2">Paramètres</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
      </Wrapper>
    </AdminPageWithNavBar>
  );
};

export default AdminHome;
