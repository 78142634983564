import { api } from './api';

export const checkResetCode = async (data: { email: string; code?: string }) => {
  return api.post(`/auth/checkResetCode`, {
    email: data.email,
    code: data.code,
  });
};

export const resetPassword = async (data: { email: string; password: string; code?: string }) => {
  return api.post(`/auth/resetPassword`, {
    email: data.email,
    password: data.password,
    code: data.code,
  });
};

export const sendResetCode = async (data: { email: string }) => {
  return api.post(`/auth/sendResetCode`, {
    email: data.email,
  });
};
