import './index.css';

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { z } from 'zod';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { customErrorMap } from './utils/zodErrorMap';

// Sentry.init({
//   dsn: 'https://cf46b341905649c496fe4546f17c9560@o4504241983127552.ingest.sentry.io/4504241984962560',
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1,
// });

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
z.setErrorMap(customErrorMap);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
