import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import { SelectOption } from '../../../../../components/forms/FormComponents.type';
import useLoadData from '../../../../../hook/useLoadData';
import useLoadDataList from '../../../../../hook/useLoadDataList';
import useToggle from '../../../../../hook/useToggle';
import { findAllCatalogs } from '../../../../../services/catalog';
import { getLeaderGranitCompanies } from '../../../../../services/leaderGranitCompanies';
import {
  activateUser,
  disableUser,
  getUserDetails,
  updateUser,
} from '../../../../../services/user';
import { emptyAddress, schemaAddress } from '../../../../../utils/address.utils';
import { handleMutation } from '../../../../../utils/api.utils';
import { areSameObject } from '../../../../../utils/object.utils';
import { isHeadOffice } from '../../../../../utils/user.utils';

const schemaUserForm = z.object({
  headOffice: z.boolean(),
  isOnMaintenance: z.boolean(),
  reason: z.string().trim().min(1),
  name: z.string(),
  function: z.string(),
  email: z.string().refine((val) => isEmail(val)),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  phone: z.string().refine((val) => isValidPhoneNumber(val)),
  deliveryAddress: schemaAddress,
  billingAddress: schemaAddress,
  leaderGranitCompanyId: z.string().trim().min(1),
  catalogIds: z.array(z.object({ name: z.string().trim().min(1), id: z.string().cuid() })),
});

type SchemaUserForm = z.infer<typeof schemaUserForm>;

const emptyUser: SchemaUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  headOffice: false,
  reason: '',
  name: '',
  function: '',
  phone: '',
  deliveryAddress: emptyAddress,
  billingAddress: emptyAddress,
  leaderGranitCompanyId: '',
  catalogIds: [],
  isOnMaintenance: false,
};

const useGeneral = () => {
  const { userId } = useParams();
  const [edit, toggleEdit, setEdit] = useToggle();
  const [billingAddress, setBillingAddress] = useState(false);

  const { data: user, onRefresh } = useLoadData(() => getUserDetails(userId));
  const { data: catalogs } = useLoadDataList(findAllCatalogs);
  const { data: companies } = useLoadDataList(getLeaderGranitCompanies);

  const defaultValues: SchemaUserForm = useMemo(
    () =>
      user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            headOffice: isHeadOffice(user),
            isOnMaintenance: user.company.isOnMaintenance,
            reason: user.company.reason,
            name: user.company.name,
            function: user.company.function,
            phone: user.company.phone,
            deliveryAddress: user.company.deliveryAddress,
            billingAddress: user.company.billingAddress,
            catalogIds: user.userCatalog.map((userCatalog) => userCatalog.catalog),
            leaderGranitCompanyId: user.leaderGranitCompanyId ?? '',
          }
        : emptyUser,
    [user],
  );

  const methods = useForm<SchemaUserForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaUserForm),
  });

  const submit: SubmitHandler<SchemaUserForm> = (data) => {
    handleMutation(
      (datas) => updateUser(datas, userId ?? ''),
      {
        ...data,
        catalogIds: methods.watch('catalogIds').map((catalog) => catalog.id),
        billingAddress: billingAddress ? data.billingAddress : data.deliveryAddress,
      },
      () => {
        toggleEdit();
        onRefresh();
        toast.success('Informations mises à jour avec succès');
      },
    );
  };

  const companyOptions: SelectOption[] = useMemo(
    () => companies.map((company) => ({ label: company.name, value: company.id, key: company.id })),
    [companies],
  );

  useEffect(() => {
    methods.reset(defaultValues);
    setBillingAddress(
      !user || !areSameObject(user.company.billingAddress, user.company.deliveryAddress),
    );
  }, [defaultValues, methods, user]);

  const handleActivateUser = () => {
    handleMutation(
      (datas) => activateUser(datas, user?.id ?? ''),
      {
        catalogIds: methods.watch('catalogIds').map((catalog) => catalog.id),
        leaderGranitCompanyId: methods.watch('leaderGranitCompanyId'),
      },
      onRefresh,
    );
  };

  const handleDisableUser = () => {
    handleMutation(disableUser, user?.id ?? '', onRefresh);
  };

  return {
    methods,
    submit,
    onRefresh,
    edit,
    toggleEdit,
    billingAddress,
    setBillingAddress,
    user,
    setEdit,
    handleActivateUser,
    handleDisableUser,
    catalogs,
    companyOptions,
    countOrders: user?.countOrders ?? 0,
    countQuotes: user?.countQuotes ?? 0,
    totalIncome: user?.totalIncome ?? 0,
  };
};

export default useGeneral;
