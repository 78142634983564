/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { Constraint, createConstraint, deleteConstraint } from 'src/services/configurator';
import { getModificationWithHistory } from 'src/utils/configurator.utils';
import styled from 'styled-components';

type ConstraintsProps = {
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  constraints: Constraint[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  refreshConstraints: () => void;
  pieces: any[];
  pieceIndex: number;
};

export const Constraints = ({
  selectedPoints,
  setSelectedPoints,
  constraints,
  history,
  refreshConstraints,
  pieces,
  pieceIndex,
}: ConstraintsProps) => {
  const [axis, setAxis] = useState<string[] | null>(null);

  const handleSetAxis = (ax: string) => {
    if (selectedPoints) {
      const index = axis ? axis.indexOf(ax) : -1;
      if (axis && index > -1) {
        setAxis(axis?.filter((a) => a !== ax));
      } else if (axis && selectedPoints?.length <= 2) {
        setAxis([...axis, ax]);
      } else {
        setAxis([ax]);
      }
    }
  };

  useEffect(() => {
    const x: any[] = [];
    const y: any[] = [];
    const z: any[] = [];

    const axisAvailable = [];

    if (selectedPoints && selectedPoints.length > 0) {
      for (const point of selectedPoints) {
        x.push(point.point[0]);
        y.push(point.point[1]);
        z.push(point.point[2]);
      }

      if (x.every((val) => val === x[0])) axisAvailable.push('x');
      if (y.every((val) => val === y[0])) axisAvailable.push('y');
      if (z.every((val) => val === z[0])) axisAvailable.push('z');
    }

    setAxis(axisAvailable);
  }, [selectedPoints]);

  return (
    <Container>
      <p>Points selectionnés: ({selectedPoints?.length})</p>
      <Button
        isActive={axis ? axis?.indexOf('x') > -1 : false}
        textColor="black"
        onClick={() => handleSetAxis('x')}
      >
        X
      </Button>
      <Button
        isActive={axis ? axis?.indexOf('y') > -1 : false}
        textColor="black"
        onClick={() => handleSetAxis('y')}
      >
        Y
      </Button>
      <Button
        isActive={axis ? axis?.indexOf('z') > -1 : false}
        textColor="black"
        onClick={() => handleSetAxis('z')}
      >
        Z
      </Button>
      <div>
        <Button
          isActive={selectedPoints ? selectedPoints?.length > 1 : false}
          textColor="white"
          onClick={async () => {
            try {
              if (axis && selectedPoints) {
                await createConstraint(
                  axis.length > 0 ? axis : ['x', 'z', 'y'],
                  selectedPoints.map((el) => el.point),
                  pieces[pieceIndex].id,
                  pieceIndex,
                );
                refreshConstraints();
                setAxis(null);
                setSelectedPoints(null);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Créer la contrainte
        </Button>
      </div>

      {constraints?.map((constraint, index) => {
        return (
          <Line key={constraint.id}>
            <Clickable
              onClick={() => {
                setSelectedPoints(
                  constraint.points.map((p) => {
                    const point = getModificationWithHistory(history[pieces[pieceIndex].uuid], p);

                    return {
                      point: new Float32Array([point.x, point.y, point.z]),
                      pieceIndex,
                    };
                  }),
                );
              }}
            >
              Contrainte {index} ({constraint.axis})
            </Clickable>
            <div
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await deleteConstraint(constraint.id);
                refreshConstraints();
              }}
            >
              x
            </div>
          </Line>
        );
      })}
    </Container>
  );
};

type ButtonType = {
  isActive: boolean;
  textColor: string;
};
const Button = styled.button<ButtonType>`
  background-color: ${(props) => (props.isActive ? '#4caf50' : '#a1a1a1')};
  border: none;
  color: ${(props) => props.textColor};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 10px;
`;

const Clickable = styled.p`
  cursor: pointer;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
