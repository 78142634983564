import Quotes from '../../quotesAndOrders/records/Quotes';
import { ORDER, QUOTE, TO_CONFIRM } from '../../quotesAndOrders/records/quotes.helper';
import AdminPageWithTabs from '../components/AdminPageWithTabs';

const tabs = ['quotes', 'toConfirm', 'orders'] as const;
type Tab = (typeof tabs)[number];

const AdminQuotesAndOrders = () => {
  const tabsToComponent = (tab: Tab) => {
    switch (tab) {
      case 'orders': {
        return <Quotes filter={ORDER} specialQuery="admin" />;
      }
      case 'toConfirm': {
        return <Quotes filter={TO_CONFIRM} specialQuery="admin" />;
      }
      default: {
        return <Quotes filter={QUOTE} specialQuery="admin" />;
      }
    }
  };

  return (
    <AdminPageWithTabs tabs={tabs} pageName="Devis / Commandes" tabToComponent={tabsToComponent} />
  );
};

export default AdminQuotesAndOrders;
