import { toast } from 'react-toastify';

import { Buckets, getUploadUrl } from '../services/file';

export const uploadImage = async (file: File, bucket: Buckets, piece = false) => {
  const data = await getUploadUrl(bucket);

  const MAX_FILE_SIZE = 1000 * 10_000;
  if (file.size > MAX_FILE_SIZE && !piece) {
    toast.error('La taille du fichier ne peut excéder 10Mo');
    return null;
  }

  if (!data) {
    return null;
  }
  const headersContent = {
    'Content-Type': file.type,
    Accept: 'application/json',
  };
  const headers = new Headers(headersContent);

  let response;
  try {
    response = await fetch(data.url, {
      method: 'PUT',
      body: file,
      headers,
    });
  } catch {
    return null;
  }
  const uploadedFileLink = response?.url ?? '';

  if (!uploadedFileLink) {
    toast.error("Erreur lors de l'import");

    return null;
  }
  return data.key;
};

export const upload3dPiece = async (file: File) => {
  const fileExtension = file.name.split('.').pop();
  if (!['glb'].includes(fileExtension ?? '')) {
    return null;
  }
  return uploadImage(file, Buckets.BUCKET_3D_PIECES, true);
};
