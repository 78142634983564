/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unsafe-optional-chaining */
import { Macro, Modification } from 'src/services/configurator';

import { getModificationWithHistory, getMovingAxisOf2Point } from './configurator.utils';

export const isModificationFix = (
  selectedModifications:
    | {
        modificationId: number;
        ratio: string;
        isFixed: boolean;
      }[]
    | undefined,
  modification: Modification,
) => {
  const selectedModification = selectedModifications?.find(
    (m) => m.modificationId === modification.id,
  );

  return selectedModification?.isFixed ?? false;
};

export const hasFixValue = (
  selectedModifications:
    | {
        modificationId: number;
        ratio: string;
        isFixed: boolean;
      }[]
    | undefined,
  modification: Modification,
) => {
  const fixedModification = selectedModifications?.find(
    (m) => m.modificationId === modification.id,
  );
  return fixedModification?.isFixed;
};

export const isModificationSelected = (
  selectedModifications:
    | {
        modificationId: number;
        ratio: string;
        isFixed: boolean;
      }[]
    | undefined,
  modification: Modification,
) => {
  if (selectedModifications?.find((element) => element.modificationId === modification.id))
    return true;
  return false;
};

export const getRatio = (
  selectedModifications:
    | {
        modificationId: number;
        ratio: string;
        isFixed: boolean;
      }[]
    | undefined,
  modification: Modification,
) => {
  const modif = selectedModifications?.find(
    (element) => element.modificationId === modification.id,
  );

  if (modif) return modif.ratio;
  return '';
};

export const getTotalRatio = (
  selectedModifications:
    | {
        modificationId: number;
        ratio: string;
        isFixed: boolean;
      }[]
    | undefined,
) => {
  let total = 0;

  if (selectedModifications)
    for (const el of selectedModifications) {
      const ratio = Number.parseFloat(el.ratio);
      if (ratio) total += ratio;
    }
  return total.toFixed(2);
};

export const getSize = (macros: Macro[], history: any, macroId: number) => {
  const macro = macros?.find((el) => el.id === macroId);

  let total = 0;
  let totalRatio = 0;
  if (macro) {
    for (const element of macro.modifications) {
      if (element.modification.points[0] && element.modification.points[1]) {
        const movingAxis = getMovingAxisOf2Point(
          element.modification.points[0],
          element.modification.points[1],
        );

        const diff =
          getModificationWithHistory(history, element.modification.points[0])[movingAxis] -
          getModificationWithHistory(history, element.modification.points[1])[movingAxis];

        total += Math.abs(diff);
        totalRatio += Number.parseFloat(element.ratio);
      }
    }
  }

  if (macro) {
    for (const element of macro.constants) {
      total += element.constant.distance / 100;
    }
  }
  return ((total / totalRatio) * 100).toFixed(2);
};

export const getSizeOfModification = (
  history: { [key: string]: Float32Array },
  modification: Modification,
) => {
  let total = 0;

  const movingAxis = getMovingAxisOf2Point(modification.points[0], modification.points[1]);

  const diff =
    getModificationWithHistory(history, modification.points[0])[movingAxis] -
    getModificationWithHistory(history, modification.points[1])[movingAxis];

  total += diff < 0 ? diff * -1 : diff;
  return (total * 100).toFixed(2);
};
