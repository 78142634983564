import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Badge,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import PasswordChecklist from '../../../components/authentication/PasswordChecklist';
import { Container, FormLine, LowerCaseButton } from '../../../components/common';
import { CustomPhoneInput } from '../../../components/forms/PhoneInputRhf';
import { TextFieldRHF } from '../../../components/forms/TextFieldRhf';
import useToggle from '../../../hook/useToggle';
import paths from '../../../navigation/paths';
import { LoginBox, Logo } from '../Login';
import useRegister from './useRegister';

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flexDirection: 'column',
  width: '55%',
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '90%',
  padding: '30px',
  overflowY: 'auto',
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    border: '1px solid #D0D5DD',
    backgroundColor: 'white',
    color: '#667085',
  },
}));

const Register = () => {
  const { methods, submit } = useRegister();

  const [showPassword, handleClickShowPassword] = useToggle();

  const [showConfirmPassword, handleClickShowConfirmPassword] = useToggle();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = methods;

  return (
    <Container>
      <LoginBox>
        <Logo src="/logo_paysage.svg" />
      </LoginBox>
      <Wrapper>
        <StyledForm onSubmit={handleSubmit(submit)}>
          <Typography variant="h1" marginTop="20px">
            Inscription
          </Typography>
          <Typography variant="h6">Informations personnelles</Typography>
          <FormLine>
            <TextFieldRHF
              name="firstName"
              control={control}
              TextFieldProps={{
                label: 'Prénom*',
                error: Boolean(errors.firstName),
                helperText: errors.firstName?.message,
              }}
            />
            <TextFieldRHF
              name="lastName"
              control={control}
              TextFieldProps={{
                label: 'Nom*',
                error: Boolean(errors.lastName),
                helperText: errors.lastName?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="email"
              control={control}
              TextFieldProps={{
                label: 'Email*',
                error: Boolean(errors.email),
                helperText: errors.email?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="password"
              control={control}
              TextFieldProps={{
                label: 'Mot de passe*',
                error: Boolean(errors.password),
                helperText: errors.password?.message,
                type: showPassword ? 'text' : 'password',
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <TextFieldRHF
              name="confirmPassword"
              control={control}
              TextFieldProps={{
                label: 'Confirmer le mot de passe*',
                error: Boolean(errors.confirmPassword),
                helperText: errors.confirmPassword?.message,
                type: showConfirmPassword ? 'text' : 'password',
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormLine>
          <PasswordChecklist password={watch('password')} />
          <Typography variant="h6">Informations de l&apos;entreprise</Typography>
          <FormLine>
            <PhoneInput
              name="phone"
              control={control}
              inputComponent={CustomPhoneInput}
              label="Téléphone*"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              defaultCountry="FR"
            />
            <TextFieldRHF
              name="reason"
              control={control}
              TextFieldProps={{
                label: 'Raison sociale*',
                error: Boolean(errors.reason),
                helperText: errors.reason?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="address.address"
              control={control}
              TextFieldProps={{
                label: 'Adresse*',
                error: Boolean(errors.address?.address),
                helperText: errors.address?.address?.message,
              }}
            />
            <TextFieldRHF
              name="address.addressDetails"
              control={control}
              TextFieldProps={{
                label: "Complément d'adresse",
                error: Boolean(errors.address?.addressDetails),
                helperText: errors.address?.addressDetails?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="address.city"
              control={control}
              TextFieldProps={{
                label: 'Ville*',
                error: Boolean(errors.address?.city),
                helperText: errors.address?.city?.message,
              }}
            />
            <TextFieldRHF
              name="address.zipCode"
              control={control}
              TextFieldProps={{
                label: 'Code Postal*',
                error: Boolean(errors.address?.zipCode),
                helperText: errors.address?.zipCode?.message,
              }}
            />
            <TextFieldRHF
              name="address.country"
              control={control}
              TextFieldProps={{
                label: 'Pays*',
                error: Boolean(errors.address?.country),
                helperText: errors.address?.country?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="siret"
              control={control}
              TextFieldProps={{
                label: 'SIRET*',
                error: Boolean(errors.siret),
                helperText: errors.siret?.message,
              }}
            />
            <TextFieldRHF
              name="function"
              control={control}
              TextFieldProps={{
                label: 'Fonction',
                error: Boolean(errors.function),
                helperText: errors.function?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={control}
              TextFieldProps={{
                label: "Nom de l'agence",
                error: Boolean(errors.name),
                helperText: errors.name?.message,
                disabled: watch('headOffice'),
              }}
            />
            <Controller
              name="headOffice"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={
                    <Badge
                      badgeContent={
                        <StyledTooltip
                          title={
                            <Typography>
                              Ces comptes sont spéciaux et permettent de gérer plusieurs agences
                            </Typography>
                          }
                          placement="top-start"
                        >
                          <Info sx={{ color: '#667085' }} />
                        </StyledTooltip>
                      }
                    >
                      M&apos;inscrire en tant que compte siège
                    </Badge>
                  }
                />
              )}
            />
          </FormLine>
          <LowerCaseButton
            variant="contained"
            sx={{ minHeight: '50px', marginTop: '20px' }}
            type="submit"
          >
            Créer mon compte
          </LowerCaseButton>
          <Link href={`/${paths.connexion.root}`} sx={{ textAlign: 'center' }}>
            J&apos;ai déjà un compte
          </Link>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default Register;
