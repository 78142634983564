/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
import { styled, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import { ImportComponent } from './Pieces/Import';
import { Update } from './Pieces/Update';

export const Pieces = () => {
  const [menu, setMenu] = useState<'update' | 'import'>('update');

  return (
    <Container>
      <Tabs
        value={menu === 'update' ? 0 : 1}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setMenu(newValue === 0 ? 'update' : 'import');
        }}
      >
        <Tab label="Modifier" />
        <Tab label="Importer" />
      </Tabs>

      {menu === 'import' ? <ImportComponent /> : null}
      {menu === 'update' ? <Update /> : null}
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
});
