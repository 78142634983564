import { SvgIcon, SvgIconProps } from '@mui/material';

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M6.5 16.5V10.3333C6.5 9.86662 6.5 9.63327 6.59083 9.45501C6.67072 9.29821 6.79821 9.17072 6.95501 9.09083C7.13327 9 7.36662 9 7.83333 9H10.1667C10.6334 9 10.8667 9 11.045 9.09083C11.2018 9.17072 11.3293 9.29821 11.4092 9.45501C11.5 9.63327 11.5 9.86662 11.5 10.3333V16.5M8.18141 1.30335L2.52949 5.69929C2.15168 5.99314 1.96278 6.14006 1.82669 6.32406C1.70614 6.48705 1.61633 6.67067 1.56169 6.86589C1.5 7.08628 1.5 7.3256 1.5 7.80423V13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1586 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7668 16.5 13.8333V7.80423C16.5 7.3256 16.5 7.08628 16.4383 6.86589C16.3837 6.67067 16.2939 6.48705 16.1733 6.32406C16.0372 6.14006 15.8483 5.99314 15.4705 5.69929L9.81859 1.30335C9.52582 1.07564 9.37943 0.961782 9.21779 0.918016C9.07516 0.879399 8.92484 0.879399 8.78221 0.918016C8.62057 0.961782 8.47418 1.07564 8.18141 1.30335Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default HomeIcon;
