/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Add, Delete, Edit, Euro, Upload } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Input,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { AutocompleteRHF } from 'src/components/forms/AutocompleteRhf';
import useLoadDataList from 'src/hook/useLoadDataList';
import { findAllCatalogs } from 'src/services/catalog';

import { PatternsLabels } from 'src/constants/pattern';
import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import { ButtonRow, FormLine, LowerCaseButton, RoundedButton } from '../../../../components/common';
import { CustomPagination } from '../../../../components/CustomPagination';
import DeleteWarningModal from '../../../../components/DeleteWarningModal';
import { NumberFieldRHF } from '../../../../components/forms/NumberInput';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../context/EnvProvider';
import useAdditionalServices, { PATTERN_PAGE_SIZE } from '../../../../hook/useAdditionalServices';
import useToggle from '../../../../hook/useToggle';
import { Buckets } from '../../../../services/file';
import { uploadImage } from '../../../../utils/file.utils';

export const ImportPattern = ({ userId }: { userId: string }) => {
  const {
    patterns,
    patternPage,
    setPatternPage,
    pageCount,
    addPattern,
    togglePattern,
    openedPattern,
    setOpenedPattern,
    patternMethods,
    submitPattern,
    handleSelectPattern,
    selectedPatterns,
    handleDeletePatterns,
    setImageRatio,
  } = useAdditionalServices(userId);

  const { data: catalogs } = useLoadDataList(findAllCatalogs);

  const [openDeletePattern, toggleOpenDeletePattern] = useToggle();

  const { buckets } = useEnv();

  const {
    control: controlPattern,
    formState: { errors: errorsPattern },
    handleSubmit: handleSubmitPatterns,
    watch: watchPattern,
    setValue,
  } = patternMethods;

  const uploadPatternImage = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
    return pictureName ?? '';
  };

  return (
    <>
      <Container>
        <Box component="div">
          <Typography variant="h5">BIBLIOTHÈQUE DE MOTIFS</Typography>
          <Typography>Vous pouvez ajouter vos propres motifs et définir leurs coûts.</Typography>
        </Box>
        <ButtonRow>
          <RoundedButton
            startIcon={<Add color="primary" />}
            color="primary"
            variant="outlined"
            onClick={togglePattern}
          >
            Ajouter un motif
          </RoundedButton>
          <RoundedButton
            startIcon={<Edit color={selectedPatterns.length === 1 ? 'primary' : 'inherit'} />}
            color="primary"
            variant="outlined"
            disabled={selectedPatterns.length !== 1}
            onClick={() => {
              togglePattern();
              setOpenedPattern(patterns?.find((pattern) => pattern.id === selectedPatterns[0]));
            }}
          >
            Modifier
          </RoundedButton>
          <RoundedButton
            startIcon={<Delete color={selectedPatterns.length === 0 ? 'inherit' : 'error'} />}
            color="error"
            variant="outlined"
            onClick={toggleOpenDeletePattern}
            disabled={selectedPatterns.length === 0}
          >
            Supprimer
          </RoundedButton>
        </ButtonRow>
        <PatternContainer>
          {patterns && patterns.length > 0 ? (
            <Grid container component="div" sx={{ padding: '16px' }} spacing={2}>
              {patterns
                .slice(
                  patternPage * PATTERN_PAGE_SIZE,
                  patternPage * PATTERN_PAGE_SIZE + PATTERN_PAGE_SIZE,
                )
                .map((pattern) => (
                  <Grid item xs={2} xl={1} key={pattern.id}>
                    <Pattern>
                      <StyledCheckBox
                        color="primary"
                        onClick={() => handleSelectPattern(pattern.id)}
                      />
                      <Image
                        src={`${buckets?.BUCKET_3D_PIECES || ''}/${pattern.image}`}
                        alt="pattern"
                      />
                      <Typography>{pattern.name}</Typography>
                      <Typography>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(pattern.price)}
                      </Typography>
                    </Pattern>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Wrapper>
              <Typography variant="body2">Pas de résultats</Typography>
            </Wrapper>
          )}
          <CustomPagination
            page={patternPage}
            pageCount={pageCount}
            handleChangePage={setPatternPage}
          />
        </PatternContainer>
      </Container>
      <CenteredModal
        open={addPattern}
        handleClose={() => {
          togglePattern();
          setOpenedPattern(undefined);
        }}
      >
        <ModalTitle>
          <Typography variant="h5">BIBLIOTHÈQUE DE MOTIFS</Typography>
        </ModalTitle>
        <StyledForm onSubmit={handleSubmitPatterns(submitPattern)}>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={controlPattern}
              TextFieldProps={{
                label: 'Nom du motif',
                error: Boolean(errorsPattern.name),
                helperText: errorsPattern.name?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="description"
              control={controlPattern}
              TextFieldProps={{
                label: 'Description',
                error: Boolean(errorsPattern.description),
                helperText: errorsPattern.description?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <Autocomplete
              defaultValue={watchPattern('type')}
              value={watchPattern('type')}
              options={PatternsLabels.map((option) => option.label)}
              multiple
              id="tags-filled"
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
                })
              }
              onChange={(event, values) => {
                setValue('type', values);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Types"
                  placeholder="Types"
                  helperText={errorsPattern.type?.message}
                  error={Boolean(errorsPattern.type)}
                />
              )}
            />
          </FormLine>
          <FormLine>
            <AutocompleteRHF
              control={controlPattern}
              name="catalogIds"
              options={catalogs}
              AutocompleteProps={{
                multiple: true,
                freeSolo: false,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (option, value) => option.id === value.id,
                renderTags: (value, getTagProps) => {
                  return (
                    <TagsContainer>
                      {value.map(({ name }, index) => (
                        <Chip variant="outlined" label={name} {...getTagProps({ index })} />
                      ))}
                    </TagsContainer>
                  );
                },
              }}
              TextFieldProps={{
                label: 'Catalogue',
                error: Boolean(errorsPattern.catalogIds),
                helperText: errorsPattern.catalogIds?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <NumberFieldRHF
              name="price"
              control={controlPattern}
              TextFieldProps={{
                label: 'Prix',
                error: Boolean(errorsPattern.price),
                helperText: errorsPattern.price?.message,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <NumberFieldRHF
              name="height"
              control={controlPattern}
              TextFieldProps={{
                label: 'Hauteur par default',
                error: Boolean(errorsPattern.height),
                helperText: errorsPattern.height?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            />
          </FormLine>
          <Controller
            name="image"
            control={controlPattern}
            render={({ field }) => {
              const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
                if (e.target.files) {
                  const fileKey = await uploadPatternImage(e.target.files[0]);
                  field.onChange(fileKey);
                }
              };

              return (
                <UploadContainer component="label">
                  {watchPattern('image') ? (
                    <Image
                      onLoad={(e) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setImageRatio(e.target.width / e.target.height);
                      }}
                      src={`${buckets?.BUCKET_3D_PIECES || ''}/${watchPattern('image')}`}
                      alt="pattern"
                    />
                  ) : (
                    <>
                      <Upload />
                      <Typography variant="body2">Cliquer pour ajouter une image</Typography>
                      <Typography variant="body2">PNG, JPG, PDF...</Typography>
                    </>
                  )}
                  <Input type="file" sx={{ display: 'none' }} onChange={onChange} />
                </UploadContainer>
              );
            }}
          />
          <Typography variant="caption">
            Nous déclinons toute responsabilité sur les images que vous allez uploader.
          </Typography>
          <LowerCaseButton variant="contained" type="submit">
            {openedPattern ? 'Modifier le motif' : 'Ajouter un motif'}
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={togglePattern}>
            Annuler
          </LowerCaseButton>
        </StyledForm>
      </CenteredModal>
      <DeleteWarningModal
        header="SUPPRIMER LES MOTIFS SÉLECTIONNÉS ?"
        handleDelete={handleDeletePatterns}
        open={openDeletePattern}
        onClose={toggleOpenDeletePattern}
      />
    </>
  );
};

const Container = styled(Box)({
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
  padding: '30px',
});

const PatternContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  border: `0.5px solid #EAECF0`,
  borderRadius: '8px',
}));

const Pattern = styled(Box)({
  border: `0.5px solid #EAECF0`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
});

const StyledCheckBox = styled(Checkbox)({
  position: 'absolute',
  left: 0,
  top: 0,
});

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '104px',
  backgroundColor: 'rgba(245, 245, 245, 0.38)',
});

const Image = styled('img')({
  width: '100%',
});

const StyledForm = styled('form')({
  padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const UploadContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.black,
})) as typeof Button;

const TagsContainer = styled(Box)({
  maxHeight: 75,
  overflow: 'auto',
  width: '100%',
});
