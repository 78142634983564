import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';

type SearchProps = {
  search: string;
  setSearch: (search: string) => void;
} & TextFieldProps;

const SearchComponent = ({ search, setSearch, ...props }: SearchProps) => {
  return (
    <TextField
      label="Rechercher"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: search !== '' && (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearch('')}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default SearchComponent;
