import { Checkbox } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { ICheckboxRHF } from './FormComponents.type';

const CheckboxRHF = <T extends FieldValues>({ name, control, checkboxProps }: ICheckboxRHF<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <Checkbox {...field} {...checkboxProps} inputRef={ref} checked={field.value} />
    )}
  />
);

export default CheckboxRHF;
