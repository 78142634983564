/* eslint-disable unicorn/prefer-array-some */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button, Checkbox, styled, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { fabricationFiltersAdmin } from 'src/components/3d/Assemblies';
import { ButtonRow } from 'src/components/common';
import { categoriesLabels, CategoryType } from 'src/constants/category';
import useFilters from 'src/hook/useFilter';
import useLoadDataList from 'src/hook/useLoadDataList';
import { StateNames, States } from 'src/Screens/Configurator';
import { createUnderAssembly } from 'src/services/assemblies';
import { getModificationsOfPieces, Modification } from 'src/services/configurator';
import { Buckets } from 'src/services/file';
import { uploadImage } from 'src/utils/file.utils';
import { WebGLRenderer } from 'three';

type SelectModificationProps = {
  activePieces: any[];
  modifications: {
    id: number;
    modifications: Modification[];
  }[];
  onSelect: (modificationId: number) => void;
  checkedModificationId: number | undefined;
  title: string;
};

const SelectModification = ({
  activePieces,
  modifications,
  onSelect,
  checkedModificationId,
  title,
}: SelectModificationProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modificationName, setModificationName] = useState<string>();

  return (
    <>
      <Typography
        variant="h1"
        style={{ fontSize: '14px', cursor: 'pointer', marginTop: '8px' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </Typography>
      {isOpen ? (
        <div style={{ padding: '14px', paddingTop: 0 }}>
          {activePieces.map((element, elementIndex) => {
            if (!modifications.find((el) => el.id === element.id)) return <></>;
            return (
              <>
                <Typography variant="h4" style={{ fontSize: '14px', textTransform: 'none' }}>
                  {element.name}
                </Typography>
                {modifications[elementIndex]?.modifications?.map((el) => {
                  return (
                    <Row>
                      <Checkbox
                        checked={checkedModificationId === el.id}
                        onClick={() => {
                          onSelect(el.id);
                          setModificationName(el.name);
                        }}
                      />
                      <Typography
                        variant="body1"
                        style={{ fontSize: '12px', textTransform: 'none', cursor: 'pointer' }}
                      >
                        {el.name}
                      </Typography>
                    </Row>
                  );
                })}
              </>
            );
          })}
        </div>
      ) : (
        <>
          {modificationName === undefined ? null : (
            <Typography
              variant="body1"
              style={{ fontSize: '14px', textTransform: 'none', marginLeft: '14px' }}
            >
              - {modificationName}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

type CreateProps = {
  pieces: any[];
  activePieces: any[];
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  centerCamera: () => void;
  gl: WebGLRenderer | undefined;
};

export const Create = ({
  pieces,
  activePieces,
  setValue,
  setPieceIndex,
  centerCamera,
  gl,
}: CreateProps) => {
  const { data: modifications, onRefresh: refreshModifications } = useLoadDataList(
    () => getModificationsOfPieces([...new Set(activePieces.map((element) => element.id))]),
    [activePieces],
  );

  useEffect(() => {
    setValue('texture', false);
    setPieceIndex(-1);
    centerCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedModificationsHeightId, setSelectedModificationsHeightId] = useState<number>();
  const [selectedModificationsWidthId, setSelectedModificationsWidthId] = useState<number>();
  const [selectedModificationsLengthId, setSelectedModificationsLengthId] = useState<number>();
  const [fabricationFiltersComponent, fabricationFilterState] = useFilters(
    fabricationFiltersAdmin,
    [],
  );

  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<CategoryType>('ANY');

  const create = () => {
    const data = {
      name,
      type: category,
      pieces: pieces.map((el) => {
        return {
          position: el.position,
          id: el.id,
        };
      }),
      modificationsIds: [
        selectedModificationsWidthId,
        selectedModificationsHeightId,
        selectedModificationsLengthId,
      ],
    };

    gl?.domElement.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], 'image.png');

        const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
        if (pictureName) {
          await createUnderAssembly({ ...data, pictureUrl: pictureName, fabricationFilterState });

          setName('');
          setSelectedModificationsHeightId(undefined);
          setSelectedModificationsWidthId(undefined);
          setSelectedModificationsLengthId(undefined);
        }
      }
    });
  };

  return (
    <Container>
      <Label>Nom du sous assemblage</Label>
      <Input
        type="text"
        name="height"
        onChange={(e) => {
          setName(e.target.value);
        }}
        value={name}
      />

      <Select
        name="sens"
        id="sens"
        onChange={(e) => {
          setCategory(e.target.value as SetStateAction<CategoryType>);
        }}
        style={{ marginTop: '8px', marginBottom: '8px' }}
      >
        {categoriesLabels.map((el, elIndex) => {
          return (
            <option key={elIndex} selected={category === el.key} value={el.key}>
              {el.label}
            </option>
          );
        })}
      </Select>
      <ButtonRow>{fabricationFiltersComponent}</ButtonRow>

      <SelectModification
        activePieces={activePieces}
        modifications={modifications}
        onSelect={(modificationId: number) => {
          setSelectedModificationsHeightId(
            modificationId === selectedModificationsHeightId ? undefined : modificationId,
          );
        }}
        checkedModificationId={selectedModificationsHeightId}
        title="Hauteur"
      />
      <SelectModification
        activePieces={activePieces}
        modifications={modifications}
        onSelect={(modificationId: number) => {
          setSelectedModificationsWidthId(
            modificationId === selectedModificationsWidthId ? undefined : modificationId,
          );
        }}
        checkedModificationId={selectedModificationsWidthId}
        title="Largeur"
      />
      <SelectModification
        activePieces={activePieces}
        modifications={modifications}
        onSelect={(modificationId: number) => {
          setSelectedModificationsLengthId(
            modificationId === selectedModificationsLengthId ? undefined : modificationId,
          );
        }}
        checkedModificationId={selectedModificationsLengthId}
        title="Longueur"
      />

      <ButtonContainer>
        <StyledButton
          disabled={!name}
          variant="contained"
          onClick={() => {
            create();
          }}
        >
          <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'none' }}>
            Enregistrer le sous assemblage
          </Typography>
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
};

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '12px',

  width: '190px',
  height: '40px',
}));

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '30px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',

  alignItems: 'center',

  gap: '8px',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '70vh',
});
