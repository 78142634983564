/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCreateMacro } from 'src/hook/useCreateMacro';
import { createMacro, Modification } from 'src/services/configurator';
import {
  getRatio,
  getTotalRatio,
  hasFixValue,
  isModificationFix,
  isModificationSelected,
} from 'src/utils/macro.utils';
import styled from 'styled-components';

type CreateMacroProps = {
  modifications: Modification[] | undefined;
  pieceId: number;
  pieceIndex: number;
  refreshMacros: () => object;
};

export const CreateMacro = ({
  modifications,
  pieceId,
  pieceIndex,
  refreshMacros,
}: CreateMacroProps) => {
  const {
    selectedModifications,
    setSelectedModifications,
    name,
    setName,
    selectedConstants,
    setSelectedConstants,
    isConstantSelected,
    constants,
  } = useCreateMacro(pieceId);

  return (
    <>
      <p>Modifications disponibles: ({getTotalRatio(selectedModifications)})</p>

      <label>
        Nom:
        <Input
          style={{ width: '100%' }}
          type="text"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </label>
      {modifications?.map((modification) => {
        return (
          <Line>
            <div
              onClick={() => {
                if (isModificationSelected(selectedModifications, modification)) {
                  setSelectedModifications(
                    selectedModifications?.filter((el) => el.modificationId !== modification.id),
                  );
                } else {
                  setSelectedModifications([
                    ...(selectedModifications ?? []),
                    {
                      modificationId: modification.id,
                      ratio: '0',
                      isFixed: false,
                    },
                  ]);
                }
              }}
            >
              <Check isChecked={isModificationSelected(selectedModifications, modification)} />
              <p>{modification.name}</p>
            </div>
            <div
              onClick={() => {
                const selectedModification = selectedModifications?.find(
                  (m) => m.modificationId === modification.id,
                );
                if (selectedModification) {
                  if (selectedModification.isFixed) {
                    setSelectedModifications(
                      selectedModifications?.map((m) => {
                        if (m.modificationId === selectedModification.modificationId) {
                          return {
                            ...m,
                            isFixed: false,
                          };
                        }
                        return m;
                      }),
                    );
                  } else {
                    setSelectedModifications(
                      selectedModifications?.map((m) => {
                        if (m.modificationId === selectedModification.modificationId) {
                          return {
                            ...m,
                            ratio: '',
                            isFixed: true,
                          };
                        }
                        return m;
                      }),
                    );
                  }
                } else {
                  setSelectedModifications([
                    ...(selectedModifications ?? []),
                    {
                      modificationId: modification.id,
                      ratio: '',
                      isFixed: true,
                    },
                  ]);
                }
              }}
            >
              <Check isChecked={isModificationFix(selectedModifications, modification)} />
              <p>fix</p>
            </div>
            <Input
              type="text"
              name="name"
              disabled={hasFixValue(selectedModifications, modification)}
              onChange={(e) => {
                if (
                  selectedModifications &&
                  isModificationSelected(selectedModifications, modification)
                ) {
                  const tmpModification = [...selectedModifications];

                  for (const [index, el] of tmpModification.entries()) {
                    if (el.modificationId === modification.id) {
                      tmpModification[index].ratio = e.target.value;
                    }
                  }
                  setSelectedModifications(tmpModification);
                } else {
                  setSelectedModifications([
                    ...(selectedModifications ?? []),
                    {
                      modificationId: modification.id,
                      ratio: '0',
                      isFixed: false,
                    },
                  ]);
                }
              }}
              value={getRatio(selectedModifications, modification)}
            />
          </Line>
        );
      })}
      <p>Constantes:</p>
      {constants?.map((constant) => {
        return (
          <Line>
            <div
              onClick={() => {
                if (isConstantSelected(constant)) {
                  setSelectedConstants(
                    selectedConstants
                      ? selectedConstants?.filter((c) => c !== constant)
                      : undefined,
                  );
                } else {
                  setSelectedConstants([...(selectedConstants ?? []), constant]);
                }
              }}
            >
              <Check isChecked={!!selectedConstants?.find((c) => c === constant)} />
              <p>{constant.name}</p>
            </div>
          </Line>
        );
      })}

      <Button
        isActive={name !== ''}
        textColor="white"
        onClick={async () => {
          if (name !== '' && selectedModifications)
            await createMacro(name, selectedModifications, selectedConstants, pieceId, pieceIndex);
          refreshMacros();
          setSelectedModifications(undefined);
          setSelectedConstants(undefined);
          setName('');
        }}
      >
        Créer une macro
      </Button>
    </>
  );
};

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

type CheckType = {
  isChecked: boolean;
};

const Check = styled.div<CheckType>`
  height: 10px;
  width: 10px;
  border: 1px solid black;
  margin-right: 10px;
  background-color: ${(props) => (props.isChecked ? 'black' : 'white')};
`;

const Input = styled.input`
  padding: 12px 20px;
  width: 40%;
  margin: 8px 0;
  box-sizing: border-box;
`;

type ButtonType = {
  isActive: boolean;
  textColor: string;
};

const Button = styled.button<ButtonType>`
  background-color: ${(props) => (props.isActive ? '#4caf50' : '#a1a1a1')};
  border: none;
  color: ${(props) => props.textColor};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
`;
