import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import useToggle from '../../../../hook/useToggle';
import { Granit } from '../../../../services/granits';
import { CoefficientCategory, SchemaDetailedCoefficientsForm } from './Coefficients';

interface IGranitsCoefficientsDropDownProps {
  granits: Granit[];
  setValue: UseFormSetValue<SchemaDetailedCoefficientsForm>;
  watch: UseFormWatch<SchemaDetailedCoefficientsForm>;
  type: CoefficientCategory;
}

const GranitsCoefficientsDropDown = ({
  granits,
  setValue,
  watch,
  type,
}: IGranitsCoefficientsDropDownProps) => {
  const [dropDown, toggleDropDown] = useToggle();

  const translateType = () => {
    if (type === 'CINERARY') return 'Cinéraires';
    if (type === 'SOLE/PLATING') return 'Semelles/Placages';
    if (type === 'MONUMENT') return 'Monuments';
    if (type === 'COLUMBARIUM') return 'Columbariums';
    return type;
  };

  return (
    <Stack alignItems="center" gap="16px">
      <GlobalCoefBox>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            marginRight: '8px',
            marginLeft: '4px',
          }}
          onClick={toggleDropDown}
        >
          {dropDown ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          {translateType()}
        </Typography>
        <TextField
          sx={{ width: '70px' }}
          value={
            granits.length > 0 &&
            granits.every(
              (granit) => watch(`${type}.${granit.id}`) === watch(`${type}.${granits[0].id}`),
            )
              ? watch(`${type}.${granits[0].id}`)
              : '-'
          }
          size="small"
          onChange={(event) => {
            for (const granit of granits) {
              setValue(`${type}.${granit.id}`, event.target.value);
            }
          }}
          onBlur={(event) => {
            if (event.target.value !== '-') {
              if (event.target.value === '') {
                for (const granit of granits) {
                  setValue(`${type}.${granit.id}`, '1');
                }
              } else {
                for (const granit of granits) {
                  setValue(`${type}.${granit.id}`, event.target.value);
                }
              }
            }
          }}
        />
      </GlobalCoefBox>
      {dropDown && (
        <Stack width="80%" gap="16px">
          {granits.map((granit) => (
            <TextField
              label={granit.name}
              value={watch(`${type}.${granit.id}`)}
              name={`${type}.${granit.id}`}
              onChange={(event) => {
                setValue(`${type}.${granit.id}`, event.target.value);
              }}
              onBlur={(event) => {
                if (event.target.value === '') {
                  setValue(`${type}.${granit.id}`, '1');
                }
              }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const GlobalCoefBox = styled(Box)({
  boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
  display: 'flex',
});

export default GranitsCoefficientsDropDown;
