import { api } from './api';

export type AdditionalsPriceType = {
  id: string;

  headNumber: number;
  bodyNumber: number;

  polishingX: number;
  polishingY: number;
  polishingZ: number;
  sawingX: number;
  sawingY: number;
  sawingZ: number;
  frenchExtra: number;

  frenchLinearDetails: {
    faceX: number;
    faceX2: number;
    faceY: number;
    faceY2: number;
    faceZ: number;
    faceZ2: number;
  };
  frenchM2Details: {
    faceX: number;
    faceX2: number;
    faceY: number;
    faceY2: number;
    faceZ: number;
    faceZ2: number;
  };
};

export const getAdditionalsPriceOnCustomStele = async (headNumber: number, bodyNumber: number) => {
  const { data } = await api.get<AdditionalsPriceType>(
    `/pieces/customStele/additionalsPrice?headNumber=${headNumber}&bodyNumber=${bodyNumber}`,
  );

  return data;
};

export const updateAdditionalsPriceOnCustomStele = (data: AdditionalsPriceType) => {
  return api.post(`/pieces/customStele/additionalsPrice`, { data });
};
