import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { ModalTitle } from 'src/components/CenteredModal';
import { FormLine, LowerCaseButton } from 'src/components/common';
import FileSettings from 'src/components/icons/FileSettingsIcon';
import useAdditionalServices from 'src/hook/useAdditionalServices';
import { ClientInformationsFormSchema } from 'src/hook/useConfiguratorNavigation';
import { AdditionalService } from 'src/services/additionalServices';
import { Assembly } from 'src/services/assemblies';
import { Quote, updateAdditionalServices } from 'src/services/quotes';
import muiTheme from 'src/themes/muiTheme';

interface IAdditionalServices {
  quote?: Quote;
  closeForm: () => void;
  handleSubmit: UseFormHandleSubmit<ClientInformationsFormSchema>;
  errors: FieldErrors<ClientInformationsFormSchema>;
  reset: UseFormReset<ClientInformationsFormSchema>;
  userId: string;
  activeAdditionalServices: string[];
  setActiveAdditionalServices: (activeAdditionalService: string[]) => void;
  assembly: Assembly | null;
  onRefresh: () => void;
}

export const AdditionalServices = ({
  quote,
  closeForm,
  handleSubmit,
  errors,
  reset,
  userId,
  activeAdditionalServices,
  setActiveAdditionalServices,
  assembly,
  onRefresh,
}: IAdditionalServices) => {
  const { additionalServices } = useAdditionalServices(userId);

  const onSubmitForm: SubmitHandler<ClientInformationsFormSchema> = async (datas) => {
    if (assembly) {
      const additionalServicesToUpdate = activeAdditionalServices
        .map((el) => {
          return additionalServices.find((element) => element.id === el);
        })
        .filter((el) => el !== undefined) as AdditionalService[];

      await updateAdditionalServices(assembly.id, additionalServicesToUpdate);
      closeForm();
      onRefresh();
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
      <ModalTitle sx={quote ? { background: '#F7F0E7' } : {}}>
        <Typography
          sx={{
            color: quote ? muiTheme.palette.secondary.main : muiTheme.palette.primary.main,
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
          variant="h2"
          textAlign="left"
        >
          <FileSettings fontSize="large" />
          Travaux supplémentaires
        </Typography>
      </ModalTitle>
      <Body>
        {activeAdditionalServices.map((el, elIndex) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormLine>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Sélectionner un travail</InputLabel>
                  <Select
                    label="Sélectionner un travail"
                    value={el}
                    onChange={(e) => {
                      setActiveAdditionalServices(
                        activeAdditionalServices.map((element, elementIndex) => {
                          if (elIndex === elementIndex) return e.target.value;
                          return element;
                        }),
                      );
                    }}
                  >
                    {additionalServices.map((element) => {
                      if (!element.active) return null;
                      return (
                        <MenuItem value={element.id}>
                          {element.name} ({element.price}€)
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </FormLine>
              <Image
                onClick={() => {
                  setActiveAdditionalServices(
                    activeAdditionalServices.filter((_element, elementIndex) => {
                      if (elIndex === elementIndex) return false;
                      return true;
                    }),
                  );
                }}
                src="/icons/bin.svg"
                alt="bin"
                style={{
                  height: 18,
                  width: 20,
                  marginLeft: 12,
                  cursor: 'pointer',
                }}
              />
            </div>
          );
        })}

        <Button
          onClick={() => setActiveAdditionalServices([...activeAdditionalServices, ''])}
          variant="outlined"
          sx={{
            borderRadius: 50,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'fit-content',
            gap: '20px',
            borderColor: '#BD9A67',
            color: '#000',
            textTransform: 'none',
          }}
        >
          <img src="/icons/plus.svg" alt="plus" />
          Ajouter un travail supplémentaire
        </Button>
        <FormLine>
          <div />
          <LowerCaseButton
            color={quote ? 'secondary' : 'primary'}
            onClick={() => {
              closeForm();
              reset();
            }}
          >
            Annuler
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            type="submit"
            color={quote ? 'secondary' : 'primary'}
          >
            Valider
          </LowerCaseButton>
        </FormLine>
      </Body>
    </StyledForm>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  background: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '10px',
}));

const Body = styled(Box)({
  padding: '30px',
  paddingRight: '50px',
  paddingLeft: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const Image = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: 5,
  cursor: 'pointer',
});
