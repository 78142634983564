/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { categoriesLabels, CategoryType } from 'src/constants/category';
import { usePieces } from 'src/hook/usePieces';
import { deletePiece } from 'src/services/pieces';

import { UpdatePiece } from './UpdatePiece';

export const Update = () => {
  const [categorieKey, setCategorieKey] = useState<CategoryType>('STELE');
  const [editingId, setEditingId] = useState<number>();

  const { pieces: piecesToImport, refreshPieces } = usePieces(categorieKey);

  const handleDelete = async (pieceId: string) => {
    await deletePiece(pieceId);
    refreshPieces();
  };

  return (
    <>
      {editingId === undefined ? (
        <Select
          name="sens"
          id="sens"
          onChange={(e) => {
            setCategorieKey(e.target.value as SetStateAction<CategoryType>);
          }}
        >
          {categoriesLabels.map((el, elIndex) => {
            return (
              <option key={elIndex} selected={categorieKey === el.key} value={el.key}>
                {el.key === 'ANY' ? 'Autre pièces' : null}
                {el.key === 'ANY_ACCESSORY' ? 'Autre accesoires' : null}
                {el.key !== 'ANY_ACCESSORY' && el.key !== 'ANY' ? el.label : null}
              </option>
            );
          })}
        </Select>
      ) : (
        <BackContainer onClick={() => setEditingId(undefined)}>
          <ArrowBackIcon />
          <Typography variant="body1" style={{ fontSize: '12px' }}>
            Retour en arrière
          </Typography>
        </BackContainer>
      )}

      <List dense style={{ width: '100%' }}>
        {piecesToImport.map((el) => {
          if (editingId && editingId !== el.id) return <></>;
          return (
            <>
              {editingId === el.id ? (
                <UpdatePiece piece={el} refreshPieces={refreshPieces} setEditingId={setEditingId} />
              ) : (
                <ListItem
                  style={{ cursor: 'pointer' }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(String(el.id))}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontSize: '12px', textTransform: 'capitalize' }}
                      >
                        delete
                      </Typography>
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={el.name}
                    onClick={() => setEditingId(editingId === el.id ? undefined : el.id)}
                  />
                </ListItem>
              )}
            </>
          );
        })}
      </List>
    </>
  );
};

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const BackContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 4,
  width: '100%',
  cursor: 'pointer',
});
