/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { styled, Typography } from '@mui/material';

interface IPatternItemProps {
  selectedPattern: number | undefined;
  elIndex: number;
  updateKanvas: () => void;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  el: any;
  changePattern: (
    pattern: {
      uuid: string;
      pieceUUID: string;
      orientation: 'x' | 'y' | 'z';
    },
    majorKey: 'position' | 'scale' | 'inverse_vertical' | 'inverse_horizontal',
    value: any,
    minorKey?: 'x' | 'y' | 'z',
  ) => void;
  applyPatternsTmp: () => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  shouldUpdateTexture: React.MutableRefObject<{
    pieceUUID: string;
    orientation: string;
  } | null>;
  setOrientation: (orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean }) => void;
}

export const PatternItem = ({
  selectedPattern,
  elIndex,
  updateKanvas,
  setSelectedPattern,
  el,
  changePattern,
  applyPatternsTmp,
  patterns,
  setPatterns,
  orientation,
  shouldUpdateTexture,
  setOrientation,
}: IPatternItemProps) => {
  const onDelete = () => {
    const newPattensTmp = { ...patterns };

    newPattensTmp[el.pieceUUID][el.orientation].elements = patterns[el.pieceUUID][
      el.orientation
    ].elements.filter((element: any) => element.uuid !== el.uuid);

    if (newPattensTmp[el.pieceUUID][el.orientation].elements.length === 0) {
      newPattensTmp[el.pieceUUID][el.orientation].texture = null;
    }
    setPatterns(newPattensTmp);

    shouldUpdateTexture.current = {
      pieceUUID: el.pieceUUID,
      orientation: el.orientation,
    };
  };
  const onSelectItem = () => {
    updateKanvas();
    if (selectedPattern === elIndex) {
      setOrientation({
        key: 'free',
        inverse: false,
      });
    } else {
      setOrientation({
        key: el.orientation,
        inverse: el.orientation.includes('inverse'),
      });
    }

    setSelectedPattern(selectedPattern === elIndex ? -1 : elIndex);
  };

  const onChangeInput = (property: 'scale' | 'position', axe: 'x' | 'y' | 'z', value: string) => {
    changePattern(
      {
        uuid: el.uuid,
        pieceUUID: el.pieceUUID,
        orientation: el.orientation,
      },
      property,
      value,
      axe,
    );
  };
  return (
    <>
      <PieceContainer isSelected={selectedPattern === elIndex}>
        <Column
          style={{
            alignItems: 'center',
            border: `1px solid ${selectedPattern === elIndex ? 'white' : '#BC9A67'}`,
            height: '100%',
            padding: '10px',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
          onClick={onSelectItem}
        >
          <Image src={el.reducedImageUrl} alt="motif" />
          <Typography
            variant="body2"
            color="#344054"
            style={{ marginTop: '23px', marginLeft: '4px', fontWeight: 700 }}
          >
            {elIndex}
          </Typography>
        </Column>

        <Column style={{ alignItems: 'flex-start' }}>
          <Typography variant="body2" color="#344054" style={{ fontWeight: 700 }}>
            Dimension
          </Typography>
          <Row style={{ gap: 8 }}>
            <Input
              disabled={orientation.key === 'free' || el.min === 0 || el.max === 0}
              type="text"
              name="depth"
              onClick={() => setSelectedPattern(elIndex)}
              onChange={(e) => onChangeInput('scale', 'x', e.target.value)}
              value={el.scale.x}
              onKeyDown={(event) => {
                if (event.key === 'Enter') applyPatternsTmp();
              }}
              onBlur={() => {
                applyPatternsTmp();
              }}
            />
            <Input
              disabled={orientation.key === 'free' || el.min === 0 || el.max === 0}
              type="text"
              name="depth"
              onClick={() => setSelectedPattern(elIndex)}
              onChange={(e) => onChangeInput('scale', 'y', e.target.value)}
              value={el.scale.y}
              onKeyDown={(event) => {
                if (event.key === 'Enter') applyPatternsTmp();
              }}
              onBlur={() => {
                applyPatternsTmp();
              }}
            />
          </Row>
          <Typography variant="body2" color="#344054" style={{ fontWeight: 700 }}>
            Position
          </Typography>
          <Row style={{ gap: 8 }}>
            <Input
              disabled={orientation.key === 'free'}
              type="text"
              name="depth"
              onClick={() => setSelectedPattern(elIndex)}
              onChange={(e) => onChangeInput('position', 'x', e.target.value)}
              value={el.position.x}
              onKeyDown={(event) => {
                if (event.key === 'Enter') applyPatternsTmp();
              }}
              onBlur={() => {
                applyPatternsTmp();
              }}
            />
            <Input
              disabled={orientation.key === 'free'}
              type="text"
              name="depth"
              onClick={() => setSelectedPattern(elIndex)}
              onChange={(e) => onChangeInput('position', 'y', e.target.value)}
              value={el.position.y}
              onKeyDown={(event) => {
                if (event.key === 'Enter') applyPatternsTmp();
              }}
              onBlur={() => {
                applyPatternsTmp();
              }}
            />
          </Row>
        </Column>
        <Column style={{ justifyContent: 'flex-end', height: '100%' }}>
          <BorderImage onClick={onDelete}>
            <Image
              src="/icons/bin.svg"
              alt="bin"
              style={{
                height: 18,
                width: 20,
              }}
            />
          </BorderImage>
        </Column>
      </PieceContainer>
    </>
  );
};

const PieceContainer = styled('div')(({ isSelected }: { isSelected: boolean }) => ({
  position: 'relative',
  width: '100%',
  height: '140px',

  borderRadius: '10px',
  border: `1px solid ${isSelected ? '#BC9A67 ' : 'white'}`,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  backgroundSize: '140px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',

  padding: '12px',
}));

const Image = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: 5,
  cursor: 'pointer',
  objectFit: 'contain',
});

const BorderImage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: 50,
  cursor: 'pointer',
  border: '1px solid #C46D5E',
});

const Input = styled('input')({
  padding: '12px 12px',
  margin: '8px 0px',
  boxSizing: 'border-box',

  border: '1px solid #C2C9D1',
  width: '100%',
  maxWidth: '100px',

  height: '32px',
  borderRadius: '8px',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
