import {
  ArrowForward,
  AutoAwesome,
  Delete,
  DirectionsBoat,
  Edit,
  HistoryEdu,
  MonetizationOn,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Piece } from 'src/services/pieces';
import { useEnv } from '../../context/EnvProvider';
import { useUser } from '../../context/UserProvider';
import useFilters from '../../hook/useFilter';
import { Assembly, deleteAssembly, UnderAssembly } from '../../services/assemblies';
import { isAdmin } from '../../utils/user.utils';
import { CenteredModal, ModalTitle } from '../CenteredModal';
import { ButtonRow, LowerCaseButton } from '../common';
import DeleteWarningModal from '../DeleteWarningModal';
import FileSettingsIcon from '../icons/FileSettingsIcon';
import FrenchFlagIcon from '../icons/FrenchFlagIcon';
import HeadStoneIcon from '../icons/HeadStoneIcon';
import PackageIcon from '../icons/PackageIcon';
import ReligiousIcon from '../icons/ReligiousIcon';
import SearchComponent from '../Search';

export type CataloguePieceName = { [id: number]: string | undefined };
interface IAssemblyProps {
  onClick: (id: number) => void;
  assemblies: Assembly[];
  onRefresh?: () => void;
  selected?: number[];
  pieces?: Piece[];
  selectedPieces?: number[];
  setSelectedPieces?: (value: number[]) => void;
  selectedUnderAssemblies?: number[];
  setSelectedUnderAssemblies?: (value: number[]) => void;
  underAssemblies?: UnderAssembly[];
  assemblyNames?: CataloguePieceName;
  setAssemblyNames?: React.Dispatch<React.SetStateAction<CataloguePieceName>>;
  pieceNames?: CataloguePieceName;
  setPieceNames?: React.Dispatch<React.SetStateAction<CataloguePieceName>>;
  underAssemblyNames?: CataloguePieceName;
  setUnderAssemblyNames?: React.Dispatch<React.SetStateAction<CataloguePieceName>>;
}

export const fabricationFiltersClient = [
  {
    key: 'IMPORT',
    label: 'Fabrication import',
    icon: <DirectionsBoat color="inherit" />,
  },
  { key: 'FRENCH', label: 'fabrication française', icon: <FrenchFlagIcon /> },
];

export const fabricationFiltersAdmin = [
  {
    key: 'CHINA',
    label: 'Fabrication chinoise',
    icon: <DirectionsBoat color="inherit" />,
  },
  {
    key: 'INDIA',
    label: 'Fabrication indienne',
    icon: <DirectionsBoat color="inherit" />,
  },
  {
    key: 'OTHER',
    label: 'Fabrication autre',
    icon: <DirectionsBoat color="inherit" />,
  },
  { key: 'FRENCH', label: 'fabrication française', icon: <FrenchFlagIcon /> },
];

export const styleFilters = [
  {
    key: 'ECONOMIC',
    label: 'Économiques',
    icon: <MonetizationOn color="inherit" />,
  },
  {
    key: 'CLASSIC',
    label: 'classiques',
    iconUrl: '/icons/Ico_Classiques.png',
  },
  { key: 'LUXURY', label: 'Luxe', icon: <AutoAwesome color="inherit" /> },
  { key: 'CONTEMPORARY', label: 'contemporains', icon: <HistoryEdu color="inherit" /> },
  { key: 'RELIGIOUS', label: 'religieux', icon: <ReligiousIcon color="inherit" /> },
  {
    key: 'OVERPRICED',
    label: 'Hors cotes',
    icon: <PackageIcon color="inherit" fontSize="small" />,
  },
  { key: 'HEADSTONE_LESS', label: 'Sans stèles', icon: <HeadStoneIcon color="inherit" /> },
];

export const typeFilters = [
  {
    key: 'ALL',
    label: 'tout afficher',
  },
  {
    key: 'MONUMENT',
    label: 'monuments',
    iconUrl: '/icons/Ico_Monument.png',
  },
  {
    key: 'CINERARY',
    label: 'cinéraires',
    iconUrl: '/icons/Ico_Cineraire.png',
  },
  {
    key: 'COLUMBARIUM',
    label: 'columbarium',
    iconUrl: '/icons/Ico_Columbarium.png',
  },
];

export const Assemblies = ({
  onClick,
  assemblies,
  onRefresh,
  selected,
  assemblyNames,
  setAssemblyNames,
  pieceNames,
  setPieceNames,
  underAssemblyNames,
  setUnderAssemblyNames,
  pieces,
  selectedPieces,
  setSelectedPieces,
  underAssemblies,
  selectedUnderAssemblies,
  setSelectedUnderAssemblies,
}: IAssemblyProps) => {
  const { buckets } = useEnv();
  const { user } = useUser();
  const [filteredAssemblies, setFilteredAssemblies] = useState<Assembly[]>([]);
  const [filteredPieces, setFilteredPieces] = useState<Piece[]>([]);
  const [filteredUnderAssemblies, setFilteredUnderAssemblies] = useState<UnderAssembly[]>([]);
  const [search, setSearch] = useState('');
  const [typeOfSelect, setTypeOfSelect] = useState<'assemblies' | 'pieces' | 'underAssembly'>(
    'assemblies',
  );
  const [typeFiltersComponent, typeFilterState] = useFilters(typeFilters, [], true);
  const [fabricationFiltersComponent, fabricationFilterState] = useFilters(
    fabricationFiltersClient,
    [],
  );
  const [styleFiltersComponent, styleFilterState] = useFilters(styleFilters, []);
  const [assemblyToDelete, setAssemblyToDelete] = useState<number>();
  const [editName, setEditName] = useState<number>();
  const [editPieceType, setEditPieceType] = useState<'piece' | 'assembly' | 'underAssembly'>();

  useEffect(() => {
    const filteredDatas = assemblies.filter((assembly) => {
      if (
        !assembly.name
          .toLowerCase()
          .replace(' ', '')
          .includes(search.toLowerCase().replace(' ', '')) &&
        !assembly.description
          .toLowerCase()
          .replace(' ', '')
          .includes(search.toLowerCase().replace(' ', ''))
      )
        return false;
      if (typeFilterState[0] !== 'ALL' && assembly.type !== typeFilterState[0]) return false;

      if (
        fabricationFilterState.length > 0 &&
        assembly.fabrication.filter((fabrication) => {
          if (fabrication === 'FRENCH') return fabricationFilterState.includes(fabrication);
          return fabricationFilterState.includes('IMPORT');
          // return false;
        }).length === 0
      )
        return false;

      if (
        styleFilterState.length > 0 &&
        assembly.style.filter((style) => styleFilterState.includes(style)).length === 0
      )
        return false;

      return true;
    });

    const sortedDatas = filteredDatas.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    setFilteredAssemblies(sortedDatas);
  }, [assemblies, fabricationFilterState, search, styleFilterState, typeFilterState]);

  useEffect(() => {
    const filteredDatas = pieces?.filter((piece) => {
      if (
        !piece.name.toLowerCase().replace(' ', '').includes(search.toLowerCase().replace(' ', ''))
      )
        return false;
      return true;
    });

    const sortedDatas = filteredDatas?.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    setFilteredPieces(sortedDatas ?? []);
  }, [search, pieces]);

  useEffect(() => {
    const filteredDatas = underAssemblies?.filter((underAssembly) => {
      if (
        !underAssembly.name
          .toLowerCase()
          .replace(' ', '')
          .includes(search.toLowerCase().replace(' ', ''))
      )
        return false;
      return true;
    });

    const sortedDatas = filteredDatas?.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    setFilteredUnderAssemblies(sortedDatas ?? []);
  }, [filteredPieces, search, underAssemblies]);

  const handleDelete = async () => {
    if (assemblyToDelete) await deleteAssembly(assemblyToDelete);
    if (onRefresh) onRefresh();
  };

  const getPieceEditName = (type: 'assembly' | 'piece' | 'underAssembly') => {
    if (type === 'assembly') return assemblyNames;
    if (type === 'underAssembly') return underAssemblyNames;
    return pieceNames;
  };

  const getPieceEditFunction = (type: 'assembly' | 'piece' | 'underAssembly') => {
    if (type === 'assembly') return setAssemblyNames;
    if (type === 'underAssembly') return setUnderAssemblyNames;
    return setPieceNames;
  };

  return (
    <Box
      component="div"
      sx={{ display: 'flex', overflowY: 'hidden', alignItems: 'stretch', flex: 1 }}
    >
      <Wrapper>
        {pieces ? (
          <Select
            value={typeOfSelect}
            label="Type de selection"
            onChange={(e) => setTypeOfSelect(e.target.value as 'assemblies' | 'pieces')}
            style={{ width: '200px' }}
          >
            <MenuItem value="assemblies">Assemblages</MenuItem>
            <MenuItem value="pieces">Pieces</MenuItem>
            <MenuItem value="underAssembly">Sous-assemblage</MenuItem>
          </Select>
        ) : null}
        <ButtonRow>{typeFiltersComponent}</ButtonRow>
        {user?.role !== 'ADMIN' && (
          <Box component="div">
            <Typography variant="h2">Catalogue des monuments</Typography>
            <Typography>
              Choisissez un monument prédéfini dans le catalogue puis personnalisez le. Vous pouvez
              aussi directement créer votre monument.
            </Typography>
          </Box>
        )}
        <Grid>
          {!selected && (
            <Box
              component="div"
              sx={{
                gridRow: '1 / span 2',
                gridColumn: '1 / span 2',
                display: 'flex',
                flexDirection: 'column',
              }}
              onClick={() => onClick(-1)}
            >
              <ItemContainer>
                <PieceImage src="/icons/newProject.svg" />
              </ItemContainer>
              <Title>
                <Typography>Créer un monument</Typography>
                <ArrowForward />
              </Title>
            </Box>
          )}
          {typeOfSelect === 'assemblies'
            ? filteredAssemblies?.map((assembly) => {
                let image = 'defaultImage';

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (assembly.customImage) image = assembly.customImage;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                else if (!assembly.customImage && assembly.pictureUrl) image = assembly.pictureUrl;

                return (
                  <ItemContainer onClick={() => onClick(assembly.id)} key={assembly.id}>
                    {isAdmin(user) &&
                      (selected ? (
                        <StyledCheckBox checked={selected.includes(assembly.id)} />
                      ) : (
                        <DeleteButton
                          onClick={(event) => {
                            setAssemblyToDelete(assembly.id);
                            event.stopPropagation();
                          }}
                        >
                          <Delete color="error" />
                        </DeleteButton>
                      ))}
                    <FabricationContainer>
                      {assembly.fabrication.some((it) => it !== 'FRENCH') && (
                        <RoundIcon>
                          <DirectionsBoat fontSize="small" />
                        </RoundIcon>
                      )}
                      {assembly.fabrication.includes('FRENCH') && (
                        <RoundIcon>
                          <FrenchFlagIcon fontSize="small" />
                        </RoundIcon>
                      )}
                    </FabricationContainer>
                    <PieceImage src={`${buckets?.BUCKET_3D_PIECES || ''}/${image}`} />

                    <Typography
                      sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        fontWeight: 600,
                        maxWidth: '80%',
                      }}
                    >
                      {assemblyNames?.[assembly.id] ?? assembly.name}
                    </Typography>
                    {assemblyNames && setAssemblyNames ? (
                      <EditButton
                        onClick={(event) => {
                          setEditName(assembly.id);
                          setEditPieceType('assembly');
                          setAssemblyNames((prev: CataloguePieceName) => ({
                            ...prev,
                            [assembly.id]: prev?.[assembly.id] ?? assembly.name,
                          }));
                          event.stopPropagation();
                        }}
                      >
                        <Edit />
                      </EditButton>
                    ) : null}
                  </ItemContainer>
                );
              })
            : null}

          {typeOfSelect === 'pieces'
            ? filteredPieces?.map((piece) => {
                return (
                  <ItemContainer
                    onClick={() => {
                      if (setSelectedPieces) {
                        if (selectedPieces?.includes(piece.id) && selectedPieces?.length > 0) {
                          setSelectedPieces(selectedPieces?.filter((id) => id !== piece.id) ?? []);
                        } else {
                          setSelectedPieces([...(selectedPieces ?? []), piece.id]);
                        }
                      }
                    }}
                    key={piece.id}
                  >
                    <StyledCheckBox checked={selectedPieces?.includes(piece.id)} />

                    <PieceImage
                      src={`${buckets?.BUCKET_3D_PIECES || ''}/${
                        piece.pictureUrl === '' ? 'defaultImage' : piece.pictureUrl
                      }`}
                    />

                    <Typography
                      sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        fontWeight: 600,
                        maxWidth: '80%',
                      }}
                    >
                      {pieceNames?.[piece.id] ?? piece.name}
                    </Typography>
                    {pieceNames && setPieceNames ? (
                      <EditButton
                        onClick={(event) => {
                          setEditName(piece.id);
                          setEditPieceType('piece');
                          setPieceNames((prev: CataloguePieceName) => ({
                            ...prev,
                            [piece.id]: prev?.[piece.id] ?? piece.name,
                          }));
                          event.stopPropagation();
                        }}
                      >
                        <Edit />
                      </EditButton>
                    ) : null}
                  </ItemContainer>
                );
              })
            : null}

          {typeOfSelect === 'underAssembly'
            ? filteredUnderAssemblies?.map((underAssembly) => {
                return (
                  <ItemContainer
                    onClick={() => {
                      if (setSelectedUnderAssemblies) {
                        if (
                          selectedUnderAssemblies?.includes(underAssembly.id) &&
                          selectedUnderAssemblies?.length > 0
                        ) {
                          setSelectedUnderAssemblies(
                            selectedUnderAssemblies?.filter((id) => id !== underAssembly.id) ?? [],
                          );
                        } else {
                          setSelectedUnderAssemblies([
                            ...(selectedUnderAssemblies ?? []),
                            underAssembly.id,
                          ]);
                        }
                      }
                    }}
                    key={underAssembly.id}
                  >
                    <StyledCheckBox checked={selectedUnderAssemblies?.includes(underAssembly.id)} />

                    <PieceImage
                      src={`${buckets?.BUCKET_3D_PIECES || ''}/${
                        underAssembly.pictureUrl === '' ? 'defaultImage' : underAssembly.pictureUrl
                      }`}
                    />

                    <Typography
                      sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        fontWeight: 600,
                        maxWidth: '80%',
                      }}
                    >
                      {underAssemblyNames?.[underAssembly.id] ?? underAssembly.name}
                    </Typography>
                    {underAssemblyNames && setUnderAssemblyNames ? (
                      <EditButton
                        onClick={(event) => {
                          setEditName(underAssembly.id);
                          setEditPieceType('underAssembly');
                          setUnderAssemblyNames((prev: CataloguePieceName) => ({
                            ...prev,
                            [underAssembly.id]: prev?.[underAssembly.id] ?? underAssembly.name,
                          }));
                          event.stopPropagation();
                        }}
                      >
                        <Edit />
                      </EditButton>
                    ) : null}
                  </ItemContainer>
                );
              })
            : null}
        </Grid>
      </Wrapper>
      <CenteredModal open={!!editName} handleClose={() => setEditName(undefined)}>
        <ModalTitle>Modifier le nom pour ce catalogue</ModalTitle>
        {editPieceType && editName ? (
          <Stack padding="30px" gap="16px">
            <TextField
              value={getPieceEditName(editPieceType)?.[editName]}
              onChange={(event) => {
                const editFunction = getPieceEditFunction(editPieceType);
                if (!editFunction) return;
                editFunction((prev: CataloguePieceName) => ({
                  ...prev,
                  [editName]: event.target.value,
                }));
              }}
            />
            <LowerCaseButton
              onClick={() => {
                const editFunction = getPieceEditFunction(editPieceType);
                if (!editFunction) return;

                editFunction((prev: CataloguePieceName) => ({
                  ...prev,
                  [editName]: undefined,
                }));
                setEditName(undefined);
              }}
              variant="outlined"
            >
              Reset
            </LowerCaseButton>
            <LowerCaseButton onClick={() => setEditName(undefined)} variant="contained">
              Confirmer
            </LowerCaseButton>
          </Stack>
        ) : null}
      </CenteredModal>
      <Filters>
        <FileSettings>
          <FileSettingsIcon color="primary" />
        </FileSettings>
        <SearchComponent search={search} setSearch={setSearch} />
        <FilterSectionTitle>FABRICATION</FilterSectionTitle>
        {fabricationFiltersComponent}
        <FilterSectionTitle>STYLE</FilterSectionTitle>
        {styleFiltersComponent}
      </Filters>
      <DeleteWarningModal
        handleDelete={handleDelete}
        header="Supprimer ce monument ?"
        onClose={() => setAssemblyToDelete(undefined)}
        open={!!assemblyToDelete}
      />
    </Box>
  );
};

const Grid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 200px)',
  gridTemplateRows: '200px repeat(auto-fill, 200px)',
  gap: '16px',
  flex: '1',
});

const PieceImage = styled('img')({
  width: '80%',
});

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.primary.main,
  width: '100%',
  color: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0 0 8px 8px',
  height: '50px',
  gap: '16px',
  cursor: 'pointer',
}));

const ItemContainer = styled(Box)({
  cursor: 'pointer',
  backgroundColor: '#F2F4F7',
  position: 'relative',
  width: '100%',
  flex: 1,
  height: '200px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
});

const DeleteButton = styled(IconButton)({
  zIndex: 3,
  position: 'absolute',
  left: 5,
  top: 5,
});

const EditButton = styled(IconButton)({
  zIndex: 3,
  position: 'absolute',
  right: 10,
  bottom: 5,
});

const StyledCheckBox = styled(Checkbox)({
  zIndex: 3,
  position: 'absolute',
  left: 5,
  top: 5,
});

const FabricationContainer = styled(Box)({
  zIndex: 3,
  position: 'absolute',
  right: 5,
  top: 5,
  display: 'flex',
  gap: '5px',
});

const RoundIcon = styled(Box)({
  height: '34px',
  width: '34px',
  background: 'white',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '30px 0 30px 30px',
  flex: 1,
  overflowY: 'auto',
});

const Filters = styled(Box)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: 16,
  boxShadow: ' -4px 0px 15px rgba(0, 0, 0, 0.1)',
  flex: '0 0 350px',
  alignItems: 'flex-start',
  padding: '30px',
  paddingTop: '100px',
  overflowY: 'auto',
});

const FileSettings = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '100%',
  padding: '5px',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  right: 30,
  top: 30,
}));

const FilterSectionTitle = styled(Typography)({
  color: '#475467',
  fontWeight: 500,
});
