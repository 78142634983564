/* eslint-disable unicorn/no-negated-condition */
import { Box, Typography, styled } from '@mui/material';
import { Container, LowerCaseButton } from '../../components/common';
import { TextFieldRHF } from '../../components/forms/TextFieldRhf';
import useForgotPassword from './useForgotPassword';

// Styles existants
const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flexDirection: 'column',
  width: '55%',
});

export const LoginBox = styled(Box)(({ theme: muiTheme }) => ({
  display: 'flex',
  width: '45%',
  height: '100vh',
  background: muiTheme.palette.primary.light,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Logo = styled('img')({
  width: '80%',
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  width: '50%',
  justifyContent: 'center',
});

const ForgotPassword = () => {
  const { control, errors, handleSubmit, onSubmit, showCodeInput, showPasswordInputs } =
    useForgotPassword();

  return (
    <Container>
      <LoginBox>
        <Logo src="/logo_paysage.svg" />
      </LoginBox>
      <Wrapper>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            Mot de passe oublié
          </Typography>

          {!showPasswordInputs ? (
            <>
              <TextFieldRHF
                name="email"
                control={control}
                TextFieldProps={{
                  label: 'Email',
                  error: Boolean(errors.email),
                  helperText: errors.email?.message,
                }}
              />

              {showCodeInput && (
                <TextFieldRHF
                  name="code"
                  control={control}
                  TextFieldProps={{
                    label: 'Code reçu par email',
                    error: Boolean(errors.code),
                    helperText: errors.code?.message,
                  }}
                />
              )}
            </>
          ) : (
            <>
              <TextFieldRHF
                name="newPassword"
                control={control}
                TextFieldProps={{
                  type: 'password',
                  label: 'Nouveau mot de passe',
                  error: Boolean(errors.newPassword),
                  helperText: errors.newPassword?.message,
                }}
              />
              <TextFieldRHF
                name="newPasswordConfirmation"
                control={control}
                TextFieldProps={{
                  type: 'password',
                  label: 'Confirmer le nouveau mot de passe',
                  error: Boolean(errors.newPasswordConfirmation),
                  helperText: errors.newPasswordConfirmation?.message,
                }}
              />
            </>
          )}

          <LowerCaseButton variant="contained" type="submit" sx={{ marginTop: '20px' }}>
            {!showCodeInput
              ? 'Réinitialiser le mot de passe'
              : !showPasswordInputs
              ? 'Vérifier le code'
              : 'Mettre à jour le mot de passe'}
          </LowerCaseButton>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;
