/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Button, styled, Switch, SwitchProps, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { StateNames, States } from 'src/Screens/Configurator';
import { getPiece } from 'src/utils/configurator.utils';
import { Box3, Vector3 } from 'three';

type AssemblyProps = {
  activePieces: any[];
  customSteles: any[];
  customPlatings: any[];
  pieces: any[];
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;
  setReloadPiece: (needToUpdate: boolean) => void;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
  setCustomPlatings: (customPlatings: any[]) => void;
};

export const Assembly = ({
  activePieces,
  pieceIndex,
  customSteles,
  pieces,
  customPlatings,
  setPieceIndex,
  setReloadPiece,
  setValue,
  getValue,
  setCustomPlatings,
}: AssemblyProps) => {
  const [selectedPiece, setSelectedPiece] = useState<1 | 2>(1);
  const [selectedPiece1, setSelectedPiece1] = useState<{
    pieceIndex: number;
    name: string;
    type: 'piece' | 'stele' | 'placage';
    selectedFace: 'x' | 'x_inverse' | 'y' | 'y_inverse' | 'z' | 'z_inverse' | '';
  }>();
  const [selectedPiece2, setSelectedPiece2] = useState<{
    pieceIndex: number;
    name: string;
    type: 'piece' | 'stele' | 'placage';
    selectedFace: 'x' | 'x_inverse' | 'y' | 'y_inverse' | 'z' | 'z_inverse' | '';
  }>();
  const [offset, setOffset] = useState<string>('0');

  useEffect(() => {
    if (selectedPiece === 1 && pieceIndex !== selectedPiece1?.pieceIndex) {
      if (activePieces[pieceIndex]) {
        setSelectedPiece1({
          pieceIndex,
          name: activePieces[pieceIndex].name,
          type: 'piece',
          selectedFace: '',
        });
      } else if (customSteles[pieceIndex - activePieces.length]) {
        setSelectedPiece1({ pieceIndex, name: 'Stèle custom', type: 'stele', selectedFace: '' });
      } else if (customPlatings[pieceIndex - activePieces.length - customSteles.length]) {
        setSelectedPiece1({
          pieceIndex,
          name: `placage custom ${pieceIndex - activePieces.length}`,
          type: 'placage',
          selectedFace: '',
        });
      }
      setSelectedPiece(2);
    } else if (selectedPiece === 2 && pieceIndex !== selectedPiece2?.pieceIndex) {
      if (activePieces[pieceIndex]) {
        setSelectedPiece2({
          pieceIndex,
          name: activePieces[pieceIndex].name,
          type: 'piece',
          selectedFace: '',
        });
      } else if (customSteles[pieceIndex - activePieces.length]) {
        setSelectedPiece2({ pieceIndex, name: 'Stèle custom', type: 'stele', selectedFace: '' });
      } else if (customPlatings[pieceIndex - activePieces.length - customSteles.length]) {
        setSelectedPiece2({
          pieceIndex,
          name: `placage custom ${pieceIndex - activePieces.length}`,
          type: 'placage',
          selectedFace: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePieces, pieceIndex]);

  useEffect(() => {
    if (
      selectedPiece1 &&
      selectedPiece2 &&
      !isTheSameFace(selectedPiece1.selectedFace, selectedPiece2.selectedFace)
    )
      setSelectedPiece2({ ...selectedPiece2, selectedFace: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPiece1]);

  useEffect(() => {
    if (selectedPiece === 1 && selectedPiece1) {
      setPieceIndex(selectedPiece1.pieceIndex);
    }
    if (selectedPiece === 2 && selectedPiece2) {
      setPieceIndex(selectedPiece2.pieceIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPiece]);

  const selectFace = (
    face: 'x' | 'x_inverse' | 'y' | 'y_inverse' | 'z' | 'z_inverse',
    pieceNumber: 1 | 2,
  ) => {
    if (pieceNumber === 1 && selectedPiece1) {
      if (selectedPiece1?.selectedFace === face) {
        setSelectedPiece1({ ...selectedPiece1, selectedFace: '' });
      } else {
        setSelectedPiece1({ ...selectedPiece1, selectedFace: face });
      }
      if (selectedPiece2?.selectedFace) setSelectedPiece2({ ...selectedPiece2, selectedFace: '' });
    } else if (
      pieceNumber === 2 &&
      selectedPiece2 &&
      ((selectedPiece1?.selectedFace.includes('x') && face.includes('x')) ||
        (selectedPiece1?.selectedFace.includes('y') && face.includes('y')) ||
        (selectedPiece1?.selectedFace.includes('z') && face.includes('z')))
    ) {
      if (selectedPiece2?.selectedFace === face) {
        setSelectedPiece2({ ...selectedPiece2, selectedFace: '' });
      } else {
        setSelectedPiece2({ ...selectedPiece2, selectedFace: face });
      }
    }
  };

  const isTheSameFace = (face1: string, face2: string) => {
    if (face1.includes('x')) return face2.includes('x');
    if (face1.includes('y')) return face2.includes('y');
    if (face1.includes('z')) return face2.includes('z');
  };

  const getAxisValue = (box: Box3, face: 'x' | 'y' | 'z', inverse: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return inverse ? box.min[face] : box.max[face];
  };

  const getOffset = () => {
    const offsetNumber = Number.parseFloat(offset);

    if (Number.isNaN(offsetNumber)) return 0;
    return offsetNumber / 100;
  };

  const centerPiece = () => {
    if (
      selectedPiece1 &&
      selectedPiece2 &&
      !activePieces[selectedPiece2.pieceIndex]?.isBlocked &&
      !customSteles[selectedPiece2.pieceIndex - activePieces.length]?.isBlocked &&
      !customPlatings[selectedPiece2.pieceIndex - activePieces.length - customSteles.length]
        ?.isBlocked
    ) {
      const piece1 = getPiece(pieces, customSteles, customPlatings, selectedPiece1?.pieceIndex);
      const piece2 = getPiece(pieces, customSteles, customPlatings, selectedPiece2?.pieceIndex);

      const box1 = new Box3().setFromArray(piece1?.geometry.attributes.position.array);
      const box2 = new Box3().setFromArray(piece2?.geometry.attributes.position.array);

      const centerBox1 = new Vector3();
      box1.getCenter(centerBox1);

      const centerBox2 = new Vector3();
      box2.getCenter(centerBox2);

      let deltaX = 0;

      if (
        (piece1.key === 'right' ||
          piece1.key === 'left' ||
          piece1.key === 'front' ||
          piece1.key === 'back') &&
        !getValue('hasAdvancedMode')
      ) {
        const positionsX = customPlatings.map((mesh) => mesh.position.x);
        const somme = positionsX.reduce((acc, val) => acc + val, 0);
        const centre = somme / positionsX.length;

        deltaX = piece2.position.x + centerBox2.x - centre;
      } else {
        deltaX = piece2.position.x + centerBox2.x - (piece1.position.x + centerBox1.x);
      }

      piece2.position.set(piece2.position.x - deltaX, piece2.position.y, piece2.position.z);

      piece2.needsUpdate = true;

      if (activePieces[selectedPiece2?.pieceIndex]?.underAssembly && !getValue('hasAdvancedMode')) {
        const underAssemblyId = activePieces[selectedPiece2.pieceIndex].underAssembly.id;
        for (const [i, piece] of activePieces.entries()) {
          if (
            piece.underAssembly &&
            piece.underAssembly.id === underAssemblyId &&
            i !== selectedPiece2?.pieceIndex
          ) {
            const pieceInUnderAssemblyToChange = getPiece(pieces, customSteles, customPlatings, i);

            pieceInUnderAssemblyToChange.position.set(
              pieceInUnderAssemblyToChange.position.x - deltaX,
              pieceInUnderAssemblyToChange.position.y,
              pieceInUnderAssemblyToChange.position.z,
            );

            pieceInUnderAssemblyToChange.needUpdate = true;
          }
        }
      }

      if (selectedPiece2?.pieceIndex >= activePieces.length + customSteles.length) {
        const customPlatingIndex =
          (selectedPiece2?.pieceIndex ?? 0) -
          (activePieces.length ?? 0) -
          (customSteles.length ?? 0);

        const customsMeshTmp = customPlatings.map((mesh, meshIndex) => {
          if (meshIndex === customPlatingIndex) return mesh;
          const newPosition = new Vector3(
            +mesh.position.x - deltaX,
            +mesh.position.y,
            +mesh.position.z,
          );

          return {
            ...mesh,
            position: newPosition,
          };
        });

        setCustomPlatings(customsMeshTmp);
      }

      setReloadPiece(true);
    }
  };

  const movePiece = () => {
    if (
      selectedPiece1 &&
      selectedPiece2 &&
      !activePieces[selectedPiece2.pieceIndex]?.isBlocked &&
      !customSteles[selectedPiece2.pieceIndex - activePieces.length]?.isBlocked &&
      !customPlatings[selectedPiece2.pieceIndex - activePieces.length - customSteles.length]
        ?.isBlocked
    ) {
      const piece1 = getPiece(pieces, customSteles, customPlatings, selectedPiece1?.pieceIndex);
      const piece2 = getPiece(pieces, customSteles, customPlatings, selectedPiece2?.pieceIndex);

      const box1 = new Box3().setFromArray(piece1?.geometry.attributes.position.array);
      const box2 = new Box3().setFromArray(piece2?.geometry.attributes.position.array);

      let deltaX = 0;
      let deltaY = 0;
      let deltaZ = 0;

      if (selectedPiece1?.selectedFace.includes('x')) {
        const box1X =
          getAxisValue(box1, 'x', selectedPiece1?.selectedFace.includes('inverse')) +
          piece1.position.x;
        const box2X =
          getAxisValue(box2, 'x', selectedPiece2?.selectedFace.includes('inverse')) +
          piece2.position.x;

        deltaX = box2X - box1X + getOffset();

        piece2.position.set(piece2.position.x - deltaX, piece2.position.y, piece2.position.z);
      }
      if (selectedPiece1?.selectedFace.includes('y')) {
        const box1Y =
          getAxisValue(box1, 'y', selectedPiece1?.selectedFace.includes('inverse')) +
          piece1.position.y;
        const box2Y =
          getAxisValue(box2, 'y', selectedPiece2?.selectedFace.includes('inverse')) +
          piece2.position.y;

        deltaY = box2Y - box1Y + getOffset();

        piece2.position.set(piece2.position.x, piece2.position.y - deltaY, piece2.position.z);
      }
      if (selectedPiece1?.selectedFace.includes('z')) {
        const box1Z =
          getAxisValue(box1, 'z', selectedPiece1?.selectedFace.includes('inverse')) +
          piece1.position.z;
        const box2Z =
          getAxisValue(box2, 'z', selectedPiece2?.selectedFace.includes('inverse')) +
          piece2.position.z;

        deltaZ = box2Z - box1Z + getOffset();

        piece2.position.set(piece2.position.x, piece2.position.y, piece2.position.z - deltaZ);
      }
      piece2.needsUpdate = true;

      if (activePieces[selectedPiece2?.pieceIndex]?.underAssembly && !getValue('hasAdvancedMode')) {
        const underAssemblyId = activePieces[selectedPiece2.pieceIndex].underAssembly.id;
        for (const [i, piece] of activePieces.entries()) {
          if (
            piece.underAssembly &&
            piece.underAssembly.id === underAssemblyId &&
            i !== selectedPiece2?.pieceIndex
          ) {
            const pieceInUnderAssemblyToChange = getPiece(pieces, customSteles, customPlatings, i);

            pieceInUnderAssemblyToChange.position.set(
              pieceInUnderAssemblyToChange.position.x - deltaX,
              pieceInUnderAssemblyToChange.position.y - deltaY,
              pieceInUnderAssemblyToChange.position.z - deltaZ,
            );

            pieceInUnderAssemblyToChange.needUpdate = true;
          }
        }
      }

      if (selectedPiece2?.pieceIndex >= activePieces.length + customSteles.length) {
        const customPlatingIndex =
          (selectedPiece2?.pieceIndex ?? 0) -
          (activePieces.length ?? 0) -
          (customSteles.length ?? 0);

        const customsMeshTmp = customPlatings.map((mesh, meshIndex) => {
          if (meshIndex === customPlatingIndex) return mesh;
          const newPosition = new Vector3(
            +mesh.position.x - deltaX,
            +mesh.position.y - deltaY,
            +mesh.position.z - deltaZ,
          );

          return {
            ...mesh,
            position: newPosition,
          };
        });

        setCustomPlatings(customsMeshTmp);
      }

      setReloadPiece(true);
    }
  };

  const getFaceImageName = (face: string | undefined) => {
    if (face === 'x_inverse') return 'left';
    if (face === 'x') return 'right';
    if (face === 'y') return 'up';
    if (face === 'y_inverse') return 'down';
    if (face === 'z') return 'front';
    if (face === 'z_inverse') return 'back';
    return 'none';
  };

  return (
    <Container>
      {getValue('hasAdvancedMode') ? (
        <div
          style={{
            backgroundColor: '#D32F2F70',
            padding: 12,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 12,
            marginBottom: 12,
          }}
        >
          <p style={{ fontSize: 12 }}>Attention, le mode avancé est activé.</p>
          <p style={{ fontSize: 12 }}>
            Les assemblages de pièces ne sont plus pris en compte. Vous modifierez et déplacerez
            chaque pièce indépendamment des autres.
          </p>
        </div>
      ) : null}

      <Row
        style={{
          alignItems: 'center',
          gap: '6px',
          marginBottom: '6px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        {selectedPiece2?.type === 'placage' ? null : (
          <>
            <Typography
              variant="body2"
              color="#344054"
              style={{ fontWeight: 400, fontSize: '12px' }}
            >
              Mode avancé
            </Typography>
            <IOSSwitch
              checked={getValue('hasAdvancedMode')}
              onChange={() => setValue('hasAdvancedMode', !getValue('hasAdvancedMode'))}
            />
          </>
        )}
      </Row>
      <PiecesContainer>
        <Piece isSelected={selectedPiece === 1} onClick={() => setSelectedPiece(1)}>
          <div style={{ height: 75 }}>
            <Typography variant="body2" color="#344054">
              {selectedPiece1 ? selectedPiece1.name : 'Sélectionnez une pièce'}
            </Typography>
            <Typography
              variant="body2"
              color="#6c737c"
              sx={{ fontSize: '10px', marginBottom: '12px' }}
            >
              Pièce de référence
            </Typography>
          </div>

          <FacesContainer>
            <FacesImg
              src={`/faces/${getFaceImageName(selectedPiece1?.selectedFace)}.png`}
              alt="cubeface"
            />
            <Face
              color="blue"
              onClick={() => selectFace('z_inverse', 1)}
              isSelected={selectedPiece1?.selectedFace === 'z_inverse'}
            >
              <p
                style={{
                  color: selectedPiece1?.selectedFace === 'z_inverse' ? 'white' : 'black',
                  fontSize: '10px',
                }}
              >
                Arrière
              </p>
            </Face>
            <Row>
              <Face
                color="red"
                onClick={() => selectFace('x_inverse', 1)}
                isSelected={selectedPiece1?.selectedFace === 'x_inverse'}
              >
                <p
                  style={{
                    color: selectedPiece1?.selectedFace === 'x_inverse' ? 'white' : 'black',
                    fontSize: '10px',
                  }}
                >
                  Gauche
                </p>
              </Face>
              <Face
                color="green"
                onClick={() => selectFace('y_inverse', 1)}
                isSelected={selectedPiece1?.selectedFace === 'y_inverse'}
              >
                <p
                  style={{
                    color: selectedPiece1?.selectedFace === 'y_inverse' ? 'white' : 'black',
                    fontSize: '10px',
                  }}
                >
                  Dessous
                </p>
              </Face>
              <Face
                color="red"
                onClick={() => selectFace('x', 1)}
                isSelected={selectedPiece1?.selectedFace === 'x'}
              >
                <p
                  style={{
                    color: selectedPiece1?.selectedFace === 'x' ? 'white' : 'black',
                    fontSize: '10px',
                  }}
                >
                  Droite
                </p>
              </Face>
            </Row>
            <Face
              color="blue"
              onClick={() => selectFace('z', 1)}
              isSelected={selectedPiece1?.selectedFace === 'z'}
            >
              <p
                style={{
                  color: selectedPiece1?.selectedFace === 'z' ? 'white' : 'black',
                  fontSize: '10px',
                }}
              >
                Devant
              </p>
            </Face>
            <Face
              color="green"
              onClick={() => selectFace('y', 1)}
              isSelected={selectedPiece1?.selectedFace === 'y'}
            >
              <p
                style={{
                  color: selectedPiece1?.selectedFace === 'y' ? 'white' : 'black',
                  fontSize: '10px',
                }}
              >
                Dessus
              </p>
            </Face>
          </FacesContainer>
        </Piece>
        <Piece isSelected={selectedPiece === 2} onClick={() => setSelectedPiece(2)}>
          <div style={{ height: 75 }}>
            <Typography variant="body2" color="#344054">
              {selectedPiece2 ? selectedPiece2.name : 'Sélectionnez une pièce'}
            </Typography>
            <Typography
              variant="body2"
              color="#6c737c"
              sx={{ fontSize: '10px', marginBottom: '12px' }}
            >
              Pièce à bouger
            </Typography>
          </div>
          {selectedPiece2 ? (
            <>
              <FacesContainer>
                <FacesImg
                  src={`/faces/${getFaceImageName(selectedPiece2?.selectedFace)}.png`}
                  alt="cubeface"
                />
                <Face
                  color="blue"
                  onClick={() => selectFace('z_inverse', 2)}
                  isSelected={selectedPiece2?.selectedFace === 'z_inverse'}
                  style={
                    selectedPiece1?.selectedFace.includes('z') ? {} : { backgroundColor: 'grey' }
                  }
                >
                  {selectedPiece1?.selectedFace.includes('z') ? (
                    <p
                      style={{
                        color: selectedPiece2?.selectedFace === 'z_inverse' ? 'white' : 'black',
                        fontSize: '10px',
                      }}
                    >
                      Arrière
                    </p>
                  ) : null}
                </Face>
                <Row>
                  <Face
                    color="red"
                    onClick={() => selectFace('x_inverse', 2)}
                    isSelected={selectedPiece2?.selectedFace === 'x_inverse'}
                    style={
                      selectedPiece1?.selectedFace.includes('x') ? {} : { backgroundColor: 'grey' }
                    }
                  >
                    {selectedPiece1?.selectedFace.includes('x') ? (
                      <p
                        style={{
                          color: selectedPiece2?.selectedFace === 'x_inverse' ? 'white' : 'black',
                          fontSize: '10px',
                        }}
                      >
                        Gauche
                      </p>
                    ) : null}
                  </Face>
                  <Face
                    color="green"
                    onClick={() => selectFace('y_inverse', 2)}
                    isSelected={selectedPiece2?.selectedFace === 'y_inverse'}
                    style={
                      selectedPiece1?.selectedFace.includes('y') ? {} : { backgroundColor: 'grey' }
                    }
                  >
                    {selectedPiece1?.selectedFace.includes('y') ? (
                      <p
                        style={{
                          color: selectedPiece2?.selectedFace === 'y_inverse' ? 'white' : 'black',
                          fontSize: '10px',
                        }}
                      >
                        Dessous
                      </p>
                    ) : null}
                  </Face>
                  <Face
                    color="red"
                    onClick={() => selectFace('x', 2)}
                    isSelected={selectedPiece2?.selectedFace === 'x'}
                    style={
                      selectedPiece1?.selectedFace.includes('x') ? {} : { backgroundColor: 'grey' }
                    }
                  >
                    {selectedPiece1?.selectedFace.includes('x') ? (
                      <p
                        style={{
                          color: selectedPiece2?.selectedFace === 'x' ? 'white' : 'black',
                          fontSize: '10px',
                        }}
                      >
                        Droite
                      </p>
                    ) : null}
                  </Face>
                </Row>
                <Face
                  color="blue"
                  onClick={() => selectFace('z', 2)}
                  isSelected={selectedPiece2?.selectedFace === 'z'}
                  style={
                    selectedPiece1?.selectedFace.includes('z') ? {} : { backgroundColor: 'grey' }
                  }
                >
                  {selectedPiece1?.selectedFace.includes('z') ? (
                    <p
                      style={{
                        color: selectedPiece2?.selectedFace === 'z' ? 'white' : 'black',
                        fontSize: '10px',
                      }}
                    >
                      Avant
                    </p>
                  ) : null}
                </Face>
                <Face
                  color="green"
                  onClick={() => selectFace('y', 2)}
                  isSelected={selectedPiece2?.selectedFace === 'y'}
                  style={
                    selectedPiece1?.selectedFace.includes('y') ? {} : { backgroundColor: 'grey' }
                  }
                >
                  {selectedPiece1?.selectedFace.includes('y') ? (
                    <p
                      style={{
                        color: selectedPiece2?.selectedFace === 'y' ? 'white' : 'black',
                        fontSize: '10px',
                      }}
                    >
                      Dessus
                    </p>
                  ) : null}
                </Face>
              </FacesContainer>
            </>
          ) : null}
        </Piece>
      </PiecesContainer>
      <SubmitContainer>
        <TextField
          id="offsets"
          label="X cm de décalage"
          variant="outlined"
          value={offset}
          onChange={(e) => setOffset(e.target.value)}
        />
        <StyledButton variant="contained" onClick={movePiece}>
          <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'capitalize' }}>
            Bouger la pièce
          </Typography>
        </StyledButton>
        {selectedPiece1?.selectedFace.includes('x') ? null : (
          <StyledButton variant="contained" onClick={centerPiece}>
            <Typography variant="body1" style={{ fontSize: '12px', textTransform: 'capitalize' }}>
              Centrer la pièce Gauche/Droite
            </Typography>
          </StyledButton>
        )}
      </SubmitContainer>
    </Container>
  );
};

const Container = styled('div')({
  padding: '12px',

  display: 'flex',
  flexDirection: 'column',

  maxHeight: window.innerHeight - 294,
  height: window.innerHeight - 294,
  overflowY: 'auto',
});

const PiecesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const Piece = styled('div')(({ isSelected }: { isSelected: boolean }) => ({
  padding: '12px',
  paddingBottom: '24px',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: isSelected ? 'auto' : 'pointer',
  boxShadow: isSelected ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : 'none',
  backgroundColor: isSelected ? '#F7F0E6' : 'white',
  '&:hover': {
    backgroundColor: isSelected ? '' : '#faf7f3',
  },
}));

const SubmitContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '22px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '12px',

  height: '55px',
}));

const FacesContainer = styled('div')({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const FacesImg = styled('img')({
  height: '4vw',
  width: '4vw',
  maxWidth: 60,
  maxHeight: 60,
  marginBottom: '12px',
  alignSelf: 'flex-start',
});

type FaceProps = {
  color: string;
  isSelected?: boolean;
};

const Face = styled('div')<FaceProps>(({ color, isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4.5vw',
  width: '4.5vw',
  maxWidth: 90,
  maxHeight: 90,
  border: `1px solid ${color}`,
  margin: '2px',
  backgroundColor: isSelected ? color : 'none',
  cursor: 'pointer',
}));

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#BC9A67' : '#BC9A67',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#BC9A67',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
