export type PatternType = 'FLOWERS' | 'LANDSCAPES' | 'JOBS' | 'RELIGIOUS' | 'BIRDS' | 'ANY' | any;

export const PatternsLabels: { key: PatternType; label: string }[] = [
  {
    key: 'FLOWERS',
    label: 'Fleurs',
  },
  {
    key: 'LANDSCAPES',
    label: 'Paysages',
  },
  {
    key: 'RELIGIOUS',
    label: 'Religieux',
  },
  {
    key: 'BIRDS',
    label: 'Oiseaux',
  },
  { key: 'ABSTRAIT', label: 'Abstrait' },
  { key: 'AMOUR', label: 'Amour' },
  { key: 'ANIMAL', label: 'Animal' },
  { key: 'ANGE', label: 'Ange' },
  { key: 'COEUR', label: 'Cœur' },
  { key: 'ENFANT', label: 'Enfant' },
  { key: 'ETOILE', label: 'Etoile' },
  { key: 'LOISIR', label: 'Loisir' },
  { key: 'METIER', label: 'Métier' },
  { key: 'MUSIQUE', label: 'Musique' },
  { key: 'NATURE', label: 'Nature' },
  { key: 'OBJET', label: 'Objet' },
  { key: 'PERSONNAGE', label: 'Personnage' },
  { key: 'SPORT', label: 'Sport' },
  { key: 'SYMBOLE', label: 'Symbole' },
  { key: 'VEGETAL', label: 'Végétal' },
  { key: 'VEHICULE', label: 'Véhicule' },
  { key: 'VILLAGE', label: 'Village' },
  {
    key: 'TMP',
    label: 'Par défaut',
  },
  {
    key: 'ANY',
    label: 'Autre',
  },
];
