/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-array-index-key */
import { zodResolver } from '@hookform/resolvers/zod';
import { DirectionsBoat, Euro, Percent } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'src/context/UserProvider';
import { isAdmin } from 'src/utils/user.utils';
import { z } from 'zod';

import { getCompany } from 'src/services/user';
import {
  FlexBox,
  FormCol,
  FormLine,
  RoundedButton,
  StyledForm,
} from '../../../../components/common';
import FrenchFlagIcon from '../../../../components/icons/FrenchFlagIcon';
import useLoadData from '../../../../hook/useLoadData';
import {
  getUserCoefficients,
  GlobalCoefficients,
  updateGlobalCoefficients,
} from '../../../../services/coefficients';
import { getGranits } from '../../../../services/granits';
import { handleMutation } from '../../../../utils/api.utils';
import { DEFAULT_COEFFICIENTS } from '../../../../utils/coefficients.utils';
import GranitsCoefficientsDropDown from './GranitsCoefficientsDropDown';

const coefficientCategory = ['MONUMENT', 'CINERARY', 'COLUMBARIUM', 'SOLE/PLATING'] as const;

export type CoefficientCategory = (typeof coefficientCategory)[number];

const schemaDetailedCoefficientsForm = z.record(
  z.enum(coefficientCategory),
  z.record(z.string(), z.string()),
);

export type SchemaDetailedCoefficientsForm = z.infer<typeof schemaDetailedCoefficientsForm>;

const schemaCoefficientsForm = z.object({
  frenchEco: z.string(),
  frenchNormal: z.string(),
  importEco: z.string(),
  importNormal: z.string(),
  patterns: z.string(),
  accessories: z.string(),
  delivery: z.string(),
  coeffFr: z.string(),
  coeffImport: z.string(),
  coeffEcoFr: z.string(),
  coeffEcoImport: z.string(),
  engraving: z.object({
    gold: z.object({
      coeff1: z.string(),
      coeff2: z.string(),
      coeff3: z.string(),
      coeff4: z.string(),
      coeff5: z.string(),
      coeff6: z.string(),
      coeff7: z.string(),
    }),
    white: z.object({
      coeff1: z.string(),
      coeff2: z.string(),
      coeff3: z.string(),
      coeff4: z.string(),
      coeff5: z.string(),
      coeff6: z.string(),
      coeff7: z.string(),
    }),
  }),
});

export type SchemaCoefficientsForm = z.infer<typeof schemaCoefficientsForm>;

const tableDefaultValues = [2, 4, 6, 8, 10, 12, 14];

const Coefficients = ({
  userId,
  isBeingEdited = false,
}: {
  userId: string;
  isBeingEdited?: boolean;
}) => {
  const { data: userCoefficients } = useLoadData(() => getUserCoefficients(userId));

  const { user } = useUser();

  type KeyEnum = 'MONUMENT' | 'CINERARY' | 'COLUMBARIUM' | 'SOLE/PLATING';

  const convertDetailedCoefsFromStringToInt = (
    input: any,
  ): { [K in KeyEnum]: { [key: string]: number } } => {
    const output: { [K in KeyEnum]: { [key: string]: number } } = {} as {
      [K in KeyEnum]: { [key: string]: number };
    };

    for (const key in input) {
      // eslint-disable-next-line no-prototype-builtins
      if (input.hasOwnProperty(key)) {
        output[key as KeyEnum] = {};

        for (const innerKey in input[key]) {
          // eslint-disable-next-line no-prototype-builtins
          if (input[key].hasOwnProperty(innerKey)) {
            const value = Number.parseFloat(input[key][innerKey]);

            if (Number.isNaN(value)) {
              output[key as KeyEnum][innerKey] = 1;
            }

            output[key as KeyEnum][innerKey] = value;
          }
        }
      }
    }

    return output;
  };
  const convertDetailedCoefsFromIntToString = (
    input: any,
  ): { [K in KeyEnum]: { [key: string]: string } } => {
    const output: { [K in KeyEnum]: { [key: string]: string } } = {} as {
      [K in KeyEnum]: { [key: string]: string };
    };

    for (const key in input) {
      // eslint-disable-next-line no-prototype-builtins
      if (input.hasOwnProperty(key)) {
        output[key as KeyEnum] = {};

        for (const innerKey in input[key]) {
          // eslint-disable-next-line no-prototype-builtins
          if (input[key].hasOwnProperty(innerKey)) {
            output[key as KeyEnum][innerKey] = String(input[key][innerKey]);
          }
        }
      }
    }

    return output;
  };

  const convertGlobalCoefsFromIntToString = (globalCoefs: GlobalCoefficients) => {
    return {
      frenchEco: String(globalCoefs.frenchEco),
      frenchNormal: String(globalCoefs.frenchNormal),
      importEco: String(globalCoefs.importEco),
      importNormal: String(globalCoefs.importNormal),
      patterns: String(globalCoefs.patterns),
      accessories: String(globalCoefs.accessories),
      delivery: String(globalCoefs.delivery),
      coeffImport: String(globalCoefs.coeffImport),
      coeffFr: String(globalCoefs.coeffFr),
      coeffEcoImport: String(globalCoefs.coeffEcoImport),
      coeffEcoFr: String(globalCoefs.coeffEcoFr),
      engraving: {
        gold: {
          coeff1: String(globalCoefs.engraving.gold.coeff1),
          coeff2: String(globalCoefs.engraving.gold.coeff2),
          coeff3: String(globalCoefs.engraving.gold.coeff3),
          coeff4: String(globalCoefs.engraving.gold.coeff4),
          coeff5: String(globalCoefs.engraving.gold.coeff5),
          coeff6: String(globalCoefs.engraving.gold.coeff6),
          coeff7: String(globalCoefs.engraving.gold.coeff7),
        },
        white: {
          coeff1: String(globalCoefs.engraving.white.coeff1),
          coeff2: String(globalCoefs.engraving.white.coeff2),
          coeff3: String(globalCoefs.engraving.white.coeff3),
          coeff4: String(globalCoefs.engraving.white.coeff4),
          coeff5: String(globalCoefs.engraving.white.coeff5),
          coeff6: String(globalCoefs.engraving.white.coeff6),
          coeff7: String(globalCoefs.engraving.white.coeff7),
        },
      },
    };
  };

  const checkIsNaN = (value: number, defautValue: number) => {
    if (Number.isNaN(value)) return defautValue;
    return value;
  };

  const convertGlobalCoefsFromStringToInt = (globalCoefs: any) => {
    return {
      frenchEco: checkIsNaN(Number.parseFloat(globalCoefs.frenchEco as string), 1),
      frenchNormal: checkIsNaN(Number.parseFloat(globalCoefs.frenchNormal as string), 1),
      importEco: checkIsNaN(Number.parseFloat(globalCoefs.importEco as string), 1),
      importNormal: checkIsNaN(Number.parseFloat(globalCoefs.importNormal as string), 1),
      patterns: checkIsNaN(Number.parseFloat(globalCoefs.patterns as string), 1),
      accessories: checkIsNaN(Number.parseFloat(globalCoefs.accessories as string), 1),
      delivery: checkIsNaN(Number.parseFloat(globalCoefs.delivery as string), 1),
      coeffImport: checkIsNaN(Number.parseFloat(globalCoefs.coeffImport as string), 1),
      coeffFr: checkIsNaN(Number.parseFloat(globalCoefs.coeffFr as string), 1),
      coeffEcoImport: checkIsNaN(Number.parseFloat(globalCoefs.coeffEcoImport as string), 1),
      coeffEcoFr: checkIsNaN(Number.parseFloat(globalCoefs.coeffEcoFr as string), 1),
      engraving: {
        gold: {
          coeff1: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff1 as string), 0),
          coeff2: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff2 as string), 0),
          coeff3: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff3 as string), 0),
          coeff4: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff4 as string), 0),
          coeff5: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff5 as string), 0),
          coeff6: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff6 as string), 0),
          coeff7: checkIsNaN(Number.parseFloat(globalCoefs.engraving.gold.coeff7 as string), 0),
        },
        white: {
          coeff1: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff1 as string), 0),
          coeff2: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff2 as string), 0),
          coeff3: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff3 as string), 0),
          coeff4: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff4 as string), 0),
          coeff5: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff5 as string), 0),
          coeff6: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff6 as string), 0),
          coeff7: checkIsNaN(Number.parseFloat(globalCoefs.engraving.white.coeff7 as string), 0),
        },
      },
    };
  };
  const defaultValues: SchemaCoefficientsForm = useMemo(() => {
    return userCoefficients?.globalCoefs
      ? convertGlobalCoefsFromIntToString(userCoefficients.globalCoefs)
      : convertGlobalCoefsFromIntToString(DEFAULT_COEFFICIENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCoefficients]);

  const { data: granits } = useLoadData(getGranits);

  const frenchGranits = useMemo(
    () =>
      granits
        ?.filter((granit) => granit.fabrication === 'FRENCH')
        .sort((a, b) => a.name.localeCompare(b.name)),
    [granits],
  );

  const importGranits = useMemo(
    () =>
      granits
        ?.filter((granit) => granit.fabrication !== 'FRENCH')
        .sort((a, b) => a.name.localeCompare(b.name)),
    [granits],
  );

  const importEcoGranits = useMemo(
    () =>
      granits
        ?.filter((granit) => granit.fabrication !== 'FRENCH' && granit.eco)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [granits],
  );

  const frenchEcoGranits = useMemo(
    () =>
      granits
        ?.filter((granit) => granit.fabrication === 'FRENCH' && granit.eco)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [granits],
  );

  const [detailed, setDetailed] = useState<boolean>(userCoefficients?.isDetailed ?? false);

  useEffect(() => {
    if (userCoefficients?.isDetailed) {
      setDetailed(true);
    }
  }, [userCoefficients]);

  const { handleSubmit, watch, setValue, reset } = useForm<SchemaCoefficientsForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaCoefficientsForm),
  });

  const {
    control: controlDetailed,
    watch: watchDetailed,
    setValue: setValueDetailed,
    reset: resetDetailed,
    getValues: getDetailedCoefs,
  } = useForm<SchemaDetailedCoefficientsForm>({
    defaultValues: userCoefficients?.detailedCoefs
      ? convertDetailedCoefsFromIntToString(userCoefficients?.detailedCoefs)
      : {},
    mode: 'onSubmit',
    resolver: zodResolver(schemaDetailedCoefficientsForm),
  });

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  useEffect(() => {
    reset(
      userCoefficients?.globalCoefs
        ? convertGlobalCoefsFromIntToString(userCoefficients?.globalCoefs)
        : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, userCoefficients?.globalCoefs]);

  useEffect(() => {
    resetDetailed(
      userCoefficients?.detailedCoefs
        ? convertDetailedCoefsFromIntToString(userCoefficients?.detailedCoefs)
        : {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDetailed, userCoefficients?.detailedCoefs]);

  const submit: SubmitHandler<SchemaCoefficientsForm> = (datas) => {
    handleMutation(
      (data) =>
        updateGlobalCoefficients(userId, {
          globalCoefs: convertGlobalCoefsFromStringToInt(data),
          isDetailed: detailed,
          detailedCoefs: convertDetailedCoefsFromStringToInt(getDetailedCoefs()),
        }),
      datas,
    );
  };

  const resetEngravingValues = (setFormValues: (name: any, value: string) => void) => {
    for (let i = 0; i < 7; i += 1) {
      setFormValues(
        `engraving.white.coeff${(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
        String((i + 1) * 2),
      );
      setFormValues(
        `engraving.gold.coeff${(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
        String((i + 1) * 2),
      );
    }
  };

  const getImportGranitsValue = useCallback(() => {
    if (detailed) {
      const normalImportValue = watch('importNormal');

      for (const granit of importGranits ?? []) {
        if (watchDetailed(`MONUMENT.${granit.id}`) !== normalImportValue) return '-';
        if (watchDetailed(`CINERARY.${granit.id}`) !== normalImportValue) return '-';
        if (watchDetailed(`COLUMBARIUM.${granit.id}`) !== normalImportValue) return '-';
        if (watchDetailed(`SOLE/PLATING.${granit.id}`) !== normalImportValue) return '-';
      }
      return normalImportValue;
    }

    if (watch('importEco') === watch('importNormal')) return watch('importEco');
    return '-';
  }, [detailed, importGranits, watch, watchDetailed]);

  const getFrenchGranitsValue = useCallback(() => {
    if (detailed) {
      const frenchNormal = watch('frenchNormal');

      for (const granit of frenchGranits ?? []) {
        if (watchDetailed(`MONUMENT.${granit.id}`) !== frenchNormal) return '-';
        if (watchDetailed(`CINERARY.${granit.id}`) !== frenchNormal) return '-';
        if (watchDetailed(`COLUMBARIUM.${granit.id}`) !== frenchNormal) return '-';
        if (watchDetailed(`SOLE/PLATING.${granit.id}`) !== frenchNormal) return '-';
      }
      return frenchNormal;
    }

    if (watch('frenchEco') === watch('frenchNormal')) return watch('frenchNormal');

    return '-';
  }, [detailed, frenchGranits, watch, watchDetailed]);

  const resetDetailedValues = () => {
    if (frenchGranits) {
      for (const granit of frenchGranits) {
        setValueDetailed(`MONUMENT.${granit.id}`, '2.3');
        setValueDetailed(`CINERARY.${granit.id}`, '2.3');
        setValueDetailed(`COLUMBARIUM.${granit.id}`, '2.3');
        setValueDetailed(`SOLE/PLATING.${granit.id}`, '2.3');
      }
    }
    if (importGranits) {
      for (const granit of importGranits) {
        setValueDetailed(`MONUMENT.${granit.id}`, '2.3');
        setValueDetailed(`CINERARY.${granit.id}`, '2.3');
        setValueDetailed(`COLUMBARIUM.${granit.id}`, '2.3');
        setValueDetailed(`SOLE/PLATING.${granit.id}`, '2.3');
      }
    }
  };

  const { data, onRefresh, refreshing } = useLoadData(() => getCompany(userId));

  if (refreshing) return <CircularProgress />;

  if (
    user?.role !== 'ADMIN' &&
    data &&
    data.canEditCoeffQuoteSettings === false &&
    !isBeingEdited
  ) {
    return (
      <StyledForm>
        <Typography variant="h5">Vous ne pouvez pas éditer cette page</Typography>
      </StyledForm>
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  return (
    <Container onBlur={handleSubmit(submit)}>
      <Typography variant="h5">PARAMÈTRES DES COEFFICIENTS</Typography>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Granits</Typography>
        <FlexBox sx={{ gap: '16px' }}>
          <RoundedButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              resetDetailedValues();
              reset(convertGlobalCoefsFromIntToString(DEFAULT_COEFFICIENTS));
            }}
          >
            <Percent color="secondary" />
            Restaurer les coefficients par défaut
          </RoundedButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={detailed}
                onChange={(event) => setDetailed(event.target.checked)}
              />
            }
            label="Appliquer des coefficients détaillés"
          />
        </FlexBox>
      </FlexBox>
      <FormLine sx={{ gap: 0 }}>
        <Column sx={{ borderRight: '1px solid #F2F4F7' }}>
          <GlobalCoefBox>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <FrenchFlagIcon fontSize="large" /> Fabrication Française
            </Typography>
            <TextField
              sx={{ width: '70px' }}
              value={getFrenchGranitsValue()}
              size="small"
              onChange={(event) => {
                if (detailed) {
                  setValue('frenchNormal', event.target.value);
                  for (const granit of frenchGranits ?? []) {
                    setValueDetailed(`MONUMENT.${granit.id}`, event.target.value);
                    setValueDetailed(`CINERARY.${granit.id}`, event.target.value);
                    setValueDetailed(`COLUMBARIUM.${granit.id}`, event.target.value);
                    setValueDetailed(`SOLE/PLATING.${granit.id}`, event.target.value);
                  }
                } else {
                  setValue('frenchEco', event.target.value);
                  setValue('frenchNormal', event.target.value);
                }
              }}
              onBlur={(event) => {
                if (event.target.value !== '-') {
                  if (event.target.value === '') {
                    for (const granit of frenchGranits ?? []) {
                      setValueDetailed(`MONUMENT.${granit.id}`, '1');
                    }
                  } else {
                    for (const granit of frenchGranits ?? []) {
                      setValueDetailed(`MONUMENT.${granit.id}`, event.target.value);
                    }
                  }
                }
              }}
            />
          </GlobalCoefBox>
          {detailed ? (
            <FormCol padding="0 30px">
              <GranitsCoefficientsDropDown
                granits={frenchGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="MONUMENT"
              />
              <GranitsCoefficientsDropDown
                granits={frenchGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="CINERARY"
              />
              <GranitsCoefficientsDropDown
                granits={frenchGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="COLUMBARIUM"
              />
              <GranitsCoefficientsDropDown
                granits={frenchGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="SOLE/PLATING"
              />
            </FormCol>
          ) : (
            <FormLine sx={{ width: '80%' }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  width: '100%',
                }}
              >
                <TextField
                  label="Granits “Eco” Français"
                  value={watch('frenchEco')}
                  name="frenchEco"
                  onChange={(event) => {
                    setValue('frenchEco', event.target.value);
                  }}
                />
                <Typography variant="caption" align="left">
                  Coefficient uniquement appliqué au
                  {frenchEcoGranits?.map(
                    (granit, granitIndex) =>
                      ` ${granit.name} ${granitIndex === frenchEcoGranits.length - 1 ? '' : ','}`,
                  )}
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  width: '100%',
                }}
              >
                <TextField
                  label="Autres granits Français"
                  value={watch('frenchNormal')}
                  name="frenchNormal"
                  onChange={(event) => {
                    setValue('frenchNormal', event.target.value);
                  }}
                />
              </Box>
            </FormLine>
          )}
        </Column>
        <Column component="div">
          <GlobalCoefBox>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <DirectionsBoat fontSize="large" /> Fabrication Import
            </Typography>
            <TextField
              sx={{ width: '70px' }}
              size="small"
              value={getImportGranitsValue()}
              onChange={(event) => {
                if (detailed) {
                  setValue('importNormal', event.target.value);
                  for (const granit of importGranits ?? []) {
                    setValueDetailed(`MONUMENT.${granit.id}`, event.target.value);
                    setValueDetailed(`CINERARY.${granit.id}`, event.target.value);
                    setValueDetailed(`COLUMBARIUM.${granit.id}`, event.target.value);
                    setValueDetailed(`SOLE/PLATING.${granit.id}`, event.target.value);
                  }
                } else {
                  setValue('importEco', event.target.value);
                  setValue('importNormal', event.target.value);
                }
              }}
              onBlur={(event) => {
                if (event.target.value !== '-') {
                  setValue('importEco', event.target.value);
                  setValue('importNormal', event.target.value);
                }
              }}
            />
          </GlobalCoefBox>

          {detailed ? (
            <FormCol padding="0 30px">
              <GranitsCoefficientsDropDown
                granits={importGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="MONUMENT"
              />
              <GranitsCoefficientsDropDown
                granits={importGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="CINERARY"
              />
              <GranitsCoefficientsDropDown
                granits={importGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="COLUMBARIUM"
              />
              <GranitsCoefficientsDropDown
                granits={importGranits ?? []}
                setValue={setValueDetailed}
                watch={watchDetailed}
                type="SOLE/PLATING"
              />
            </FormCol>
          ) : (
            <FormLine sx={{ width: '80%' }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  width: '100%',
                }}
              >
                <TextField
                  label="Granits “Eco” Import"
                  value={watch('importEco')}
                  name="importEco"
                  onChange={(event) => {
                    setValue('importEco', event.target.value);
                  }}
                />
                <Typography variant="caption" align="left">
                  Coefficient uniquement appliqué au
                  {importEcoGranits?.map(
                    (granit, granitIndex) =>
                      ` ${granit.name} ${granitIndex === importEcoGranits.length - 1 ? '' : ','}`,
                  )}
                </Typography>
              </Box>

              <TextField
                label="Autres granits Import"
                value={watch('importNormal')}
                name="importNormal"
                onChange={(event) => {
                  setValue('importNormal', event.target.value);
                }}
              />
            </FormLine>
          )}
        </Column>
      </FormLine>
      <FormLine sx={{ borderBottom: '1px solid #F2F4F7', paddingBottom: '30px' }}>
        <Typography variant="caption" maxWidth="40%" color="black">
          Les coefficients se calculent de votre prix d’achat HT à votre prix de vente HT. Exemple :
          pour un monument à 100 € HT port dû avec coefficient 2,2 : PVHT x Coeff + TVA = 264 € TTC
        </Typography>
      </FormLine>
      <Box sx={{ borderBottom: '1px solid #F2F4F7', paddingBottom: '30px' }} component="div">
        <Typography variant="h6">Gravure</Typography>
        <Typography color="#273135" fontSize="small">
          Votre tarif à la lettre en euros TTC pour :
        </Typography>
        <FormLine sx={{ gap: 0 }}>
          <Column sx={{ borderRight: '1px solid #F2F4F7', flexDirection: 'row' }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledHeaderCell sx={{ borderTopLeftRadius: '8px' }} />
                  {tableDefaultValues.map((_value, index) =>
                    index === tableDefaultValues.length - 1 ? (
                      <StyledHeaderCell
                        sx={{ borderTopRightRadius: '8px' }}
                        key={`${index}cm`}
                      >{`${index}cm+`}</StyledHeaderCell>
                    ) : (
                      <StyledHeaderCell key={`${index}cm`}>{`${index + 1}cm`}</StyledHeaderCell>
                    ),
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <StyledHeaderCell> Lettres dorées / noires</StyledHeaderCell>
                  {Object.keys(watch('engraving.gold')).map((_value, index) => {
                    return (
                      <StyledCell key={`gold${index}`}>
                        <TableInput
                          value={watch(
                            `engraving.gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                          )}
                          name={`engraving.gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`}
                          onChange={(event) => {
                            setValue(
                              `engraving.gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                              event.target.value,
                            );
                          }}
                        />
                      </StyledCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <StyledHeaderCell sx={{ borderBottomLeftRadius: '8px' }}>
                    Lettres blanches
                  </StyledHeaderCell>
                  {Object.keys(watch('engraving.white')).map((_value, index) => {
                    return (
                      <StyledCell key={`white${index}`}>
                        <TableInput
                          value={watch(
                            `engraving.white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                          )}
                          name={`engraving.white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`}
                          onChange={(event) => {
                            setValue(
                              `engraving.white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                              event.target.value,
                            );
                          }}
                        />
                      </StyledCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </StyledTable>
            <FormLine>
              <RoundedButton
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetEngravingValues(setValue);
                }}
              >
                <Euro color="secondary" />
                Restaurer les tarifs par défaut
              </RoundedButton>
            </FormLine>
          </Column>
          <Column component="div">
            <FormLine sx={{ flexWrap: 'wrap' }}>
              <TextField
                label="Motifs"
                value={watch('patterns')}
                name="patterns"
                onChange={(event) => {
                  setValue('patterns', event.target.value);
                }}
              />
              <TextField
                label="Accessoires"
                value={watch('accessories')}
                name="accessories"
                onChange={(event) => {
                  setValue('accessories', event.target.value);
                }}
              />
              <TextField
                label="Transport"
                value={watch('delivery')}
                name="delivery"
                onChange={(event) => {
                  setValue('delivery', event.target.value);
                }}
              />
            </FormLine>
          </Column>
        </FormLine>
      </Box>
      {isAdmin(user) ? (
        <Box component="div">
          <Typography variant="h6">Coefficients</Typography>
          <Typography color="#273135" fontSize="small">
            Coefficients détaillés:
          </Typography>

          <CoefContainer>
            <TextField
              label="Coefficient français"
              value={watch('coeffFr')}
              name="coeffFr"
              onChange={(event) => {
                setValue('coeffFr', event.target.value);
              }}
            />
            <TextField
              label="Coefficient import"
              value={watch('coeffImport')}
              name="coeffImport"
              onChange={(event) => {
                setValue('coeffImport', event.target.value);
              }}
            />
            <TextField
              label="Coefficient français eco"
              value={watch('coeffEcoFr')}
              name="coeffEcoFr"
              onChange={(event) => {
                setValue('coeffEcoFr', event.target.value);
              }}
            />
            <TextField
              label="Coefficient import eco"
              value={watch('coeffEcoImport')}
              name="coeffEcoImport"
              onChange={(event) => {
                setValue('coeffEcoImport', event.target.value);
              }}
            />
          </CoefContainer>
        </Box>
      ) : null}
    </Container>
  );
};

const Container = styled('form')({
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
  padding: '30px',
});

const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '30px',
});

const GlobalCoefBox = styled(Box)({
  boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
  display: 'flex',
});

const TableInput = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 0,
    border: 0,
  },
});

const StyledTable = styled(Table)({
  borderRadius: '8px',
  borderCollapse: 'unset',
  borderLeft: '0.5px solid #BC9A67',
  borderTop: '0.5px solid #BC9A67',
  maxWidth: '60%',
});

const StyledCell = styled(TableCell)({
  borderRight: '0.5px solid #BC9A67',
  borderBottom: '0.5px solid #BC9A67',
  padding: 0,
});

const StyledHeaderCell = styled(StyledCell)({
  background: '#F7F0E7',
  padding: '6px 8px',
  textAlign: 'center',
});

const CoefContainer = styled('form')({
  display: 'flex',
  flexDirection: 'row',
  padding: '30px',
  gap: 16,
});

export default Coefficients;
