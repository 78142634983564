import { CheckCircle } from '@mui/icons-material';
import { styled } from '@mui/material';
import React, { useState } from 'react';

import { RoundedButton } from '../components/common';

export type Filter = {
  key: string;
  label: string;
  icon?: React.ReactNode;
  iconUrl?: string;
};
const UncheckedButton = styled(RoundedButton)({
  textTransform: 'capitalize',
  border: '1px solid #344054',
});

const CheckedButton = styled(RoundedButton)({
  color: 'white',
  textTransform: 'capitalize',
});

const useFilters = (
  filters: Filter[],
  defaultValue: ('CHINA' | 'INDIA' | 'OTHER' | 'FRENCH' | 'IMPORT')[],
  singleSelection = false,
): [JSX.Element, string[]] => {
  const [filterState, setFiltersState] = useState(
    singleSelection ? [filters[0].key] : defaultValue,
  );

  const component = (
    <>
      {filters.map((filter) =>
        filterState.includes(filter.key) ? (
          <CheckedButton
            key={filter.key}
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!singleSelection)
                setFiltersState((prev) => prev.filter((key) => key !== filter.key));
            }}
          >
            {filter.icon}
            {filter.iconUrl && (
              <img
                src={filter.iconUrl}
                alt={filter.label}
                style={{ width: 23, height: 23, filter: 'invert(1)' }}
              />
            )}
            {filter.label}
            <CheckCircle sx={{ marginLeft: '20px' }} />
          </CheckedButton>
        ) : (
          <UncheckedButton
            key={filter.key}
            onClick={() =>
              setFiltersState((prev) => (singleSelection ? [filter.key] : [...prev, filter.key]))
            }
          >
            {filter.icon}
            {filter.iconUrl && (
              <img src={filter.iconUrl} alt={filter.label} style={{ width: 23, height: 23 }} />
            )}
            {filter.label}
          </UncheckedButton>
        ),
      )}
    </>
  );

  return [component, filterState];
};

export default useFilters;
