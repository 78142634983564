import { SvgIcon, SvgIconProps } from '@mui/material';

const HelpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M20 11.6648C20.0002 10.8898 19.7599 10.1338 19.3122 9.50112C18.8644 8.86848 18.2314 8.3904 17.5004 8.13286V7.4988C17.5004 5.50999 16.7104 3.60264 15.3041 2.19635C13.8978 0.790049 11.9904 0 10.0016 0C8.0128 0 6.10545 0.790049 4.69915 2.19635C3.29286 3.60264 2.50281 5.50999 2.50281 7.4988V8.13286C1.77101 8.39084 1.13728 8.86951 0.68901 9.50286C0.240736 10.1362 0 10.893 0 11.669C0 12.4449 0.240736 13.2017 0.68901 13.8351C1.13728 14.4684 1.77101 14.9471 2.50281 15.2051C2.6917 15.2718 2.89388 15.2922 3.09231 15.2645C3.29073 15.2368 3.47959 15.1618 3.64295 15.0458C3.80632 14.9298 3.9394 14.7763 4.03099 14.5981C4.12257 14.4199 4.16998 14.2223 4.16921 14.0219V7.4988C4.16921 6.73288 4.32007 5.97445 4.61317 5.26684C4.90628 4.55922 5.33589 3.91626 5.87748 3.37467C6.41906 2.83308 7.06202 2.40347 7.76964 2.11036C8.47726 1.81726 9.23568 1.6664 10.0016 1.6664C10.7675 1.6664 11.5259 1.81726 12.2336 2.11036C12.9412 2.40347 13.5841 2.83308 14.1257 3.37467C14.6673 3.91626 15.0969 4.55922 15.39 5.26684C15.6831 5.97445 15.834 6.73288 15.834 7.4988V14.0219C15.8358 14.1953 15.8738 14.3663 15.9455 14.5242C16.0173 14.682 16.1212 14.8231 16.2506 14.9384V15.4117C16.2506 16.6398 15.1749 17.0781 14.1676 17.0781H12.6862C12.5028 16.7604 12.2196 16.5121 11.8807 16.3717C11.5418 16.2314 11.1661 16.2067 10.8117 16.3017C10.4574 16.3966 10.1443 16.6058 9.92098 16.8969C9.69767 17.1879 9.57663 17.5445 9.57663 17.9113C9.57663 18.2781 9.69767 18.6347 9.92098 18.9257C10.1443 19.2168 10.4574 19.426 10.8117 19.5209C11.1661 19.6159 11.5418 19.5912 11.8807 19.4508C12.2196 19.3105 12.5028 19.0622 12.6862 18.7445H14.1676C16.3747 18.7445 17.917 17.3739 17.917 15.4117V15.0134C18.541 14.7038 19.0664 14.2266 19.4343 13.6351C19.8022 13.0436 19.9981 12.3614 20 11.6648Z" />
    </SvgIcon>
  );
};

export default HelpIcon;
