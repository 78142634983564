/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { setDefaultPattern } from 'src/services/pieces';

interface Props {
  pieces: any[];
  patterns: {
    [key: string]: {
      [key: string]: any;
    }[];
  };
}

export const DefaultPattern = ({ pieces, patterns }: Props) => {
  const handleSubmit = async () => {
    let patternsDetails: any[] = [];
    // eslint-disable-next-line guard-for-in
    for (const key1 in patterns) {
      // eslint-disable-next-line guard-for-in, @typescript-eslint/no-for-in-array
      for (const key2 in patterns[key1]) {
        const { elements, texture, camera } = patterns[key1][key2];
        patternsDetails = [
          ...patternsDetails,
          ...elements.map((el: any) => {
            return { ...el, pieceUUID: key1, texture, camera };
          }),
        ];
      }
    }

    const mappedPatterns = patternsDetails.map((pattern) => {
      const piece = pieces.find((el) => el.uuid === pattern.pieceUUID);
      return {
        pieceId: piece.id,
        patternId: pattern.patternId,
        positionX: pattern.position.x,
        positionY: pattern.position.y,
        scaleX: pattern.scale?.x,
        scaleY: pattern.scale?.y,
        orientation: pattern.orientation,
        image: pattern.texture?.image?.toDataURL(),
        camera: JSON.stringify(pattern.camera.matrix.toArray()),
        imageRatio: pattern.ratio,
      };
    });

    try {
      await setDefaultPattern(mappedPatterns);
      toast.success('Motifs par défaut enregistrés avec succès');
    } catch {
      toast.error("Erreur lors de l'enregistrement des motifs par défaut");
    }
  };

  return (
    <div>
      <p>Explications :</p>
      <p>1 - Aller dans l&apos;onglet Motifs</p>
      <p>2 - Placer les motifs que l&apos;on veut mettre par défaut</p>
      <p>3 - Revenir sur cette page et mettre les motifs par défaut</p>
      <p>
        Attention, si vous aviez précédemment mis un motif par défaut, il sera supprimé. Seuls les
        motifs disponibles dans l&apos;onglet Motifs seront pris en compte.
      </p>

      <Button
        onClick={async () => {
          await handleSubmit();
        }}
      >
        Mettre les motifs par défaut sur la pièce
      </Button>
    </div>
  );
};
