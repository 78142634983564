import axios from 'axios';

import environment from '../environement';

export const api = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const auth = localStorage.getItem('auth');
  const { accessToken } = JSON.parse(auth ?? '{}') as {
    accessToken: string;
    expiry: number;
  };
  config.headers.authorization = `Bearer ${accessToken}`;

  return config;
});
