import { Upload } from '@mui/icons-material';
import { Box, Button, Input, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ModalTitle } from 'src/components/CenteredModal';
import { FormLine, LowerCaseButton } from 'src/components/common';
import FileSettings from 'src/components/icons/FileSettingsIcon';
import { useEnv } from 'src/context/EnvProvider';
import { Buckets } from 'src/services/file';
import { Quote, updateQuote, updateQuoteCustomImage } from 'src/services/quotes';
import muiTheme from 'src/themes/muiTheme';
import { uploadImage } from 'src/utils/file.utils';

interface IUpdateQuoteImage {
  quote?: Quote;
  closeForm: () => void;
  onRefresh: () => void;
}

export const UpdateQuoteImage = ({ quote, closeForm, onRefresh }: IUpdateQuoteImage) => {
  const { buckets } = useEnv();
  const [image, setImage] = useState<string | null>(quote?.customImage || null);

  const uploadProfilePicture = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
    return pictureName ?? '';
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files) {
      const fileKey = await uploadProfilePicture(e.target.files[0]);
      setImage(fileKey);
    }
  };

  const handleUpdateQuoteCustomImage = async () => {
    if (quote?.id) {
      await updateQuoteCustomImage(quote.id, image);
      onRefresh();
      closeForm();
    }
  };

  const handleDeleteQuoteCustomImage = async () => {
    if (quote?.id) {
      await updateQuoteCustomImage(quote.id, null);
      onRefresh();
      closeForm();
    }
  };

  return (
    <StyledForm>
      <ModalTitle sx={quote ? { background: '#F7F0E7' } : {}}>
        <Typography
          sx={{
            color: quote ? muiTheme.palette.secondary.main : muiTheme.palette.primary.main,
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
          variant="h2"
          textAlign="left"
        >
          <FileSettings fontSize="large" />
          Image du monument
        </Typography>
      </ModalTitle>
      <Body>
        <UploadContainer component="label">
          {image ? (
            <Image src={`${buckets?.BUCKET_3D_PIECES || ''}/${image}`} alt="logo" />
          ) : (
            <>
              <Upload />
              <Typography variant="body2">Cliquer pour ajouter une image</Typography>
              <Typography variant="body2">PNG, JPG, PDF...</Typography>
            </>
          )}
          <Input type="file" sx={{ display: 'none' }} onChange={onChange} />
        </UploadContainer>
        <FormLine>
          <LowerCaseButton
            color={quote ? 'secondary' : 'primary'}
            onClick={handleDeleteQuoteCustomImage}
          >
            {image ? 'Supprimer la photo' : 'Fermer'}
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            color={quote ? 'secondary' : 'primary'}
            onClick={handleUpdateQuoteCustomImage}
          >
            Sauvegarder
          </LowerCaseButton>
        </FormLine>
      </Body>
    </StyledForm>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  background: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '10px',
}));

const Body = styled(Box)({
  padding: '30px',
  paddingRight: '50px',
  paddingLeft: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const Image = styled('img')({
  width: '200px',
  height: '200px',
  borderRadius: 5,
  cursor: 'pointer',
  objectFit: 'cover',
});

const UploadContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  height: 200,
  color: theme.palette.common.black,
})) as typeof Button;
