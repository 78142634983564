/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { zodResolver } from '@hookform/resolvers/zod';
import { Edit } from '@mui/icons-material';
import { Chip, IconButton } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'src/context/UserProvider';
import { z } from 'zod';

import { PatternsLabels } from 'src/constants/pattern';
import {
  AdditionalService,
  createAdditionalServices,
  deleteAdditionalServices,
  getAdditionalServices,
  updateAdditionalServices,
} from '../services/additionalServices';
import {
  createPattern,
  deletePatterns,
  getBoPatterns,
  Pattern,
  updatePattern,
} from '../services/pattern';
import { handleMutation } from '../utils/api.utils';
import useLoadDataList from './useLoadDataList';
import useToggle from './useToggle';

const schemaServicesForm = z.object({
  name: z.string().trim().min(1),
  price: z.number().min(0),
  active: z.boolean(),
});

type SchemaServicesForm = z.infer<typeof schemaServicesForm>;

const schemaPatternForm = z.object({
  name: z.string().trim().min(1),
  price: z.number().min(0),
  image: z.string().trim().min(1),
  leaderGranitEngraving: z.boolean(),
  description: z.string().trim().min(0),
  type: z.string().array(),
  height: z.number().min(0),
  catalogIds: z.array(z.object({ name: z.string().trim().min(1), id: z.string().cuid() })),
});

type SchemaPatternForm = z.infer<typeof schemaPatternForm>;

export const PATTERN_PAGE_SIZE = 48;

const useAdditionalServices = (userId: string) => {
  const { user } = useUser();
  const [patternPage, setPatternPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const [addService, toggleService] = useToggle();
  const [addPattern, togglePattern] = useToggle();

  const [openedService, setOpenedServices] = useState<AdditionalService>();
  const [openedPattern, setOpenedPattern] = useState<Pattern>();
  const [selectedPatterns, setSelectedPatterns] = useState<string[]>([]);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [imageRatio, setImageRatio] = useState<number>(1);

  const { data: additionalServices, onRefresh: refreshServices } = useLoadDataList(() =>
    getAdditionalServices(userId),
  );
  const { data: patterns, onRefresh: refreshPatterns } = useLoadDataList(() =>
    getBoPatterns(userId),
  );

  const additionalServicesColumns: GridColDef<AdditionalService>[] = [
    {
      field: 'name',
      headerName: 'Service',
      flex: 2,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Prix',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams<number>) =>
        `${new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(params.value ?? 0)} TTC`,
    },
    {
      field: 'height',
      headerName: 'Hauteur',
      flex: 1,
    },
    {
      field: 'active',
      headerName: 'Statut',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams<boolean>) =>
        params.value ? <Chip label="● Actif" color="primary" /> : <Chip label="Inactif" />,
    },
    {
      field: 'actions',
      headerName: 'Modifier',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          color="primary"
          onClick={() => {
            toggleService();
            setOpenedServices(params.row as AdditionalService);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ];

  const serviceDefaultValues = useMemo(
    () => ({
      name: openedService?.name ?? '',
      price: +(openedService?.price ?? 0),
      active: openedService?.active ?? false,
    }),
    [openedService?.active, openedService?.name, openedService?.price],
  );

  const additionalServicesMethods = useForm<SchemaServicesForm>({
    defaultValues: serviceDefaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaServicesForm),
  });

  useEffect(
    () => additionalServicesMethods.reset(serviceDefaultValues),
    [additionalServicesMethods, serviceDefaultValues],
  );

  const patternDefaultValues = useMemo(
    () => ({
      name: openedPattern?.name ?? '',
      price: +(openedPattern?.price ?? 0),
      image: openedPattern?.image ?? '',
      leaderGranitEngraving:
        user?.role === 'ADMIN' ? true : openedPattern?.leaderGranitEngraving ?? false,
      description: openedPattern?.description ?? '',
      type:
        openedPattern?.type?.map((el) => {
          return PatternsLabels.find(({ key }) => key === el)?.label as string;
        }) ?? [],
      height: +(openedPattern?.height ?? 0),
      catalogIds:
        openedPattern?.patternCatalog?.map((patternCatalog) => patternCatalog.catalog) ?? [],
    }),
    [openedPattern, user?.role],
  );

  const patternMethods = useForm<SchemaPatternForm>({
    defaultValues: patternDefaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaPatternForm),
  });

  useEffect(
    () => patternMethods.reset(patternDefaultValues),
    [patternMethods, patternDefaultValues],
  );

  useEffect(() => {
    if (patterns) setPageCount(Math.ceil(patterns.length / PATTERN_PAGE_SIZE));
  }, [patterns]);

  const submitAdditionalService: SubmitHandler<SchemaServicesForm> = (data) => {
    if (openedService)
      handleMutation(updateAdditionalServices, { ...data, id: openedService.id }, () => {
        toggleService();
        setOpenedServices(undefined);
        additionalServicesMethods.reset(serviceDefaultValues);
        refreshServices();
      });
    else
      handleMutation(
        (datas) => createAdditionalServices(datas, userId),
        data,
        () => {
          toggleService();
          additionalServicesMethods.reset(serviceDefaultValues);
          refreshServices();
        },
      );
  };

  const submitPattern: SubmitHandler<SchemaPatternForm> = (data) => {
    if (openedPattern)
      handleMutation(
        updatePattern,
        {
          ...data,
          ratio: imageRatio,
          id: openedPattern.id,
          catalogIds: patternMethods.watch('catalogIds').map((catalog) => catalog.id),
          type: patternMethods.watch('type').map((type) => {
            return PatternsLabels.find(({ label }) => label === type)?.key as string;
          }),
        },
        () => {
          togglePattern();
          setOpenedPattern(undefined);
          patternMethods.reset(patternDefaultValues);
          refreshPatterns();
        },
      );
    else
      handleMutation(
        (datas) =>
          createPattern(
            {
              ...datas,
              ratio: imageRatio,
              catalogIds: patternMethods.watch('catalogIds').map((catalog) => catalog.id),
              type: patternMethods.watch('type').map((type) => {
                return PatternsLabels.find(({ label }) => label === type)?.key as string;
              }),
            },
            userId,
          ),
        data,
        () => {
          togglePattern();
          patternMethods.reset(patternDefaultValues);
          refreshPatterns();
        },
      );
  };

  const handleSelectPattern = (id: string) => {
    if (selectedPatterns.includes(id)) setSelectedPatterns((ids) => ids.filter((it) => it !== id));
    else setSelectedPatterns((ids) => [...ids, id]);
  };

  const handleDeleteAdditionalServices = () => {
    handleMutation(deleteAdditionalServices, selectionModel as string[], refreshServices);
  };

  const handleDeletePatterns = () => {
    handleMutation(deletePatterns, selectedPatterns, refreshPatterns);
    setSelectedPatterns([]);
  };

  return {
    additionalServices,
    additionalServicesColumns,
    patterns,
    patternPage,
    setPatternPage,
    pageCount,
    addService,
    toggleService,
    addPattern,
    togglePattern,
    openedPattern,
    setOpenedPattern,
    openedService,
    setOpenedServices,
    additionalServicesMethods,
    patternMethods,
    submitAdditionalService,
    submitPattern,
    handleSelectPattern,
    selectedPatterns,
    selectionModel,
    setSelectionModel,
    handleDeleteAdditionalServices,
    handleDeletePatterns,
    imageRatio,
    setImageRatio,
  };
};

export default useAdditionalServices;
