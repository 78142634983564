import { createContext, useContext, useMemo } from 'react';

import useLoadData from '../hook/useLoadData';
import { BucketsUrls, getBuckets } from '../services/file';

type ContextProps = {
  buckets: BucketsUrls | null;
};

interface IEnvProviderProps {
  children: React.ReactNode;
}

export const EnvContext = createContext<ContextProps>({
  buckets: null,
});

export const EnvProvider = ({ children }: IEnvProviderProps) => {
  const { data: buckets } = useLoadData(getBuckets);

  const envProviderValue = useMemo(() => ({ buckets }), [buckets]);

  return <EnvContext.Provider value={envProviderValue}>{children}</EnvContext.Provider>;
};

export const useEnv = () => useContext(EnvContext);
