/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { styled, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { CategoryType } from 'src/constants/category';
import { useEnv } from 'src/context/EnvProvider';
import { useUser } from 'src/context/UserProvider';
import { usePieces } from 'src/hook/usePieces';
import { useUnderAssemblies } from 'src/hook/useUnderAssemblies';
import { Granit } from 'src/services/granits';
import { Piece } from 'src/services/pieces';
import { isAccesory } from 'src/utils/configurator.utils';
import { createPlatings } from 'src/utils/plating.utils';
import { changeSteleShape, getSteleExtrudeSettings } from 'src/utils/stele.utils';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

type CatalogType = {
  customSteles: any[];
  setCustomSteles: (customSteles: any[]) => void;
  pieceIndex: number;
  activePieces: Piece[];
  setSelectedPage: (selectedPage: 'pieces' | 'dimension' | 'textures') => void;
  setPieceIndex: (pieceIndex: number) => void;

  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];

  categorieKey: CategoryType | undefined;
  pieces: any[];
  granits: Granit[];
  importPiece: (
    selectedPiece: any,
    options?: {
      swapPieceIndex?: number;
      callback?: () => void;
    },
  ) => void;
  step: string;
};

export const Catalog = ({
  customSteles,
  setCustomSteles,
  pieceIndex,
  activePieces,
  setSelectedPage,
  setPieceIndex,
  categorieKey,
  pieces,
  setCustomPlatings,
  customPlatings,
  granits,
  importPiece,
  step,
}: CatalogType) => {
  const { user } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { pieces: piecesToImport } = usePieces(categorieKey);
  const { underAssemblies } = useUnderAssemblies(categorieKey);
  const { buckets } = useEnv();
  const [filter, setFilter] = useState<string>('');

  const createStele = () => {
    const shape = new THREE.Shape();
    const extrudeSettings = getSteleExtrudeSettings(0.1);
    const loader = new THREE.TextureLoader();
    let loadedTexture: any | null = null;

    if (granits && granits[0]) {
      loadedTexture = loader.load(
        `${buckets?.BUCKET_3D_PIECES || ''}/${granits[0].image}?t=${Date.now()}`,
      );
      loadedTexture.wrapS = THREE.RepeatWrapping;
      loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
      loadedTexture.offset.x = 0.5;
      loadedTexture.repeat.x = 1;
    }
    changeSteleShape(shape, 0.7, 0.7, 0.8, 0.9, 0.8, 0, 0);
    setCustomSteles([
      ...customSteles,
      {
        id: generateUUID(),
        boxLeftHeight: 0.7,
        boxRightHeight: 0.7,
        boxHeight: 0.8,
        boxUpWidth: 0.9,
        boxDownWidth: 0.8,
        headIndex: 0,
        baseIndex: 0,
        depth: 0.1,
        shape,
        extrudeSettings,
        position: new Vector3(0, 0, 0),
        texture: loadedTexture ?? null,
        granit: granits.length > 0 ? granits[0] : null,
        isBlocked: false,
      },
    ]);
  };

  const filteredUnderAssemly = underAssemblies.filter((el) => {
    if (user?.role !== 'ADMIN') {
      if (step === 'CUSTOMIZATION' && isAccesory(el.type)) return false;
      if (step === 'ACCESSORIES' && !isAccesory(el.type)) return false;
    }

    return el.name
      .toLowerCase()
      .replaceAll(' ', '')
      .includes(filter.toLowerCase().replaceAll(' ', ''));
  });

  const filteredPieces = piecesToImport.filter((el) => {
    if (user?.role !== 'ADMIN') {
      if (step === 'CUSTOMIZATION' && isAccesory(el.type)) return false;
      if (step === 'ACCESSORIES' && !isAccesory(el.type)) return false;
    }
    return el.name
      .toLowerCase()
      .replaceAll(' ', '')
      .includes(filter.toLowerCase().replaceAll(' ', ''));
  });

  return (
    <Container>
      <PiecesContainer>
        <TextField
          style={{ width: '100%' }}
          autoFocus
          variant="standard"
          label="Rechercher un mot clé..."
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        {categorieKey === 'STELE' ? (
          <PieceContainer
            style={{ backgroundPosition: 'right 50% top 20%' }}
            backgroundImage="/icons/stele.svg"
            backgroundColor="#F6F0E7"
            isSelected={false}
            onClick={() => {
              const allPieceLength = activePieces.length + customSteles.length;

              createStele();
              setSelectedPage('dimension');
              setPieceIndex(allPieceLength === 0 ? 0 : allPieceLength);
            }}
          >
            <Typography
              variant="body2"
              color="#344054"
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                margin: '10px',
              }}
            >
              Dessinez votre stèle
            </Typography>
          </PieceContainer>
        ) : null}
        {categorieKey === 'PLATING' ? (
          <PieceContainer
            style={{ backgroundPosition: 'right 50% top 30%' }}
            backgroundImage="/icons/placage.svg"
            backgroundColor="#F6F0E7"
            isSelected={false}
            onClick={() => {
              const allPieceLength = activePieces.length + customSteles.length;
              const loader = new THREE.TextureLoader();
              let loadedTexture: THREE.Texture | null = null;

              if (granits[0]) {
                loadedTexture = loader.load(
                  `${buckets?.BUCKET_3D_PIECES || ''}/${granits[0].image}?t=${Date.now()}`,
                );
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
                loadedTexture.offset.x = 0.5;
                loadedTexture.repeat.x = 1;
              }

              setSelectedPage('dimension');
              setPieceIndex(allPieceLength === 0 ? 0 : allPieceLength);
              createPlatings(
                loadedTexture ?? null,
                granits.length > 0 ? granits[0] : null,
                pieces,
                [],
                setCustomPlatings,
                0.03,
                'closoir',
              );
            }}
          >
            <Typography
              variant="body2"
              color="#344054"
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                margin: '10px',
              }}
            >
              Dessinez votre placage
            </Typography>
          </PieceContainer>
        ) : null}

        {filteredUnderAssemly?.map((el, index) => {
          return (
            <PieceContainer
              key={`underAssembly_${index}`}
              backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/${el.pictureUrl}`}
              isSelected={false}
              onClick={() => {
                importPiece(el);
              }}
            >
              <PieceNameContainer>
                <Typography
                  variant="body2"
                  color="#344054"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  {el.name}
                </Typography>
              </PieceNameContainer>
            </PieceContainer>
          );
        })}

        {filteredPieces?.map((el, index) => {
          return (
            <PieceContainer
              key={index}
              backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/${el.pictureUrl}`}
              isSelected={false}
              onClick={() => {
                importPiece(el);
              }}
            >
              <PieceNameContainer>
                <Typography
                  variant="body2"
                  color="#344054"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  {el.name}
                </Typography>
              </PieceNameContainer>
            </PieceContainer>
          );
        })}
      </PiecesContainer>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
});

const PiecesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
  padding: '22px',

  maxHeight: window.innerHeight - 334,
  overflowY: 'auto',
});

const PieceNameContainer = styled('div')({
  width: '100%',
  backgroundColor: '#F2F4F7',

  padding: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
});

const PieceContainer = styled('div')(
  ({
    backgroundImage,
    isSelected,
    backgroundColor,
  }: {
    backgroundImage: string;
    isSelected: boolean;
    backgroundColor?: string;
  }) => ({
    width: '140px',
    height: '140px',

    backgroundColor: `${backgroundColor || '#F2F4F7'}`,
    borderRadius: '10px',
    border: `${isSelected ? 1 : 0}px solid #BC9A67`,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    backgroundImage: `url("${backgroundImage}");`,
    backgroundSize: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    cursor: 'pointer',
  }),
);
