import PageWithTabs from '../../components/PageWithTabs';
import { useUser } from '../../context/UserProvider';
import Quotes from '../quotesAndOrders/records/Quotes';
import { ORDER, QUOTE } from '../quotesAndOrders/records/quotes.helper';
import AgenciesManagement from './agenciesManagement/AgenciesManagement';

const tabs = ['quote', 'toConfirm', 'orders', 'agencies'] as const;

type Tabs = (typeof tabs)[number];

const Agencies = () => {
  const { user } = useUser();
  const tabsToComponent = (tab: Tabs) => {
    if (!user) return <Quotes filter={ORDER} specialQuery="agencies" />;
    switch (tab) {
      case 'orders': {
        return <Quotes filter={ORDER} specialQuery="agencies" />;
      }
      case 'toConfirm': {
        return <Quotes filter={['WAITING_HEAD_OFFICE']} specialQuery="agencies" />;
      }
      case 'agencies': {
        return <AgenciesManagement userId={user.id} />;
      }
      default: {
        return <Quotes filter={QUOTE} specialQuery="agencies" />;
      }
    }
  };
  return <PageWithTabs pageName="Mes agences" tabs={tabs} tabToComponent={tabsToComponent} />;
};

export default Agencies;
