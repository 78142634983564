/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled, Switch, SwitchProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { StateNames, States } from 'src/Screens/Configurator';
import { changePlatingStatus, createPlatings, isPlatingUse } from 'src/utils/plating.utils';

type CustomPlatingsProps = {
  pieces: any[];
  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
};

export const CustomPlatings = ({
  pieces,
  setCustomPlatings,
  customPlatings,
  setValue,
  getValue,
}: CustomPlatingsProps) => {
  const [orientation, setOrientation] = useState<'z' | 'x'>('z');
  const [width, setWidth] = useState<string>();
  const [length, setLength] = useState<string>();
  const [depth, setDepth] = useState<string>();
  const [error, setError] = useState<string>();
  const [hasSlope, setHasSlope] = useState<boolean>(false);

  const [height, setHeight] = useState<{ front: string; back: string }>({
    front: '2000',
    back: '',
  });
  const [mode, setMode] = useState<'closoir' | 'double closoir' | 'tete'>('closoir');

  const checkMinMax = (value: number, granit: any) => {
    if (value < Number.parseFloat(granit.minLength) / 100)
      return Number.parseFloat(granit.minLength) / 100;
    if (value > Number.parseFloat(granit.maxLength) / 100) {
      setError(
        `Attention, votre pièce dépasse la taille maximale de ce granit (${Number.parseFloat(
          granit.maxLength,
        )}). Elle sera coupée.`,
      );
    }
    return value;
  };

  useEffect(() => {
    if (customPlatings.length > 0) {
      setWidth(
        String(Math.round(Number.parseFloat(customPlatings[0]?.shapeDetails.monumentWidth) * 100)),
      );
      setLength(
        String(Math.round(Number.parseFloat(customPlatings[0]?.shapeDetails.monumentLength) * 100)),
      );
      setDepth(String(Math.round(Number.parseFloat(customPlatings[0]?.shapeDetails.depth) * 100)));

      setHeight({
        front:
          customPlatings[0]?.shapeDetails.monumentFront === undefined
            ? String(Number.parseFloat(height.front) / 100)
            : String(
                Math.round(Number.parseFloat(customPlatings[0]?.shapeDetails.monumentFront) * 100),
              ),
        back:
          customPlatings[0]?.shapeDetails.monumentBack === undefined
            ? ''
            : String(
                Math.round(Number.parseFloat(customPlatings[0]?.shapeDetails.monumentBack) * 100),
              ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPlatings]);

  const updatePiece = (forceInformation?: {
    orientation?: 'x' | 'z';
    mode?: 'closoir' | 'double closoir' | 'tete';
    customPlatings?: any[];
    height?: { front: string; back: string };
  }) => {
    const heightToTake = forceInformation?.height ?? height;
    setError(undefined);
    createPlatings(
      customPlatings[0]?.texture,
      customPlatings[0]?.granit,
      pieces,
      forceInformation?.customPlatings ?? customPlatings,
      setCustomPlatings,
      Number.parseInt(depth ?? '0', 10) / 100,
      forceInformation?.mode ?? mode,
      {
        length: checkMinMax(Number.parseInt(length ?? '0', 10) / 100, customPlatings[0]?.granit),
        width: checkMinMax(Number.parseInt(width ?? '0', 10) / 100, customPlatings[0]?.granit),
        heightFront: checkMinMax(
          Number.parseInt(heightToTake.front ?? '0', 10) / 100,
          customPlatings[0]?.granit,
        ),
        heightBack:
          heightToTake.back === ''
            ? undefined
            : checkMinMax(Number.parseInt(heightToTake.back, 10) / 100, customPlatings[0]?.granit),
        orientation: forceInformation?.orientation ?? orientation,
      },
    );
  };

  return (
    <Container>
      {getValue('hasAdvancedMode') ? (
        <div
          style={{
            backgroundColor: '#D32F2F70',
            padding: 12,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 12,
            marginBottom: 12,
          }}
        >
          <p style={{ fontSize: 12 }}>Attention, le mode avancé est activé.</p>
          <p style={{ fontSize: 12 }}>
            Les assemblages de pièces ne sont plus pris en compte. Vous modifierez et déplacerez
            chaque pièce indépendamment des autres.
          </p>
        </div>
      ) : null}

      <Row style={{ justifyContent: 'space-between' }}>
        <Row>
          <Typography
            variant="body2"
            color="#344054"
            style={{
              cursor: 'pointer',
              zIndex: 2,
            }}
          >
            Dimension
          </Typography>
          <Typography
            variant="body2"
            color="#344054"
            style={{ marginLeft: '4px', marginBottom: '10px', fontWeight: 700 }}
          >
            Placage
          </Typography>
        </Row>
        <Row style={{ alignItems: 'center', gap: '6px', marginLeft: '0px' }}>
          <Typography variant="body2" color="#344054" style={{ fontWeight: 400, fontSize: '12px' }}>
            Mode avancé
          </Typography>
          <IOSSwitch
            checked={getValue('hasAdvancedMode')}
            onChange={() => setValue('hasAdvancedMode', !getValue('hasAdvancedMode'))}
          />
        </Row>
      </Row>
      {/* <Row style={{ alignItems: 'center' }}>
        <Label style={{ margin: '0px' }}>Position de l&apos;équerre</Label>
        <SquareButton isActive={square === 'up'} onClick={() => setSquare('up')}>
          <SquareSvg src="/icons/up-square.svg" isActive={square === 'up'} />
          <SquareButtonText isActive={square === 'up'}>En haut</SquareButtonText>
        </SquareButton>
        <SquareButton isActive={square === 'down'} onClick={() => setSquare('down')}>
          <SquareSvg src="/icons/down-square.svg" isActive={square === 'down'} />

          <SquareButtonText isActive={square === 'down'}>En bas</SquareButtonText>
        </SquareButton>
      </Row> */}

      {error ? (
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            paddingTop: '16px',
          }}
          color="error"
        >
          {error}
        </Typography>
      ) : null}

      <Row>
        <Row style={{ width: '60%' }}>
          <Column>
            <Label>Longueur totale</Label>
            <Row>
              <Input
                value={length}
                onChange={(e) => setLength(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') updatePiece();
                }}
                onBlur={() => {
                  updatePiece();
                }}
              />
              <MathSign>x</MathSign>
            </Row>
          </Column>
          <Column>
            <Label>Largeur totale</Label>
            <Row>
              <Input
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') updatePiece();
                }}
                onBlur={() => {
                  updatePiece();
                }}
              />
              <MathSign />
            </Row>
          </Column>
        </Row>
        <Column style={{ width: '40%' }}>
          <Label>Epaisseur</Label>
          <Input
            value={depth}
            onChange={(e) => setDepth(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') updatePiece();
            }}
            onBlur={() => {
              updatePiece();
            }}
          />
        </Column>
      </Row>
      <Row>
        <Row style={{ width: '60%' }}>
          <Column>
            <Label>Hauteur {hasSlope ? (orientation === 'z' ? 'Avant' : 'Gauche') : ''}</Label>
            <Row>
              <Input
                value={height.front}
                onChange={(e) => setHeight({ ...height, front: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') updatePiece();
                }}
                onBlur={() => {
                  updatePiece();
                }}
              />
              {hasSlope ? <MathSign>/</MathSign> : null}
            </Row>
          </Column>

          {hasSlope ? (
            <Column style={{ justifyContent: 'flex-end' }}>
              <Label>Hauteur {hasSlope ? (orientation === 'z' ? 'Arrière' : 'Droite') : ''}</Label>

              <Row>
                <Input
                  value={height.back}
                  onChange={(e) => setHeight({ ...height, back: e.target.value })}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') updatePiece();
                  }}
                  onBlur={() => {
                    updatePiece();
                  }}
                />
                <MathSign />
              </Row>
            </Column>
          ) : null}
        </Row>
        {hasSlope ? (
          <Column style={{ width: '40%' }}>
            <Label>Sens de la pente</Label>
            <Select
              name="sens"
              id="sens"
              onChange={(e) => {
                setOrientation(e.target.value as 'x' | 'z');
                updatePiece({ orientation: e.target.value as 'x' | 'z' });
              }}
              disabled={!hasSlope}
              value={orientation}
            >
              <option selected={orientation === 'z'} value="z">
                Avant/Arrière
              </option>
              <option selected={orientation === 'x'} value="x">
                Gauche/Droit
              </option>
            </Select>
          </Column>
        ) : null}
      </Row>

      <Row style={{ alignItems: 'center', marginTop: 12 }}>
        <CheckBox
          type="checkbox"
          checked={hasSlope}
          onChange={() => {
            setHeight({ ...height, back: '' });
            setHasSlope(!hasSlope);

            updatePiece({ height: { front: height.front, back: '' } });
          }}
        />
        <Typography variant="body2" color="#273135">
          Rajouter une pente
        </Typography>
      </Row>
      <Row>
        <PlatingContainer>
          <Row style={{ height: '100%' }}>
            <PlatingSvg
              src="/icons/plating-left.svg"
              width={82}
              height={105}
              isActive={isPlatingUse(customPlatings, 'left')}
              onClick={() => {
                changePlatingStatus('left', customPlatings, setCustomPlatings);
                updatePiece({
                  customPlatings: customPlatings.map((el) => {
                    if (el.key === 'left')
                      return {
                        ...el,
                        isActive: !el.isActive,
                      };
                    return el;
                  }),
                });
              }}
            />
            <Column style={{ justifyContent: 'space-between' }}>
              <PlatingSvg
                src="/icons/plating-back.svg"
                width={80}
                height={29}
                style={{ marginLeft: '5px', marginTop: '2px', zIndex: 2 }}
                isActive={isPlatingUse(customPlatings, 'back')}
                onClick={() => {
                  changePlatingStatus('back', customPlatings, setCustomPlatings);
                  updatePiece({
                    customPlatings: customPlatings.map((el) => {
                      if (el.key === 'back')
                        return {
                          ...el,
                          isActive: !el.isActive,
                        };
                      return el;
                    }),
                  });
                }}
              />
              <PlatingSvg
                src="/icons/plating-front.svg"
                width={117}
                height={53}
                style={{ marginLeft: '-70px', marginBottom: '50px' }}
                isActive={isPlatingUse(customPlatings, 'front')}
                onClick={() => {
                  changePlatingStatus('front', customPlatings, setCustomPlatings);
                  updatePiece({
                    customPlatings: customPlatings.map((el) => {
                      if (el.key === 'front')
                        return {
                          ...el,
                          isActive: !el.isActive,
                        };
                      return el;
                    }),
                  });
                }}
              />
            </Column>
            <PlatingSvg
              src="/icons/plating-right.svg"
              width={39}
              height={130}
              style={{ marginLeft: '-30px', marginTop: '10px' }}
              isActive={isPlatingUse(customPlatings, 'right')}
              onClick={() => {
                changePlatingStatus('right', customPlatings, setCustomPlatings);
                updatePiece({
                  customPlatings: customPlatings.map((el) => {
                    if (el.key === 'right')
                      return {
                        ...el,
                        isActive: !el.isActive,
                      };
                    return el;
                  }),
                });
              }}
            />
          </Row>
        </PlatingContainer>
        <ModeContainer>
          <Row style={{ alignItems: 'center' }}>
            <CheckBox
              type="checkbox"
              checked={mode === 'tete'}
              onChange={() => {
                setMode('tete');
                updatePiece({ mode: 'tete' });
              }}
            />
            <Typography variant="body2" color="#273135">
              Tête avant / tête arrière
            </Typography>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <CheckBox
              type="checkbox"
              checked={mode === 'closoir'}
              onChange={() => {
                setMode('closoir');
                updatePiece({ mode: 'closoir' });
              }}
            />
            <Typography variant="body2" color="#273135">
              Closoir arrière
            </Typography>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <CheckBox
              type="checkbox"
              checked={mode === 'double closoir'}
              onChange={() => {
                setMode('double closoir');
                updatePiece({ mode: 'double closoir' });
              }}
            />
            <Typography variant="body2" color="#273135">
              Double closoir
            </Typography>
          </Row>
        </ModeContainer>
      </Row>
    </Container>
  );
};

const Container = styled('div')({
  height: '100%',
  width: '100%',
});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',
  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '18px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const MathSign = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px',
  color: '#667085',
});

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const PlatingContainer = styled('div')({
  position: 'relative',
  marginTop: '42px',
  width: '188px',
  height: '193px',
});

const PlatingSvg = styled('img')(
  ({ width, height, isActive }: { width: number; height: number; isActive: boolean }) => ({
    width,
    height,

    cursor: 'pointer',
    filter: isActive
      ? ''
      : 'invert(41%) sepia(2%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(90%);',
  }),
);

const ModeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  justifyContent: 'center',

  padding: '20px',
});

const CheckBox = styled('input')({
  width: '1.3em',
  height: '1.3em',
  backgroundColor: 'white',
  borderRadius: ' 50%',
  verticalAlign: 'middle',
  border: '1px solid #ddd',
  appearance: 'none',
  '-webkit-appearance': 'none',
  outline: 'none',
  cursor: 'pointer',

  padding: '8px',
  marginRight: '8px',
  '&:checked': {
    backgroundColor: '#BC9A67',
    borderColor: '#BC9A67',
  },
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#BC9A67' : '#BC9A67',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#BC9A67',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
