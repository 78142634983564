import { api } from './api';

export type DeliveryCost = {
  code: string;
  price: number;
};

export const findAllDeliveryCosts = async () => {
  const { data } = await api.get<DeliveryCost[]>('/delivery-cost');
  return data;
};

export const createDeliveryCosts = async (data: DeliveryCost) => {
  return api.post<void>('/delivery-cost', data);
};

export const updateDeliveryCosts = async (data: DeliveryCost) => {
  return api.patch<void>('/delivery-cost', data);
};

export const deleteDeliveryCosts = async (data: { code: string }) => {
  return api.delete<void>(`/delivery-cost/${data.code}`);
};

export const getDeliveryCosts = async (code: string) => {
  return api.get<void>(`/delivery-cost/${code}`);
};
