import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, styled, SxProps, Typography } from '@mui/material';

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  border: `0.5px solid #D0D5DD`,
  borderTop: '1px solid #D0D5DD',
  padding: '5px',
  boxSizing: 'border-box',
  borderRadius: '0 0 8px 8px',
});

type ICustomPaginationProps = {
  page: number;
  pageCount: number;
  handleChangePage: (newPage: number) => void;
  sx?: SxProps;
};

export const CustomPagination = ({
  page,
  pageCount,
  handleChangePage,
  sx,
}: ICustomPaginationProps) => {
  return pageCount > 1 ? (
    <Wrapper sx={sx}>
      <IconButton disabled={page === 0} onClick={() => handleChangePage(page - 1)}>
        <ArrowBackIos />
      </IconButton>
      <Typography>{`Page ${page + 1} sur ${pageCount}`}</Typography>
      <IconButton disabled={page + 1 === pageCount} onClick={() => handleChangePage(page + 1)}>
        <ArrowForwardIos />
      </IconButton>
    </Wrapper>
  ) : (
    <div />
  );
};
