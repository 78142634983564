import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { EnvProvider } from '../../context/EnvProvider';
import { UserProvider } from '../../context/UserProvider';
import useLoadData from '../../hook/useLoadData';
import paths from '../../navigation/paths';
import { verify } from '../../services/auth';

const RequireAuth = ({ adminAuth = false }: { adminAuth?: boolean }) => {
  const { data, refreshing } = useLoadData(verify);

  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');
  const admin = localStorage.getItem('admin') === 'true';

  const { accessToken, expiry } = JSON.parse(auth ?? '{}') as {
    accessToken: string;
    expiry: number;
  };

  const [token, setToken] = useState<string | null>(accessToken);

  useEffect(() => {
    if (!adminAuth && admin) navigate(`/${paths.admin.root}`, { replace: true });
    if (adminAuth && !admin) navigate(`/${paths.home.root}`, { replace: true });

    if ((!data && !refreshing) || data?.status === 'INVALID') {
      localStorage.clear();
      setToken(null);
      navigate(`/${paths.connexion.root}`, { replace: true });
    }
  }, [refreshing, data, admin, navigate, adminAuth]);

  if (!token || expiry < Date.now()) {
    localStorage.clear();
    return <Navigate to={`/${paths.connexion.root}`} replace />;
  }

  if (data?.status === 'MAINTENANCE') {
    return <Navigate to={`/${paths.maintenance.root}`} />;
  }

  return (
    <EnvProvider>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </EnvProvider>
  );
};

export default RequireAuth;
