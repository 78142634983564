import { Euro, Percent } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  styled,
  Typography,
} from '@mui/material';

import { getCompany } from 'src/services/user';
import useLoadData from 'src/hook/useLoadData';
import { useUser } from 'src/context/UserProvider';
import { FormLine, StyledForm } from '../../../../components/common';
import SwitchRHF from '../../../../components/forms/SwitchRhf';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import useQuoteSettings from './useQuoteSettings';

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 16,
  height: '100%',
});

const CenteredContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '56px',
});

const StyledLine = styled(FormLine)({ borderBottom: '1px solid #F2F4F7', padding: '16px 0' });

const QuoteSettings = ({
  userId,
  isBeingEdited = false,
}: {
  userId: string;
  isBeingEdited?: boolean;
}) => {
  const { methods, submit } = useQuoteSettings(userId);

  const { data, onRefresh, refreshing } = useLoadData(() => getCompany(userId));
  const { user } = useUser();

  if (refreshing) return <CircularProgress />;

  if (user?.role !== 'ADMIN' && data && data.canEditQuoteSettings === false && !isBeingEdited) {
    return (
      <StyledForm>
        <Typography variant="h5">Vous ne pouvez pas éditer cette page</Typography>
      </StyledForm>
    );
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = methods;

  return (
    <StyledForm onBlur={handleSubmit(submit)}>
      <Typography variant="h5">PARAMÈTRES DU DEVIS</Typography>
      <StyledLine>
        <Wrapper sx={{ flex: '2 1 30px', alignItems: 'center' }}>
          <Box
            component="div"
            sx={{ width: 'calc(50% - 15px)', display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <SwitchRHF name="activeDeposit" control={control} />
            <TextFieldRHF
              name="deposit"
              control={control}
              TextFieldProps={{
                label: 'Acompte',
                error: Boolean(errors.deposit),
                helperText: errors.deposit?.message,
                disabled: !watch('activeDeposit'),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Wrapper>
        <Wrapper>
          <CenteredContent>
            <SwitchRHF name="activeSoleInstallation" control={control} />
          </CenteredContent>
          <TextFieldRHF
            name="soleInstallation"
            control={control}
            caption="(prix TTC)"
            TextFieldProps={{
              label: 'Forfait pose semelles',
              error: Boolean(errors.soleInstallation),
              helperText: errors.soleInstallation?.message,
              disabled: !watch('activeSoleInstallation'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Wrapper>
        <FormControlLabel
          control={<SwitchRHF name="addInstallationToSolePrice" control={control} />}
          label="Ajouter la pose au tarif de la semelle"
          sx={{
            padding: '0 16px',
            border: '1px solid #F2F4F7',
            borderRadius: '10px',
            flex: '1.5',
            height: '56px',
            margin: 0,
          }}
        />
      </StyledLine>

      <StyledLine>
        <Wrapper>
          <CenteredContent>
            <SwitchRHF name="activeCineraryInstallation" control={control} />
          </CenteredContent>
          <TextFieldRHF
            name="cineraryInstallation"
            control={control}
            caption="(Jusqu’à 100x100 cm, prix TTC)"
            TextFieldProps={{
              label: 'Forfait pose cinéraire',
              error: Boolean(errors.cineraryInstallation),
              helperText: errors.cineraryInstallation?.message,
              disabled: !watch('activeCineraryInstallation'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Wrapper>
        <Wrapper>
          <CenteredContent>
            <SwitchRHF name="activeMonumentInstallation" control={control} />
          </CenteredContent>
          <TextFieldRHF
            name="monumentInstallation"
            control={control}
            caption="(Monument standard prix TTC)"
            TextFieldProps={{
              label: 'Forfait pose monument',
              error: Boolean(errors.monumentInstallation),
              helperText: errors.monumentInstallation?.message,
              disabled: !watch('activeMonumentInstallation'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Wrapper>
        <Wrapper>
          <CenteredContent>
            <SwitchRHF name="activeSpecialInstallation" control={control} />
          </CenteredContent>
          <TextFieldRHF
            name="specialInstallation"
            control={control}
            caption="(Pour les monuments double & columbarium)"
            TextFieldProps={{
              label: 'Forfait pose spécial',
              error: Boolean(errors.specialInstallation),
              helperText: errors.specialInstallation?.message,
              disabled: !watch('activeSpecialInstallation'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Wrapper>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            flex: '1.5 1 30px',
            margin: 0,
          }}
        >
          <FormControlLabel
            control={<SwitchRHF name="addInstallationToMonumentPrice" control={control} />}
            label="Ajouter la pose au tarif du monument"
            sx={{
              border: '1px solid #F2F4F7',
              borderRadius: '10px',
              padding: '0 16px',
              height: '56px',
              margin: 0,
              width: '100%',
            }}
          />
          <Typography variant="caption" align="right">
            Si activé, le montant de la pose sera intégré au total du monument mais ne sera pas
            détaillé
          </Typography>
        </Box>
      </StyledLine>

      <StyledLine>
        <Wrapper>
          <CenteredContent>
            <SwitchRHF name="activeCustomDelivery" control={control} />
          </CenteredContent>
          <TextFieldRHF
            name="customDelivery"
            control={control}
            caption="Si activé, Visiogranits ne se chargera pas du transport, prix TTC. Le coefficient de transport ne s'applique pas à ce tarif"
            TextFieldProps={{
              label: 'Transport personnalisé',
              error: Boolean(errors.customDelivery),
              helperText: errors.customDelivery?.message,
              disabled: !watch('activeCustomDelivery'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                    <Typography>/Tonnes</Typography>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Wrapper>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            margin: 0,
          }}
        >
          <FormControlLabel
            control={<SwitchRHF name="displayDelivery" control={control} />}
            label="Affichage du prix du transport"
            sx={{
              border: '1px solid #F2F4F7',
              borderRadius: '10px',
              padding: '0 16px',
              height: '56px',
              margin: 0,
              width: '100%',
            }}
          />
          <Typography variant="caption" align="right">
            Si activé, le transport est détaillé sur le devis
          </Typography>
        </Box>

        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            width: '100%',
          }}
        >
          <FormControlLabel
            control={<SwitchRHF name="postageDue" control={control} />}
            label="Devis port dû 
            (hors transport)"
            sx={{
              border: '1px solid #F2F4F7',
              borderRadius: '10px',
              height: '56px',
              width: '100%',
              margin: 0,
            }}
          />
          <Typography variant="caption" align="right">
            Si activé, le transport ne se calculera pas
          </Typography>
        </Box>

        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            width: '100%',
          }}
        >
          <FormControlLabel
            control={<SwitchRHF name="simplifiedQuote" control={control} />}
            label="Devis simplifié"
            sx={{
              border: '1px solid #F2F4F7',
              borderRadius: '10px',
              height: '56px',
              width: '100%',
              margin: 0,
            }}
          />
          <Typography variant="caption" align="right">
            Si activé, seul le total est affiché dans le devis
          </Typography>
        </Box>
      </StyledLine>
      <Typography variant="h5">PIED DU DEVIS</Typography>
      <Typography variant="body2">
        Rentrer les informations que vous voulez afficher en bas du devis.
      </Typography>
      <FormLine>
        <TextFieldRHF
          name="footerText"
          control={control}
          TextFieldProps={{
            label: 'Informations à afficher',
            error: Boolean(errors.footerText),
            helperText: errors.footerText?.message,
            multiline: true,
          }}
        />
      </FormLine>
    </StyledForm>
  );
};

export default QuoteSettings;
