import { SvgIcon, SvgIconProps } from '@mui/material';

const FileSettings = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 20">
      <path d="M8.82035 15.49C8.84091 15.33 8.85633 15.17 8.85633 15C8.85633 14.83 8.84091 14.67 8.82035 14.51L9.90495 13.685C10.0026 13.61 10.0283 13.475 9.96663 13.365L8.93858 11.635C8.87689 11.525 8.73811 11.485 8.62502 11.525L7.3451 12.025C7.0778 11.825 6.78995 11.66 6.47639 11.535L6.28106 10.21C6.26564 10.09 6.1577 10 6.02919 10H3.97308C3.84458 10 3.73663 10.09 3.72121 10.21L3.52588 11.535C3.21232 11.66 2.92447 11.83 2.65717 12.025L1.37725 11.525C1.25902 11.48 1.12537 11.525 1.06369 11.635L0.0356383 13.365C-0.0311852 13.475 -0.000343524 13.61 0.0973215 13.685L1.18192 14.51C1.16136 14.67 1.14594 14.835 1.14594 15C1.14594 15.165 1.16136 15.33 1.18192 15.49L0.0973215 16.315C-0.000343524 16.39 -0.0260449 16.525 0.0356383 16.635L1.06369 18.365C1.12537 18.475 1.26416 18.515 1.37725 18.475L2.65717 17.975C2.92447 18.175 3.21232 18.34 3.52588 18.465L3.72121 19.79C3.73663 19.91 3.84458 20 3.97308 20H6.02919C6.1577 20 6.26564 19.91 6.28106 19.79L6.47639 18.465C6.78995 18.34 7.0778 18.17 7.3451 17.975L8.62502 18.475C8.74325 18.52 8.87689 18.475 8.93858 18.365L9.96663 16.635C10.0283 16.525 10.0026 16.39 9.90495 16.315L8.82035 15.49ZM5.00114 16.75C4.00906 16.75 3.20204 15.965 3.20204 15C3.20204 14.035 4.00906 13.25 5.00114 13.25C5.99321 13.25 6.80023 14.035 6.80023 15C6.80023 15.965 5.99321 16.75 5.00114 16.75Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00938 1.9C3.00938 0.855 3.84375 0 4.875 0L11.5969 0C12.0938 0 12.5719 0.1995 12.9187 0.5605L17.4469 5.149C17.8031 5.5005 18 5.985 18 6.4885V17.1C18 18.145 17.1562 19 16.125 19H10.3061L11.2658 17.3851L11.275 17.3687C11.6847 16.638 11.5338 15.6746 10.8185 15.1253L10.6538 15L10.8131 14.8789L10.8185 14.8747C11.5338 14.3254 11.6847 13.362 11.275 12.6313L11.2658 12.6149L10.234 10.8787C9.76137 10.0627 8.81954 9.86512 8.12482 10.1109L8.10188 10.119L7.80117 10.2364L7.76623 9.99945C7.63722 9.07348 6.83494 8.5 6.02919 8.5H3.97308C3.63303 8.5 3.29359 8.60214 3.00513 8.78982L3.00938 1.9ZM11.4375 1.425V5.7C11.4375 6.2225 11.8594 6.65 12.375 6.65H16.5938L11.4375 1.425Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86562 19H6.39752L6.47639 18.465C6.78995 18.34 7.0778 18.17 7.3451 17.975L8.62502 18.475C8.74325 18.52 8.87689 18.475 8.93858 18.365L9.96663 16.635C10.0283 16.525 10.0026 16.39 9.90495 16.315L8.82035 15.49C8.84091 15.33 8.85633 15.17 8.85633 15C8.85633 14.83 8.84091 14.67 8.82035 14.51L9.90495 13.685C10.0026 13.61 10.0283 13.475 9.96663 13.365L8.93858 11.635C8.87689 11.525 8.73811 11.485 8.62502 11.525L7.3451 12.025C7.0778 11.825 6.78995 11.66 6.47639 11.535L6.28106 10.21C6.26564 10.09 6.1577 10 6.02919 10H3.97308C3.84458 10 3.73663 10.09 3.72121 10.21L3.52588 11.535C3.3433 11.6078 3.16944 11.6958 3.00327 11.7952L3 17.1C3 18.145 3.83437 19 4.86562 19ZM3.20204 15C3.20204 15.965 4.00906 16.75 5.00114 16.75C5.99321 16.75 6.80023 15.965 6.80023 15C6.80023 14.035 5.99321 13.25 5.00114 13.25C4.00906 13.25 3.20204 14.035 3.20204 15Z"
      />
    </SvgIcon>
  );
};

export default FileSettings;
