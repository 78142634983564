import { api } from './api';
import { Address } from './user';

export type LeaderGranitCompany = {
  id: string;
  name: string;
  address: Address;
  email: string;
  quoteFooter: string;
  logo: string;
  webSite: string;
  phone: string;
  fax: string;
};

export const getLeaderGranitCompanies = async () => {
  const { data } = await api.get<LeaderGranitCompany[]>(`/leader-granit-companies`);

  return data;
};

export const createLeaderGranitCompanies = async (datas: Omit<LeaderGranitCompany, 'id'>) => {
  return api.post<void>(`/leader-granit-companies`, datas);
};

export const updateLeaderGranitCompanies = async (datas: LeaderGranitCompany) => {
  return api.patch<void>('/leader-granit-companies', datas);
};

export const deleteLeaderGranitCompanies = async (datas: string) => {
  return api.delete<void>(`/leader-granit-companies/${datas}`);
};
