/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable unicorn/no-negated-condition */
import { styled, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { categoriesLabels, CategoryType } from 'src/constants/category';
import { useEnv } from 'src/context/EnvProvider';
import { QuoteStep } from 'src/Screens/project/components/NavBar';
import { Granit } from 'src/services/granits';
import { Piece } from 'src/services/pieces';
import { isAccesory } from 'src/utils/configurator.utils';
import * as THREE from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { useUser } from 'src/context/UserProvider';

import { StateNames, States } from 'src/Screens/Configurator';
import { getDefaultTextureSetup } from 'src/utils/piece';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Catalog } from './Pieces/Catalog';

export type ImportPageType =
  | 'stele'
  | 'tombale'
  | 'foundations'
  | 'platings'
  | 'coffrets'
  | 'sidewalks'
  | 'soles';

type PiecesProps = {
  activePieces: Piece[];
  setActivePieces: (activePieces: any) => void;

  customSteles: any[];
  setCustomSteles: (customSteles: any[]) => void;
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;
  setSelectedPage: (selectedPage: 'pieces' | 'dimension' | 'textures') => void;
  removeOnePiece: (pieceIndexToRemove: number) => void;
  pieces: any[];
  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];
  granits: Granit[];
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  step: QuoteStep;
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
};
export const Pieces = ({
  activePieces,
  setActivePieces,
  customSteles,
  setCustomSteles,
  pieceIndex,
  setSelectedPage,
  setPieceIndex,
  removeOnePiece,
  pieces,
  setCustomPlatings,
  customPlatings,
  granits,
  step,
  setValue,
  setPatterns,
  patterns,
}: PiecesProps) => {
  const { buckets } = useEnv();
  const { user } = useUser();

  const [isButtonOpen, setIsButtonOpen] = useState<boolean>(false);
  const [selectedImportPage, setSelectedImportPage] = useState<CategoryType>();

  const [underAssemblies, setUnderAssemblies] = useState<any[]>([]);
  const [openUnderAssembly, setOpenUnderAssembly] = useState<string | null>(null);

  const [swapPieceIndex, setSwapPieceIndex] = useState<number | undefined>(undefined);

  const assemblyPieces = activePieces.filter((el) => el.underAssembly);
  const simplePieces = activePieces.filter((el) => {
    if (step === 'CUSTOMIZATION' && !isAccesory(el.type) && !el.underAssembly) return true;
    if (step === 'ACCESSORIES' && isAccesory(el.type) && !el.underAssembly) return true;
    return false;
  });

  useEffect(() => {
    const underAssembliesTmp: any[] = [];

    for (const el of assemblyPieces) {
      if (!underAssembliesTmp[el.underAssembly.uuid]) {
        underAssembliesTmp[el.underAssembly.uuid] = {
          name: el.underAssembly.name,
          pieces: [],
        };
      }
      underAssembliesTmp[el.underAssembly.uuid].pieces.push({ ...el });
    }

    setUnderAssemblies(underAssembliesTmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePieces]);

  const selectPieceWithUUID = (UUIDToSelect: string) => {
    for (const [elIndex, el] of activePieces.entries()) {
      if (el.uuid === UUIDToSelect || el.underAssembly?.uuid === UUIDToSelect) {
        setPieceIndex(elIndex);
      }
    }
  };

  const isSelectedPieceWithUUID = (selectedUUID: string) => {
    if (
      activePieces[pieceIndex]?.uuid === selectedUUID ||
      activePieces[pieceIndex]?.underAssembly?.uuid === selectedUUID
    )
      return true;
    return false;
  };

  const importPiece = (selectedPiece: any) => {
    const loader = new THREE.TextureLoader();
    let loadedTexture: any | null = null;
    let availableGranit = null;

    if (selectedPiece.pieceOnUnderAssembly === undefined) {
      const filteredGranits = granits.filter((el) => {
        return selectedPiece?.pieceFabrication
          ?.map((el: any) => el.fabrication)
          .includes(el.fabrication);
      });

      availableGranit = filteredGranits.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      if (availableGranit && availableGranit.length > 0) {
        loadedTexture = loader.load(
          `${buckets?.BUCKET_3D_PIECES || ''}/${availableGranit[0].image}?t=${Date.now()}`,
        );

        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
        loadedTexture.repeat.x = 1;
      }

      const defaultPatterns = getDefaultTextureSetup(selectedPiece, buckets);

      let filteredActivePieces = [...activePieces];

      if (swapPieceIndex !== undefined) {
        const tmpPatterns = { ...patterns };
        delete tmpPatterns[filteredActivePieces[swapPieceIndex].uuid];

        setPatterns(tmpPatterns);
        filteredActivePieces[swapPieceIndex] = {
          ...selectedPiece,
          defaultPatterns,
          uuid: generateUUID(),
          granit: availableGranit.length > 0 ? availableGranit[0] : null,
          texture: loadedTexture,
          fabrications: selectedPiece.pieceFabrication?.map((el: any) => el?.fabrication),
          isBlocked: false,
        };
      } else {
        filteredActivePieces = [
          ...filteredActivePieces,
          {
            ...selectedPiece,
            defaultPatterns,
            uuid: generateUUID(),
            granit: availableGranit.length > 0 ? availableGranit[0] : null,
            texture: loadedTexture,
            fabrications: selectedPiece.pieceFabrication?.map((el: any) => el?.fabrication),
            isBlocked: false,
          },
        ];
      }

      setActivePieces(filteredActivePieces);
      setSelectedImportPage(undefined);
      setSwapPieceIndex(undefined);
    } else {
      availableGranit = granits.filter((el) => {
        return selectedPiece?.UnderAssemblyFabrication?.map((el: any) => el.fabrication).includes(
          el.fabrication,
        );
      });

      if (availableGranit && availableGranit.length > 0) {
        loadedTexture = loader.load(
          `${buckets?.BUCKET_3D_PIECES || ''}/${availableGranit[0].image}?t=${Date.now()}`,
        );
        loadedTexture.offset.x = 0.5;
        loadedTexture.repeat.x = 1;
        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
      }

      const uuid = generateUUID();
      const underAssemblyPieces = selectedPiece.pieceOnUnderAssembly.map((el: any) => {
        const defaultPatterns = getDefaultTextureSetup(el.piece, buckets);

        return {
          uuid: generateUUID(),
          ...el.piece,
          defaultPatterns,
          position: el.position,
          pieceOnUnderAssemblyId: el.id,
          granit: granits.length > 0 ? granits[0] : null,
          texture: loadedTexture,
          isBlocked: false,
          needToBeCentered: true,
          underAssembly: {
            id: selectedPiece.id,
            name: selectedPiece.name,
            fabrications: selectedPiece.UnderAssemblyFabrication?.map((el: any) => el.fabrication),
            uuid,
            modifications: selectedPiece.modificationOnUnderAssembly.map((el: any) => {
              return { ...el.modification, type: el.type };
            }),
          },
        };
      });

      setActivePieces([...activePieces, ...underAssemblyPieces]);
      setSelectedImportPage(undefined);
    }
  };

  const menuLabels = useMemo(() => {
    if (user?.role === 'ADMIN') return categoriesLabels;
    return categoriesLabels.filter((el) => {
      if (step === 'ACCESSORIES' && !isAccesory(el.key)) return false;
      if (step !== 'ACCESSORIES' && isAccesory(el.key)) return false;
      return true;
    });
  }, [step, user?.role]);

  const handleSwapPiece = (uuid: string) => {
    const findIndex = activePieces.findIndex((element) => element.uuid === uuid);

    setSwapPieceIndex(findIndex);
    setSelectedImportPage(activePieces[findIndex].type);
  };

  return (
    <>
      <Container>
        {selectedImportPage ? (
          <>
            <Row>
              <Typography
                variant="body2"
                color="#344054"
                style={{
                  marginLeft: '23px',
                  marginTop: '23px',
                  cursor: 'pointer',
                  zIndex: 2,
                }}
                onClick={() => setSelectedImportPage(undefined)}
              >
                {'Elements >'}
              </Typography>
              <Typography
                variant="body2"
                color="#344054"
                style={{ marginTop: '23px', marginLeft: '4px', fontWeight: 700 }}
              >
                {categoriesLabels.find((el) => el.key === selectedImportPage)?.label}
              </Typography>
            </Row>
            <Catalog
              customSteles={customSteles}
              setCustomSteles={setCustomSteles}
              pieceIndex={pieceIndex}
              setPieceIndex={setPieceIndex}
              activePieces={activePieces}
              setSelectedPage={setSelectedPage}
              categorieKey={selectedImportPage}
              pieces={pieces}
              setCustomPlatings={setCustomPlatings}
              customPlatings={customPlatings}
              granits={granits}
              importPiece={importPiece}
              step={step}
            />
          </>
        ) : (
          <PiecesContainer>
            {Object.keys(underAssemblies).map((element) => {
              if (step === 'ACCESSORIES') return;

              return (
                <PieceContainer
                  isSelected={isSelectedPieceWithUUID(element)}
                  backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/`}
                  onClick={() => {
                    selectPieceWithUUID(element);

                    if (openUnderAssembly === element) {
                      setOpenUnderAssembly(null);
                    } else {
                      setOpenUnderAssembly(element);
                    }
                  }}
                  key={element}
                >
                  <DeletePiece
                    onClick={(e) => {
                      removeOnePiece(
                        activePieces.map((el) => el.underAssembly?.uuid).indexOf(element),
                      );
                      setValue('modelId', -1);
                      e.stopPropagation();
                    }}
                  />
                  <BlockPiece
                    isBlocked={
                      activePieces.find((el) => el.underAssembly?.uuid === element)?.isBlocked ??
                      false
                    }
                    onClick={() => {
                      setActivePieces(
                        activePieces.map((activePiece) => {
                          return {
                            ...activePiece,
                            isBlocked:
                              activePiece.underAssembly?.uuid === element
                                ? !activePiece.isBlocked
                                : activePiece.isBlocked,
                          };
                        }),
                      );
                    }}
                  />
                  <PieceNameContainer>
                    <Typography
                      variant="body2"
                      color="#344054"
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      {underAssemblies[element].name}
                    </Typography>
                  </PieceNameContainer>
                </PieceContainer>
              );
            })}
            {simplePieces?.map((el: any, elIndex: number) => {
              if (step === 'ACCESSORIES' && !isAccesory(el.type)) return;
              return (
                <PieceContainer
                  isSelected={isSelectedPieceWithUUID(el.uuid)}
                  backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/${el.pictureUrl as string}`}
                  onClick={() => {
                    selectPieceWithUUID(el.uuid);
                  }}
                  key={elIndex}
                >
                  <BlockPiece
                    isBlocked={el.isBlocked}
                    onClick={() => {
                      setActivePieces(
                        activePieces.map((activePiece) => {
                          return {
                            ...activePiece,
                            isBlocked:
                              el.uuid === activePiece.uuid
                                ? !activePiece.isBlocked
                                : activePiece.isBlocked,
                          };
                        }),
                      );
                    }}
                  />

                  <SwapPiece
                    onClick={() => handleSwapPiece(el.uuid)}
                    isSelected={isSelectedPieceWithUUID(el.uuid)}
                  >
                    <SwapHorizIcon />
                  </SwapPiece>
                  <DeletePiece
                    onClick={(e) => {
                      const findIndex = activePieces.findIndex(
                        (element) => element.uuid === el.uuid,
                      );

                      removeOnePiece(findIndex === -1 ? activePieces.findIndex(el) : findIndex);
                      setValue('modelId', -1);

                      e.stopPropagation();
                    }}
                  />

                  <PieceNameContainer>
                    <Typography
                      variant="body2"
                      color="#344054"
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      {el.name}
                    </Typography>
                  </PieceNameContainer>
                </PieceContainer>
              );
            })}
            {customSteles?.map((el: any, elIndex: number) => {
              if (step === 'ACCESSORIES') return;

              return (
                <PieceContainer
                  isSelected={pieceIndex === activePieces.length + elIndex}
                  backgroundImage={`${buckets?.BUCKET_3D_PIECES || ''}/${el.pictureUrl as string}`}
                  onClick={() => setPieceIndex(activePieces.length + elIndex)}
                  key={elIndex}
                >
                  <DeletePiece
                    onClick={(e) => {
                      removeOnePiece(activePieces.length + elIndex);
                      setValue('modelId', -1);
                      setPieceIndex(activePieces.length - 1);
                      e.stopPropagation();
                    }}
                  />
                  <BlockPiece
                    isBlocked={el?.isBlocked ?? false}
                    onClick={() => {
                      setCustomSteles(
                        customSteles.map((customStele) => {
                          return {
                            ...customStele,
                            isBlocked:
                              el.id === customStele.id
                                ? !customStele.isBlocked
                                : customStele.isBlocked,
                          };
                        }),
                      );
                    }}
                  />
                  <PieceNameContainer>
                    <Typography
                      variant="body2"
                      color="#344054"
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      Stèle {elIndex}
                    </Typography>
                  </PieceNameContainer>
                </PieceContainer>
              );
            })}

            {customPlatings.length > 0 && step !== 'ACCESSORIES' ? (
              <PieceContainer
                isSelected={
                  pieceIndex >= activePieces.length + customSteles.length &&
                  pieceIndex < activePieces.length + customSteles.length + customPlatings.length
                }
                backgroundImage=""
                onClick={() => setPieceIndex(activePieces.length + customSteles.length)}
              >
                <DeletePiece
                  onClick={(e) => {
                    setCustomPlatings([]);
                    setPieceIndex(activePieces.length - 1);
                    e.stopPropagation();
                  }}
                />
                <BlockPiece
                  isBlocked={customPlatings[0] ? customPlatings[0].isBlocked : false}
                  onClick={() => {
                    setCustomPlatings(
                      customPlatings.map((customPlating) => {
                        return {
                          ...customPlating,
                          isBlocked: !customPlating.isBlocked,
                        };
                      }),
                    );
                  }}
                />
                <PieceNameContainer>
                  <Typography
                    variant="body2"
                    color="#344054"
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}
                  >
                    Placage
                  </Typography>
                </PieceNameContainer>
              </PieceContainer>
            ) : null}
          </PiecesContainer>
        )}
      </Container>
      <PanelButtonContainer isOpen={isButtonOpen}>
        <PanelChoices
          isOpen={isButtonOpen}
          style={{
            flexDirection: menuLabels.length > 10 ? 'row' : 'column',
            flexWrap: 'wrap',
            width: menuLabels.length > 10 ? '100%' : '159px',
          }}
        >
          {[...menuLabels, { key: 'ALL', label: 'Toutes les pièces' }]?.map((el) => {
            return (
              <PanelChoice
                style={{ width: menuLabels.length > 10 ? '33%' : '99%' }}
                onClick={() => {
                  setSelectedImportPage(el.key);
                  setIsButtonOpen(false);
                }}
                key={el.key}
              >
                <Typography
                  key={el.key}
                  variant="body2"
                  color="#344054"
                  style={{
                    fontSize: '12px',
                    userSelect: 'none',
                  }}
                >
                  {el.label}
                </Typography>
              </PanelChoice>
            );
          })}
        </PanelChoices>
        <PanelButton isOpen={isButtonOpen} onClick={() => setIsButtonOpen(!isButtonOpen)}>
          <Typography
            variant="body2"
            color={isButtonOpen ? 'black' : 'white'}
            style={{
              fontSize: '12px',
            }}
          >
            {isButtonOpen ? 'Annuler' : 'Ajouter un élément'}
          </Typography>
        </PanelButton>
      </PanelButtonContainer>
    </>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',

  top: 0,
});

const PiecesContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
  padding: '22px',

  maxHeight: window.innerHeight - 294,
  overflowY: 'auto',
});

const PieceContainer = styled('div')(
  ({ backgroundImage, isSelected }: { backgroundImage: string; isSelected: boolean }) => ({
    position: 'relative',
    width: '140px',
    height: '140px',

    backgroundColor: '#F2F4F7',
    borderRadius: '10px',
    border: `${isSelected ? 1 : 0}px solid #BC9A67`,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    backgroundImage: `url("${backgroundImage}");`,
    backgroundSize: '140px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    cursor: 'pointer',
  }),
);

const PieceNameContainer = styled('div')({
  width: '100%',
  backgroundColor: '#F2F4F7',

  padding: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
});

const PanelButtonContainer = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',

  padding: '27px',

  backgroundColor: `rgb(0, 0, 0, ${isOpen ? 0.4 : 0})`,
  transition: 'background-color 0.5s ease',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  top: '0px',

  pointerEvents: 'none',
}));

const PanelButton = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '159px',
  height: '32px',

  backgroundColor: isOpen ? 'white' : '#BC9A67',
  transition: 'background-color 0.5s ease',

  borderRadius: '28px',
  padding: '8px 16px',

  cursor: 'pointer',
  zIndex: 2,
  pointerEvents: 'all',
}));

const PanelChoices = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  display: isOpen ? 'flex' : 'none',

  justifyContent: 'center',
  alignItems: 'flex-start',

  borderRadius: '10px',
  backgroundColor: 'white',

  marginBottom: '12px',

  '& > *:not(:last-child)': {
    borderBottom: '1px solid #98A2B3',
  },

  zIndex: 2,
  pointerEvents: 'all',
}));

const PanelChoice = styled('div')({
  padding: '10px 16px',

  cursor: 'pointer',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const DeletePiece = styled('div')({
  position: 'absolute',
  width: '20px',
  height: '20px',
  zIndex: 2,
  top: '0px',
  right: '0px',

  margin: '7px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',

  padding: '7px',

  backgroundImage: `url("/icons/cross.svg");`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const BlockPiece = styled('div')(({ isBlocked }: { isBlocked: boolean }) => ({
  position: 'absolute',
  width: '20px',
  height: '20px',
  zIndex: 2,
  top: '0px',
  left: '0px',

  margin: '7px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',

  padding: '7px',

  backgroundImage: `url("/icons/${isBlocked ? 'lock.svg' : 'lock-unlock.svg'}");`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const SwapPiece = styled('div')(({ isSelected }: { isSelected: boolean }) => ({
  position: 'absolute',
  width: '20px',
  height: '20px',
  zIndex: 0,
  top: '-20px',
  left: '62px',
  transform: 'translateX(-50%)',

  margin: '7px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',

  padding: '12px',
  backgroundColor: '#F2F4F7',
  borderRadius: '100%',
  border: `1px solid ${isSelected ? '#BC9A67' : '#F2F4F7'}`,
}));
