import { Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues } from 'react-hook-form';

import { IFieldRHF } from './FormComponents.type';

export const TextFieldRHF = <T extends FieldValues>({
  name,
  control,
  TextFieldProps,
  caption,
}: IFieldRHF<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <Stack
        alignItems="end"
        sx={{
          width: '100%',
          ...TextFieldProps?.sx,
        }}
      >
        <TextField
          {...field}
          {...TextFieldProps}
          inputRef={ref}
          sx={{ width: '100%', ...TextFieldProps?.sx }}
        />
        {caption && (
          <Typography variant="caption" align="right">
            {caption}
          </Typography>
        )}
      </Stack>
    )}
  />
);
