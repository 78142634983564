import { styled } from '@mui/material';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;

  children: React.ReactNode;
};

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  return (
    <>
      {isOpen ? (
        <ModalContainer onClick={() => onClose()}>
          <Body>
            <ChildParent onClick={(e) => e.stopPropagation()}>{children}</ChildParent>
          </Body>
        </ModalContainer>
      ) : null}
    </>
  );
};

export const ModalContainer = styled('div')({
  backgroundColor: 'rgba(0,0,0,0.2)',
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Body = styled('div')({
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  padding: '50px',
});

const ChildParent = styled('div')({
  cursor: 'default',
});
