import { CategoryType } from 'src/constants/category';
import { getPieces } from 'src/services/pieces';

import useLoadDataList from './useLoadDataList';

export const usePieces = (categorieKey: CategoryType | undefined) => {
  const { data: pieces, onRefresh: refreshPieces } = useLoadDataList(
    () => getPieces(categorieKey),
    [categorieKey],
  );

  return { pieces, refreshPieces };
};
