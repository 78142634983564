import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ClientInformationsFormSchema,
  clientInformationsFormSchema,
} from 'src/hook/useConfiguratorNavigation';
import { emptyAddress } from 'src/utils/address.utils';

import { Assemblies } from '../../components/3d/Assemblies';
import { useUser } from '../../context/UserProvider';
import useLoadDataList from '../../hook/useLoadDataList';
import paths from '../../navigation/paths';
import { getUserAssemblies } from '../../services/assemblies';
import { createQuote, updateAssemblyQuote } from '../../services/quotes';
import { handleMutation } from '../../utils/api.utils';
import ClientInformationsForm from './components/ClientInformationsForm';
import NavBar from './components/NavBar';

const Container = styled(Box)({
  width: '100%',
  height: '100vh',
  overflowY: 'auto',
  backgroundColor: '#F5F5F5',
  display: 'flex',
  flexDirection: 'column',
});

const Wrapper = styled(Box)({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
});

const SelectMonument = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<ClientInformationsFormSchema>({
    defaultValues: {
      clientFirstName: '',
      clientLastName: '',
      clientAddress: emptyAddress,
      cemetary: '',
      concessionName: '',
      concessionHeight: 0,
      concessionWidth: 0,
      useDimensions: false,
      familyRef: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(clientInformationsFormSchema),
  });

  const [initForm, setIniForm] = useState(true);
  const navigate = useNavigate();
  const { user, homePath } = useUser();

  const { assemblyId: currentAssembly } = useParams();

  const { data: assemblies, onRefresh } = useLoadDataList(
    () => getUserAssemblies(user?.id ?? ''),
    [user],
  );

  useEffect(() => {
    if (currentAssembly) setIniForm(false);
  }, [currentAssembly]);

  const submit = useCallback(
    (assemblyId: number) => {
      const {
        home: {
          project: { root, configurator },
        },
      } = paths;

      handleMutation(
        (datas) =>
          currentAssembly
            ? updateAssemblyQuote({
                ...datas,
                step: 'CUSTOMIZATION',
                oldAssemblyId: Number(currentAssembly),
              })
            : createQuote({ ...datas, step: 'CUSTOMIZATION' }, user?.id ?? ''),
        { ...getValues(), assemblyId },
        () => {},
        (data) => navigate(`${homePath}/${root}/${configurator.root}/${data}`),
      );
    },
    [currentAssembly, getValues, homePath, navigate, user?.id],
  );

  return (
    <Container>
      <NavBar currStep="CATALOG" />
      {initForm ? (
        <Wrapper>
          <ClientInformationsForm
            closeForm={() => setIniForm(false)}
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            reset={reset}
          />
        </Wrapper>
      ) : (
        <Assemblies onClick={submit} assemblies={assemblies} onRefresh={onRefresh} />
      )}
    </Container>
  );
};

export default SelectMonument;
