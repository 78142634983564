/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useLoadData from 'src/hook/useLoadData';
import { getAdditionalsPriceOnCustomStele } from 'src/services/customStele';
import { changeSteleShape, getSteleExtrudeSettings } from 'src/utils/stele.utils';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

export type CustomSteleType = {
  id: string;
  boxLeftHeight: number;
  boxRightHeight: number;
  boxHeight: number;
  boxUpWidth: number;
  boxDownWidth: number;
  headIndex: number;
  baseIndex: number;
  depth: number;
  shape: any;
  extrudeSettings: any;
  position: Vector3;
};

type SteleProps = {
  customSteles: any[];
  setCustomSteles: (customSteles: any[]) => void;
  steleIndex: number;
  pieceIndex: number;
};

export const CustomSteles = ({
  customSteles,
  setCustomSteles,
  steleIndex,
  pieceIndex,
}: SteleProps) => {
  const mesh = customSteles[steleIndex];

  const [error, setError] = useState<string>();
  const [boxLeftHeight, setBoxLeftHeight] = useState<number>(mesh?.boxLeftHeight ?? 0.7);
  const [boxRightHeight, setBoxRightHeight] = useState<number>(mesh?.boxRightHeight ?? 0.7);
  const [boxHeight, setBoxHeight] = useState<number>(mesh?.boxHeight ?? 0.8);
  const [boxUpWidth, setBoxUpWidth] = useState<number>(mesh?.boxUpWidth ?? 0.9);
  const [boxDownWidth, setBoxDownWidth] = useState<number>(mesh?.boxDownWidth ?? 0.8);
  const [headIndex, setHeadIndex] = useState<any>(mesh?.headIndex ?? 0);
  const [baseIndex, setBaseIndex] = useState<any>(mesh?.baseIndex ?? 0);
  const [depth, setDepth] = useState<any>(mesh?.depth ?? 0.05);

  const { data: additionalPriceForCustomStele, refreshing } = useLoadData(
    () => getAdditionalsPriceOnCustomStele(headIndex, baseIndex),
    [headIndex, baseIndex],
  );

  const checkMinMax = (value: number) => {
    if (value < Number.parseFloat(customSteles[steleIndex].granit?.minLength) / 100)
      return Number.parseFloat(customSteles[steleIndex].granit?.minLength) / 100;
    if (value > Number.parseFloat(customSteles[steleIndex].granit?.maxLength) / 100) {
      setError(
        `Attention, votre pièce dépasse la taille maximale de ce granit (${Number.parseFloat(
          customSteles[steleIndex].granit?.maxLength,
        )}). Elle sera coupée.`,
      );
    }
    return value;
  };

  useEffect(() => {
    if (mesh) {
      setBoxLeftHeight(mesh.boxLeftHeight);
      setBoxRightHeight(mesh.boxRightHeight);
      setBoxHeight(mesh.boxHeight);
      setBoxUpWidth(mesh.boxUpWidth);
      setBoxDownWidth(mesh.boxDownWidth);
      setHeadIndex(mesh.headIndex);
      setBaseIndex(mesh.baseIndex);
      setDepth(mesh.depth);
    }
  }, [steleIndex, customSteles, mesh]);

  const extrudeSettings = getSteleExtrudeSettings(depth);

  const nbrBase = 3;
  const nbrHead = 10;
  const shape = new THREE.Shape();

  changeSteleShape(
    shape,
    boxLeftHeight,
    boxRightHeight,
    boxHeight,
    boxUpWidth,
    boxDownWidth,
    headIndex,
    baseIndex,
  );

  const handleSubmit = (event?: any) => {
    if (event) event.preventDefault();
    setError(undefined);

    if (customSteles.length > 0) {
      const customMeshTmp = customSteles.map((el, index) => {
        if (index === steleIndex) {
          return {
            ...el,
            boxLeftHeight: checkMinMax(boxLeftHeight),
            boxRightHeight: checkMinMax(boxRightHeight),
            boxHeight: checkMinMax(boxHeight),
            boxUpWidth: checkMinMax(boxUpWidth),
            boxDownWidth: checkMinMax(boxDownWidth),
            headIndex,
            baseIndex,
            depth,
            shape,
            extrudeSettings,
            additionalPriceForCustomStele,
          };
        }
        return el;
      });
      setCustomSteles(customMeshTmp);
    } else {
      setCustomSteles([
        {
          id: generateUUID(),
          boxLeftHeight,
          boxRightHeight,
          boxHeight,
          boxUpWidth,
          boxDownWidth,
          headIndex,
          baseIndex,
          depth,
          shape,
          extrudeSettings,
          position: new Vector3(0, 0, 0),
          additionalPriceForCustomStele,
        },
      ]);
    }
  };

  useEffect(() => {
    if (customSteles.length > 0 && additionalPriceForCustomStele) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseIndex, headIndex, additionalPriceForCustomStele]);

  const checkParsedValue = (value: string) => {
    const parsedFloat = Number.parseFloat(value);
    if (Number.isNaN(parsedFloat)) {
      return 0;
    }
    return parsedFloat;
  };

  return (
    <Container>
      <Row>
        <Typography
          variant="body2"
          color="#344054"
          style={{
            cursor: 'pointer',
            zIndex: 2,
          }}
        >
          Dimension
        </Typography>
        <Typography
          variant="body2"
          color="#344054"
          style={{ marginLeft: '4px', marginBottom: '10px', fontWeight: 700 }}
        >
          Stèle
        </Typography>
      </Row>

      {error ? (
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            paddingTop: '16px',
          }}
          color="error"
        >
          {error}
        </Typography>
      ) : null}

      <form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'space-between', gap: '24px' }}>
          <Column style={{ width: '100%' }}>
            <div>
              <Label>Haut de la stèle</Label>
              <Select
                name="head"
                id="heads"
                value={headIndex}
                onChange={(e) => {
                  setHeadIndex(Number.parseInt(e.target.value, 10));
                }}
              >
                {Array.from({ length: nbrHead })
                  .map((_, i) => i + 1)
                  .map((head, index) => {
                    return (
                      <option key={index} selected={index === headIndex} value={index}>
                        Haut {index + 1}
                      </option>
                    );
                  })}
              </Select>
            </div>
          </Column>
          <Column style={{ width: '100%' }}>
            <div>
              <Label>Bas de la stèle</Label>
              <Select
                name="base"
                id="bases"
                onChange={(e) => {
                  setBaseIndex(Number.parseInt(e.target.value, 10));
                }}
              >
                {Array.from({ length: nbrBase })
                  .map((_, i) => i + 1)
                  .map((base, index) => {
                    return (
                      <option key={index} selected={index === baseIndex} value={index}>
                        Bas {index + 1}
                      </option>
                    );
                  })}
              </Select>
            </div>
          </Column>
        </Row>

        <Row style={{ justifyContent: 'space-between', alignItems: 'flex-end', gap: '16px' }}>
          <Column style={{ width: '33%' }}>
            <Label>Hauteur</Label>
            <Input
              type="text"
              name="height"
              onChange={(e) => {
                setBoxHeight(checkParsedValue(e.target.value) / 100);
              }}
              value={(boxHeight * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
          <Column style={{ width: '33%' }}>
            <Label>Hauteur coté droit</Label>
            <Input
              type="text"
              name="rightHeight"
              onChange={(e) => {
                setBoxRightHeight(checkParsedValue(e.target.value) / 100);
              }}
              value={(boxRightHeight * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
          <Column style={{ width: '33%' }}>
            <Label>Hauteur coté gauche</Label>
            <Input
              type="text"
              name="leftHeight"
              onChange={(e) => {
                setBoxLeftHeight(checkParsedValue(e.target.value) / 100);
              }}
              value={(boxLeftHeight * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
        </Row>

        <Row style={{ justifyContent: 'space-between', alignItems: 'flex-end', gap: '16px' }}>
          <Column style={{ width: '33%' }}>
            <Label>Largeur</Label>
            <Input
              type="text"
              name="topWidth"
              onChange={(e) => {
                setBoxUpWidth(checkParsedValue(e.target.value) / 100);
              }}
              value={(boxUpWidth * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
          <Column style={{ width: '33%' }}>
            <Label>Largeur pied</Label>
            <Input
              type="text"
              name="bottomWidth"
              onChange={(e) => {
                setBoxDownWidth(checkParsedValue(e.target.value) / 100);
              }}
              value={(boxDownWidth * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
          <Column style={{ width: '33%' }}>
            <Label>Épaisseur</Label>
            <Input
              type="text"
              name="depth"
              onChange={(e) => {
                setDepth(checkParsedValue(e.target.value) / 100);
              }}
              value={(depth * 100).toFixed(0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSubmit();
              }}
              onBlur={() => {
                handleSubmit();
              }}
            />
          </Column>
        </Row>
      </form>
    </Container>
  );
};

const Container = styled('div')({
  height: '100%',
  width: '100%',
});

const Input = styled('input')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginTop: '30px',
  marginBottom: '8px',

  color: 'rgba(0, 0, 0, 0.5)',
});
