import { createTheme, Shadows } from '@mui/material/styles';

// import type {} from '@mui/x-data-grid-premium/themeAugmentation';
import theme from './theme';

const muiTheme = createTheme({
  palette: {
    common: {
      black: theme.colors.black,
    },
    primary: {
      main: theme.colors.turquoise,
      dark: theme.colors.darkTurquoise,
      light: theme.colors.ligthTurquoise,
      contrastText: theme.colors.white,
    },
    secondary: { main: theme.colors.gold, contrastText: theme.colors.white },
    background: {
      default: theme.backgroundColors.grey,
    },
  },
  shadows: [
    'none',
    theme.shadows.common,
    ...Array.from<string>({ length: 23 }).fill('none'),
  ] as Shadows,
  typography: {
    fontFamily: theme.typography.fontFamily.default,
    body1: {
      fontSize: theme.typography.size.medium,
      lineHeight: theme.typography.lineHeight.medium,
      letterSpacing: theme.typography.letterSpacing.xsmall,
      fontWeight: 400,
    },
    body2: {
      fontSize: theme.typography.size.small,
      lineHeight: theme.typography.lineHeight.small,
      letterSpacing: theme.typography.letterSpacing.xsmall,
      fontWeight: 500,
    },
    caption: {
      fontSize: theme.typography.size.xsmall,
      lineHeight: theme.typography.lineHeight.small,
      letterSpacing: theme.typography.letterSpacing.xsmall,
      color: theme.typography.colors.light,
    },
    h1: {
      fontSize: theme.typography.size.xxxlarge,
      lineHeight: theme.typography.lineHeight.medium,
      fontWeight: 700,
    },
    h2: {
      fontSize: theme.typography.size.xlarge,
      lineHeight: theme.typography.lineHeight.small,
      fontWeight: 700,
    },
    h3: {
      fontSize: theme.typography.size.xsmall,
      lineHeight: theme.typography.lineHeight.small,
      color: theme.colors.turquoise,
      fontWeight: 700,
    },
    h4: {
      fontSize: theme.typography.size.large,
      lineHeight: theme.typography.lineHeight.small,
      letterSpacing: theme.typography.letterSpacing.small,
      fontWeight: 600,
    },
    h5: {
      fontSize: theme.typography.size.medium,
      lineHeight: theme.typography.lineHeight.small,
      color: theme.colors.turquoise,
      fontWeight: 600,
    },
    h6: {
      fontSize: theme.typography.size.medium,
      lineHeight: theme.typography.lineHeight.medium,
      fontWeight: 500,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: theme.typography.size.xsmall,
          lineHeight: theme.typography.size.large,
        },
        icon: {
          '& .MuiSvgIcon-root': {
            height: 'auto',
          },
        },
      },
    },
  },
});

export default muiTheme;
