import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import PasswordChecklist from '../../../components/authentication/PasswordChecklist';
import { ModalTitle } from '../../../components/CenteredModal';
import { LowerCaseButton, StyledForm } from '../../../components/common';
import { TextFieldRHF } from '../../../components/forms/TextFieldRhf';
import useToggle from '../../../hook/useToggle';
import { updatePassword } from '../../../services/user';
import { handleMutation } from '../../../utils/api.utils';
import { isPassword } from '../../../utils/user.utils';

const schemaEditPasswordForm = z
  .object({
    oldPassword: z.string().trim().min(1),
    password: z
      .string()
      .trim()
      .min(1)
      .refine((val) => isPassword(val)),
    confirmPassword: z.string().trim().min(1),
  })
  .superRefine(({ confirmPassword, password, oldPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Ces mots de passe ne correspondent pas. Veuillez réessayer.',
        path: ['confirmPassword'],
      });
    }
    if (oldPassword === password) {
      ctx.addIssue({
        code: 'custom',
        message: "Le nouveau mot de passe doit être différent de l'ancien",
        path: ['password'],
      });
    }
  });
type SchemaEditPasswordForm = z.infer<typeof schemaEditPasswordForm>;

const EditPasswordForm = ({ handleClose }: { handleClose: () => void }) => {
  const methods = useForm<SchemaEditPasswordForm>({
    defaultValues: {
      confirmPassword: '',
      oldPassword: '',
      password: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(schemaEditPasswordForm),
  });

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = methods;

  const [showPassword, handleClickShowPassword] = useToggle();

  const [showConfirmPassword, handleClickShowConfirmPassword] = useToggle();

  const [showOldPassword, handleClickShowOldPassword] = useToggle();

  const submit: SubmitHandler<SchemaEditPasswordForm> = (data) => {
    handleMutation(
      updatePassword,
      {
        oldPassword: data.oldPassword,
        newPassword: data.password,
      },
      () => {
        handleClose();
        toast.success('Mot de passe mis à jour avec succès');
      },
    );
  };

  return (
    <>
      <ModalTitle>
        <Typography variant="h5">MODIFIER LE MOT DE PASSE</Typography>
      </ModalTitle>
      <StyledForm onSubmit={handleSubmit(submit)}>
        <TextFieldRHF
          name="oldPassword"
          control={control}
          TextFieldProps={{
            label: 'Ancien mot de passe',
            error: Boolean(errors.oldPassword),
            helperText: errors.oldPassword?.message,
            type: showOldPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <TextFieldRHF
          name="password"
          control={control}
          TextFieldProps={{
            label: 'Mot de passe',
            error: Boolean(errors.password),
            helperText: errors.password?.message,
            type: showPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <TextFieldRHF
          name="confirmPassword"
          control={control}
          TextFieldProps={{
            label: 'Confirmer le mot de passe',
            error: Boolean(errors.confirmPassword),
            helperText: errors.confirmPassword?.message,
            type: showConfirmPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <PasswordChecklist password={watch('password')} />
        <LowerCaseButton variant="contained" type="submit">
          CONFIRMER
        </LowerCaseButton>
      </StyledForm>
    </>
  );
};

export default EditPasswordForm;
