import { User } from '../services/user';

export const isPassword = (s: string) => {
  const hasSpecial = /[!-/:-@[-`{-~]/.test(s);
  const hasDigit = /\d/.test(s);
  const hasUpper = /[A-Z]/.test(s);
  const hasLower = /[a-z]/.test(s);

  return s.length >= 8 && hasSpecial && hasDigit && hasLower && hasUpper;
};

export const isAdmin = (user?: User | null) => {
  return !!user && user.role === 'ADMIN';
};

export const isHeadOffice = (user?: User | null) => {
  return !!user && user.role === 'HEAD_OFFICE';
};

export const isElevatedRole = (user?: User | null) => {
  return isAdmin(user) || isHeadOffice(user);
};
