import { Edit } from '@mui/icons-material';
import { Button, Checkbox, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { CenteredModal, ModalTitle } from 'src/components/CenteredModal';
import { LowerCaseButton } from 'src/components/common';
import AdditionalServices from 'src/Screens/mySpace/record/additionalServices/AdditionalServices';
import Coefficients from 'src/Screens/mySpace/record/coefficients/Coefficients';
import QuoteSettings from 'src/Screens/mySpace/record/quoteSettings/QuoteSettings';
import { Agency, updateCanEdit } from 'src/services/user';
import { handleMutation } from 'src/utils/api.utils';

type EditAgenciesProps = {
  openedAgency: Agency | undefined;
  open: boolean;
  handleClose: () => void;
  handleEditAgency: (agency: Agency) => void;
};

export const EditAgencies = ({
  openedAgency,
  open,
  handleClose,
  handleEditAgency,
}: EditAgenciesProps) => {
  const [editingPageName, setEditingPageName] = useState<
    'quote' | 'coefficients' | 'additionalServices'
  >();

  if (!openedAgency) return null;

  const getWindowSize = () => {
    if (editingPageName === 'quote') return { width: '80%', height: '80%' };
    if (editingPageName === 'coefficients') return { width: '80%', height: '80%' };
    if (editingPageName === 'additionalServices') return { width: '80%', height: '80%' };
    return { width: '50%', height: '70%' };
  };

  const handleChangeEditOption = async (
    option:
      | 'canEditQuoteSettings'
      | 'canEditCoeffQuoteSettings'
      | 'canEditAdditionalService'
      | 'canSeePurchasePrice'
      | 'isOnMaintenance',
    value: boolean,
  ) => {
    await updateCanEdit(openedAgency.company.user.id, option, value);
    handleEditAgency({
      ...openedAgency,
      company: {
        ...openedAgency.company,
        canEditQuoteSettings:
          option === 'canEditQuoteSettings' ? value : openedAgency.company.canEditQuoteSettings,
        canEditAdditionalService:
          option === 'canEditAdditionalService'
            ? value
            : openedAgency.company.canEditAdditionalService,
        canEditCoeffQuoteSettings:
          option === 'canEditCoeffQuoteSettings'
            ? value
            : openedAgency.company.canEditCoeffQuoteSettings,
        canSeePurchasePrice:
          option === 'canSeePurchasePrice' ? value : openedAgency.company.canSeePurchasePrice,
        isOnMaintenance:
          option === 'isOnMaintenance' ? value : openedAgency.company.isOnMaintenance,
      },
    });
  };

  return (
    <CenteredModal
      open={open}
      handleClose={() => {
        setEditingPageName(undefined);
        handleClose();
      }}
      sx={{ width: getWindowSize().width, height: getWindowSize().height, overflowY: 'scroll' }}
    >
      <Container>
        <ModalTitle sx={{ gap: 4 }}>
          <Typography variant="h5" sx={{}} onClick={() => {}}>
            Editer les paramètres
          </Typography>
        </ModalTitle>
        {editingPageName ? (
          <Footer>
            <LowerCaseButton
              variant="contained"
              type="submit"
              color="inherit"
              onClick={() => setEditingPageName(undefined)}
            >
              Revenir en arrière
            </LowerCaseButton>
          </Footer>
        ) : null}
        {editingPageName ? (
          <Body>
            {editingPageName === 'quote' ? (
              <QuoteSettings userId={openedAgency.company.user.id} isBeingEdited />
            ) : null}
            {editingPageName === 'coefficients' ? (
              <Coefficients userId={openedAgency.company.user.id} isBeingEdited />
            ) : null}
            {editingPageName === 'additionalServices' ? (
              <AdditionalServices userId={openedAgency.company.user.id} isBeingEdited />
            ) : null}
          </Body>
        ) : (
          <Body>
            <BodySection>
              <Typography variant="h4" onClick={() => {}}>
                Paramètres de maintenance
              </Typography>
              <Row>
                <Typography variant="body1" onClick={() => {}}>
                  Mettre l&apos;agence en maintenance
                </Typography>
                <StyledCheckBox
                  checked={openedAgency.company.isOnMaintenance}
                  onChange={(e) => {
                    handleChangeEditOption('isOnMaintenance', e.target.checked);
                  }}
                />
              </Row>
            </BodySection>
            <BodySection>
              <Row>
                <Typography variant="body1" onClick={() => {}}>
                  Autoriser l&apos;agence à consulter les prix d&apos;achat
                </Typography>
                <StyledCheckBox
                  checked={openedAgency.company.canSeePurchasePrice}
                  onChange={(e) => {
                    handleChangeEditOption('canSeePurchasePrice', e.target.checked);
                  }}
                />
              </Row>
            </BodySection>
            <BodySection>
              <Row>
                <Typography variant="h4" onClick={() => {}}>
                  Paramètres de devis
                </Typography>
                <Row>
                  <Typography variant="body1" onClick={() => {}}>
                    Éditable par l&apos;agence
                  </Typography>
                  <StyledCheckBox
                    checked={openedAgency.company.canEditQuoteSettings}
                    onChange={(e) => {
                      handleChangeEditOption('canEditQuoteSettings', e.target.checked);
                    }}
                  />
                </Row>
              </Row>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                color="secondary"
                sx={{ borderRadius: '50px', width: 'fit-content', textTransform: 'capitalize' }}
                onClick={() => setEditingPageName('quote')}
              >
                Editer
              </Button>
            </BodySection>
            <BodySection>
              <Row>
                <Typography variant="h4" onClick={() => {}}>
                  Paramètres des coefficients
                </Typography>
                <Row>
                  <Typography variant="body1" onClick={() => {}}>
                    Éditable par l&apos;agence
                  </Typography>
                  <StyledCheckBox
                    checked={openedAgency.company.canEditCoeffQuoteSettings}
                    onChange={(e) => {
                      handleChangeEditOption('canEditCoeffQuoteSettings', e.target.checked);
                    }}
                  />
                </Row>
              </Row>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                color="secondary"
                sx={{ borderRadius: '50px', width: 'fit-content', textTransform: 'capitalize' }}
                onClick={() => setEditingPageName('coefficients')}
              >
                Editer
              </Button>
            </BodySection>
            <BodySection>
              <Row>
                <Typography variant="h4" onClick={() => {}}>
                  Services supplémentaires
                </Typography>
                <Row>
                  <Typography variant="body1" onClick={() => {}}>
                    Éditable par l&apos;agence
                  </Typography>
                  <StyledCheckBox
                    checked={openedAgency.company.canEditAdditionalService}
                    onChange={(e) => {
                      handleChangeEditOption('canEditAdditionalService', e.target.checked);
                    }}
                  />
                </Row>
              </Row>

              <Button
                variant="outlined"
                startIcon={<Edit />}
                color="secondary"
                sx={{ borderRadius: '50px', width: 'fit-content', textTransform: 'capitalize' }}
                onClick={() => setEditingPageName('additionalServices')}
              >
                Editer
              </Button>
            </BodySection>
          </Body>
        )}
      </Container>
    </CenteredModal>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Body = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
});

const BodySection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '5px',
  paddingLeft: '30px',
  paddingRight: '30px',
});

const Footer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 8,
  padding: '25px',
});

const StyledCheckBox = styled(Checkbox)({});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
