import { useCallback, useState } from 'react';

const useToggle = (): [boolean, () => void, (newState: boolean) => void] => {
  const [state, setState] = useState(false);
  const toggleState = useCallback(() => setState((prev) => !prev), []);

  return [state, toggleState, setState];
};

export default useToggle;
