/* eslint-disable prefer-destructuring */
/* eslint-disable operator-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Modification } from 'src/services/configurator';
import { Box3, Vector3 } from 'three';

import { getModificationWithHistory } from './configurator.utils';

export const getNewGeometryWithSimpleMode = (
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  },
  pieceIndex: number,
  data: {
    points: any[];
    simpleModeAxis: string | undefined;
  },
  size: { size: string },
  pieces: any[],
) => {
  const dataPoints = data.points.sort((a, b) => {
    if (a.index !== undefined && b.index !== undefined) {
      return a.index < b.index ? -1 : 1;
    }
    return 0;
  });

  const firstPoint = getModificationWithHistory(history[pieces[pieceIndex].uuid], dataPoints[0]);
  const secondPoint = getModificationWithHistory(history[pieces[pieceIndex].uuid], dataPoints[1]);

  let vectorFirstPoint = new Vector3(firstPoint.x, firstPoint.y, firstPoint.z);
  let vectorSecondPoint = new Vector3(secondPoint.x, secondPoint.y, secondPoint.z);

  let actualSize = Math.round(vectorFirstPoint.distanceTo(vectorSecondPoint) * 100);

  if (data.simpleModeAxis) {
    if (vectorFirstPoint[data.simpleModeAxis] > vectorSecondPoint[data.simpleModeAxis]) {
      vectorFirstPoint = new Vector3(secondPoint.x, secondPoint.y, secondPoint.z);
      vectorSecondPoint = new Vector3(firstPoint.x, firstPoint.y, firstPoint.z);
    }
    actualSize =
      vectorSecondPoint[data.simpleModeAxis] > vectorFirstPoint[data.simpleModeAxis]
        ? (vectorSecondPoint[data.simpleModeAxis] - vectorFirstPoint[data.simpleModeAxis]) * 100
        : (vectorFirstPoint[data.simpleModeAxis] - vectorSecondPoint[data.simpleModeAxis]) * 100;
    actualSize = Number.parseFloat(actualSize.toFixed(4));
  }

  let modificationFirstRefPoint = vectorFirstPoint;
  let modificationSecondRefPoint = vectorSecondPoint;

  if (dataPoints.length === 4) {
    const thirdPoint = getModificationWithHistory(history[pieces[pieceIndex].uuid], dataPoints[2]);
    const fourthPoint = getModificationWithHistory(history[pieces[pieceIndex].uuid], dataPoints[3]);

    const vectorThirdPoint = new Vector3(thirdPoint.x, thirdPoint.y, thirdPoint.z);
    const vectorFourthPoint = new Vector3(fourthPoint.x, fourthPoint.y, fourthPoint.z);

    if (data.simpleModeAxis) {
      if (thirdPoint[data.simpleModeAxis] < fourthPoint[data.simpleModeAxis]) {
        modificationFirstRefPoint = vectorThirdPoint;
        modificationSecondRefPoint = vectorFourthPoint;
      } else {
        modificationFirstRefPoint = vectorFourthPoint;
        modificationSecondRefPoint = vectorThirdPoint;
      }
    }
  }

  const delta = (Number.parseFloat(size.size) - actualSize) / 100;

  const deltaPourcentage = (Number.parseFloat(size.size) * 100) / (actualSize * 100);

  const geometryRef = [...pieces[pieceIndex].geometry.attributes.position.array];
  const pieceBox = new Box3().setFromArray(geometryRef);

  const copyGeometry: number[] = Array.from({
    length: pieces[pieceIndex].geometry.attributes.position.array.length,
  }).fill(0) as number[];

  for (let i = 0; i < copyGeometry.length; i += 3) {
    copyGeometry[i] = geometryRef[i];
    copyGeometry[i + 1] = geometryRef[i + 1];
    copyGeometry[i + 2] = geometryRef[i + 2];

    if (data.simpleModeAxis === 'x') {
      if (geometryRef[i] >= modificationSecondRefPoint.x) {
        copyGeometry[i] = copyGeometry[i] + delta / 2;
      } else if (geometryRef[i] <= modificationFirstRefPoint.x) {
        copyGeometry[i] = copyGeometry[i] - delta / 2;
      } else if (
        geometryRef[i] < modificationSecondRefPoint.x &&
        geometryRef[i] > modificationFirstRefPoint.x
      ) {
        copyGeometry[i] = copyGeometry[i] * deltaPourcentage;
      }
    }
    if (data.simpleModeAxis === 'y') {
      if (geometryRef[i + 1] >= modificationSecondRefPoint.y) {
        copyGeometry[i + 1] = copyGeometry[i + 1] + delta;
      } else if (
        geometryRef[i + 1] > modificationFirstRefPoint.y &&
        geometryRef[i + 1] < modificationSecondRefPoint.y
      ) {
        const newPoint = (copyGeometry[i + 1] - pieceBox.min.y) * deltaPourcentage + pieceBox.min.y;
        copyGeometry[i + 1] = newPoint;
      }
    }
    if (data.simpleModeAxis === 'z') {
      if (geometryRef[i + 2] >= modificationSecondRefPoint.z) {
        copyGeometry[i + 2] = copyGeometry[i + 2] + delta;
      } else if (
        geometryRef[i + 2] > modificationFirstRefPoint.z &&
        geometryRef[i + 2] < modificationSecondRefPoint.z
      ) {
        const newPoint = (copyGeometry[i + 2] - pieceBox.min.z) * deltaPourcentage + pieceBox.min.z;
        copyGeometry[i + 2] = newPoint;
      }
    }
  }

  return copyGeometry;
};

export const getModificationData = (modification: Modification) => {
  const p1 = modification.points[0];
  const p2 = modification.points[1];

  const v2supv1 = p1.x + p1.y + p1.z < p2.x + p2.y + p2.z;

  const sortedPoint = modification.points;

  if (!v2supv1) {
    const selectedPointCopy = sortedPoint[0];
    sortedPoint[0] = sortedPoint[1];
    sortedPoint[1] = selectedPointCopy;
  }

  return {
    ...modification,
    points: sortedPoint,
    distance: modification.distance,
    simpleModeAxis: modification.simpleModeAxis,
  };
};
