import { Add } from '@mui/icons-material';
import { Box, Button, Stack, styled, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import { LowerCaseButton, RoundedButton } from '../../../../components/common';
import { CustomDataGrid } from '../../../../components/DataGrid';
import { formatNumber } from '../../../../components/forms/NumberInput';
import useLoadDataList from '../../../../hook/useLoadDataList';
import useToggle from '../../../../hook/useToggle';
import {
  createDeliveryCosts,
  deleteDeliveryCosts,
  DeliveryCost,
  findAllDeliveryCosts,
  updateDeliveryCosts,
} from '../../../../services/delivery-cost';
import { handleMutation } from '../../../../utils/api.utils';

const Deliveries = () => {
  const { data, onRefresh } = useLoadDataList(findAllDeliveryCosts);
  const [add, toggleAdd] = useToggle();
  const [price, setPrice] = useState<string | number>(0);
  const [code, setCode] = useState('');

  const columns: GridColDef<DeliveryCost>[] = [
    {
      field: 'code',
      headerName: 'Département/Pays',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Tarif pour 1 tonne en € HT',
      flex: 1,
      editable: true,
      valueSetter: (params) => ({ ...params.row, price: formatNumber(params.value as string) }),
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              await deleteDeliveryCosts({ code: params.row.code });
              await onRefresh();
            }}
          >
            X
          </Button>
        );
      },
    },
  ];

  const create = () => {
    if (typeof price === 'number' && !!code) {
      handleMutation(createDeliveryCosts, { price, code }, () => {
        onRefresh();
        toggleAdd();
        setPrice(0);
        setCode('');
      });
    }
  };

  return (
    <Container>
      <RoundedButton
        variant="outlined"
        sx={{ position: 'absolute', right: 20 }}
        onClick={toggleAdd}
      >
        <Add color="primary" />
        Ajouter un Pays/Département
      </RoundedButton>
      <CustomDataGrid
        columns={columns}
        rows={data}
        getRowId={(row) => row.code}
        autoPageSize={false}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={(newRow) => {
          handleMutation(updateDeliveryCosts, newRow);
          return newRow;
        }}
      />
      <CenteredModal open={add} handleClose={toggleAdd}>
        <ModalTitle>Ajouter un tarif</ModalTitle>
        <Stack gap="16px" padding="16px">
          <TextField value={code} onChange={(event) => setCode(event.target.value)} />
          <TextField
            value={price}
            onChange={(event) => setPrice(event.target.value)}
            onBlur={(event) => setPrice(formatNumber(event.target.value))}
          />
          <LowerCaseButton variant="contained" onClick={create}>
            Ajouter
          </LowerCaseButton>
        </Stack>
      </CenteredModal>
    </Container>
  );
};

const Container = styled(Box)({
  width: '40%',
  alignSelf: 'center',
  margin: '30px 0',
  height: '100%',
  // position: 'relative',
});

export default Deliveries;
