/* eslint-disable unicorn/no-for-loop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable unicorn/no-negated-condition */
import { BucketsUrls } from 'src/services/file';
import * as THREE from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

const getRotation = (orientation: string) => {
  if (orientation.includes('y')) {
    return new THREE.Euler(0, orientation.includes('inverse') ? Math.PI : 0, 0);
  }
  return new THREE.Euler(0, 0, 0);
};

const getNumberParseFloat = (value: any) => {
  return Number.parseFloat(value as string);
};

export const getDefaultTextureSetup = (selectedPiece: any, buckets: BucketsUrls | null) => {
  const defaultPatterns: any[] = [];

  selectedPiece.defaultPatterns?.forEach((el: any) => {
    if (!defaultPatterns[el.orientation]) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const imageObj = new Image();
      imageObj.src = el.image;

      imageObj.onload = () => {
        canvas.width = imageObj.width;
        canvas.height = imageObj.height;
        if (context) context.drawImage(imageObj, 0, 0);
      };
      const newCamera = new THREE.PerspectiveCamera();
      newCamera.matrix.fromArray(JSON.parse(el.camera));
      newCamera.matrix.decompose(newCamera.position, newCamera.quaternion, newCamera.scale);

      defaultPatterns[el.orientation] = {
        id: el.orientation,
        texture: new THREE.CanvasTexture(canvas),
        elements: [],
        camera: newCamera,
      };
    }
    defaultPatterns[el.orientation].elements?.push({
      uuid: generateUUID(),
      imageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.image}`,
      orientation: el.orientation,
      position: new THREE.Vector3(el.positionX, el.positionY, 0),
      rotation: getRotation(el.orientation),
      scale: new THREE.Vector2(el.scaleX, el.scaleY),
      inverse_horizontal: false,
      inverse_vertical: false,
      default: new THREE.Vector2(
        getNumberParseFloat(
          Math.round(
            (el.pattern?.height ?? 1) * (Number.parseFloat(String(el.pattern?.ratio)) ?? 1),
          ),
        ),
        getNumberParseFloat(el.pattern?.height),
      ),
    });
  });

  return defaultPatterns;
};

export const isAbove = (box1: THREE.Box3, box2: THREE.Box3): boolean => {
  const touchesX = box1.min.x <= box2.max.x && box1.max.x >= box2.min.x;
  const touchesZ = box1.min.z <= box2.max.z && box1.max.z >= box2.min.z;

  if (touchesX && touchesZ) {
    return box1.min.y >= box2.max.y;
  }
  return false;
};

export const isBelow = (box1: THREE.Box3, box2: THREE.Box3): boolean => {
  const touchesX = box1.min.x <= box2.max.x && box1.max.x >= box2.min.x;
  const touchesZ = box1.min.z <= box2.max.z && box1.max.z >= box2.min.z;

  if (touchesX && touchesZ) {
    return box1.min.y <= box2.max.y;
  }
  return false;
};

export const getBoxWithPieceAndPosition = (piece: any, position: THREE.Vector3) => {
  return new THREE.Box3().setFromArray(
    // eslint-disable-next-line array-callback-return
    piece.geometry.attributes.position.array.map((el: any, index: number) => {
      if (index % 3 === 0) return el + position.x;
      if (index % 3 === 1) return el + position.y;
      if (index % 3 === 2) return el + position.z;
    }),
  );
};

export const movePieceOnTop = (pieces: any[], pieceIndex: number, piecePosition: THREE.Vector3) => {
  const hasUpdatedPosition = false;
  const box1 = getBoxWithPieceAndPosition(pieces[pieceIndex], piecePosition);

  if (!hasUpdatedPosition) {
    let hasBoxAbove = false;
    let hasBoxBelow = false;
    let isTouchesBox = false;
    const aboveBox = new THREE.Box3();
    const belowBox = new THREE.Box3();
    for (let i = 0; i < pieces.length; i += 1) {
      if (pieceIndex !== i) {
        const box2 = getBoxWithPieceAndPosition(pieces[i], pieces[i].position);

        if (isAbove(box1, box2) && (belowBox.max.y < box2.max.y || !hasBoxBelow)) {
          belowBox.copy(box2);
          hasBoxBelow = true;
        }

        if (isBelow(box1, box2) && (aboveBox.max.y < box2.max.y || !hasBoxAbove)) {
          aboveBox.copy(box2);
          hasBoxAbove = true;
        }
        const box1Copy = box1.clone();
        if (box1Copy.intersectsBox(box2)) isTouchesBox = true;
      }
    }

    let deltaY = 0;

    if (hasBoxAbove) {
      deltaY = aboveBox.max.y - box1.min.y;
    } else if (hasBoxBelow && !isTouchesBox) {
      deltaY = belowBox.max.y - box1.min.y;
    }

    pieces[pieceIndex]?.position.set(piecePosition.x, piecePosition.y + deltaY, piecePosition.z);
  }
};
