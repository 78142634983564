/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unknown-property */
import { Edges, MeshReflectorMaterial, TransformControls } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { QuoteStep } from 'src/Screens/project/components/NavBar';
import { Granit } from 'src/services/granits';
import { getPiece } from 'src/utils/configurator.utils';
import * as THREE from 'three';
import { Vector2, Vector3 } from 'three';

import { StateNames, States } from '../../Screens/Configurator';

export type CustomSteleType = {
  id: string;
  boxLeftHeight: number;
  boxRightHeight: number;
  boxHeight: number;
  boxUpWidth: number;
  boxDownWidth: number;
  headIndex: number;
  baseIndex: number;
  depth: number;
  shape: any;
  extrudeSettings: any;
  position: Vector3;
  stele?: any;
  granit?: Granit;
  texture?: THREE.Texture | null;
  isBlocked?: boolean;
};

type SteleProps = {
  orbitRef: React.MutableRefObject<any>;
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;
  index: number;
  customStele: any;
  setCustomSteles: (customSteles: any[]) => void;
  customSteles: any[];
  customPlatings: any[];
  // eslint-disable-next-line react/no-unused-prop-types
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: (stateName: StateNames) => States[StateNames];
  step: QuoteStep;
  pieces: any[];
  patterns: any;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
};

export const Stele = ({
  orbitRef,
  pieceIndex,
  setPieceIndex,
  index,
  customStele,
  setCustomSteles,
  customSteles,
  getValue,
  step,
  pieces,
  patterns,
  orientation,
  customPlatings,
}: SteleProps) => {
  const transformRef = useRef<any>();
  const meshRef = useRef<any>();

  const piece = getPiece(pieces, customSteles, customPlatings, pieceIndex);

  useEffect(() => {
    if (transformRef.current) {
      if (meshRef.current) transformRef.current.attach(meshRef.current);
      const controls = transformRef.current;
      const callback = (event: any) => {
        orbitRef.current.enabled = !event.value;
      };
      controls.addEventListener('dragging-changed', callback);
      return () => controls.removeEventListener('dragging-changed', callback);
    }
  });

  const getShaderMaterial = (
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
    texture: any,
    uRoomOrigin: Vector3,
    uRoomSize: Vector2,
    uRoomOrientation: Vector3,
    uTextureNormal: Vector3,
  ) => {
    const vertexShader = `
    varying vec4 vWorldPosition;
    varying vec3 vWorldNormal;

    void main() {
      vWorldPosition = modelMatrix * vec4(position, 1.0);
      vWorldNormal = normalize(mat3(modelMatrix) * normal);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    }
  `;
    const fragmentShader = `
    varying vec4 vWorldPosition;
    varying vec3 vWorldNormal;
    uniform sampler2D uTexture;
    uniform sampler2D uDepthTexture;
    uniform vec4 uClippingPlane;
    uniform vec3 uRoomOrigin;
    uniform vec2 uRoomSize;
    uniform vec3 uTextureNormal;
    uniform vec3 uCameraPosition;

    void main() {
      float clipDist = dot(vWorldPosition, uClippingPlane);
      // if (clipDist < 0.0) discard;
    
      //Ce bout de code permet de ne pas afficher la texture sur l'avant et l'arrière de la pièce en même temps
      vec3 viewVector = normalize(uCameraPosition - vWorldPosition.xyz);
      float viewDotNormal = dot(viewVector, vWorldNormal);
      if (viewDotNormal <= 0.0) discard;
    
      // Calculez l'angle entre la normale de la face et le vecteur de projection de la texture
      float angle = acos(dot(vWorldNormal, uTextureNormal));
  
      // Si l'angle est supérieur à 90 degrés, ne pas afficher le shader
      // if (angle > radians(90.0)) discard;

      vec3 worldPosition = vWorldPosition.xyz - uRoomOrigin;
      vec2 worldUv;
    
      if (abs(vWorldNormal.x) > 0.5) {
        if (float(uTextureNormal.x) == float(0)) discard;
        worldUv = vec2(worldPosition.z, worldPosition.y) / uRoomSize;
      } else if (abs(vWorldNormal.y) > 0.5) {
        if (float(uTextureNormal.y) == float(0)) discard;
        worldUv = vec2(worldPosition.x, worldPosition.z) / uRoomSize;
      } else {
        if (float(uTextureNormal.z) == float(0)) discard;
        worldUv = vec2(worldPosition.x, worldPosition.y) / uRoomSize;
      }
    
      if (worldUv.x < 0.0 || worldUv.x > 1.0 || worldUv.y < 0.0 || worldUv.y > 1.0) {
        // discard;
      }

      gl_FragColor = texture2D(uTexture, worldUv);
    }
    
  `;

    return new THREE.ShaderMaterial({
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -4,
      uniforms: {
        uRoomOrigin: {
          value: uRoomOrigin,
        },
        uRoomSize: { value: uRoomSize },
        uTexture: { value: texture },
        uProjectorViewProjectionMatrix: {
          value: new THREE.Matrix4().multiplyMatrices(
            camera.projectionMatrix,
            camera.matrixWorldInverse,
          ),
        },
        uClippingPlane: { value: new THREE.Vector4() },
        uRoomOrientation: { value: uRoomOrientation },
        uTextureNormal: { value: uTextureNormal }, // Définir la normale du plan
        uCameraPosition: { value: camera.position },
        uDepthTexture: { value: new THREE.DepthTexture(texture.image.width, texture.image.height) },
      },
      vertexShader,
      fragmentShader,
      clipping: true,
    });
  };

  const getFaceMaterial = (face: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse') => {
    let perspectiveCamera: THREE.PerspectiveCamera | null = null;
    let texture: any | null = null;

    if (patterns[piece.uuid] && patterns[piece.uuid][face]) {
      if (patterns[piece.uuid][face].camera) perspectiveCamera = patterns[piece.uuid][face].camera;
      if (
        perspectiveCamera &&
        patterns[piece.uuid][face].texture &&
        patterns[piece.uuid][face].elements.length > 0
      ) {
        texture = patterns[piece.uuid][face].texture;

        const orthoProjector = new THREE.PerspectiveCamera();
        orthoProjector.copy(perspectiveCamera);
        orthoProjector.updateProjectionMatrix();
        orthoProjector.updateMatrixWorld(true);

        piece.geometry.computeBoundingBox();
        const boundingSize = piece.geometry.boundingBox.getSize(new THREE.Vector3());
        const boundingMin = piece.geometry.boundingBox.min.clone();
        const boundingMax = piece.geometry.boundingBox.max.clone();

        let uRoomOrigin: Vector3 | null = null;
        let uRoomOrientation: Vector3 | null = null;
        let uRoomSize: Vector2 | null = null;
        let clippingPlane: THREE.Plane | null = null;
        let uTextureNormal: Vector3 | null = null;

        if (face === 'z') {
          uRoomOrigin = new Vector3(
            boundingMin.x + piece.position.x,
            boundingMin.y + piece.position.y,
            boundingMin.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(boundingSize.x, boundingSize.y);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(0, 0, 1);
          uTextureNormal = new Vector3(0, 0, 1);
        }
        if (face === 'z_inverse') {
          uRoomOrigin = new Vector3(
            boundingMax.x + piece.position.x,
            boundingMin.y + piece.position.y,
            boundingMin.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(-boundingSize.x, boundingSize.y);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(0, 0, -1);
          uTextureNormal = new Vector3(0, 0, 1);
        }
        if (face === 'y') {
          uRoomOrigin = new Vector3(
            boundingMin.x + piece.position.x,
            boundingMax.y + piece.position.y,
            boundingMax.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(boundingSize.x, -boundingSize.z);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(0, 1, 0);
          uTextureNormal = new Vector3(0, 1, 0);
        }
        if (face === 'y_inverse') {
          uRoomOrigin = new Vector3(
            boundingMin.x + piece.position.x,
            boundingMin.y + piece.position.y,
            boundingMin.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(boundingSize.x, boundingSize.z);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(0, 1, 0);
          uTextureNormal = new Vector3(0, 1, 0);
        }
        if (face === 'x') {
          uRoomOrigin = new Vector3(
            boundingMax.x + piece.position.x,
            boundingMin.y + piece.position.y,
            boundingMax.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(-boundingSize.z, boundingSize.y);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(-1, 0, 0);
          uTextureNormal = new Vector3(1, 0, 0);
        }
        if (face === 'x_inverse') {
          uRoomOrigin = new Vector3(
            boundingMin.x + piece.position.x,
            boundingMin.y + piece.position.y,
            boundingMin.z + piece.position.z,
          );
          uRoomSize = new THREE.Vector2(boundingSize.z, boundingSize.y);
          clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
          uRoomOrientation = new Vector3(-1, 0, 0);
          uTextureNormal = new Vector3(1, 0, 0);
        }

        if (perspectiveCamera && uRoomOrigin && uRoomSize && uRoomOrientation && uTextureNormal) {
          const shaderMaterial = getShaderMaterial(
            orthoProjector,
            texture,
            uRoomOrigin,
            uRoomSize,
            uRoomOrientation,
            uTextureNormal,
          );
          if (clippingPlane) {
            shaderMaterial?.uniforms.uClippingPlane.value.set(
              clippingPlane.normal.x,
              clippingPlane.normal.y,
              clippingPlane.normal.z,
              clippingPlane.constant,
            );
          }
          return shaderMaterial;
        }
      }
    }

    return new THREE.MeshPhysicalMaterial({ transparent: true, opacity: 0 });
  };

  const faces = ['x', 'y', 'z', 'x_inverse', 'y_inverse', 'z_inverse'] as const;

  return (
    <TransformControls
      ref={transformRef}
      mode="translate"
      size={!customStele.isBlocked && index === pieceIndex ? 1 : 0}
      onMouseUp={() => {
        if (index === pieceIndex) {
          const customsMeshTmp = customSteles.map((mesh) => {
            if (mesh.id === customStele.id) {
              return {
                ...mesh,
                position: meshRef?.current?.position,
              };
            }
            return mesh;
          });

          setCustomSteles(customsMeshTmp);
        }
      }}
    >
      <>
        {(orientation.key === 'free' && step === 'PATTERNS') ||
        step === 'CATALOG' ||
        step === 'QUOTE'
          ? faces.map((el, elIndex) => {
              return (
                <mesh
                  // eslint-disable-next-line react/no-array-index-key
                  key={elIndex}
                  geometry={piece.geometry}
                  material={getFaceMaterial(el)}
                  position={piece.position}
                />
              );
            })
          : null}
        <mesh
          ref={meshRef}
          position={customStele.position}
          onClick={(e) => {
            if (e.intersections && e.intersections[0].distance === e.distance) {
              setPieceIndex(pieceIndex);
            }
          }}
        >
          <extrudeGeometry
            attach="geometry"
            args={[customStele.shape, customStele.extrudeSettings]}
          />
          {getValue('texture') ? (
            <meshStandardMaterial map={customStele.texture} metalness={0.95} roughness={0.15} />
          ) : (
            <>
              <Edges />
              <meshStandardMaterial transparent opacity={getValue('wired') ? 0 : 1} />
            </>
          )}
          {index === pieceIndex &&
          (step === 'CUSTOMIZATION' || step === 'ACCESSORIES' || step === 'PATTERNS') ? (
            <Edges color="red" threshold={10} />
          ) : null}
        </mesh>
      </>
    </TransformControls>
  );
};
