/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Delete, Edit, Euro, Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import {
  ButtonRow,
  FormLine,
  LowerCaseButton,
  RoundedButton,
  StyledForm,
} from '../../../../components/common';
import { CustomDataGrid } from '../../../../components/DataGrid';
import DeleteWarningModal from '../../../../components/DeleteWarningModal';
import { AutocompleteRHF } from '../../../../components/forms/AutocompleteRhf';
import CheckboxRHF from '../../../../components/forms/CheckboxRhf';
import { formatNumber, NumberFieldRHF } from '../../../../components/forms/NumberInput';
import { SelectRHF } from '../../../../components/forms/SelectRhf';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../context/EnvProvider';
import useLoadDataList from '../../../../hook/useLoadDataList';
import useToggle from '../../../../hook/useToggle';
import { fabrications } from '../../../../services/assemblies';
import { Buckets } from '../../../../services/file';
import {
  createGranit,
  deleteGranits,
  getGranits,
  Granit,
  updateGranit,
} from '../../../../services/granits';
import { getLeaderGranitCompanies } from '../../../../services/leaderGranitCompanies';
import { handleMutation } from '../../../../utils/api.utils';
import { uploadImage } from '../../../../utils/file.utils';
import DeliveryTimesForm from './DeliveryTimesForm';

const schemaGranitForm = z.object({
  name: z.string().trim().min(1),
  image: z.string().trim().min(1),
  fabrication: z.enum(fabrications),
  societiesId: z.array(z.object({ name: z.string().trim().min(1), id: z.string().cuid() })),
  volumePrice: z.number().min(0),
  sawingPrice: z.number().min(0),
  polishingPrice: z.number().min(0),
  purchaseVolumePrice: z.number().min(0),
  purchaseSawingPrice: z.number().min(0),
  purchasePolishingPrice: z.number().min(0),
  weight: z.number().min(0),
  maxWorkableLength: z.number().min(0),
  minWorkableThickness: z.number().min(0),
  eco: z.boolean(),
  minLength: z.number().min(0),
  maxLength: z.number().min(0),
  largeSize: z.number().min(0),
  largeExtra: z.string(),
});

type SchemaGranitForm = z.infer<typeof schemaGranitForm>;

export enum FabricationLabels {
  FRENCH = 'France',
  CHINA = 'Chine',
  INDIA = 'Inde',
  OTHER = 'Autre import',
}

const fabricationOption = fabrications.map((fabrication) => ({
  value: fabrication,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  label: FabricationLabels[fabrication],
  key: fabrication,
}));

const euroFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const distanceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'unit',
  unit: 'centimeter',
});

const densityFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'unit',
  unit: 'kilogram',
});

const Granits = () => {
  const { data, onRefresh } = useLoadDataList(getGranits);

  const { buckets } = useEnv();

  const [openModal, toggleModal] = useToggle();
  const [editDeliveryTimes, toggleEditDeliveryTimes] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [openedGranit, setOpenedGranit] = useState<Granit>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { data: companies } = useLoadDataList(getLeaderGranitCompanies);

  const columns: GridColDef<Granit>[] = [
    {
      field: 'name',
      headerName: 'nom',
      flex: 1,
    },
    {
      field: 'reducedImage',
      headerName: 'texture',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <img
          src={`${buckets?.BUCKET_3D_PIECES || ''}/${params.value || ''}`}
          alt="logo"
          style={{ padding: '5px', objectFit: 'contain', width: '100%', height: '100%' }}
        />
      ),
    },
    {
      field: 'fabrication',
      headerName: 'Fabrication',
      flex: 1,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      valueGetter: (params) => FabricationLabels[params.row.fabrication],
    },
    {
      field: 'weight',
      headerName: 'Densité',
      flex: 1,
      valueGetter: (params) => `${densityFormatter.format(Number(params.value))}/m3`,
    },
    {
      field: 'volumePrice',
      headerName: 'Tarif au m3',
      flex: 1,
      valueGetter: (params) => euroFormatter.format(Number(params.value)),
    },
    {
      field: 'sawingPrice',
      headerName: 'Tarif du sciage au m2',
      flex: 1,
      valueGetter: (params) => euroFormatter.format(Number(params.value)),
    },
    {
      field: 'polishingPrice',
      headerName: 'Tarif polissage au m2',
      flex: 1,
      valueGetter: (params) => euroFormatter.format(Number(params.value)),
    },
    {
      field: 'maxWorkableLength',
      headerName: 'Longueur maximale usinable',
      flex: 1,
      valueGetter: (params) => distanceFormatter.format(Number(params.value)),
    },
    {
      field: 'minWorkableThickness',
      headerName: 'Épaisseur minimale usinable',
      flex: 1,
      valueGetter: (params) => distanceFormatter.format(Number(params.value)),
    },
    {
      field: 'minLength',
      headerName: 'Longueur minimale',
      flex: 1,
      valueGetter: (params) => distanceFormatter.format(Number(params.value)),
    },
    {
      field: 'maxLength',
      headerName: 'Longueur Maximale',
      flex: 1,
      valueGetter: (params) => distanceFormatter.format(Number(params.value)),
    },
    {
      field: 'largeSize',
      headerName: 'Grande dimension',
      flex: 1,
      valueGetter: (params) => distanceFormatter.format(Number(params.value)),
    },
    {
      field: 'largeExtra',
      headerName: 'Plus value grande dimension',
      flex: 1,
      valueGetter: (params) => String(params.value),
    },
    {
      field: 'action',
      headerName: 'Modifier',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          color="primary"
          onClick={() => {
            toggleModal();
            setOpenedGranit(params.row as Granit);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ];

  const defaultValues: SchemaGranitForm = useMemo(
    () => ({
      name: openedGranit?.name ?? '',
      image: openedGranit?.image ?? '',
      fabrication: openedGranit?.fabrication ?? 'FRENCH',
      volumePrice: formatNumber(openedGranit?.volumePrice ?? 0),
      polishingPrice: formatNumber(openedGranit?.polishingPrice ?? 0),
      sawingPrice: formatNumber(openedGranit?.sawingPrice ?? 0),
      purchaseVolumePrice: formatNumber(openedGranit?.purchaseVolumePrice ?? 0),
      purchasePolishingPrice: formatNumber(openedGranit?.purchasePolishingPrice ?? 0),
      purchaseSawingPrice: formatNumber(openedGranit?.purchaseSawingPrice ?? 0),
      weight: formatNumber(openedGranit?.weight ?? 0),
      maxWorkableLength: formatNumber(openedGranit?.maxWorkableLength ?? 0),
      minWorkableThickness: formatNumber(openedGranit?.minWorkableThickness ?? 0),
      eco: openedGranit?.eco ?? false,
      societiesId: openedGranit?.societies?.map((granitSociety) => granitSociety.society) ?? [],
      minLength: formatNumber(openedGranit?.minLength ?? 0),
      maxLength: formatNumber(openedGranit?.maxLength ?? 0),
      largeSize: formatNumber(openedGranit?.largeSize ?? 0),
      largeExtra: String(openedGranit?.largeExtra ?? 0),
    }),
    [openedGranit],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<SchemaGranitForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaGranitForm),
  });

  useEffect(() => reset(defaultValues), [reset, defaultValues]);

  const submit: SubmitHandler<SchemaGranitForm> = (granit) => {
    if (openedGranit)
      handleMutation(
        updateGranit,
        {
          ...granit,
          largeExtra: Number.parseFloat(granit.largeExtra),
          id: openedGranit.id,
          societiesId: granit.societiesId.map((society) => society.id),
        },
        () => {
          toggleModal();
          reset(defaultValues);
          setOpenedGranit(undefined);
          onRefresh();
        },
      );
    else
      handleMutation(
        createGranit,
        {
          ...granit,
          largeExtra: Number.parseFloat(granit.largeExtra),
          societiesId: granit.societiesId.map((society) => society.id),
        },
        () => {
          toggleModal();
          onRefresh();
          reset(defaultValues);
        },
      );
  };

  const handleDelete = () => {
    handleMutation(deleteGranits, selectionModel as string[], onRefresh);
  };
  const uploadTexture = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
    return pictureName ?? '';
  };
  return (
    <Stack height="100%">
      <ButtonRow padding="16px" justifyContent="space-between">
        <ButtonRow>
          <RoundedButton
            startIcon={<Add color="primary" />}
            color="primary"
            variant="outlined"
            onClick={toggleModal}
          >
            Ajouter un granit
          </RoundedButton>
          <RoundedButton
            startIcon={<Delete color={selectionModel.length === 0 ? 'inherit' : 'error'} />}
            color="error"
            variant="outlined"
            onClick={toggleDelete}
            disabled={selectionModel.length === 0}
          >
            Supprimer
          </RoundedButton>
        </ButtonRow>
        <RoundedButton
          startIcon={<Edit color="primary" />}
          color="primary"
          variant="outlined"
          onClick={toggleEditDeliveryTimes}
          sx={{ placeSelf: 'flex-end' }}
        >
          Éditer les délais de livraison
        </RoundedButton>
      </ButtonRow>
      <CustomDataGrid
        columns={columns}
        rows={data}
        checkboxSelection
        pageSize={6}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        autoHeight
      />
      <CenteredModal
        open={openModal}
        handleClose={() => {
          toggleModal();
          setOpenedGranit(undefined);
        }}
      >
        <ModalTitle>
          <Typography variant="h5">BIBLIOTHÈQUE DE GRANITS</Typography>
        </ModalTitle>
        <StyledForm onSubmit={handleSubmit(submit)}>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={control}
              TextFieldProps={{
                label: 'Nom du granit',
                error: Boolean(errors.name),
                helperText: errors.name?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <AutocompleteRHF
              control={control}
              name="societiesId"
              options={companies}
              AutocompleteProps={{
                multiple: true,
                freeSolo: false,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (option, value) => option.id === value.id,
                renderTags: (value, getTagProps) => (
                  <TagsContainer>
                    {value.map(({ name }, index) => (
                      <Chip variant="outlined" label={name} {...getTagProps({ index })} />
                    ))}
                  </TagsContainer>
                ),
              }}
              TextFieldProps={{
                label: 'Sociétés',
                error: Boolean(errors.societiesId),
                helperText: errors.societiesId?.message,
              }}
            />
            <SelectRHF
              control={control}
              name="fabrication"
              options={fabricationOption}
              TextFieldProps={{
                label: 'Fabrication',
                error: Boolean(errors.fabrication),
                helperText: errors.fabrication?.message,
              }}
            />
          </FormLine>

          <FormLine>
            <NumberFieldRHF
              name="largeSize"
              control={control}
              TextFieldProps={{
                label: 'Grande Dimension',
                error: Boolean(errors.minLength),
                helperText: errors.minLength?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            />
            <TextFieldRHF
              name="largeExtra"
              control={control}
              TextFieldProps={{
                label: 'Plus value grande dimension',
                error: Boolean(errors.maxLength),
                helperText: errors.maxLength?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">coeff</InputAdornment>,
                },
              }}
            />
          </FormLine>
          <FormLine>
            <NumberFieldRHF
              name="weight"
              control={control}
              TextFieldProps={{
                label: 'Densité',
                error: Boolean(errors.weight),
                helperText: errors.weight?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">kg/m3</InputAdornment>,
                },
              }}
            />
          </FormLine>
          <FormLine>
            <NumberFieldRHF
              name="volumePrice"
              control={control}
              TextFieldProps={{
                label: 'Tarif au m3',
                error: Boolean(errors.volumePrice),
                helperText: errors.volumePrice?.message,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro /> /m3
                    </InputAdornment>
                  ),
                },
              }}
            />
            <NumberFieldRHF
              name="purchaseVolumePrice"
              control={control}
              TextFieldProps={{
                label: 'Tarif au m3 achat',
                error: Boolean(errors.purchaseVolumePrice),
                helperText: errors.purchaseVolumePrice?.message,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro /> /m3
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormLine>
          {watch('fabrication') === 'FRENCH' && (
            <>
              <FormLine>
                <NumberFieldRHF
                  name="polishingPrice"
                  control={control}
                  TextFieldProps={{
                    label: 'Tarif polissage au m2',
                    error: Boolean(errors.polishingPrice),
                    helperText: errors.polishingPrice?.message,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro /> /m2
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <NumberFieldRHF
                  name="purchasePolishingPrice"
                  control={control}
                  TextFieldProps={{
                    label: 'Tarif polissage au m2 achat',
                    error: Boolean(errors.purchasePolishingPrice),
                    helperText: errors.purchasePolishingPrice?.message,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro /> /m2
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormLine>
              <FormLine>
                <NumberFieldRHF
                  name="sawingPrice"
                  control={control}
                  TextFieldProps={{
                    label: 'Tarif du sciage au m2',
                    error: Boolean(errors.sawingPrice),
                    helperText: errors.sawingPrice?.message,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro /> /m2
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <NumberFieldRHF
                  name="purchaseSawingPrice"
                  control={control}
                  TextFieldProps={{
                    label: 'Tarif du sciage au m2 achat',
                    error: Boolean(errors.purchaseSawingPrice),
                    helperText: errors.purchaseSawingPrice?.message,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro /> /m2
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormLine>
            </>
          )}
          <FormLine>
            <NumberFieldRHF
              name="minLength"
              control={control}
              TextFieldProps={{
                label: 'Longueur minimale usinable',
                error: Boolean(errors.minLength),
                helperText: errors.minLength?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            />
            <NumberFieldRHF
              name="maxLength"
              control={control}
              TextFieldProps={{
                label: 'Longueur maximale',
                error: Boolean(errors.maxLength),
                helperText: errors.maxLength?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            />
          </FormLine>
          <FormLine>
            {/* <NumberFieldRHF
              name="maxWorkableLength"
              control={control}
              TextFieldProps={{
                label: 'Longueur maximale usinable',
                error: Boolean(errors.maxWorkableLength),
                helperText: errors.maxWorkableLength?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            /> */}
            {/* <NumberFieldRHF
              name="minWorkableThickness"
              control={control}
              TextFieldProps={{
                label: 'Épaisseur minimale usinable',
                error: Boolean(errors.minWorkableThickness),
                helperText: errors.minWorkableThickness?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            /> */}
          </FormLine>
          <Controller
            name="image"
            control={control}
            render={({ field }) => {
              const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
                if (e.target.files) {
                  const fileKey = await uploadTexture(e.target.files[0]);
                  field.onChange(fileKey);
                }
              };

              return (
                <UploadContainer component="label">
                  {watch('image') ? (
                    <Image
                      src={`${buckets?.BUCKET_3D_PIECES || ''}/${watch('image')}`}
                      alt="texture"
                    />
                  ) : (
                    <>
                      <Upload />
                      <Typography variant="body2">Cliquer pour ajouter une image</Typography>
                      <Typography variant="body2">PNG, JPG, PDF...</Typography>
                    </>
                  )}
                  <Input type="file" sx={{ display: 'none' }} onChange={onChange} />
                </UploadContainer>
              );
            }}
          />
          <FormLine>
            <FormControlLabel
              control={<CheckboxRHF name="eco" control={control} />}
              label={<Typography>Eco Granit</Typography>}
            />
          </FormLine>
          <LowerCaseButton variant="contained" type="submit">
            {openedGranit ? 'Modifier le granit' : 'Ajouter un granit'}
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={toggleModal}>
            Annuler
          </LowerCaseButton>
        </StyledForm>
      </CenteredModal>
      <DeleteWarningModal
        header="SUPPRIMER LES GRANITS SÉLECTIONNÉS ?"
        handleDelete={handleDelete}
        open={openDelete}
        onClose={toggleDelete}
      />
      <DeliveryTimesForm open={editDeliveryTimes} toggleOpen={toggleEditDeliveryTimes} />
    </Stack>
  );
};

const UploadContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.black,
})) as typeof Button;

const Image = styled('img')({
  width: '100%',
});

const TagsContainer = styled(Box)({
  maxHeight: 75,
  overflow: 'auto',
  width: '100%',
});

export default Granits;
