/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { CheckCircle, ContentCopy, Delete } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Box, CircularProgress, Modal, Pagination, TextField, Typography } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalContainer } from '../../../components/3d/Fragments/Modal';
import { CenteredModal, ModalTitle } from '../../../components/CenteredModal';
import { ButtonRow, RoundedButton } from '../../../components/common';
import { CustomDataGrid } from '../../../components/DataGrid';
import DeleteWarningModal from '../../../components/DeleteWarningModal';
import SearchComponent from '../../../components/Search';
import { NavigationTabs } from '../../../components/Tabs';
import { getFile } from '../../../services/file';
import {
  confirmOrders,
  deleteQuotes,
  duplicateQuotes,
  orderQuotes,
  QuoteFile,
  QuoteFileType,
  QuoteStatus,
  updateQuote,
} from '../../../services/quotes';
import { isElevatedRole } from '../../../utils/user.utils';
import { Pdf } from '../components/Pdf';
import useQuotes from './useQuotes';

export const GridPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value - 1);
  };

  return pageCount > 1 ? (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '16px',
        borderTop: '1px solid #D0D5DD',
      }}
    >
      <Pagination page={page + 1} count={pageCount} onChange={handleChange} />
    </Box>
  ) : (
    <Box component="div" />
  );
};

const Quotes = ({
  filter,
  specialQuery,
}: {
  filter?: QuoteStatus[];
  specialQuery?: 'admin' | 'agencies';
}) => {
  const [docUrl, setDocUrl] = useState('');
  const {
    rows,
    search,
    setSearch,
    showDocs,
    setShowDoc,
    canDelete,
    columns,
    checkedQuotes,
    setCheckedQuotes,
    handleAction,
    canDuplicateOrOrder,
    loading,
    quotesToDelete,
    setQuotesToDelete,
    canConfirmOrders,
    user,
    quotePage,
    setQuotePage,
    onRefresh,
    canOrder,
  } = useQuotes(filter, specialQuery);

  const setUrl = async (key: string) => {
    const url = await getFile(key);

    setDocUrl(url);
  };

  const [isSecured, setIsSecured] = useState<boolean>(true);
  const [fileData, setFileData] = useState<{
    quoteNumber?: string;
    deliveryDelay?: string;
    totalPrice?: string;
    supplier?: string;
  }>();
  const [file, setFile] = useState<QuoteFile>();

  useEffect(() => {
    setIsSecured(true);
  }, [quotePage]);

  useEffect(() => {
    setFileData({
      quoteNumber: file?.quoteNumber,
      deliveryDelay: file?.deliveryDelay,
      totalPrice: file?.totalPrice,
      supplier: file?.supplier,
    });
  }, [file]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = async () => {
    if (file && fileData) {
      const newQuote = await updateQuote({
        assemblyId: file.assemblyId,
        type: file.type,
        ...fileData,
      });

      await onRefresh();
      if (newQuote) {
        const showDocTmp = newQuote.quoteFile.map((el) => {
          return { ...el, assemblyId: file.assemblyId };
        });

        const fileTmp = showDocTmp.find((doc) => doc.type === file.type);

        setFile(fileTmp);
        setUrl(fileTmp?.key || '');
        setShowDoc(showDocTmp);
      }
    }
  };

  const displayDocument = useCallback(
    (tab: QuoteFileType) => {
      const fileTmp = showDocs.find((doc) => doc.type === tab);

      if (!file || file.key !== fileTmp?.key) {
        setFile(fileTmp);
        setUrl(fileTmp?.key || '');
      }

      const downloadPDF = async (
        url: string,
        filename = `${fileTmp?.quoteNumber ?? 'document'}.pdf`,
      ) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          saveAs(blob, filename);
        } catch (error) {
          console.error('Erreur lors du téléchargement du PDF :', error);
        }
      };

      return isSecured && (tab === 'DELIVERY_QUOTE' || tab === 'COMPANY_QUOTE') ? (
        <Box
          component="div"
          sx={{
            display: 'flex',
            padding: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Ce document est sécurisé, voulez vous le visualiser ?</Typography>
          <Typography
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setIsSecured(false)}
          >
            oui
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            component="div"
            sx={{
              padding: '16px',
              paddingBottom: '0px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <DownloadIcon style={{ cursor: 'pointer' }} onClick={() => downloadPDF(docUrl)} />
            <FileOpenIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(docUrl, '_blank');
              }}
            />
            {/* {file?.type === 'DELIVERY_QUOTE' && user?.role === 'ADMIN' ? (
              <TextField
                id="outlined-basic"
                label="Numéro"
                variant="outlined"
                value={fileData?.quoteNumber}
                onChange={(e) => setFileData({ ...fileData, quoteNumber: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleUpdate();
                }}
              />
            ) : null} */}

            {(file?.type === 'DELIVERY_QUOTE' || file?.type === 'DELIVERY_ORDER') &&
            user?.role === 'ADMIN' ? (
              <TextField
                id="outlined-basic"
                label="Livraison"
                variant="outlined"
                value={fileData?.deliveryDelay}
                onChange={(e) => setFileData({ ...fileData, deliveryDelay: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleUpdate();
                }}
                onBlur={() => {
                  handleUpdate();
                }}
              />
            ) : null}

            {file?.type === 'DELIVERY_ORDER' && user?.role === 'ADMIN' ? (
              <>
                <TextField
                  id="outlined-basic"
                  label="Prix total"
                  variant="outlined"
                  value={fileData?.totalPrice}
                  onChange={(e) => setFileData({ ...fileData, totalPrice: e.target.value })}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') handleUpdate();
                  }}
                  onBlur={() => {
                    handleUpdate();
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Fournisseur"
                  variant="outlined"
                  value={fileData?.supplier}
                  onChange={(e) => setFileData({ ...fileData, supplier: e.target.value })}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') handleUpdate();
                  }}
                  onBlur={() => {
                    handleUpdate();
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Numéro"
                  variant="outlined"
                  value={fileData?.quoteNumber}
                  onChange={(e) => setFileData({ ...fileData, quoteNumber: e.target.value })}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') handleUpdate();
                  }}
                />
              </>
            ) : null}
          </Box>
          <Pdf url={docUrl} />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showDocs, file?.key, isSecured, fileData, docUrl, handleUpdate],
  );

  const navigatorKeys: string[] = useMemo(() => {
    return showDocs
      .map((doc) => doc.type)
      .filter((el) => {
        if (quotePage === 'quote') {
          if (
            (el === 'COMPANY_QUOTE' && user?.company?.canSeePurchasePrice) ||
            el === 'CLIENT_QUOTE'
          )
            return true;
          return false;
        }
        if (quotePage === 'voucher') {
          if (user?.role !== 'ADMIN' && el === 'DELIVERY_ORDER') return false;
          if (
            el === 'CLIENT_DELIVERY_QUOTE' ||
            (el === 'DELIVERY_QUOTE' && user?.company?.canSeePurchasePrice) ||
            el === 'DELIVERY_ORDER'
          )
            return true;
          return false;
        }
        return false;
      })
      .sort((a, b) => {
        const sortOrder = {
          CLIENT_QUOTE: 1,
          COMPANY_QUOTE: 2,
          CLIENT_DELIVERY_QUOTE: 3,
          DELIVERY_QUOTE: 4,
          DELIVERY_ORDER: 5,
        };

        return sortOrder[a] - sortOrder[b];
      });
  }, [quotePage, showDocs, user]);

  const hasPurchasedOrder = useMemo(() => {
    const orders = showDocs.filter((el) => {
      if (
        el.type === 'CLIENT_DELIVERY_QUOTE' ||
        el.type === 'DELIVERY_QUOTE' ||
        el.type === 'DELIVERY_ORDER'
      ) {
        return true;
      }
      return false;
    });

    if (orders.length > 0) {
      return true;
    }
    return false;
  }, [showDocs]);

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          padding: '16px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonRow>
          <RoundedButton
            variant="outlined"
            onClick={() =>
              handleAction(
                checkedQuotes as number[],
                orderQuotes,
                'La commande a bien été commandée',
              )
            }
            disabled={!canOrder}
          >
            <CheckCircle color={canOrder ? 'primary' : 'inherit'} />
            Commander
          </RoundedButton>
          {isElevatedRole(user) && (
            <RoundedButton
              variant="outlined"
              onClick={() =>
                handleAction(
                  checkedQuotes as number[],
                  confirmOrders,
                  'La commande à bien été validée',
                )
              }
              disabled={!canConfirmOrders}
            >
              <CheckCircle color={canConfirmOrders ? 'primary' : 'inherit'} />
              Valider la commande
            </RoundedButton>
          )}
          <RoundedButton
            variant="outlined"
            onClick={() => handleAction(checkedQuotes as number[], duplicateQuotes)}
            disabled={!canDuplicateOrOrder}
          >
            <ContentCopy color={canDuplicateOrOrder ? 'primary' : 'inherit'} />
            Dupliquer
          </RoundedButton>
          <RoundedButton
            variant="outlined"
            onClick={() => setQuotesToDelete(checkedQuotes as number[])}
            disabled={!canDelete}
          >
            <Delete color={canDelete ? 'primary' : 'inherit'} />
            Supprimer
          </RoundedButton>
        </ButtonRow>
        <SearchComponent search={search} setSearch={setSearch} />
      </Box>
      <CustomDataGrid
        columns={columns}
        rows={rows}
        rowHeight={80}
        checkboxSelection
        components={{
          Footer: GridPagination,
        }}
        sx={{ flex: '1' }}
        onSelectionModelChange={(newSelectionModel) => {
          setCheckedQuotes(newSelectionModel);
        }}
        selectionModel={checkedQuotes}
      />
      <CenteredModal
        open={showDocs.length > 0}
        handleClose={() => setShowDoc([])}
        sx={{ width: '50%', height: '80%', overflowY: 'scroll' }}
      >
        <ModalTitle sx={{ gap: 4 }}>
          <Typography
            variant="h5"
            sx={{ cursor: 'pointer', textDecoration: quotePage === 'quote' ? 'underline' : 'none' }}
            onClick={() => setQuotePage('quote')}
          >
            Devis
          </Typography>
          {hasPurchasedOrder ? (
            <Typography
              variant="h5"
              sx={{
                cursor: 'pointer',
                textDecoration: quotePage === 'voucher' ? 'underline' : 'none',
              }}
              onClick={() => setQuotePage('voucher')}
            >
              Bon de commande
            </Typography>
          ) : null}
        </ModalTitle>
        <NavigationTabs tabs={navigatorKeys} tabToComponent={displayDocument} />
      </CenteredModal>
      <Modal open={loading}>
        <ModalContainer>
          <CircularProgress />
        </ModalContainer>
      </Modal>
      <DeleteWarningModal
        open={quotesToDelete.length > 0}
        onClose={() => setQuotesToDelete([])}
        header={`Supprimer ${quotesToDelete.length} devis ?`}
        handleDelete={() => handleAction(quotesToDelete, deleteQuotes)}
      />
    </>
  );
};

export default Quotes;
