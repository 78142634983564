import { StateNames, States } from 'src/Screens/Configurator';
import { Assembly } from 'src/services/assemblies';
import { Modification } from 'src/services/configurator';
import { Piece } from 'src/services/pieces';
import { isAccesory } from 'src/utils/configurator.utils';
import styled from 'styled-components';

import { Box } from './Dimension/Box';
import { CustomPlatings } from './Dimension/CustomPlatings';
import { CustomSteles } from './Dimension/CustomSteles';
import { Foundation } from './Dimension/Foundation';
import { Others } from './Dimension/Other';
import { Sole } from './Dimension/Sole';
import { Steles } from './Dimension/Steles';
import { Tombale } from './Dimension/Tombale';

interface IDimensionProps {
  activePieces: Piece[];
  customSteles: any[];
  pieceIndex: number;
  setCustomSteles: (customSteles: any[]) => void;
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  geometry: number[];
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void;
  pieces: any[];
  assembly: Assembly | null;
  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void;
  modifications: Modification[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  refreshModifications: () => void;
  reloadPiece: boolean;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
}

export const Dimension = ({
  activePieces,
  customSteles,
  pieceIndex,
  setCustomSteles,
  changeGeometry,
  geometry,
  checkCollision,
  pieces,
  assembly,
  setCustomPlatings,
  customPlatings,
  selectedPoints,
  setSelectedPoints,
  changePoint,
  modifications,
  history,
  refreshModifications,
  reloadPiece,
  setValue,
  getValue,
}: IDimensionProps) => {
  if (pieceIndex === -1) return null;
  return (
    <Container>
      {activePieces.length > 0 || customSteles.length > 0 || customPlatings.length > 0 ? (
        <>
          {pieceIndex >= activePieces.length ? (
            <>
              {pieceIndex >= activePieces.length &&
              pieceIndex < activePieces.length + customSteles.length ? (
                <CustomSteles
                  customSteles={customSteles}
                  setCustomSteles={setCustomSteles}
                  steleIndex={pieceIndex - (activePieces ? activePieces.length : 0)}
                  pieceIndex={pieceIndex}
                />
              ) : null}
              {pieceIndex >= activePieces.length + customSteles.length ? (
                <CustomPlatings
                  pieces={pieces}
                  setCustomPlatings={setCustomPlatings}
                  customPlatings={customPlatings}
                  setValue={setValue}
                  getValue={getValue}
                />
              ) : null}
            </>
          ) : (
            <>
              {activePieces[pieceIndex]?.type === 'STELE' ? (
                <Steles
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  geometry={geometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}

              {activePieces[pieceIndex]?.type === 'ANY' ||
              activePieces[pieceIndex]?.type === 'PLATING' ||
              isAccesory(activePieces[pieceIndex]?.type) ? (
                <Others
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  geometry={geometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}
              {activePieces[pieceIndex]?.type === 'TOMB' ? (
                <Tombale
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  geometry={geometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}
              {activePieces[pieceIndex]?.type === 'FOUNDATION' ? (
                <Foundation
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  geometry={geometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}
              {activePieces[pieceIndex]?.type === 'BOX' ? (
                <Box
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  geometry={geometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}
              {activePieces[pieceIndex]?.type === 'SOLE' ? (
                <Sole
                  setValue={setValue}
                  getValue={getValue}
                  changeGeometry={changeGeometry}
                  checkCollision={checkCollision}
                  pieces={pieces}
                  pieceIndex={pieceIndex}
                  assembly={assembly}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                  changePoint={changePoint}
                  modifications={modifications}
                  history={history}
                  refreshModifications={refreshModifications}
                  activePieces={activePieces}
                  reloadPiece={reloadPiece}
                />
              ) : null}
            </>
          )}
        </>
      ) : null}
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',

  maxHeight: window.innerHeight - 334,
  overflowY: 'auto',

  padding: '22px',

  top: 0,
});
