import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path d="M6.01375 7.98315C7.28015 9.24875 8.74735 10.46 9.32735 9.87995C10.1569 9.05035 10.6689 8.32715 12.4993 9.79835C14.3289 11.2688 12.9233 12.2496 12.1193 13.0528C11.1913 13.9808 7.73215 13.1024 4.31295 9.68395C0.89455 6.26475 0.018549 2.80555 0.94735 1.87755C1.75135 1.07275 2.72735 -0.332047 4.19775 1.49755C5.66895 3.32715 4.94655 3.83915 4.11535 4.66955C3.53775 5.24955 4.74815 6.71675 6.01375 7.98315Z" />
    </SvgIcon>
  );
};

export default PhoneIcon;
