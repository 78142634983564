import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M11.6667 13.3338L11.6667 13.75V13.75C11.6667 14.2921 11.6667 14.5632 11.6405 14.7912C11.4279 16.638 9.97096 18.0949 8.12413 18.3075C7.89617 18.3338 7.62512 18.3338 7.08301 18.3338H6.66672C5.73744 18.3338 5.27279 18.3338 4.88641 18.2569C3.29963 17.9413 2.05922 16.7009 1.7436 15.1141C1.66675 14.7277 1.66675 14.2631 1.66675 13.3338L1.66675 6.66711C1.66675 5.7378 1.66675 5.27315 1.74361 4.88675C2.05924 3.29999 3.29963 2.0596 4.88639 1.74397C5.27278 1.66711 5.73744 1.66711 6.66675 1.66711L7.08382 1.66711C7.62522 1.66711 7.89591 1.66711 8.12358 1.69329C9.97087 1.90565 11.4282 3.36299 11.6406 5.21028C11.6667 5.43795 11.6667 5.70865 11.6667 6.25004V6.25004V6.66711M7.50008 10L18.3334 10M18.3334 10L15.0001 6.66671M18.3334 10L15.0001 13.3334"
        stroke="#769FAC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LogoutIcon;
