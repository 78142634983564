import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Assemblies } from '../../../components/3d/Assemblies';
import { useUser } from '../../../context/UserProvider';
import useLoadDataList from '../../../hook/useLoadDataList';
import paths from '../../../navigation/paths';
import { getAllAssemblies } from '../../../services/assemblies';
import AdminPageWithTabs from '../components/AdminPageWithTabs';
import Catalogs from './records/Catalogs';

const tabs = ['catalogs', 'allAssemblies'] as const;

type Tabs = (typeof tabs)[number];

const CatalogManagement = () => {
  const { homePath } = useUser();
  const navigate = useNavigate();
  const { data: assemblies, onRefresh } = useLoadDataList(getAllAssemblies);

  const tabsToComponent = useCallback(
    (tab: Tabs) => {
      const {
        home: {
          project: { root, configurator },
        },
      } = paths;
      switch (tab) {
        case 'allAssemblies': {
          return (
            <Assemblies
              onClick={(id: number) => navigate(`${homePath}/${root}/${configurator.root}/${id}`)}
              assemblies={assemblies}
              onRefresh={onRefresh}
            />
          );
        }
        default: {
          return <Catalogs />;
        }
      }
    },
    [assemblies, homePath, navigate, onRefresh],
  );
  return (
    <AdminPageWithTabs
      pageName="Gestions des monuments "
      tabs={tabs}
      tabToComponent={tabsToComponent}
    />
  );
};

export default CatalogManagement;
