/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { StateNames, States } from 'src/Screens/Configurator';
import { Mesh, PerspectiveCamera, Vector2 } from 'three';
import * as THREE from 'three';

export const getCameraDirection = (axis: 'x' | 'y' | 'z'): THREE.Vector3 => {
  switch (axis) {
    case 'x': {
      return new THREE.Vector3(1, 0, 0);
    }
    case 'y': {
      return new THREE.Vector3(0, 1, 0);
    }
    case 'z': {
      return new THREE.Vector3(0, 0, 1);
    }
    default: {
      throw new Error('Invalid axis');
    }
  }
};

const calculateCameraDistance = (
  mesh: Mesh,
  camera: PerspectiveCamera,
  canvasWidth: number,
  canvasHeight: number,
): number => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const array = mesh.geometry.attributes.position.array as number[];

  const boundingBox = new THREE.Box3().setFromArray([...array]);

  const size = boundingBox.getSize(new THREE.Vector3());
  const maxSize = Math.max(size.x, size.y, size.z);

  const aspect = canvasWidth / canvasHeight;
  const verticalFov = camera.fov * (Math.PI / 180);
  const horizontalFov = 2 * Math.atan(Math.tan(verticalFov / 2) * aspect);

  const distanceX = maxSize / (2 * Math.tan(horizontalFov / 2));
  const distanceY = maxSize / (2 * Math.tan(verticalFov / 2));

  return Math.max(distanceX, distanceY);
};

export const calculateCameraPosition = (
  mesh: Mesh,
  distance: number,
  cameraDirection: THREE.Vector3,
): THREE.Vector3 => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const array = mesh.geometry.attributes.position.array as number[];
  const boundingBox = new THREE.Box3().setFromArray([...array]);

  const meshCenter = boundingBox.getCenter(new THREE.Vector3());

  const cameraPosition = meshCenter
    .clone()
    .add(cameraDirection.clone().normalize().multiplyScalar(distance));

  return cameraPosition;
};

export const updateCameraPosition = (
  gl: any,
  orbitRef: any,
  pieceIndex: number,
  pieces: any[],
  customSteles: any[],
  customPlatings: any[],
  orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean },
) => {
  if (pieceIndex === -1) return;
  let mesh: Mesh<any, THREE.MeshBasicMaterial> | null = null;

  if (pieceIndex < pieces.length) {
    // Pieces classique
    mesh = new THREE.Mesh(pieces[pieceIndex]?.geometry, new THREE.MeshBasicMaterial());
  } else if (
    // Custom platings
    pieceIndex >= pieces.length + customSteles.length &&
    pieceIndex < pieces.length + customSteles.length + customPlatings.length
  ) {
    const extrudeGeometry = new THREE.ExtrudeGeometry(
      customPlatings[pieceIndex - pieces.length - customSteles.length].shape,
      {
        curveSegments: 12,
        steps: 2,
        depth: customPlatings[pieceIndex - pieces.length - customSteles.length].shapeDetails.depth,
        bevelEnabled: false,
      },
    );

    mesh = new THREE.Mesh(extrudeGeometry, new THREE.MeshBasicMaterial());
  } else {
    // Custom stèle
    const extrudeGeometry = new THREE.ExtrudeGeometry(
      customSteles[pieceIndex - pieces.length].shape,
      customSteles[pieceIndex - pieces.length].extrudeSettings,
    );
    mesh = new THREE.Mesh(extrudeGeometry, new THREE.MeshBasicMaterial());
  }
  const canvasSize = gl?.getSize(new Vector2());

  const requiredDistance = calculateCameraDistance(
    mesh,
    orbitRef.current.object,
    canvasSize?.x ?? 0,
    canvasSize?.y ?? 0,
  );

  if (orientation.key !== 'free') {
    const cameraDirection = getCameraDirection(orientation.key).negate(); // Placer la caméra dans la direction opposée
    const cameraPosition = calculateCameraPosition(mesh, requiredDistance, cameraDirection);
    orbitRef.current.object.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    orbitRef.current.object.lookAt(mesh.position);
  }
};

export const centerCameraOnPiece = (pieceIndex: number, piece: any, orbitRef: any) => {
  if (pieceIndex === -1) return;
  const mesh = new THREE.Mesh(piece.geometry, new THREE.MeshBasicMaterial({ color: 'red' }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const array = mesh.geometry.attributes.position.array as number[];
  const box = new THREE.Box3().setFromArray([...array]);

  const center = box.getCenter(new THREE.Vector3()); // Obtenir le centre de la boîte

  orbitRef.current.target = new THREE.Vector3(
    piece.position.x + center.x,
    piece.position.y + center.y,
    piece.position.z + center.z,
  );
};

export const updateOrbit = (
  gl: any,
  orbitRef: any,
  pieceIndex: number,
  pieces: any[],
  customSteles: any[],
  customPlatings: any[],
  orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean },
  centerCamera: () => void,
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void,
  updateKanvas: () => void,
) => {
  if (orientation.key === 'free') {
    centerCamera();

    orbitRef.current.enablePan = true;
    orbitRef.current.enableRotate = true;
    orbitRef.current.object.zoom = 1;
    orbitRef.current.object.fov = 40;
    setValue('allPieces', true);
  } else {
    orbitRef.current.enablePan = false;
    orbitRef.current.enableRotate = false;
    orbitRef.current.object.zoom = 1;
    orbitRef.current.object.fov = 1;
    orbitRef.current.object.position.z = 100;
    orbitRef.current.dampingFactor = 1;
    setValue('allPieces', false);
    updateCameraPosition(
      gl,
      orbitRef,
      pieceIndex,
      pieces,
      customSteles,
      customPlatings,
      orientation,
    );
    updateKanvas();
    if (orientation.key === 'y') {
      orbitRef.current.setAzimuthalAngle(0);
      orbitRef.current.setPolarAngle(orientation.inverse ? Math.PI : 0);
      orbitRef.current.object.updateProjectionMatrix();
      orbitRef.current.setAzimuthalAngle(0);
      orbitRef.current.setPolarAngle(orientation.inverse ? Math.PI : 0);
    }
    if (orientation.key === 'x') {
      orbitRef.current.setAzimuthalAngle(orientation.inverse ? Math.PI + Math.PI / 2 : Math.PI / 2);
      orbitRef.current.setPolarAngle(Math.PI / 2);
      orbitRef.current.object.updateProjectionMatrix();
      orbitRef.current.setAzimuthalAngle(orientation.inverse ? Math.PI + Math.PI / 2 : Math.PI / 2);
      orbitRef.current.setPolarAngle(Math.PI / 2);
    }
    orbitRef.current.object.updateProjectionMatrix();
  }
  if (orientation.key === 'z') {
    orbitRef.current.setAzimuthalAngle(orientation.inverse ? Math.PI : 0);
    orbitRef.current.setPolarAngle(Math.PI / 2);
    orbitRef.current.object.updateProjectionMatrix();
    orbitRef.current.setAzimuthalAngle(orientation.inverse ? Math.PI : 0);
    orbitRef.current.setPolarAngle(Math.PI / 2);
  }
};
