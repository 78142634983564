/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
import { styled, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { StateNames, States } from 'src/Screens/Configurator';
import { WebGLRenderer } from 'three';

import { Create } from './UnderAssembly/Create';
import { Update } from './UnderAssembly/Update';

type UnderAssemblyProps = {
  pieces: any[];
  activePieces: any[];
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  centerCamera: () => void;
  gl: WebGLRenderer | undefined;
};

export const UnderAssembly = ({
  pieces,
  activePieces,
  setValue,
  setPieceIndex,
  centerCamera,
  gl,
}: UnderAssemblyProps) => {
  const [menu, setMenu] = useState<'create' | 'update'>('create');

  return (
    <Container>
      <Tabs
        value={menu === 'create' ? 0 : 1}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setMenu(newValue === 0 ? 'create' : 'update');
        }}
      >
        <Tab label="Sous assemblage" />
        <Tab label="Modifier" />
      </Tabs>

      {menu === 'create' && pieces && pieces.length > 0 ? (
        <Create
          pieces={pieces}
          activePieces={activePieces}
          setValue={setValue}
          setPieceIndex={setPieceIndex}
          centerCamera={centerCamera}
          gl={gl}
        />
      ) : null}

      {menu === 'update' ? <Update /> : null}
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  padding: '15px',

  width: '100%',
  gap: '12px',
});
