import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

type ErrorResponse = {
  status: number;
  message: string | string[];
};

export const handleApiError = (error: unknown, navigate?: () => void) => {
  if (axios.isAxiosError(error) && error.response) {
    switch (error.response.status) {
      case 403:
      case 400: {
        if (typeof error.response.data === 'object' && error.response.data !== null) {
          const { message } = error.response.data as ErrorResponse;
          if (Array.isArray(message) && message.length > 1) {
            toast.error(
              <ul>
                {message.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>,
            );
          } else {
            toast.error(message.toString());
          }
        }
        break;
      }
      case 401: {
        toast.error('Accès non autorisé');
        if (navigate) navigate();
        break;
      }
      default: {
        toast.error('Une erreur inconnue est survenue');
      }
    }
  } else {
    toast.error('Une erreur inconnue est survenue');
  }
};

export const handleMutation = async <T, U>(
  mutationFunction: (datas: T) => Promise<AxiosResponse<U>>,
  datas: T,
  postMutationActions?: () => void,
  handleDatas?: (data: U) => void,
) => {
  try {
    const { data } = await mutationFunction(datas);

    if (postMutationActions) postMutationActions();
    if (handleDatas) handleDatas(data);
  } catch (error) {
    handleApiError(error);
  }
};
