import 'react-phone-number-input/style.css';

import TextField from '@mui/material/TextField';
import { forwardRef, HTMLProps } from 'react';

type CustomPhoneInputProps = HTMLProps<HTMLInputElement> & {
  error: boolean;
  helperText?: string;
  isRequired?: boolean;
};

export const CustomPhoneInput = forwardRef<HTMLInputElement, CustomPhoneInputProps>(
  ({ error, helperText, isRequired, disabled, label, ...props }, ref) => {
    const customLabel = label && isRequired ? `${label} *` : label;

    return (
      <TextField
        disabled={disabled}
        error={error}
        helperText={helperText}
        inputProps={props}
        inputRef={ref}
        fullWidth
        label={customLabel}
        variant="outlined"
      />
    );
  },
);
