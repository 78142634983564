import { SxProps, Typography } from '@mui/material';

import PageWithNavBar from './PageWithNavBar';
import { NavigationTabs } from './Tabs';

export type INavigationTabsProps<T extends string> = {
  tabs: readonly T[];
  pageName: React.ReactNode;
  tabToComponent: (tab: T) => React.ReactNode;
  tabsSx?: SxProps;
  defaultTab?: T;
};

const PageWithTabs = <T extends string>({ pageName, ...props }: INavigationTabsProps<T>) => {
  return (
    <PageWithNavBar>
      <Typography variant="h1" sx={{ flex: 0 }}>
        {pageName}
      </Typography>
      <NavigationTabs {...props} />
    </PageWithNavBar>
  );
};

export default PageWithTabs;
