import { Check, Clear } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';

const StyledList = styled(List)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#F2F4F7',
});

const PasswordChecklist = ({ password }: { password: string }) => {
  const hasRequiredLength = password.length >= 8;
  const hasDigit = /\d/.test(password);
  const hasLower = /[a-z]/.test(password);
  const hasUpper = /[A-Z]/.test(password);
  const hasSpecial = /[!-/:-@[-`{-~]/.test(password);

  return (
    <StyledList dense>
      <div>
        <ListItem>
          <ListItemText>Votre mot de passe doit contenir</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasRequiredLength ? <Check color="primary" /> : <Clear color="primary" />}
          </ListItemIcon>
          <ListItemText>au moins 8 caractères</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasDigit ? <Check color="primary" /> : <Clear color="primary" />}
          </ListItemIcon>
          <ListItemText>au moins un chiffre</ListItemText>
        </ListItem>
      </div>
      <div>
        <ListItem>
          <ListItemIcon>
            {hasUpper ? <Check color="primary" /> : <Clear color="primary" />}
          </ListItemIcon>
          <ListItemText>au moins une lettre majuscule</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasLower ? <Check color="primary" /> : <Clear color="primary" />}
          </ListItemIcon>
          <ListItemText>au moins une lettre minuscule</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasSpecial ? <Check color="primary" /> : <Clear color="primary" />}
          </ListItemIcon>
          <ListItemText>au moins 1 caractère spécial</ListItemText>
        </ListItem>
      </div>
    </StyledList>
  );
};

export default PasswordChecklist;
