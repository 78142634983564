import PageWithTabs from '../../components/PageWithTabs';
import Quotes from './records/Quotes';
import { ORDER, QUOTE, TO_CONFIRM } from './records/quotes.helper';

const tabs = ['quotes', 'orders'] as const;
type Tab = (typeof tabs)[number];

const QuotesAndOrders = () => {
  const tabsToComponent = (tab: Tab) => {
    switch (tab) {
      case 'orders': {
        return <Quotes filter={[...ORDER, ...TO_CONFIRM]} />;
      }
      default: {
        return <Quotes filter={['QUOTE', 'DRAFT']} />;
      }
    }
  };

  return <PageWithTabs tabs={tabs} pageName="Devis / Commandes" tabToComponent={tabsToComponent} />;
};

export default QuotesAndOrders;
