import { Close } from '@mui/icons-material';
import { Box, Modal, Paper, styled, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface ICenteredModalProps {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
  disableCloseOnOutsideClick?: boolean;
  padding?: boolean;
  sx?: SxProps;
}

const Container = styled(Paper)({
  position: 'absolute',
  width: '40%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '80%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const StyledCloseIcon = styled(Close)({
  position: 'absolute',
  top: 20,
  right: 32,

  '&:hover': {
    cursor: 'pointer',
    opacity: 0.7,
  },
});

export const ModalTitle = styled(Box)({
  backgroundColor: '#ECF7FA',
  display: 'flex',
  paddingTop: '50px',
  paddingBottom: '20px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CenteredModal = ({
  open,
  handleClose,
  children,
  disableCloseOnOutsideClick,
  sx,
  padding = true,
}: ICenteredModalProps) => (
  <Modal open={open} onClose={disableCloseOnOutsideClick ? undefined : handleClose}>
    <Container elevation={8} sx={{ padding: padding ? 0 : '32px', ...sx }}>
      <StyledCloseIcon onClick={handleClose} />
      {children}
    </Container>
  </Modal>
);
