import { DependencyList, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'src/navigation/paths';

import { handleApiError } from '../utils/api.utils';

function useLoadData<T>(
  loadFunction: () => Promise<T | null>,
  hookDependencies?: DependencyList,
  condition = true,
) {
  const [data, setData] = useState<T | null>(null);
  const [refreshing, setRefreshing] = useState(true);
  const navigate = useNavigate();

  const loadData = async () => {
    try {
      if (condition) {
        const res = await loadFunction();
        setData(res);
        setRefreshing(false);
      } else {
        setData(null);
        setRefreshing(false);
      }
    } catch (error) {
      setRefreshing(false);
      handleApiError(error, () => navigate(`/${paths.connexion.root}`, { replace: true }));
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  return { refreshing, loadData, onRefresh, data };
}

export default useLoadData;
