/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-array-index-key */

import { zodResolver } from '@hookform/resolvers/zod';
import { Euro } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormLine, RoundedButton } from 'src/components/common';
import useLoadData from 'src/hook/useLoadData';
import {
  Engravings,
  getAdminEngravingsPrice,
  updateAdminEngravingsCoefficients,
} from 'src/services/coefficients';
import { handleMutation } from 'src/utils/api.utils';
import { DEFAULT_ENGRAVINGS } from 'src/utils/coefficients.utils';
import styled from 'styled-components';
import { z } from 'zod';

export const SetEngravingsPrice = () => {
  const tableDefaultValues = [2, 3, 4, 5, 6, 7, 8];

  const { data: adminEngravingsPrice } = useLoadData(() => getAdminEngravingsPrice());

  const schemaCoefficientsForm = z.object({
    gold: z.object({
      coeff1: z.string(),
      coeff2: z.string(),
      coeff3: z.string(),
      coeff4: z.string(),
      coeff5: z.string(),
      coeff6: z.string(),
      coeff7: z.string(),
    }),
    white: z.object({
      coeff1: z.string(),
      coeff2: z.string(),
      coeff3: z.string(),
      coeff4: z.string(),
      coeff5: z.string(),
      coeff6: z.string(),
      coeff7: z.string(),
    }),
  });
  type SchemaCoefficientsForm = z.infer<typeof schemaCoefficientsForm>;

  const convertGlobalCoefsFromIntToString = (globalCoefs: Engravings) => {
    return {
      gold: {
        coeff1: String(globalCoefs.gold.coeff1),
        coeff2: String(globalCoefs.gold.coeff2),
        coeff3: String(globalCoefs.gold.coeff3),
        coeff4: String(globalCoefs.gold.coeff4),
        coeff5: String(globalCoefs.gold.coeff5),
        coeff6: String(globalCoefs.gold.coeff6),
        coeff7: String(globalCoefs.gold.coeff7),
      },
      white: {
        coeff1: String(globalCoefs.white.coeff1),
        coeff2: String(globalCoefs.white.coeff2),
        coeff3: String(globalCoefs.white.coeff3),
        coeff4: String(globalCoefs.white.coeff4),
        coeff5: String(globalCoefs.white.coeff5),
        coeff6: String(globalCoefs.white.coeff6),
        coeff7: String(globalCoefs.white.coeff7),
      },
    };
  };
  const checkIsNaN = (value: number, defautValue: number) => {
    if (Number.isNaN(value)) return defautValue;
    return value;
  };

  const convertGlobalCoefsFromStringToInt = (engravings: any) => {
    return {
      gold: {
        coeff1: checkIsNaN(Number.parseFloat(engravings.gold.coeff1 as string), 0),
        coeff2: checkIsNaN(Number.parseFloat(engravings.gold.coeff2 as string), 0),
        coeff3: checkIsNaN(Number.parseFloat(engravings.gold.coeff3 as string), 0),
        coeff4: checkIsNaN(Number.parseFloat(engravings.gold.coeff4 as string), 0),
        coeff5: checkIsNaN(Number.parseFloat(engravings.gold.coeff5 as string), 0),
        coeff6: checkIsNaN(Number.parseFloat(engravings.gold.coeff6 as string), 0),
        coeff7: checkIsNaN(Number.parseFloat(engravings.gold.coeff7 as string), 0),
      },
      white: {
        coeff1: checkIsNaN(Number.parseFloat(engravings.white.coeff1 as string), 0),
        coeff2: checkIsNaN(Number.parseFloat(engravings.white.coeff2 as string), 0),
        coeff3: checkIsNaN(Number.parseFloat(engravings.white.coeff3 as string), 0),
        coeff4: checkIsNaN(Number.parseFloat(engravings.white.coeff4 as string), 0),
        coeff5: checkIsNaN(Number.parseFloat(engravings.white.coeff5 as string), 0),
        coeff6: checkIsNaN(Number.parseFloat(engravings.white.coeff6 as string), 0),
        coeff7: checkIsNaN(Number.parseFloat(engravings.white.coeff7 as string), 0),
      },
    };
  };

  const defaultValues = useMemo(() => {
    return adminEngravingsPrice
      ? convertGlobalCoefsFromIntToString(adminEngravingsPrice)
      : convertGlobalCoefsFromIntToString(DEFAULT_ENGRAVINGS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminEngravingsPrice]);

  const { handleSubmit, watch, setValue, reset } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaCoefficientsForm),
  });

  useEffect(() => {
    reset(
      adminEngravingsPrice ? convertGlobalCoefsFromIntToString(adminEngravingsPrice) : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, adminEngravingsPrice]);

  const resetEngravingValues = (setFormValues: (name: any, value: string) => void) => {
    for (let i = 0; i < 7; i += 1) {
      setFormValues(`white.coeff${(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`, String(i + 1));
      setFormValues(`gold.coeff${(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`, String(i + 1));
    }
  };

  const submit: SubmitHandler<SchemaCoefficientsForm> = (datas) => {
    handleMutation(
      (data) => updateAdminEngravingsCoefficients(convertGlobalCoefsFromStringToInt(data)),
      datas,
    );
  };

  return (
    <Container onBlur={handleSubmit(submit)}>
      <Box component="div">
        <Typography variant="h5">Prix d&apos;achat des gravures</Typography>
        <Typography>Vous pouvez définir le prix d&apos;achat des gravures</Typography>
      </Box>

      <Box sx={{ borderBottom: '1px solid #F2F4F7', paddingBottom: '30px' }} component="div">
        <Typography variant="h6">Gravure</Typography>
        <Typography color="#273135" fontSize="small">
          Votre tarif à la lettre en euros HT pour :
        </Typography>
        <FormLine sx={{ gap: 0 }}>
          <Column sx={{ borderRight: '1px solid #F2F4F7', flexDirection: 'row' }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledHeaderCell sx={{ borderTopLeftRadius: '8px' }} />
                  {tableDefaultValues.map((_value, index) =>
                    index === tableDefaultValues.length - 1 ? (
                      <StyledHeaderCell
                        sx={{ borderTopRightRadius: '8px' }}
                        key={`${index}cm`}
                      >{`${index}cm+`}</StyledHeaderCell>
                    ) : (
                      <StyledHeaderCell key={`${index}cm`}>{`${index + 1}cm`}</StyledHeaderCell>
                    ),
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <StyledHeaderCell> Lettres dorées / noires</StyledHeaderCell>
                  {Object.keys(watch('gold')).map((_value, index) => {
                    return (
                      <StyledCell key={`gold${index}`}>
                        <TableInput
                          value={watch(`gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`)}
                          name={`gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`}
                          onChange={(event) => {
                            setValue(
                              `gold.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                              event.target.value,
                            );
                          }}
                        />
                      </StyledCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <StyledHeaderCell sx={{ borderBottomLeftRadius: '8px' }}>
                    Lettres blanches
                  </StyledHeaderCell>
                  {Object.keys(watch('white')).map((_value, index) => {
                    return (
                      <StyledCell key={`white${index}`}>
                        <TableInput
                          value={watch(`white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`)}
                          name={`white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`}
                          onChange={(event) => {
                            setValue(
                              `white.coeff${(index + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7}`,
                              event.target.value,
                            );
                          }}
                        />
                      </StyledCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </StyledTable>
            <FormLine>
              <RoundedButton
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetEngravingValues(setValue);
                }}
              >
                <Euro color="secondary" />
                Restaurer les tarifs par défaut
              </RoundedButton>
            </FormLine>
          </Column>
        </FormLine>
      </Box>
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
  padding: '30px',
});

const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '30px',
});

const StyledCell = styled(TableCell)({
  borderRight: '0.5px solid #BC9A67',
  borderBottom: '0.5px solid #BC9A67',
  padding: 0,
});

const StyledHeaderCell = styled(StyledCell)({
  background: '#F7F0E7',
  padding: '6px 8px',
  textAlign: 'center',
});

const CoefContainer = styled('form')({
  display: 'flex',
  flexDirection: 'row',
  padding: '30px',
  gap: 16,
});

const TableInput = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 0,
    border: 0,
  },
});

const StyledTable = styled(Table)({
  borderRadius: '8px',
  borderCollapse: 'unset',
  borderLeft: '0.5px solid #BC9A67',
  borderTop: '0.5px solid #BC9A67',
  maxWidth: '60%',
});
