import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import useLoadData from '../../../../hook/useLoadData';
import { getUserDetails } from '../../../../services/user';
import { isHeadOffice } from '../../../../utils/user.utils';
import AgenciesManagement from '../../../agencies/agenciesManagement/AgenciesManagement';
import AdditionalServices from '../../../mySpace/record/additionalServices/AdditionalServices';
import Coefficients from '../../../mySpace/record/coefficients/Coefficients';
import QuoteSettings from '../../../mySpace/record/quoteSettings/QuoteSettings';
import AdminPageWithTabs from '../../components/AdminPageWithTabs';
import General from './general/General';

const tabs = ['informations', 'quoteSettings', 'coefficients', 'additionalServices'] as const;
const headOfficeTabs = [...tabs, 'agencies'] as const;

type HeadOfficeTab = (typeof headOfficeTabs)[number];

const User = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data } = useLoadData(() => getUserDetails(userId));

  const tabsToComponent = (tab: HeadOfficeTab) => {
    if (!userId) return <General />;
    switch (tab) {
      case 'additionalServices': {
        return <AdditionalServices userId={userId} />;
      }
      case 'quoteSettings': {
        return <QuoteSettings userId={userId} />;
      }
      case 'coefficients': {
        return <Coefficients userId={userId} />;
      }
      case 'agencies': {
        return <AgenciesManagement userId={userId} />;
      }
      default: {
        return <General />;
      }
    }
  };
  return (
    <AdminPageWithTabs
      pageName={
        <>
          <IconButton onClick={() => navigate(-1)} color="inherit">
            <ArrowBack fontSize="large" />
          </IconButton>
          {data &&
            data.company.reason +
              (isHeadOffice(data) || !data.company.name ? '' : ` - ${data.company.name}`)}
        </>
      }
      tabs={data?.role === 'HEAD_OFFICE' ? headOfficeTabs : tabs}
      tabToComponent={tabsToComponent}
    />
  );
};

export default User;
