import { Box, Typography } from '@mui/material';

import { CenteredModal, ModalTitle } from './CenteredModal';
import { LowerCaseButton } from './common';

interface IDeleteWarningModale {
  header: string;
  onClose: () => void;
  open: boolean;
  handleDelete: () => void;
}

const DeleteWarningModal = ({ header, handleDelete, open, onClose }: IDeleteWarningModale) => {
  return (
    <CenteredModal open={open} handleClose={onClose}>
      <ModalTitle>
        <Typography variant="h5">{header}</Typography>
      </ModalTitle>
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '30px' }}
      >
        <LowerCaseButton
          variant="outlined"
          type="submit"
          onClick={() => {
            handleDelete();
            onClose();
          }}
        >
          Supprimer
        </LowerCaseButton>
        <LowerCaseButton variant="contained" onClick={onClose}>
          Annuler
        </LowerCaseButton>
      </Box>
    </CenteredModal>
  );
};

export default DeleteWarningModal;
