import { useUser } from 'src/context/UserProvider';

import AdminPageWithTabs from '../components/AdminPageWithTabs';
import Deliveries from './deliveries/Deliveries';
import Granits from './granits/Granits';
import AdminInformations from './informations/AdminInformations';
import LeaderGranitCompanies from './leaderGranitCompanies/LeaderGranitCompanies';
import { ImportPattern } from './patterns/ImportPattern';
import { SetEngravingsPrice } from './engravings/SetEngravingsPrice';

const tabs = [
  'informations',
  'companies',
  'deliveries',
  'granits',
  'patterns',
  'engravings',
] as const;
type Tab = (typeof tabs)[number];

const AdminSettings = () => {
  const { user } = useUser();

  if (!user) return <></>;
  const tabsToComponent = (tab: Tab) => {
    switch (tab) {
      case 'companies': {
        return <LeaderGranitCompanies />;
      }
      case 'deliveries': {
        return <Deliveries />;
      }
      case 'granits': {
        return <Granits />;
      }
      case 'patterns': {
        return <ImportPattern userId={user.id} />;
      }
      case 'engravings': {
        return <SetEngravingsPrice />;
      }
      default: {
        return <AdminInformations />;
      }
    }
  };

  return <AdminPageWithTabs pageName="Paramètres" tabs={tabs} tabToComponent={tabsToComponent} />;
};

export default AdminSettings;
