export type CategoryType =
  | 'STELE'
  | 'TOMB'
  | 'FOUNDATION'
  | 'PLATING'
  | 'BOX'
  | 'SIDEWALKS'
  | 'SOLE'
  | 'ANY'
  | 'VASE'
  | 'FLOWER'
  | 'URNS'
  | 'ANY_ACCESSORY'
  | string;

export const categoriesLabels: { key: CategoryType; label: string }[] = [
  {
    key: 'STELE',
    label: 'Stèle',
  },
  {
    key: 'TOMB',
    label: 'Tombale',
  },
  {
    key: 'FOUNDATION',
    label: 'Soubassement',
  },
  {
    key: 'PLATING',
    label: 'Placage',
  },
  {
    key: 'BOX',
    label: 'Coffret',
  },
  {
    key: 'SIDEWALKS',
    label: 'Trottoir',
  },
  {
    key: 'SOLE',
    label: 'Semelles',
  },
  {
    key: 'ANY',
    label: 'Autre piece',
  },
  {
    key: 'VASE',
    label: 'Vases',
  },
  {
    key: 'FLOWER',
    label: 'Jardinières',
  },
  {
    key: 'URNS',
    label: 'Urnes',
  },
  {
    key: 'ANY_ACCESSORY',
    label: 'Autre accessoire',
  },
];
