import * as THREE from 'three';

export const changeSteleShape = (
  shape: THREE.Shape,
  boxLeftHeight: number,
  boxRightHeight: number,
  boxHeight: number,
  boxUpWidth: number,
  boxDownWidth: number,
  headIndex: number,
  baseIndex: number,
) => {
  const x = 0;
  const y = 0;

  // BASE
  const boxRightHeightTmp = headIndex === 1 ? boxHeight : boxRightHeight;
  const boxLeftHeightTmp = headIndex === 1 ? boxHeight : boxLeftHeight;

  // eslint-disable-next-line default-case
  switch (baseIndex) {
    case 0: {
      shape.moveTo(boxUpWidth / 2, boxRightHeightTmp);
      shape.lineTo(boxUpWidth / 2, 0);
      shape.lineTo(x - boxUpWidth / 2, y);
      shape.lineTo(-boxUpWidth / 2, boxLeftHeightTmp);

      break;
    }
    case 1: {
      shape.moveTo(boxUpWidth / 2, boxRightHeightTmp);
      shape.lineTo(boxDownWidth / 2, 0);
      shape.lineTo(x - boxDownWidth / 2, y);
      shape.lineTo(-boxUpWidth / 2, boxLeftHeightTmp);

      break;
    }
    case 2: {
      shape.moveTo(boxUpWidth / 2, boxRightHeightTmp);
      shape.quadraticCurveTo(boxUpWidth / 1.8, boxRightHeightTmp / 2, boxDownWidth / 2, 0);
      shape.lineTo(x - boxDownWidth / 2, y);
      shape.quadraticCurveTo(
        -boxUpWidth / 1.8,
        boxLeftHeightTmp / 2,
        -boxUpWidth / 2,
        boxLeftHeightTmp,
      );

      break;
    }
    // No default
  }

  // HEAD
  // eslint-disable-next-line default-case
  switch (headIndex) {
    case 0: {
      shape.quadraticCurveTo(-boxUpWidth / 3, boxHeight, -boxUpWidth / 6, boxHeight);
      shape.quadraticCurveTo(
        0,
        boxHeight,
        boxUpWidth / 8,
        boxHeight - (boxHeight - boxRightHeight) / 2,
      );
      shape.quadraticCurveTo(
        boxUpWidth / 4,
        boxHeight - (boxHeight - boxRightHeight),
        boxUpWidth / 2,
        boxRightHeight,
      );

      break;
    }
    case 2: {
      shape.lineTo(-boxUpWidth / 3, boxHeight);
      shape.lineTo(boxUpWidth / 3, boxHeight);
      shape.lineTo(boxUpWidth / 2, boxRightHeight);

      break;
    }
    case 3: {
      shape.lineTo(-boxUpWidth / 6, boxHeight);
      shape.lineTo(boxUpWidth / 2, boxRightHeight);

      break;
    }
    case 4: {
      shape.quadraticCurveTo(
        -boxUpWidth / 2 + boxUpWidth / 6,
        boxLeftHeight,
        -boxUpWidth / 5,
        boxHeight - (boxHeight - boxLeftHeight) / 2,
      );
      shape.quadraticCurveTo(
        0,
        boxHeight,
        boxUpWidth / 5,
        boxHeight - (boxHeight - boxRightHeight) / 2,
      );
      shape.quadraticCurveTo(
        boxUpWidth / 2 - boxUpWidth / 6,
        boxRightHeight,
        boxUpWidth / 2,
        boxRightHeight,
      );

      break;
    }
    case 5: {
      shape.quadraticCurveTo(0, boxHeight, boxUpWidth / 2, boxRightHeight);

      break;
    }
    case 6: {
      shape.lineTo(-boxUpWidth / 6, boxHeight);
      shape.quadraticCurveTo(
        0,
        boxHeight - (boxHeight - boxRightHeight) / 2,
        boxUpWidth / 2,
        boxRightHeight,
      );

      break;
    }
    case 7: {
      shape.lineTo(-boxUpWidth / 6, boxHeight);
      shape.lineTo(-boxUpWidth / 6, boxRightHeight);
      shape.lineTo(boxUpWidth / 2, boxRightHeight);

      break;
    }
    case 8: {
      shape.quadraticCurveTo(
        -boxUpWidth / 2 + boxUpWidth / 6,
        boxHeight - (boxHeight - boxLeftHeight) / 2,
        -boxUpWidth / 3,
        boxHeight,
      );
      shape.lineTo(boxUpWidth / 3, boxHeight - 0.05);

      break;
    }
    case 9: {
      shape.lineTo(-boxUpWidth / 3, boxHeight);
      shape.lineTo(boxUpWidth / 3, boxHeight - 0.05);
      shape.quadraticCurveTo(
        boxUpWidth / 2 - boxUpWidth / 8,
        boxHeight - 0.05 - (boxHeight - boxRightHeight) / 2,
        boxUpWidth / 2,
        boxRightHeight,
      );

      break;
    }
    // No default
  }
};

export const getSteleExtrudeSettings = (depth: number) => {
  return {
    curveSegments: 12,
    steps: 2,
    depth,
    bevelEnabled: false,
  };
};
