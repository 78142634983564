/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChevronRight, Edit, ExpandMore, Share, Visibility } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useUser } from 'src/context/UserProvider';
import { PieceDetail } from 'src/hook/useConfigurator';
import { useReacpPrice } from 'src/hook/useRecapPrice';
import Decimal from 'decimal.js';
import { LowerCaseButton } from '../../components/common';
import StyledSwitch from '../../components/forms/StyledSwitch';
import DimensionalSettingsIcon from '../../components/icons/DimensionalSettingsIcon';
import { Assembly } from '../../services/assemblies';
import { ModalType, StateNames, States } from '../Configurator';

export const getColorName = (color: string) => {
  if (color === '#ECB86B') return 'Or';
  if (color === '#FFFFFF') return 'Blanc';
  if (color === '#101828') return 'Noir';
};

export const getFontName = (font: string) => {
  if (font === 'arial') return 'Baton';
  if (font === 'times') return 'Romaine';
  if (font === 'england') return 'Anglaise';
};

export const traductAlign = (align: 'left' | 'right' | 'center') => {
  switch (align) {
    case 'right': {
      return 'droite';
    }
    case 'center': {
      return 'centre';
    }
    default: {
      return 'gauche';
    }
  }
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, Math.max(0, maxLength))}...`;
  }
  return text;
};

interface IRecapProps {
  assembly: Assembly;
  setModal: (modalType: ModalType) => void;
  submit: ({
    detailed,
    discount,
    hasToBeOrdered,
    installations,
  }: {
    detailed?: boolean;
    discount?: number;
    hasToBeOrdered?: boolean;
    installations?: { price: number; name: string }[];
  }) => void;
  getAssemblyDetails: () => {
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    patterns: {
      scale: {
        x: number;
        y: number;
      };
    }[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
  };
  getAssemblySize: (wihoutSole?: boolean) => { depth: number; width: number };
  isLoading: boolean;
  activePieces: any[];
  platings: any[];
  steles: any[];
  activeInstallations: { price: number; name: string }[];
  setActiveInstallations: (activeInstallations: { price: number; name: string }[]) => void;
}

const Recap = ({
  assembly,
  setModal,
  submit,
  getAssemblyDetails,
  getAssemblySize,
  isLoading,
  activePieces,
  platings,
  steles,
  activeInstallations,
  setActiveInstallations,
}: IRecapProps) => {
  const { user } = useUser();

  const {
    simplesPieces,
    assemblyDetails,
    showPurchasePrice,
    detailedQuote,
    priceFormatter,
    additionalServicesPrice,
    delivery,
    toggleShowEditActions,
    buttonActionsSelectionRef,
    showEditActions,
    actionsSelectionRef,
    setDiscount,
    toggleShowVisibilityActions,
    buttonVisibilityDialogRef,
    showVisibilityActions,
    visibilityDialogRef,
    setDetailedQuote,
    displayPurchasePrice,
    setIsEditingFinalPrice,
    setShowPurchasePrice,
    setDisplayPurchasePrice,
    discount,
    isEditingDiscount,
    setIsEditingDiscount,
    isEditingDiscountPercentage,
    percentage,
    setPercentage,
    setIsEditingDiscountPercentage,
    totalSalePrice,
    isEditingFinalPrice,
    setFinalPrice,
    finalPrice,
    getFormatedPrice,
    totalPurchasePrice,
    getMonumentPrice,
    setOpenUnderAssemblies,
    openUnderAssemblies,
    detailKeys,
    getAccessoriesPrice,
    setOpenedPattern,
    openedPattern,
  } = useReacpPrice({
    assembly,
    getAssemblyDetails,
    getAssemblySize,
    activePieces,
    platings,
    steles,
    activeInstallations,
    setActiveInstallations,
  });

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTextWrapper>
          {assembly.quote?.cemetary && (
            <Line>
              <HeaderTypography>CIMETIÈRE</HeaderTypography>
              <Typography sx={{ fontSize: '12px', lineHeight: '24px', fontWeight: 600 }}>
                {assembly.quote?.cemetary}
              </Typography>
            </Line>
          )}
          {assembly.quote?.concessionName && (
            <Line>
              <HeaderTypography>N° DE CONCESSION</HeaderTypography>
              <Typography sx={{ fontSize: '12px', lineHeight: '24px', fontWeight: 600 }}>
                {assembly.quote?.concessionName}
              </Typography>
            </Line>
          )}
        </HeaderTextWrapper>
        {assembly.quote?.familyRef && (
          <HeaderTextWrapper>
            <HeaderTypography align="right">DEVIS RÉFÉRENCE</HeaderTypography>
            <Typography align="right" variant="h2">
              {assembly.quote?.familyRef}
            </Typography>
          </HeaderTextWrapper>
        )}
      </HeaderWrapper>
      <RecapWrapper>
        {(simplesPieces.pieces && simplesPieces.pieces.length > 0) ||
        (simplesPieces.platings && simplesPieces.platings.length > 0) ||
        (simplesPieces.steles && simplesPieces.steles.length > 0) ||
        (assemblyDetails?.underAssemblies && assemblyDetails?.underAssemblies.length > 0) ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Monument</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">{getMonumentPrice()}</Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.underAssemblies.map((underAssembly, index) => {
              return (
                <>
                  <RecapItem
                    style={{ cursor: 'pointer', gap: 4, alignItems: 'center' }}
                    onClick={() => {
                      setOpenUnderAssemblies(
                        openUnderAssemblies.map((el, elIndex) => {
                          if (elIndex === index) return !el;
                          return el;
                        }),
                      );
                    }}
                  >
                    {openUnderAssemblies[index] ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="60%"
                      style={{ fontSize: 14 }}
                    >
                      {underAssembly.underAssemblyName}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {Math.floor(underAssembly.z * 100)} x {Math.floor(underAssembly.x * 100)} x{' '}
                      {Math.floor(underAssembly.y * 100)}
                      ht
                    </Typography>
                  </RecapItem>

                  {openUnderAssemblies[index]
                    ? underAssembly.pieces.map((piece) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <RecapItem key={`${piece.name}_${index}`} style={{ padding: 0 }}>
                            <Row style={{ width: '55%', marginLeft: '10%' }}>
                              <Typography
                                variant="inherit"
                                textTransform="uppercase"
                                style={{ fontSize: 12 }}
                              >
                                {piece.name}
                              </Typography>
                              -
                              <Typography
                                variant="inherit"
                                textTransform="uppercase"
                                style={{ fontSize: 12 }}
                              >
                                {piece.granit?.name}
                              </Typography>
                            </Row>
                            <Typography variant="inherit" style={{ fontSize: 12 }}>
                              {Math.round(piece.z * 100)} x {Math.round(piece.x * 100)} x{' '}
                              {Math.round(piece.y * 100)}
                              ht
                            </Typography>
                          </RecapItem>
                        );
                      })
                    : null}
                </>
              );
            })}
            {detailKeys.map((key) =>
              simplesPieces[key]?.map((el: PieceDetail, index: number) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <RecapItem key={`${el.name}_${index}`} style={{ fontSize: 14 }}>
                    <Row style={{ width: '64%' }}>
                      <Typography variant="inherit" textTransform="uppercase">
                        {el.name}
                      </Typography>
                      -
                      <Typography variant="inherit" textTransform="uppercase">
                        {el.granit?.name}
                      </Typography>
                    </Row>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {Math.round(el.z * 100)} x {Math.round(el.x * 100)} x {Math.round(el.y * 100)}
                      ht
                    </Typography>
                  </RecapItem>
                );
              }),
            )}
          </>
        ) : null}

        {assemblyDetails?.accesories && assemblyDetails?.accesories.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Accessoires</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">{getAccessoriesPrice()}</Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.accesories.map((accesory: any, index: number) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <RecapItem key={`accesory_${index}`} style={{ fontSize: 14 }}>
                  <Row style={{ width: '64%' }}>
                    <Typography variant="inherit" textTransform="uppercase">
                      {accesory.name} - {accesory.granit?.name}
                    </Typography>
                  </Row>

                  <Typography variant="inherit" style={{ fontSize: 14 }}>
                    {Math.round(accesory.z * 100)} x {Math.round(accesory.x * 100)} x{' '}
                    {Math.round(accesory.y * 100)}
                    ht
                  </Typography>
                </RecapItem>
              );
            })}
          </>
        ) : null}

        {assemblyDetails?.patterns && assemblyDetails?.patterns.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Motifs</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {assemblyDetails?.patterns
                    /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
                    .reduce(
                      (total, obj) =>
                        (total as number) +
                        (showPurchasePrice ? (obj.htPrice as number) : (obj.ttcPrice as number)),
                      0,
                    )
                    .toFixed(2)}{' '}
                  €
                </Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.patterns.map((pattern: any, index: number) => {
              return (
                <>
                  <RecapItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`pattern_${index}`}
                    style={{ fontSize: 14, cursor: 'pointer' }}
                    onClick={() =>
                      setOpenedPattern(openedPattern === pattern.uuid ? undefined : pattern.uuid)
                    }
                  >
                    {openedPattern === pattern.uuid ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="55%"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {pattern.name}{' '}
                      {showPurchasePrice && pattern.htPrice === 0 && pattern.leaderGranitEngraving
                        ? '(Motif non chiffré, tarif à déterminer)'
                        : ''}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {pattern.scale.x} x {pattern.scale.y}
                      ht
                    </Typography>
                  </RecapItem>
                  {openedPattern === pattern.uuid ? (
                    <RecapItem
                      style={{ fontSize: 12, paddingLeft: 32 }}
                      onClick={() => setOpenedPattern(pattern.uuid)}
                    >
                      <Typography variant="inherit" textTransform="uppercase" width="100%">
                        {pattern.description}
                      </Typography>
                    </RecapItem>
                  ) : null}
                </>
              );
            })}
          </>
        ) : null}

        {assemblyDetails?.engravings && assemblyDetails?.engravings.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Gravure</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {detailedQuote ? (
                    <Typography variant="inherit">
                      {assemblyDetails?.engravings
                        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
                        .reduce(
                          (total, obj) =>
                            (total as number) +
                            (showPurchasePrice
                              ? (obj.htPrice as number)
                              : (obj.ttcPrice as number)),
                          0,
                        )
                        .toFixed(2)}{' '}
                      €
                    </Typography>
                  ) : null}
                </Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.engravings.map((engraving: any, index: number) => {
              return (
                <>
                  <RecapItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`engraving_${index}`}
                    style={{ fontSize: 14, cursor: 'pointer' }}
                    onClick={() =>
                      setOpenedPattern(
                        openedPattern === engraving.uuid ? undefined : engraving.uuid,
                      )
                    }
                  >
                    {openedPattern === engraving.uuid ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="55%"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {truncateText(engraving.text, 15)}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {engraving.fontSize}
                      cm
                    </Typography>
                  </RecapItem>
                  {openedPattern === engraving.uuid ? (
                    <RecapItem
                      style={{ fontSize: 12, paddingLeft: 32 }}
                      onClick={() => setOpenedPattern(engraving.uuid)}
                    >
                      <div>
                        <Typography variant="inherit" width="100%">
                          Couleur: {getColorName(engraving.color)}
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Police: {getFontName(engraving.fontFamily)}
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Espacement: {engraving.letterSpacing} cm
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Alignement: {traductAlign(engraving.align)}
                        </Typography>
                      </div>
                    </RecapItem>
                  ) : null}
                </>
              );
            })}
          </>
        ) : null}

        {showPurchasePrice
          ? null
          : activeInstallations.map((installation) => {
              return (
                <RecapHeader sx={{ marginBottom: '22px' }}>
                  <Typography variant="inherit">{installation.name}</Typography>
                  {detailedQuote ? (
                    <Typography variant="inherit">
                      {priceFormatter.format(installation.price)}
                    </Typography>
                  ) : null}
                </RecapHeader>
              );
            })}

        {!showPurchasePrice && additionalServicesPrice ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Travaux supplémentaires</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {priceFormatter.format(additionalServicesPrice)}
                </Typography>
              ) : null}

              {/* assembly.quote?.additionalServicesOnQuote */}
            </RecapHeader>
            {assembly.quote?.additionalServicesOnQuote?.map((additionalServicesOnQuote: any) => {
              return (
                <RecapItem>
                  <Typography variant="inherit" textTransform="uppercase" sx={{ fontSize: '14px' }}>
                    {additionalServicesOnQuote?.additionalService.name}
                  </Typography>
                </RecapItem>
              );
            })}
          </>
        ) : null}

        {(assembly?.quote?.user?.quoteSettings?.displayDelivery || showPurchasePrice) &&
        !assembly?.quote?.user?.quoteSettings?.postageDue ? (
          <RecapHeader>
            <Typography variant="inherit">Livraison</Typography>
            {detailedQuote ? (
              <Typography variant="inherit">{priceFormatter.format(delivery)}</Typography>
            ) : null}
          </RecapHeader>
        ) : null}
      </RecapWrapper>

      <ActionsWrapper>
        <ActionsButton
          color="secondary"
          onClick={toggleShowEditActions}
          ref={buttonActionsSelectionRef}
        >
          {showEditActions && (
            <ActionSelection ref={actionsSelectionRef}>
              <ActionSelectionText noWrap onClick={() => setModal('installations')}>
                Modifier les forfaits pose
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setModal('additionalServices')}>
                Ajouter un travail supplémentaire
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setModal('editInformations')}>
                Modifier les informations client
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setDiscount('0')}>
                Réinitialiser les prix
              </ActionSelectionText>
              <ActionSelectionText
                noWrap
                sx={{ borderBottom: 'none' }}
                onClick={() => setModal('quoteImage')}
              >
                Changer l&apos;image
              </ActionSelectionText>
            </ActionSelection>
          )}
          <Edit />
        </ActionsButton>
        <ActionsButton color="secondary" disabled>
          <DimensionalSettingsIcon />
        </ActionsButton>
        <ActionsButton
          color="secondary"
          onClick={toggleShowVisibilityActions}
          ref={buttonVisibilityDialogRef}
        >
          {showVisibilityActions && (
            <ActionSelection ref={visibilityDialogRef} onClick={(event) => event.stopPropagation()}>
              <StyledControlLabel
                control={
                  <StyledSwitch
                    color="secondary"
                    checked={detailedQuote}
                    onChange={(event) => setDetailedQuote(event.target.checked)}
                  />
                }
                label={<Typography noWrap>Afficher le détail du devis</Typography>}
                sx={{
                  borderBottom: assembly?.quote?.user?.company?.canSeePurchasePrice
                    ? 'auto'
                    : 'none',
                }}
              />
              {assembly?.quote?.user?.company?.canSeePurchasePrice ? (
                <>
                  {displayPurchasePrice ? (
                    <StyledControlLabel
                      control={
                        <StyledSwitch
                          color="secondary"
                          checked={showPurchasePrice}
                          onChange={(event) => {
                            setIsEditingFinalPrice(false);
                            setShowPurchasePrice(event.target.checked);
                          }}
                        />
                      }
                      label={<Typography noWrap>Afficher le prix d’achat</Typography>}
                      sx={{ borderBottom: 'none' }}
                    />
                  ) : (
                    <StyledControlLabel
                      control={
                        <StyledSwitch
                          color="secondary"
                          checked={displayPurchasePrice}
                          onChange={(event) => setDisplayPurchasePrice(event.target.checked)}
                        />
                      }
                      label={<Typography noWrap>Afficher le bouton du prix d’achat</Typography>}
                      sx={{ borderBottom: 'none' }}
                    />
                  )}
                </>
              ) : null}
            </ActionSelection>
          )}
          <Visibility />
        </ActionsButton>
        <ActionsButton color="secondary" disabled>
          <Share />
        </ActionsButton>
      </ActionsWrapper>
      <div style={{ width: '100%' }}>
        {Number.parseFloat(discount ?? '') > 0 || discount === '' ? (
          <Footer style={{ padding: '10px', paddingBottom: '0px' }}>
            <Typography sx={{ color: '#98A2B3' }} align="right">
              Remise
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
              {isEditingDiscount ? (
                <TextField
                  id="outlined-basic"
                  label="Remise"
                  variant="outlined"
                  value={discount}
                  style={{ width: '150px' }}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setIsEditingDiscount(false);
                    }
                  }}
                  onBlur={() => {
                    setIsEditingDiscount(false);
                  }}
                />
              ) : (
                <Typography
                  sx={{ color: '#98A2B3' }}
                  align="right"
                  onClick={() => {
                    if (!showPurchasePrice) setIsEditingDiscount(true);
                  }}
                >
                  {Number.parseFloat(discount ?? '').toFixed(2)} €
                </Typography>
              )}
              {isEditingDiscountPercentage ? (
                <TextField
                  id="outlined-basic"
                  label="Remise %"
                  variant="outlined"
                  value={percentage}
                  style={{ width: '150px' }}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setIsEditingDiscountPercentage(false);
                      const percentageTmp = Number.parseFloat(percentage ?? '');
                      if (percentageTmp && percentageTmp >= 0) {
                        setDiscount((totalSalePrice * (percentageTmp / 100)).toFixed(2));
                      }
                    }
                  }}
                  onBlur={() => {
                    setIsEditingDiscountPercentage(false);
                    const percentageTmp = Number.parseFloat(percentage ?? '');
                    if (percentageTmp && percentageTmp >= 0) {
                      setDiscount((totalSalePrice * (percentageTmp / 100)).toFixed(2));
                    }
                  }}
                />
              ) : (
                <Typography
                  sx={{ color: '#98A2B3' }}
                  align="right"
                  onClick={() => {
                    if (!showPurchasePrice) setIsEditingDiscountPercentage(true);
                  }}
                >
                  {Number.parseFloat(percentage ?? '')} %
                </Typography>
              )}
            </div>
          </Footer>
        ) : null}
        <Footer>
          {isEditingFinalPrice ? (
            <TextField
              id="outlined-basic"
              label="Prix total"
              variant="outlined"
              value={finalPrice}
              style={{ width: '150px' }}
              onChange={(e) => {
                setFinalPrice(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const finalPriceParsed = new Decimal(Number.parseFloat(finalPrice));
                  const totalSalePriceParsed = new Decimal(totalSalePrice);

                  const diff = finalPriceParsed.minus(totalSalePriceParsed).toNumber() * -1;

                  setDiscount(String(diff));
                  setIsEditingFinalPrice(false);
                }
              }}
              onBlur={() => {
                const discountTmp = (Number.parseFloat(finalPrice) - totalSalePrice) * -1;
                setDiscount(String(discountTmp));
                setIsEditingFinalPrice(false);
              }}
            />
          ) : (
            <Box component="div">
              <Typography sx={{ color: '#98A2B3' }} align="right">
                TOTAL
              </Typography>
              <Typography
                variant="h2"
                align="right"
                onClick={() => {
                  if (!showPurchasePrice) setIsEditingFinalPrice(true);
                }}
              >
                {showPurchasePrice
                  ? priceFormatter.format(getFormatedPrice(String(totalPurchasePrice)))
                  : priceFormatter.format(getFormatedPrice(finalPrice))}{' '}
                {showPurchasePrice ? 'HT' : 'TTC'}
              </Typography>
            </Box>
          )}
          <LowerCaseButton
            sx={{
              background: 'white',
              color: 'black',
              boxShadow: ' 0px 0.5px 4px rgba(0, 0, 0, 0.15)',
            }}
            onClick={() => {
              submit({
                detailed: detailedQuote,
                discount: Number.parseFloat(discount ?? '') * -1,
                hasToBeOrdered: true,
                installations: activeInstallations,
              });
            }}
          >
            {isLoading ? <CircularProgress size={12} color="inherit" /> : 'Commander'}
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              submit({
                detailed: detailedQuote,
                discount: Number.parseFloat(discount ?? '') * -1,
                installations: activeInstallations,
              });
            }}
          >
            {isLoading ? <CircularProgress size={12} color="inherit" /> : 'Enregistrer'}
          </LowerCaseButton>
        </Footer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '24px',
  overflowY: 'auto',
});

const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 40px 32px',
  background: '#F9FAFB',
  width: '100%',
  position: 'relative',
});

const HeaderTypography = styled(Typography)({
  fontWeight: 700,
  color: '#BC9A67',
  fontSize: 12,
  lineHeight: '24px',
});

const HeaderWrapper = styled(Box)({
  width: '100%',
  padding: '26px',
  display: 'flex',
  backgroundColor: '#FFFCF6',
});

const HeaderTextWrapper = styled(Box)({
  flex: 1,
  height: '100%',
  alignItems: 'center',
});

const Line = styled(Box)({
  display: 'flex',
  gap: '8px',
});

const ActionsWrapper = styled(Box)({
  display: 'flex',
  gap: '24px',
  boxShadow: '-4px 0px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '0px 8px 8px 0px',
  left: 0,
  padding: '24px 32px',
  alignSelf: 'flex-start',
});

const ActionsButton = styled(IconButton)({
  border: '1px solid #BC9A67',
  position: 'relative',

  '&:disabled': {
    borderColor: 'inherit',
  },
});

const RecapWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  flex: 1,
});

const ActionSelection = styled(Box)({
  position: 'absolute',
  border: '1px solid #98A2B3',
  borderRadius: '16px',
  bottom: 'calc(100% + 16px)',
  left: '0',
  width: 'fit-content',
  background: 'white',
  boxShadow: '0px -1px 16px rgba(123, 123, 123, 0.1)',
});

const ActionSelectionText = styled(Typography)({
  color: '#475467',
  padding: '16px',
  borderBottom: '1px solid #98A2B3',
});

const RecapHeader = styled(Box)({
  padding: '10px 20px',
  background: '#F6F0E7',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'space-between',
  width: '80%',
  fontWeight: 700,
  fontSize: '20px',
});

const RecapItem = styled(Box)({
  padding: '10px 20px',
  display: 'flex',
  borderRadius: '8px',
  alignSelf: 'flex-start',
  marginLeft: '10%',
  width: '80%',
  fontWeight: 600,
  fontSize: '16px',
  color: '#475467',
  gap: '20px',
});

const StyledControlLabel = styled(FormControlLabel)({
  color: 'black',
  borderBottom: '1px solid #98A2B3',
  padding: '16px',
  margin: 0,
  width: '100%',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export default Recap;
