import { Box, IconButton, Link, styled, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import HomeIcon from '../../../components/icons/HomeIcon';
import LogoutIcon from '../../../components/icons/LogoutIcon';
import paths from '../../../navigation/paths';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 100px',
  gap: '20px',
  backgroundColor: 'white',
  borderBottom: '1px solid #F2F4F7',
  width: '100%',
  height: '100px',
});

const Logo = styled('img')({
  height: '60%',
  objectFit: 'contain',
});

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  color: theme.palette.primary.main,
  height: '100%',
}));

const NavigateHome = styled(Link)(({ theme: muiTheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: muiTheme.palette.primary.main,
  height: '100%',
  gap: '10px',
  padding: '0 30px',
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'none',
  zIndex: 3,
}));

const AdminNavBar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isHome = location.pathname.split('/').reverse()[0] === 'home';

  const handleLogout = () => {
    localStorage.removeItem('auth');
    navigate(`/${paths.connexion.root}`);
  };

  return (
    <Container>
      <Wrapper>
        {!isHome && (
          <NavigateHome href={`/${paths.admin.root}`}>
            <HomeIcon color="primary" />
            Accueil
          </NavigateHome>
        )}
        <Logo src="/logo_paysage.svg" sx={{ margin: isHome ? '0 40px' : 0 }} />
      </Wrapper>
      <Typography
        variant="h3"
        sx={{ fontWeight: 700, fontSize: '30px', position: 'absolute', width: '100%' }}
        textAlign="center"
      >
        Back Office Admin
      </Typography>
      <IconButton onClick={handleLogout} sx={{ color: 'white', marginRight: '30px' }}>
        <LogoutIcon fontSize="large" />
      </IconButton>
    </Container>
  );
};

export default AdminNavBar;
