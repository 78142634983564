import { api } from './api';
import { UnderAssembly } from './assemblies';

export const deleteUnderAssembly = async (id: string) => {
  await api.delete(`/under-assemblies/${id}`);
};

export const updateUnderAssemblyName = async (underAssemblyId: number, name: string) => {
  await api.patch(`/under-assemblies/${underAssemblyId}`, {
    name,
  });
};

export const getAllUnderAssemblies = async () => {
  const { data } = await api.get<UnderAssembly[]>(`/under-assemblies`);
  return data;
};
