/* eslint-disable react/no-array-index-key */
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import { categoriesLabels, CategoryType } from 'src/constants/category';
import { useUnderAssemblies } from 'src/hook/useUnderAssemblies';
import { deleteUnderAssembly, updateUnderAssemblyName } from 'src/services/underAssembly';

export const Update = () => {
  const [categorieKey, setCategorieKey] = useState<CategoryType>('STELE');
  const [editingId, setEditingId] = useState<number>();
  const [name, setName] = useState<string>();

  const { underAssemblies, refreshUnderAssemblies } = useUnderAssemblies(categorieKey);

  const handleDelete = async (underAssemblyId: string) => {
    await deleteUnderAssembly(underAssemblyId);
    refreshUnderAssemblies();
  };

  const handleUpdateUnderAssemblyName = async (underAssemblyId: number) => {
    if (underAssemblyId && name && name !== '') {
      await updateUnderAssemblyName(underAssemblyId, name);
      refreshUnderAssemblies();
    }
  };

  return (
    <>
      <Select
        name="sens"
        id="sens"
        onChange={(e) => {
          setCategorieKey(e.target.value as SetStateAction<CategoryType>);
        }}
      >
        {categoriesLabels.map((el, elIndex) => {
          return (
            <option key={elIndex} selected={categorieKey === el.key} value={el.key}>
              {el.label}
            </option>
          );
        })}
      </Select>

      <List dense style={{ width: '100%' }}>
        {underAssemblies.map((el, elIndex) => {
          return (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(String(el.id))}
                >
                  <Typography
                    variant="body1"
                    style={{ fontSize: '12px', textTransform: 'capitalize' }}
                  >
                    delete
                  </Typography>
                </IconButton>
              }
            >
              {editingId !== undefined && editingId === elIndex ? (
                <TextField
                  autoFocus
                  margin="dense"
                  label="Edition du nom"
                  defaultValue={el.name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setEditingId(undefined);
                      handleUpdateUnderAssemblyName(el.id);
                    }
                  }}
                  onBlur={() => {
                    setEditingId(undefined);
                    handleUpdateUnderAssemblyName(el.id);
                  }}
                />
              ) : (
                <ListItemText primary={el.name} onClick={() => setEditingId(elIndex)} />
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  border: '1px solid #C2C9D1',

  color: '#273135',
});
