import { Navigate } from 'react-router-dom';

import paths from '../../navigation/paths';

interface IRedirectAuthenticatedProps {
  children: JSX.Element;
}

const RedirectAuthenticated = ({ children }: IRedirectAuthenticatedProps) => {
  const auth = localStorage.getItem('auth');
  const admin = localStorage.getItem('admin') === 'true';

  const { accessToken, expiry } = JSON.parse(auth ?? '{}') as {
    accessToken: string;
    expiry: number;
  };

  if (accessToken && expiry > Date.now()) {
    const redirectPath = admin ? `/${paths.admin.root}` : `/${paths.home.root}`;

    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default RedirectAuthenticated;
