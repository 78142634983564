import { SvgIcon, SvgIconProps } from '@mui/material';

const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path d="M5.00004 0.333374C2.42004 0.333374 0.333374 2.42004 0.333374 5.00004C0.333374 8.50004 5.00004 13.6667 5.00004 13.6667C5.00004 13.6667 9.66671 8.50004 9.66671 5.00004C9.66671 2.42004 7.58004 0.333374 5.00004 0.333374ZM5.00004 6.66671C4.08004 6.66671 3.33337 5.92004 3.33337 5.00004C3.33337 4.08004 4.08004 3.33337 5.00004 3.33337C5.92004 3.33337 6.66671 4.08004 6.66671 5.00004C6.66671 5.92004 5.92004 6.66671 5.00004 6.66671Z" />
    </SvgIcon>
  );
};

export default LocationIcon;
