import { Chip } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';

import { QuoteFile, QuoteStatus, ReducedQuote } from '../../../services/quotes';
import { QuoteStep } from '../../project/components/NavBar';

export type DataRow = {
  image: string;
  customImage: string;
  status: QuoteStatus;
  familyRef: string;
  model: string;
  granit: string;
  updatedAt: string;
  price: number;
  companyName: string;
  companyReason: string;
  id: number;
  files: QuoteFile[];
  quoteId: string;
  step: QuoteStep;
  assembly: {
    pictureUrl: string;
  };
};

export const TO_CONFIRM: QuoteStatus[] = ['WAITING', 'WAITING_HEAD_OFFICE'];
export const QUOTE: QuoteStatus[] = ['DRAFT', 'QUOTE'];
export const ORDER: QuoteStatus[] = ['WAITING_ORDER', 'ORDER_VALIDATED'];

export const formatRow = ({ user, quoteFile, id, assemblyId, ...row }: ReducedQuote): DataRow => {
  const deliveryOrder = quoteFile.find((file) => file.type === 'DELIVERY_ORDER');

  return {
    ...row,
    price: row.price ?? 0,
    updatedAt: new Date(row.updatedAt).toLocaleDateString('fr'),
    companyName: user.company?.name,
    companyReason: user.company?.reason,
    files: quoteFile,
    id: assemblyId,
    quoteId: deliveryOrder?.quoteNumber ?? row.quoteNumber,
    image: row.assembly.pictureUrl ?? '',
    customImage: row.customImage,
  };
};

export const getOrdersStatusComponent = (params: GridRenderCellParams<QuoteStatus>) => {
  switch (params.value) {
    case 'ORDER_VALIDATED': {
      return <Chip label="● Validé" sx={{ color: '#027A48', background: '#ECFDF3' }} />;
    }
    default: {
      return <Chip label="● En attente" sx={{ color: '#B54708', background: '#FFFAEB' }} />;
    }
  }
};

export const getSpecialQueryColumns = (
  specialQuery?: 'admin' | 'agencies',
): GridColumns<DataRow> => {
  switch (specialQuery) {
    case 'admin': {
      return [
        {
          field: 'companyReason',
          headerName: 'Raison',
          flex: 1,
        },
        {
          field: 'companyName',
          headerName: 'Agence',
          flex: 1,
        },
      ];
    }
    case 'agencies': {
      return [
        {
          field: 'companyName',
          headerName: 'Agence',
          flex: 1,
        },
      ];
    }
    default: {
      return [];
    }
  }
};
