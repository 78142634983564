const theme = {
  colors: {
    white: '#FFFFFF',
    grey: 'rgba(39, 49, 53, 1)',
    greyLight: 'rgba(242, 244, 247, 1)',
    black: 'rgba(54, 55, 55, 0.87)',
    turquoise: '#548797',
    darkTurquoise: '#3D5C66',
    ligthTurquoise: '#DDE7EA',
    gold: 'rgba(188, 154, 103, 1)',
    red: 'rgba(196, 109, 94, 1)',
  },
  shadows: {
    common: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  },
  backgroundColors: {
    white: '#FFFFFF',
    grey: '#F5F5F5',
  },
  typography: {
    size: {
      xsmall: `${12 / 16}rem`,
      small: `${14 / 16}rem`,
      medium: `${16 / 16}rem`,
      large: `${20 / 16}rem`,
      xlarge: `${24 / 16}rem`,
      xxxlarge: `${32 / 16}rem`,
    },
    lineHeight: {
      small: 20 / 16,
      medium: 24 / 16,
      large: 30 / 16,
    },
    letterSpacing: {
      none: 0,
      xsmall: `${0.15 / 16}rem`,
      small: `${0.4 / 16}rem`,
    },
    fontFamily: {
      default: "'Inter', sans-serif",
    },
    colors: {
      default: 'rgba(39, 49, 53, 1)',
      light: 'rgba(142, 142, 142, 1)',
      black: 'rgba(54, 55, 55, 0.87)',
    },
  },
};

export default theme;
