const staging = {
  apiUrl: 'https://staging-api.visiogranits.fr/',
};

const prod = {
  apiUrl: 'https://api.visiogranits.fr/',
};

const dev = {
  apiUrl: 'http://localhost:3000/',
};

// const environment = process.env.REACT_APP_NODE_ENV === 'staging' ? dev : prod;

const environment =
  process.env.REACT_APP_NODE_ENV === 'dev'
    ? dev
    : process.env.REACT_APP_NODE_ENV === 'staging'
    ? staging
    : prod;

export default environment;
