import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Delete, Edit, Upload } from '@mui/icons-material';
import { Button, IconButton, Input, styled, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { countryToAlpha2 } from 'country-to-iso';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import {
  ButtonRow,
  FormLine,
  LowerCaseButton,
  RoundedButton,
  StyledForm,
} from '../../../../components/common';
import { CustomDataGrid } from '../../../../components/DataGrid';
import DeleteWarningModal from '../../../../components/DeleteWarningModal';
import { CustomPhoneInput } from '../../../../components/forms/PhoneInputRhf';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../context/EnvProvider';
import useLoadDataList from '../../../../hook/useLoadDataList';
import useToggle from '../../../../hook/useToggle';
import { Buckets } from '../../../../services/file';
import {
  createLeaderGranitCompanies,
  deleteLeaderGranitCompanies,
  getLeaderGranitCompanies,
  LeaderGranitCompany,
  updateLeaderGranitCompanies,
} from '../../../../services/leaderGranitCompanies';
import { emptyAddress, schemaAddress } from '../../../../utils/address.utils';
import { handleMutation } from '../../../../utils/api.utils';
import { uploadImage } from '../../../../utils/file.utils';

const Image = styled('img')({
  width: '100%',
});

const UploadContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.black,
})) as typeof Button;

const schemaLeaderGranitCompanyForm = z.object({
  name: z.string().trim().min(1),
  logo: z.string().trim().min(1),
  email: z.string().refine((val) => isEmail(val)),
  phone: z.string().refine((val) => isValidPhoneNumber(val)),
  assistancePhoneNumber: z.string().refine((val) => isValidPhoneNumber(val)),
  address: schemaAddress,
  fax: z.string(),
  webSite: z.string(),
  quoteFooter: z.string(),
});

type SchemaLeaderGranitCompanyForm = z.infer<typeof schemaLeaderGranitCompanyForm>;

const LeaderGranitCompanies = () => {
  const { data: rows, onRefresh } = useLoadDataList(getLeaderGranitCompanies);
  const { buckets } = useEnv();

  const [addCompany, toggleAddCompany] = useToggle();

  const [openedCompany, setOpenedCompany] = useState<LeaderGranitCompany>();
  const [companyToDelete, setCompanyToDelete] = useState<string>();

  const defaultValues = useMemo(
    () =>
      openedCompany ?? {
        name: '',
        logo: '',
        email: '',
        phone: '',
        assistancePhoneNumber: '',
        fax: '',
        webSite: '',
        quoteFooter: '',
        address: emptyAddress,
      },
    [openedCompany],
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<SchemaLeaderGranitCompanyForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaLeaderGranitCompanyForm),
  });

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  const columns: GridColDef<LeaderGranitCompany>[] = [
    {
      field: 'name',
      headerName: 'Nom de la société',
      flex: 1,
    },

    {
      field: 'logo',
      headerName: 'Logo',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <img
          src={`${buckets?.BUCKET_PROFILE_PICTURES || ''}/${params.value ?? ''}`}
          alt="logo"
          style={{ padding: '5px', objectFit: 'contain', width: '100%', height: '100%' }}
        />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      valueGetter: (param: GridValueGetterParams<string>) => formatPhoneNumber(param.value ?? ''),
      flex: 1,
    },
    {
      field: 'assistancePhoneNumber',
      headerName: "Téléphone d'assistance",
      valueGetter: (param: GridValueGetterParams<string>) => formatPhoneNumber(param.value ?? ''),
      flex: 1,
    },
    {
      field: 'fax',
      headerName: 'Fax',
      flex: 1,
    },
    {
      field: 'webSite',
      headerName: 'Site internet',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams<void, LeaderGranitCompany>) => (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              toggleAddCompany();
              setOpenedCompany(params.row);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              setCompanyToDelete(params.row.id);
            }}
          >
            <Delete color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const submit: SubmitHandler<SchemaLeaderGranitCompanyForm> = (data) => {
    if (openedCompany)
      handleMutation(
        updateLeaderGranitCompanies,
        {
          ...data,
          address: { ...data.address, country: countryToAlpha2(data.address.country) ?? '' },
          id: openedCompany.id,
        },
        () => {
          toggleAddCompany();
          setOpenedCompany(undefined);
          reset(defaultValues);
          onRefresh();
        },
      );
    else
      handleMutation(
        (datas) =>
          createLeaderGranitCompanies({
            ...datas,
            address: { ...data.address, country: countryToAlpha2(data.address.country) ?? '' },
          }),
        data,
        () => {
          toggleAddCompany();
          reset(defaultValues);
          onRefresh();
        },
      );
  };

  const uploadProfilePicture = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_PROFILE_PICTURES);
    return pictureName ?? '';
  };

  const handleDelete = () => {
    if (companyToDelete) handleMutation(deleteLeaderGranitCompanies, companyToDelete, onRefresh);
  };

  return (
    <>
      <ButtonRow padding="16px">
        <RoundedButton
          startIcon={<Add color="primary" />}
          color="primary"
          variant="outlined"
          onClick={toggleAddCompany}
        >
          Ajouter une société
        </RoundedButton>
      </ButtonRow>
      <CustomDataGrid columns={columns} rows={rows} />
      <CenteredModal
        open={addCompany}
        handleClose={() => {
          toggleAddCompany();
          setOpenedCompany(undefined);
        }}
      >
        <ModalTitle>
          <Typography variant="h5">SOCIÉTÉ</Typography>
        </ModalTitle>
        <StyledForm onSubmit={handleSubmit(submit)}>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={control}
              TextFieldProps={{
                label: 'Nom de la société',
                error: Boolean(errors.name),
                helperText: errors.name?.message,
              }}
            />
          </FormLine>
          <Controller
            name="logo"
            control={control}
            render={({ field }) => {
              const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
                if (e.target.files) {
                  const fileKey = await uploadProfilePicture(e.target.files[0]);
                  field.onChange(fileKey);
                }
              };

              return (
                <UploadContainer component="label">
                  {watch('logo') ? (
                    <Image
                      src={`${buckets?.BUCKET_PROFILE_PICTURES || ''}/${watch('logo')}`}
                      alt="logo"
                    />
                  ) : (
                    <>
                      <Upload />
                      <Typography variant="body2">Cliquer pour ajouter une image</Typography>
                      <Typography variant="body2">PNG, JPG, PDF...</Typography>
                    </>
                  )}
                  <Input type="file" sx={{ display: 'none' }} onChange={onChange} />
                </UploadContainer>
              );
            }}
          />
          <FormLine>
            <TextFieldRHF
              name="email"
              control={control}
              TextFieldProps={{
                label: 'Email',
                error: Boolean(errors.name),
                helperText: errors.name?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <PhoneInput
              name="phone"
              control={control}
              inputComponent={CustomPhoneInput}
              label="Téléphone"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              defaultCountry="FR"
              isRequired
            />
            <PhoneInput
              name="assistancePhoneNumber"
              control={control}
              inputComponent={CustomPhoneInput}
              label="Téléphone d'assistance"
              error={Boolean(errors.assistancePhoneNumber)}
              helperText={errors.assistancePhoneNumber?.message}
              defaultCountry="FR"
              isRequired
            />
          </FormLine>
          <TextFieldRHF
            name="fax"
            control={control}
            TextFieldProps={{
              label: 'Fax',
              error: Boolean(errors.name),
              helperText: errors.name?.message,
            }}
          />
          <FormLine>
            <TextFieldRHF
              name="address.address"
              control={control}
              TextFieldProps={{
                label: 'Adresse*',
                error: Boolean(errors.address?.address),
                helperText: errors.address?.address?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="address.city"
              control={control}
              TextFieldProps={{
                label: 'Ville*',
                error: Boolean(errors.address?.city),
                helperText: errors.address?.city?.message,
              }}
            />
            <TextFieldRHF
              name="address.zipCode"
              control={control}
              TextFieldProps={{
                label: 'Code Postal*',
                error: Boolean(errors.address?.zipCode),
                helperText: errors.address?.zipCode?.message,
              }}
            />
            <TextFieldRHF
              name="address.country"
              control={control}
              TextFieldProps={{
                label: 'Pays*',
                error: Boolean(errors.address?.country),
                helperText: errors.address?.country?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="webSite"
              control={control}
              TextFieldProps={{
                label: 'Site internet',
                error: Boolean(errors.webSite),
                helperText: errors.webSite?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="quoteFooter"
              control={control}
              TextFieldProps={{
                label: 'Informations du devis',
                error: Boolean(errors.webSite),
                helperText: errors.webSite?.message,
                multiline: true,
              }}
            />
          </FormLine>
          <LowerCaseButton variant="contained" type="submit">
            {openedCompany ? 'Modifier la société' : 'Ajouter une société'}
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={toggleAddCompany}>
            Annuler
          </LowerCaseButton>
        </StyledForm>
      </CenteredModal>
      <DeleteWarningModal
        header="SUPPRIMER LA SOCIÉTÉ ?"
        onClose={() => {
          setCompanyToDelete(undefined);
        }}
        open={!!companyToDelete}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default LeaderGranitCompanies;
