import { Box, styled } from '@mui/material';

import AdminNavBar from './AdminNavBar';

interface IPageWithNavBarProps {
  children: React.ReactNode;
}

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: '#F5F5F5',
  alignItems: 'center',
});

const Wrapper = styled(Box)({
  padding: '40px 10%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  boxSizing: 'border-box',
});

const AdminPageWithNavBar = ({ children }: IPageWithNavBarProps) => {
  return (
    <Container>
      <AdminNavBar />
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default AdminPageWithNavBar;
