import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import PageWithNavBar from '../components/PageWithNavBar';
import { useUser } from '../context/UserProvider';
import paths from '../navigation/paths';
import { isHeadOffice } from '../utils/user.utils';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  gap: '20px',
  width: '100%',
  height: '100%',
});

type ButtonProps = {
  large?: number;
};

const StyledButton = styled(Button)<ButtonProps>(({ large }) => ({
  display: 'flex',
  gridRow: large ? '1 / span 2' : 'auto / span 1',
  flexDirection: large ? 'column' : 'row',
  textTransform: 'unset',
  textAlign: 'left',
  position: 'relative',
  padding: '20px 20px',
}));

const Illustrations = styled('img')({
  height: '90%',
  maxWidth: '40%',
});

const TextWrapper = styled(Box)({
  justifyContent: 'left',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '40px',
  padding: '0 30px',
  gap: '10px',
});

const FixedBox = styled(Box)({
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: '30px',
  height: '40px',
  width: '40px',
  borderRadius: '6px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const Home = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const waitingValidation = useMemo(() => {
    return user?.status === 'PENDING';
  }, [user?.status]);

  return (
    <PageWithNavBar>
      <Wrapper>
        <StyledButton
          variant="contained"
          large={+!isHeadOffice(user)}
          onClick={() => navigate(paths.home.project.root)}
          disabled={waitingValidation}
        >
          <Illustrations
            src="/icons/newProject.svg"
            alt="newProject"
            sx={{ height: '70%', maxWidth: isHeadOffice(user) ? '40%' : '100%' }}
          />
          <TextWrapper>
            <Typography variant="h2">Nouveau Projet</Typography>
            <Typography>
              Personnalisez votre monument avec une simulation 3D et obtenez un devis en temps réel
            </Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        {isHeadOffice(user) && (
          <StyledButton variant="contained" onClick={() => navigate(paths.home.agencies)}>
            <Illustrations src="/icons/agence.svg" alt="agence" />
            <TextWrapper>
              <Typography variant="h2">Mes agences</Typography>
              <Typography>Gérer vos agences : Devis, commandes et leur paramètres</Typography>
            </TextWrapper>
            <FixedBox>
              <ArrowForwardIosIcon color="primary" />
            </FixedBox>
          </StyledButton>
        )}
        <StyledButton variant="contained" onClick={() => navigate(paths.home['quote&orders'])}>
          <Illustrations src="/icons/taxIllustration.svg" alt="taxIllustration" />
          <TextWrapper>
            <Typography variant="h2">Devis & commandes</Typography>
            <Typography>Gérer vos devis et commandes effectuées</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        <StyledButton variant="contained" onClick={() => navigate(paths.home.mySpace)}>
          <Illustrations src="/icons/organizational.svg" alt="organizational" />
          <TextWrapper>
            <Typography variant="h2">Mon espace</Typography>
            <Typography>Gérer les paramètres de vos devis et travaux supplémentaires</Typography>
          </TextWrapper>
          <FixedBox>
            <ArrowForwardIosIcon color="primary" />
          </FixedBox>
        </StyledButton>
        {/* <StyledButton disabled variant="contained" sx={{ gridColumn: '1 / span 2' }}>
          <Typography variant="h2">ACTUALITÉS</Typography>
        </StyledButton> */}
      </Wrapper>
    </PageWithNavBar>
  );
};

export default Home;
