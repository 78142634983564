/* eslint-disable unicorn/no-negated-condition */
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BrushIcon from '@mui/icons-material/Brush';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUser } from 'src/context/UserProvider';
import { Assembly as AssemblyType } from 'src/services/assemblies';
import { Constraint, Modification } from 'src/services/configurator';
import { Granit } from 'src/services/granits';
import { Piece } from 'src/services/pieces';
import styled from 'styled-components';
import { WebGLRenderer } from 'three';

import { StateNames, States } from '../../../Screens/Configurator';
import { QuoteStep } from '../../../Screens/project/components/NavBar';
import { isAdmin } from '../../../utils/user.utils';
import DimensionalSettingsIcon from '../../icons/DimensionalSettingsIcon';
import FileSettingsIcon from '../../icons/FileSettingsIcon';
import SettingIcon from '../../icons/SettingIcon';
import { Settings } from '../Fragments/3dIndex/Settings';
import { Modal } from '../Fragments/Modal';
import { Admin } from './Admin';
import { Assembly } from './Customization/Assembly';
import { Dimension } from './Customization/Dimension';
import { Pieces } from './Customization/Pieces';
import { Textures } from './Customization/Textures';
import { PatternsEngraving } from './PatternsEngravings/PatternsEngraving';

type InterfaceType = {
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (selectedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  constraints: Constraint[] | undefined;
  changePoint: (
    newCoords: {
      firstPoint: { x: number; z: number; y: number };
      secondPoint: { x: number; z: number; y: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => void;
  modifications: Modification[] | undefined;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  refreshConstraints: () => void;
  refreshModifications: () => void;
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  geometry: number[];
  setActivePieces: (pieces: Piece[]) => void;
  pieces: any[];
  activePieces: Piece[];
  customSteles: any[];
  setCustomSteles: (customSteles: any[]) => void;
  checkCollision: (pieceIndexToCheck: number, axe: 'x' | 'y' | 'z') => void;
  assembly: AssemblyType | null;
  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];
  removeOnePiece: (pieceIndexToRemove: number) => void;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
  findPointOnTheSameAxe: (axe: 'x' | 'y' | 'z') => void;
  findPointAround: () => void;
  movePieceOnAxe: (axe: 'x' | 'y' | 'z') => void;
  orbitRef: React.MutableRefObject<any>;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  step: QuoteStep;
  reloadPiece: boolean;
  quote: boolean;
  granits: Granit[];
  setReloadPiece: (reloadPiece: boolean) => void;
  gl: WebGLRenderer | undefined;
  centerCamera: () => void;
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  selectedPattern: number | undefined;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setOrientation: (orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean }) => void;
  selectedPage: string;
  setSelectedPage: (selectedPage: string) => void;
  isLoading: boolean;
};

export const Interface = ({
  selectedPoints,
  setSelectedPoints,
  constraints,
  changePoint,
  modifications,
  history,
  refreshConstraints,
  refreshModifications,
  pieceIndex,
  setPieceIndex,
  changeGeometry,
  geometry,
  setActivePieces,
  pieces,
  activePieces,
  customSteles,
  setCustomSteles,
  checkCollision,
  assembly,
  setCustomPlatings,
  customPlatings,
  removeOnePiece,
  setValue,
  getValue,
  findPointOnTheSameAxe,
  findPointAround,
  movePieceOnAxe,
  orbitRef,
  handleNextStep,
  handlePrevStep,
  step,
  reloadPiece,
  quote,
  granits,
  setReloadPiece,
  centerCamera,
  gl,
  setPatterns,
  patterns,
  selectedPattern,
  setSelectedPattern,
  orientation,
  setOrientation,
  selectedPage,
  setSelectedPage,
  isLoading,
}: InterfaceType) => {
  const { user } = useUser();

  const [modalName, setModalName] = useState<'dimension' | 'settings' | 'fileSettings' | 'close'>(
    'close',
  );
  const [option, setOption] = useState<number>(0);

  const bodyPages = [
    {
      key: 'pieces',
      label: 'Pièces',
      step: ['CUSTOMIZATION', 'ACCESSORIES'],
      icon: ViewQuiltIcon,
    },
    {
      key: 'dimension',
      label: 'Dimensions',
      step: ['CUSTOMIZATION', 'ACCESSORIES'],
      icon: SquareFootIcon,
    },
    {
      key: 'assembly',
      label: 'Assemblage',
      step: ['CUSTOMIZATION', 'ACCESSORIES'],
      icon: SquareFootIcon,
    },
    {
      key: 'textures',
      label: 'Granits',
      step: ['CUSTOMIZATION', 'ACCESSORIES'],
      icon: BrushIcon,
    },
    {
      key: 'patterns',
      label: 'Motifs',
      step: ['PATTERNS'],
      icon: LocalFloristIcon,
    },
    {
      key: 'engravings',
      label: 'Gravures',
      step: ['PATTERNS'],
      icon: HistoryEduIcon,
    },
    {
      key: 'admin',
      label: 'Admin',
      icon: AdminPanelSettingsIcon,
    },
  ];

  useEffect(() => {
    if (
      ((!isAdmin(user) && step === 'CUSTOMIZATION') || step === 'ACCESSORIES') &&
      (selectedPage === 'patterns' || selectedPage === 'engravings')
    ) {
      setSelectedPage('pieces');
    }
    if (step === 'PATTERNS' && selectedPage !== 'patterns' && selectedPage !== 'engravings') {
      setSelectedPage('patterns');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <Container>
      <div>
        <Modal isOpen={modalName !== 'close'} onClose={() => setModalName('close')}>
          {modalName === 'settings' ? (
            <Settings
              pieceIndex={pieceIndex}
              setValue={setValue}
              getValue={getValue}
              findPointOnTheSameAxe={findPointOnTheSameAxe}
              setSelectedPoints={setSelectedPoints}
              findPointAround={findPointAround}
              removeOnePiece={removeOnePiece}
              movePieceOnAxe={movePieceOnAxe}
              pieces={pieces}
              orbitRef={orbitRef}
            />
          ) : null}
        </Modal>
        <SettingsContainer>
          <BorderedIconContainer
            onClick={() => {
              setModalName('settings');
            }}
          >
            <SettingIcon color="primary" />
          </BorderedIconContainer>
        </SettingsContainer>
        {user?.role === 'ADMIN' ? (
          <Tabs
            value={option}
            scrollButtons
            variant="scrollable"
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setOption(newValue);
              setSelectedPage(bodyPages[newValue].key);
              if (bodyPages[newValue].key === 'textures') setValue('texture', true);
            }}
          >
            {bodyPages.map((el, elIndex) => {
              if (el.key === 'admin' && !isAdmin(user)) return null;

              if (el.key === 'dimension' && activePieces[pieceIndex]?.blockedAxis.length === 3) {
                if (selectedPage === 'dimension') setSelectedPage('pieces');
                return null;
              }
              return <Tab key={el.key} label={el.label} />;
            })}
          </Tabs>
        ) : (
          <BodyPagesContainer>
            {bodyPages.map((el) => {
              if (el.step && !el.step.includes(step)) return null;
              if (el.key === 'admin' && !isAdmin(user)) return null;

              if (el.key === 'dimension' && activePieces[pieceIndex]?.blockedAxis.length === 3) {
                if (selectedPage === 'dimension') setSelectedPage('pieces');
                return null;
              }
              return (
                <BodyPagesContainerName
                  key={el.key}
                  isActive={selectedPage === el.key}
                  onClick={() => {
                    setSelectedPage(el.key);
                    if (el.key === 'textures') setValue('texture', true);
                  }}
                >
                  <el.icon
                    style={{
                      color: selectedPage === el.key ? 'black' : '#98A2B3',
                      marginRight: 5,
                      width: 16,
                      height: 16,
                    }}
                  />
                  <Typography variant="h5" color={selectedPage === el.key ? 'black' : '#98A2B3'}>
                    {el.label}
                  </Typography>
                </BodyPagesContainerName>
              );
            })}
          </BodyPagesContainer>
        )}
      </div>
      <div style={{ height: '100%' }}>
        {(isAdmin(user) && selectedPage !== 'patterns' && selectedPage !== 'engravings') ||
        (!isAdmin(user) && (step === 'CUSTOMIZATION' || step === 'ACCESSORIES')) ? (
          <Body>
            {selectedPage === 'pieces' ? (
              <Pieces
                activePieces={activePieces}
                setActivePieces={setActivePieces}
                customSteles={customSteles}
                setCustomSteles={setCustomSteles}
                pieceIndex={pieceIndex}
                setPieceIndex={setPieceIndex}
                setSelectedPage={setSelectedPage}
                removeOnePiece={removeOnePiece}
                pieces={pieces}
                setCustomPlatings={setCustomPlatings}
                customPlatings={customPlatings}
                granits={granits}
                step={step}
                setValue={setValue}
                setPatterns={setPatterns}
                patterns={patterns}
              />
            ) : null}

            {selectedPage === 'dimension' ? (
              <Dimension
                setValue={setValue}
                getValue={getValue}
                activePieces={activePieces}
                customSteles={customSteles}
                pieceIndex={pieceIndex}
                setCustomSteles={setCustomSteles}
                changeGeometry={changeGeometry}
                geometry={geometry}
                checkCollision={checkCollision}
                pieces={pieces}
                assembly={assembly}
                setCustomPlatings={setCustomPlatings}
                customPlatings={customPlatings}
                selectedPoints={selectedPoints}
                setSelectedPoints={setSelectedPoints}
                changePoint={changePoint}
                modifications={modifications}
                history={history}
                refreshModifications={refreshModifications}
                reloadPiece={reloadPiece}
              />
            ) : null}
            {selectedPage === 'assembly' ? (
              <Assembly
                activePieces={activePieces}
                pieceIndex={pieceIndex}
                customSteles={customSteles}
                pieces={pieces}
                setPieceIndex={setPieceIndex}
                setReloadPiece={setReloadPiece}
                customPlatings={customPlatings}
                setValue={setValue}
                getValue={getValue}
                setCustomPlatings={setCustomPlatings}
              />
            ) : null}

            {selectedPage === 'textures' ? (
              <Textures
                activePieces={activePieces}
                setActivePieces={setActivePieces}
                pieceIndex={pieceIndex}
                customSteles={customSteles}
                setCustomSteles={setCustomSteles}
                customPlatings={customPlatings}
                setCustomPlatings={setCustomPlatings}
                setPieceIndex={setPieceIndex}
                getValue={getValue}
                pieces={pieces}
                setValue={setValue}
              />
            ) : null}

            {selectedPage === 'admin' && isAdmin(user) ? (
              <Admin
                gl={gl}
                pieces={pieces}
                selectedPoints={selectedPoints}
                setSelectedPoints={setSelectedPoints}
                constraints={constraints}
                refreshConstraints={refreshConstraints}
                pieceIndex={pieceIndex}
                history={history}
                assembly={assembly}
                customPlatings={customPlatings}
                customSteles={customSteles}
                activePieces={activePieces}
                setValue={setValue}
                setPieceIndex={setPieceIndex}
                centerCamera={centerCamera}
                patterns={patterns}
              />
            ) : null}
          </Body>
        ) : null}
        {step === 'PATTERNS' ||
        (isAdmin(user) && (selectedPage === 'patterns' || selectedPage === 'engravings')) ? (
          <Body>
            <PatternsEngraving
              setPatterns={setPatterns}
              patterns={patterns}
              pieceIndex={pieceIndex}
              pieces={pieces}
              selectedPattern={selectedPattern}
              setSelectedPattern={setSelectedPattern}
              orbitRef={orbitRef}
              centerCamera={centerCamera}
              setValue={setValue}
              gl={gl}
              orientation={orientation}
              setOrientation={setOrientation}
              selectedPage={selectedPage}
              customSteles={customSteles}
              customPlatings={customPlatings}
            />
          </Body>
        ) : null}
      </div>
      {quote && (
        <Buttons>
          <StyledButton
            variant="text"
            onClick={handlePrevStep}
            disabled={
              isLoading || (assembly?.quote?.status !== 'DRAFT' && step === 'CUSTOMIZATION')
            }
          >
            {assembly?.quote?.status !== 'DRAFT' && step === 'CUSTOMIZATION' ? (
              <></>
            ) : (
              <>
                <FixedBox>
                  <ArrowBackIcon style={{ color: '#475467' }} />
                </FixedBox>
                <Typography
                  variant="body1"
                  style={{ fontSize: '12px', textTransform: 'capitalize', color: '#475467' }}
                >
                  Etape précédente
                </Typography>
              </>
            )}
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              if (!isLoading) handleNextStep();
            }}
          >
            {isLoading ? (
              <CircularProgress size={12} color="inherit" />
            ) : (
              <>
                <Typography
                  variant="body1"
                  style={{ fontSize: '12px', textTransform: 'capitalize' }}
                >
                  Etape suivante
                </Typography>
                <FixedBox>
                  <ArrowForwardIcon color="inherit" />
                </FixedBox>
              </>
            )}
          </StyledButton>
        </Buttons>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  height: '100%',
  width: '100%',
});

const SettingsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',

  width: '100%',
  height: '85px',

  padding: '27px',
  gap: '16px',
});

const BorderedIconContainer = styled(Box)(({ theme }) => ({
  border: `1px solid #548797`,
  borderRadius: '100%',
  padding: '5px',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const Body = styled(Box)({
  position: 'relative',
  height: '100%',
});

const BodyPagesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  overflowX: 'auto',
});

const BodyPagesContainerName = styled('div')(({ isActive }: { isActive: boolean }) => ({
  backgroundColor: isActive ? '#F7F0E7' : 'white',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',

  cursor: 'pointer',
  borderBottom: `4px solid ${isActive ? '#BC9A67' : '#F2F4F7'}`,
}));

const Buttons = styled(Box)({
  width: '100%',
  height: '85px',
  backgroundColor: '#F2F4F7',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});

type ButtonProps = {
  color?: boolean;
};

const StyledButton = styled(Button)<ButtonProps>(({ color }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '12px',

  width: '190px',
  height: '40px',
}));

const FixedBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});
