import { api } from './api';
import { Fabrication } from './assemblies';

export type DeliveryTime = {
  fabrication: Fabrication;
  deliveryTime: number;
};

export const getDeliveryTimes = async () => {
  const { data } = await api.get<DeliveryTime[]>(`delivery-time`);

  return data;
};

export const updateDeliveryTimes = async (deliveryTimes: DeliveryTime[]) => {
  return api.put(`/delivery-time`, deliveryTimes);
};
