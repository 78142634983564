import { Navigate, useLocation } from 'react-router-dom';

const BackLink = () => {
  const { pathname } = useLocation();
  const newPath = pathname.split('/').slice(0, -1).join('/');

  return <Navigate to={newPath} replace />;
};

export default BackLink;
