import { Add, Delete, Edit, Euro, Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  styled,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import useLoadData from 'src/hook/useLoadData';
import { getCompany } from 'src/services/user';
import { useUser } from 'src/context/UserProvider';
import { CenteredModal, ModalTitle } from '../../../../components/CenteredModal';
import { ButtonRow, FormLine, LowerCaseButton, RoundedButton } from '../../../../components/common';
import { CustomPagination } from '../../../../components/CustomPagination';
import { CustomDataGrid } from '../../../../components/DataGrid';
import DeleteWarningModal from '../../../../components/DeleteWarningModal';
import CheckboxRHF from '../../../../components/forms/CheckboxRhf';
import { NumberFieldRHF } from '../../../../components/forms/NumberInput';
import SwitchRHF from '../../../../components/forms/SwitchRhf';
import { TextFieldRHF } from '../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../context/EnvProvider';
import useAdditionalServices, { PATTERN_PAGE_SIZE } from '../../../../hook/useAdditionalServices';
import useToggle from '../../../../hook/useToggle';
import { Buckets } from '../../../../services/file';
import { uploadImage } from '../../../../utils/file.utils';

const Container = styled(Box)({
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
  padding: '30px',
});

const PatternContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  border: `0.5px solid #EAECF0`,
  borderRadius: '8px',
}));

const Pattern = styled(Box)({
  border: `0.5px solid #EAECF0`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
});

const StyledCheckBox = styled(Checkbox)({
  position: 'absolute',
  left: 0,
  top: 0,
});

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '104px',
  backgroundColor: 'rgba(245, 245, 245, 0.38)',
});

const Image = styled('img')({
  width: '100%',
});

const StyledForm = styled('form')({
  padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const UploadContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.black,
})) as typeof Button;

const AdditionalServices = ({
  userId,
  isBeingEdited = false,
}: {
  userId: string;
  isBeingEdited?: boolean;
}) => {
  const {
    additionalServicesColumns,
    additionalServices,
    patterns,
    patternPage,
    setPatternPage,
    pageCount,
    addService,
    toggleService,
    addPattern,
    togglePattern,
    openedPattern,
    setOpenedPattern,
    openedService,
    setOpenedServices,
    additionalServicesMethods,
    patternMethods,
    submitAdditionalService,
    submitPattern,
    handleSelectPattern,
    selectedPatterns,
    selectionModel,
    setSelectionModel,
    handleDeleteAdditionalServices,
    handleDeletePatterns,
    setImageRatio,
  } = useAdditionalServices(userId);
  const [openDeleteAdditionalServices, toggleOpenDeleteAdditionalServices] = useToggle();
  const [openDeletePattern, toggleOpenDeletePattern] = useToggle();

  const { user } = useUser();

  const { buckets } = useEnv();
  const {
    control: controlService,
    formState: { errors: errorsService },
    handleSubmit: handleSubmitService,
    watch: watchService,
  } = additionalServicesMethods;

  const {
    control: controlPattern,
    formState: { errors: errorsPattern },
    handleSubmit: handleSubmitPatterns,
    watch: watchPattern,
  } = patternMethods;

  const uploadPatternImage = async (file: File) => {
    const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);
    return pictureName ?? '';
  };

  const { data, onRefresh, refreshing } = useLoadData(() => getCompany(userId));

  if (refreshing) return <CircularProgress />;

  if (user?.role !== 'ADMIN' && data && data.canEditAdditionalService === false && !isBeingEdited) {
    return (
      <StyledForm>
        <Typography variant="h5">Vous ne pouvez pas éditer cette page</Typography>
      </StyledForm>
    );
  }

  return (
    <>
      <Container>
        <Typography variant="h5">SERVICES SUPPLÉMENTAIRES</Typography>
        <ButtonRow>
          <RoundedButton
            startIcon={<Add color="primary" />}
            color="primary"
            variant="outlined"
            onClick={toggleService}
          >
            Ajouter un service
          </RoundedButton>
          <RoundedButton
            startIcon={<Delete color={selectionModel.length === 0 ? 'inherit' : 'error'} />}
            color="error"
            variant="outlined"
            onClick={toggleOpenDeleteAdditionalServices}
            disabled={selectionModel.length === 0}
          >
            Supprimer
          </RoundedButton>
        </ButtonRow>
        <CustomDataGrid
          rows={additionalServices ?? []}
          columns={additionalServicesColumns}
          checkboxSelection
          pageSize={6}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          autoHeight
        />
        <Box component="div">
          <Typography variant="h5">BIBLIOTHÈQUE DE MOTIFS</Typography>
          <Typography>Vous pouvez ajouter vos propres motifs et définir leurs coûts.</Typography>
        </Box>
        <ButtonRow>
          <RoundedButton
            startIcon={<Add color="primary" />}
            color="primary"
            variant="outlined"
            onClick={togglePattern}
          >
            Ajouter un motif
          </RoundedButton>
          <RoundedButton
            startIcon={<Edit color={selectedPatterns.length === 1 ? 'primary' : 'inherit'} />}
            color="primary"
            variant="outlined"
            disabled={selectedPatterns.length !== 1}
            onClick={() => {
              togglePattern();
              setOpenedPattern(patterns?.find((pattern) => pattern.id === selectedPatterns[0]));
            }}
          >
            Modifier
          </RoundedButton>
          <RoundedButton
            startIcon={<Delete color={selectedPatterns.length === 0 ? 'inherit' : 'error'} />}
            color="error"
            variant="outlined"
            onClick={toggleOpenDeletePattern}
            disabled={selectedPatterns.length === 0}
          >
            Supprimer
          </RoundedButton>
        </ButtonRow>
        <PatternContainer>
          {patterns && patterns.length > 0 ? (
            <Grid container component="div" sx={{ padding: '16px' }} spacing={2}>
              {patterns
                .slice(
                  patternPage * PATTERN_PAGE_SIZE,
                  patternPage * PATTERN_PAGE_SIZE + PATTERN_PAGE_SIZE,
                )
                .map((pattern) => (
                  <Grid item xs={2} xl={1} key={pattern.id}>
                    <Pattern>
                      <StyledCheckBox
                        color="primary"
                        onClick={() => handleSelectPattern(pattern.id)}
                      />
                      <Image
                        src={`${buckets?.BUCKET_3D_PIECES || ''}/${pattern.image}`}
                        alt="pattern"
                      />
                      <Typography>{pattern.name}</Typography>
                      <Typography>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(pattern.price)}
                      </Typography>
                    </Pattern>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Wrapper>
              <Typography variant="body2">Pas de résultats</Typography>
            </Wrapper>
          )}
          <CustomPagination
            page={patternPage}
            pageCount={pageCount}
            handleChangePage={setPatternPage}
          />
        </PatternContainer>
      </Container>
      <CenteredModal
        open={addService}
        handleClose={() => {
          toggleService();
          setOpenedServices(undefined);
        }}
      >
        <ModalTitle>
          <Typography variant="h5">SERVICES SUPPLÉMENTAIRES</Typography>
        </ModalTitle>
        <StyledForm onSubmit={handleSubmitService(submitAdditionalService)}>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={controlService}
              TextFieldProps={{
                label: 'Nom du service',
                error: Boolean(errorsService.name),
                helperText: errorsService.name?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <NumberFieldRHF
              name="price"
              control={controlService}
              TextFieldProps={{
                label: 'Prix',
                error: Boolean(errorsService.price),
                helperText: errorsService.price?.message,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro />
                    </InputAdornment>
                  ),
                },
              }}
              caption="(Visiogranits n’interviendra pas cette prestation, prix TTC)"
            />
            <FormControlLabel
              control={
                <SwitchRHF
                  name="active"
                  control={controlService}
                  switchProps={{
                    defaultChecked: openedService?.active,
                  }}
                />
              }
              label={watchService('active') ? 'Actif' : 'Inactif'}
              sx={{
                padding: '0 16px',
                border: '1px solid #F2F4F7',
                borderRadius: '10px',
                flex: '0.5',
                height: '56px',
                margin: 0,
              }}
            />
          </FormLine>
          <LowerCaseButton variant="contained" type="submit">
            {openedService ? 'Modifier le service' : 'Ajouter un service'}
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={toggleService}>
            Annuler
          </LowerCaseButton>
        </StyledForm>
      </CenteredModal>
      <CenteredModal
        open={addPattern}
        handleClose={() => {
          togglePattern();
          setOpenedPattern(undefined);
        }}
      >
        <ModalTitle>
          <Typography variant="h5">BIBLIOTHÈQUE DE MOTIFS</Typography>
        </ModalTitle>
        <StyledForm onSubmit={handleSubmitPatterns(submitPattern)}>
          <FormLine>
            <TextFieldRHF
              name="name"
              control={controlPattern}
              TextFieldProps={{
                label: 'Nom du motif',
                error: Boolean(errorsPattern.name),
                helperText: errorsPattern.name?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <TextFieldRHF
              name="description"
              control={controlPattern}
              TextFieldProps={{
                label: 'Description',
                error: Boolean(errorsPattern.description),
                helperText: errorsPattern.description?.message,
              }}
            />
          </FormLine>
          <FormLine>
            <NumberFieldRHF
              name="price"
              control={controlPattern}
              TextFieldProps={{
                label: 'Prix',
                error: Boolean(errorsPattern.price),
                helperText: errorsPattern.price?.message,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <NumberFieldRHF
              name="height"
              control={controlPattern}
              TextFieldProps={{
                label: 'Hauteur par default',
                error: Boolean(errorsPattern.height),
                helperText: errorsPattern.height?.message,
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              }}
            />
          </FormLine>
          <FormLine>
            <FormControlLabel
              control={<CheckboxRHF name="leaderGranitEngraving" control={controlPattern} />}
              label={
                <>
                  <Typography> Faire réaliser la gravure par VisioGranits</Typography>{' '}
                  <Typography variant="caption">
                    (VisioGranits se réserve le droit de valider le motif avant d’éventuellement
                    l’effectuer.)
                  </Typography>
                </>
              }
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
              }}
            />
          </FormLine>
          <Controller
            name="image"
            control={controlPattern}
            render={({ field }) => {
              const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
                if (e.target.files) {
                  const fileKey = await uploadPatternImage(e.target.files[0]);
                  field.onChange(fileKey);
                }
              };

              return (
                <UploadContainer component="label">
                  {watchPattern('image') ? (
                    <Image
                      onLoad={(e) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setImageRatio(e.target.width / e.target.height);
                      }}
                      src={`${buckets?.BUCKET_3D_PIECES || ''}/${watchPattern('image')}`}
                      alt="pattern"
                    />
                  ) : (
                    <>
                      <Upload />
                      <Typography variant="body2">Cliquer pour ajouter une image</Typography>
                      <Typography variant="body2">PNG, JPG, PDF...</Typography>
                    </>
                  )}
                  <Input type="file" sx={{ display: 'none' }} onChange={onChange} />
                </UploadContainer>
              );
            }}
          />
          <Typography variant="caption">
            Nous déclinons toute responsabilité sur les images que vous allez uploader.
          </Typography>
          <LowerCaseButton variant="contained" type="submit">
            {openedPattern ? 'Modifier le motif' : 'Ajouter un motif'}
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={togglePattern}>
            Annuler
          </LowerCaseButton>
        </StyledForm>
      </CenteredModal>
      <DeleteWarningModal
        header="SUPPRIMER LES SERVICES SÉLECTIONNÉS ?"
        handleDelete={handleDeleteAdditionalServices}
        open={openDeleteAdditionalServices}
        onClose={toggleOpenDeleteAdditionalServices}
      />
      <DeleteWarningModal
        header="SUPPRIMER LES MOTIFS SÉLECTIONNÉS ?"
        handleDelete={handleDeletePatterns}
        open={openDeletePattern}
        onClose={toggleOpenDeletePattern}
      />
    </>
  );
};

export default AdditionalServices;
