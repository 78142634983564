import { Typography } from '@mui/material';

import { INavigationTabsProps } from '../../../components/PageWithTabs';
import { NavigationTabs } from '../../../components/Tabs';
import AdminPageWithNavBar from './AdminPageWithNavBar';

const AdminPageWithTabs = <T extends string>({ pageName, ...props }: INavigationTabsProps<T>) => {
  return (
    <AdminPageWithNavBar>
      <Typography variant="h1" sx={{ flex: 0, display: 'flex', alignItems: 'center' }}>
        {pageName}
      </Typography>
      <NavigationTabs {...props} />
    </AdminPageWithNavBar>
  );
};

export default AdminPageWithTabs;
