import { api } from './api';

export type AdditionalService = {
  id: string;
  name: string;
  price: number;
  active: boolean;
};

export const getAdditionalServices = async (userId: string) => {
  const { data } = await api.get<AdditionalService[]>(`/additional-services/${userId}`);

  return data;
};

export const createAdditionalServices = async (
  datas: Omit<AdditionalService, 'id'>,
  userId: string,
) => {
  return api.post<void>(`/additional-services/${userId}`, datas);
};

export const updateAdditionalServices = async (datas: AdditionalService) => {
  return api.patch<void>('/additional-services', datas);
};

export const deleteAdditionalServices = async (datas: string[]) => {
  return api.delete<void>('/additional-services', { params: { ids: datas } });
};
