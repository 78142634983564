import { CategoryType } from 'src/constants/category';
import { getUnderAssemblies } from 'src/services/assemblies';

import useLoadDataList from './useLoadDataList';

export const useUnderAssemblies = (categorieKey: CategoryType | undefined) => {
  const { data: underAssemblies, onRefresh: refreshUnderAssemblies } = useLoadDataList(
    () => getUnderAssemblies(categorieKey),
    [categorieKey],
  );

  return { underAssemblies, refreshUnderAssemblies };
};
