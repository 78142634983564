/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unknown-property */
import { Edges, MeshReflectorMaterial, TransformControls } from '@react-three/drei';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Euler, Vector3 } from 'three';

import { StateNames, States } from '../../Screens/Configurator';

type PlatingProps = {
  customPlating: {
    id: string;
    key: string;
    shape: any;
    shapeDetails: any;
    position: Vector3;
    texture: THREE.Texture;
    isBlocked: boolean;
  };
  orbitRef: React.MutableRefObject<any>;
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;
  index: number;
  setCustomPlatings: (customPlatings: any[]) => void;
  customPlatings: any[];
  getValue: (stateName: StateNames) => States[StateNames];
  step: string;
};

export const Plating = ({
  customPlating,
  orbitRef,
  pieceIndex,
  setPieceIndex,
  index,
  setCustomPlatings,
  customPlatings,
  getValue,
  step,
}: PlatingProps) => {
  const transformRef = useRef<any>();
  const meshRef = useRef<any>();

  useEffect(() => {
    if (transformRef.current) {
      if (meshRef.current) transformRef.current.attach(meshRef.current);
      const controls = transformRef.current;
      const callback = (event: any) => {
        orbitRef.current.enabled = !event.value;
      };
      controls.addEventListener('dragging-changed', callback);
      return () => controls.removeEventListener('dragging-changed', callback);
    }
  });

  const handleChangePiecePosition = useCallback(() => {
    if (index === pieceIndex) {
      const deltaPosition = new Vector3(
        (meshRef?.current?.position.x ?? 0) - customPlating.position.x,
        (meshRef?.current?.position.y ?? 0) - customPlating.position.y,
        (meshRef?.current?.position.z ?? 0) - customPlating.position.z,
      );

      const customsMeshTmp = customPlatings.map((mesh) => {
        const newPosition = new Vector3(
          +mesh.position.x + deltaPosition.x,
          +mesh.position.y + deltaPosition.y,
          +mesh.position.z + deltaPosition.z,
        );

        return {
          ...mesh,
          position: newPosition,
        };
      });

      setCustomPlatings(customsMeshTmp);
    }
  }, [
    index,
    pieceIndex,
    customPlating.position.x,
    customPlating.position.y,
    customPlating.position.z,
    customPlatings,
    setCustomPlatings,
  ]);

  return (
    <TransformControls
      ref={transformRef}
      mode="translate"
      size={index === pieceIndex && !customPlating.isBlocked ? 1 : 0}
      onMouseUp={() => handleChangePiecePosition()}
    >
      <mesh
        ref={meshRef}
        position={customPlating.position}
        rotation={new Euler(0, customPlating.shapeDetails.isRotated ? 1.57 : 0, 0, 'XYZ')}
        onClick={(e) => {
          if (e.intersections && e.intersections[0].distance === e.distance) {
            setPieceIndex(pieceIndex);
          }
        }}
      >
        <extrudeGeometry
          attach="geometry"
          args={[
            customPlating.shape,
            {
              curveSegments: 12,
              steps: 2,
              depth: customPlating.shapeDetails.depth,
              bevelEnabled: false,
            },
          ]}
        />
        {getValue('texture') ? (
          <meshStandardMaterial map={customPlating.texture} metalness={0.95} roughness={0.15} />
        ) : (
          <>
            <Edges />
            <meshStandardMaterial />
          </>
        )}
        {index === pieceIndex &&
        (step === 'CUSTOMIZATION' || step === 'ACCESSORIES' || step === 'PATTERNS') ? (
          <Edges color="red" threshold={10} />
        ) : null}
      </mesh>
    </TransformControls>
  );
};
