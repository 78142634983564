import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import useLoadData from '../../../../hook/useLoadData';
import { findQuoteSettings, updateQuoteSettings } from '../../../../services/quoteSetting';
import { handleMutation } from '../../../../utils/api.utils';

const schemaQuoteForm = z.object({
  activeDeposit: z.boolean(),
  deposit: z.string(),
  activeSoleInstallation: z.boolean(),
  soleInstallation: z.string(),
  addInstallationToSolePrice: z.boolean(),
  activeMonumentInstallation: z.boolean(),
  monumentInstallation: z.string(),
  activeCineraryInstallation: z.boolean(),
  cineraryInstallation: z.string(),
  activeSpecialInstallation: z.boolean(),
  specialInstallation: z.string(),
  addInstallationToMonumentPrice: z.boolean(),
  activeCustomDelivery: z.boolean(),
  customDelivery: z.string(),
  postageDue: z.boolean(),
  simplifiedQuote: z.boolean(),
  footerText: z.string(),
  displayDelivery: z.boolean(),
});

type SchemaQuoteForm = z.infer<typeof schemaQuoteForm>;

const useQuoteSettings = (userId: string) => {
  const [euroDeposit, setEuroDeposit] = useState(false);
  const { data, refreshing } = useLoadData(() => findQuoteSettings(userId));

  const defaultValues = useMemo(() => {
    const getValue = (value: number | string) => {
      if (refreshing) return '';
      return value;
    };

    return {
      activeDeposit: data?.activeDeposit ?? true,
      deposit: String(data?.deposit ?? getValue(30)),
      activeSoleInstallation: data?.activeSoleInstallation ?? true,
      soleInstallation: String(data?.soleInstallation ?? getValue(200)),
      addInstallationToSolePrice: data?.addInstallationToSolePrice ?? true,
      activeMonumentInstallation: data?.activeMonumentInstallation ?? true,
      monumentInstallation: String(data?.monumentInstallation ?? getValue(700)),
      activeCineraryInstallation: data?.activeCineraryInstallation ?? false,
      cineraryInstallation: String(data?.cineraryInstallation ?? getValue(400)),
      activeSpecialInstallation: data?.activeSpecialInstallation ?? true,
      specialInstallation: String(data?.specialInstallation ?? getValue(1000)),
      addInstallationToMonumentPrice: data?.addInstallationToMonumentPrice ?? true,
      activeCustomDelivery: data?.activeCustomDelivery ?? true,
      customDelivery: String(data?.customDelivery ?? getValue(2)),
      postageDue: data?.postageDue ?? false,
      displayDelivery: data?.displayDelivery ?? false,
      simplifiedQuote: data?.simplifiedQuote ?? false,
      footerText: data?.footerText ?? '',
    };
  }, [data, refreshing]);

  const methods = useForm<SchemaQuoteForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schemaQuoteForm),
  });

  const submit: SubmitHandler<SchemaQuoteForm> = (submitDatas) => {
    handleMutation((datas) => updateQuoteSettings(datas, userId), { ...submitDatas, euroDeposit });
  };

  useEffect(() => methods.reset(defaultValues), [defaultValues, methods]);

  useEffect(() => setEuroDeposit(!!data?.euroDeposit), [data]);

  return { methods, euroDeposit, setEuroDeposit, submit, refreshing };
};

export default useQuoteSettings;
