import { Box, styled } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { SyntheticEvent, useEffect, useState } from 'react';

import { INavigationTabsProps } from './PageWithTabs';

const Tabs = styled(MuiTabs)({
  margin: '0 12px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgba(188, 154, 103, 1)',
    height: '4px',
  },
});

const Tab = styled(MuiTab)({
  minWidth: 50,
  padding: '15px 30px',
  color: 'rgba(102, 112, 133, 1)',
  fontSize: `${16 / 16}rem`,
  textTransform: 'none',
  '&.Mui-selected': {
    color: 'rgba(188, 154, 103, 1)',
  },
});

const Container = styled(Box)({
  backgroundColor: 'white',
  border: '0.5px solid #EAECF0',
  borderRadius: '12px',
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const tabMap: { [value: string]: string } = {
  general: 'Général',
  quote: 'Devis',
  coefficients: 'Coefficients',
  additionalServices: 'Services supplémentaires',
  quotes: 'Devis',
  orders: 'Commandes',
  informations: 'Informations',
  all: 'Tous les comptes',
  agency: 'Comptes agences',
  headOffice: 'Comptes sièges',
  quoteSettings: 'Paramètres des devis',
  agencies: 'Agences',
  companies: 'Sociétés',
  catalogs: 'Catalogues',
  allAssemblies: 'Tous les monuments',
  pending: "En attente d'activation",
  disabled: 'Comptes désactivés',
  toConfirm: 'Devis à valider',
  DELIVERY_QUOTE: 'Achat',
  DELIVERY_ORDER: 'Bon de commande',
  CLIENT_QUOTE: 'Vente',
  CLIENT_DELIVERY_QUOTE: 'Vente',
  COMPANY_QUOTE: 'Achat',
  granits: 'Granits',
  deliveries: 'Tarifs de livraison',
  patterns: 'Motifs',
  engravings: "Prix d'achat des gravures",
} as const;

export const NavigationTabs = <T extends string>({
  tabs,
  tabToComponent,
  tabsSx,
  defaultTab,
}: Omit<INavigationTabsProps<T>, 'pageName'>) => {
  const [currentTab, setCurrentTab] = useState<T>(defaultTab ?? tabs[0]);

  useEffect(() => {
    setCurrentTab(defaultTab ?? tabs[0]);
  }, [tabs, defaultTab]);

  const handleChangeTab = (event: SyntheticEvent, tab: T) => {
    setCurrentTab(tab);
  };

  if (!currentTab || !tabs.includes(currentTab)) return null;

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{ borderBottom: 1, borderColor: 'divider', ...tabsSx }}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab value={tab} label={tabMap[tab] ?? tab} key={tab} />
        ))}
      </Tabs>
      <Container>{tabToComponent(currentTab)}</Container>
    </>
  );
};
